import ThemeContext from "../Context/ThemeContext";
import React, { FC, useEffect, useState } from "react";
import { Theme } from "../Themes/Themes";
import axios, { AxiosError } from "axios";
import { ApplicationJsonConfigHeader, BaseUrl } from "../Api/axios.config";

const ThemeProvider: FC = ({ children }) => {
  const [gender, setCurrGender] = useState<"Male" | "Female" | undefined>();

  useEffect(() => {
    updateTheme();
  }, []);

  const updateTheme = async () => {
    const gender = await getGender();
    setCurrGender(gender);
  };

  return (
    <ThemeContext.Provider
      value={{
        theme:
          gender === "Male"
            ? Theme.blueMode
            : gender === "Female"
            ? Theme.pinkMode
            : undefined,
        getTheme: updateTheme,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

const getGender = async (): Promise<"Male" | "Female"> => {
  try {
    const token: string | null = localStorage.getItem("token");
    const response = await axios.get(BaseUrl + "/authentication/getGender", {
      headers: {
        ...ApplicationJsonConfigHeader,
        authorization: `Bearer ${token}`,
      },
      responseType: "json",
    });
    return response.data.gender;
  } catch (e) {
    const err = e as AxiosError;
    const { error } = err && err.response?.data;
    console.log(error);
    return "Male";
  }
};

export default ThemeProvider;
