import imaages3 from "../../Assets/delete icon.png";

const AddingDeleteIcons = ({ id, deleteItem }: any) => {
  return (
    <span
      className='material-icons-outlined my-2'
      onClick={() => deleteItem(id)}
    >
      delete
    </span>
  );
};
export default AddingDeleteIcons;
