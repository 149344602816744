import { SpinnerLoadingTypes } from '../types/index';

const { SPINNER_LOADING_TYPE } = SpinnerLoadingTypes;

const SpinnerLoadingAction = (data: boolean) => {
    return {
        type: SPINNER_LOADING_TYPE,
        payload: data
    }
}

export { SpinnerLoadingAction };