import { useEffect, useState } from "react";
import MyCourseAPIService from "../../Api/MyCourses/MyCourseAPIService";
import RatingImg from "../../Assets/rating.png";
import NoCourse from "../../Components/Common/NoCourse";
import Menu from "../../Components/Template/Menu";
import MyCourseCard from "../../Components/Template/MyCourseCard";
import Template from "../../Components/Template/Template";
import CartAPIService from "../../Api/Cart/CartAPIService";
import { connect } from "react-redux";
import { CartAction, SpinnerLoadingAction } from "../../redux/action";
import { ErrorMsgToaster } from "../../Components/Utils/Toaster";
import TrendingCourse from "../../Components/Template/TrendingCourse";
import { MyCourseDetailsType } from "../../Utils/types";
import { ThemeProps } from "../../Themes/Themes";
import ThemeContext from "../../Context/ThemeContext";

type CheckboxListType = {
  value: string;
  checked: boolean;
};

const MyCourses = (props: any) => {
  const myCourseAPIService = new MyCourseAPIService();
  const cartAPIService = new CartAPIService();
  const ratingList = ["All", "5", "4", "3", "2", "1"];

  const [courseFilters, setCourseFilters] = useState({
    rating: 0,
    completionPercentage: 0,
  });
  const [sliderVal, setSliderVal] = useState(0);
  const [myCourseData, setMyCourseData] =
    useState<Array<MyCourseDetailsType>>();
  const [ratingFilters, setRatingFilters] = useState<Array<CheckboxListType>>(
    []
  );

  useEffect(() => {
    const data = ratingList.map((rec) => {
      return { value: rec, checked: rec !== "All" ? false : true };
    });
    setRatingFilters(data);
    myCourseAPIService
      .fetchMyCourseDetail(courseFilters)
      .then((data) => setMyCourseData(data?.data))
      .catch((err) => ErrorMsgToaster(err));
    cartAPIService
      .fetchCourseInCartList()
      .then((resData) => {
        if (resData.data) {
          const { cart, totalAmount } = resData.data;
          props.updateCoursesInCart({ cart, totalAmount });
        }
      })
      .catch((err) => ErrorMsgToaster(err));
  }, []);

  useEffect(() => {
    myCourseAPIService
      .fetchMyCourseDetail(courseFilters)
      .then((data) => setMyCourseData(data?.data))
      .catch((err) => ErrorMsgToaster(err));
  }, [courseFilters]);

  const handleRatingChange = (e: any, changedIndex: number) => {
    let ratingData = [...ratingFilters];
    ratingData = ratingData.map((rec) => ({ ...rec, checked: false }));
    ratingData[changedIndex].checked = true;
    setRatingFilters(ratingData);
    const ratingParam = e.target.value === "All" ? 0 : Number(e.target.value);
    setCourseFilters({ ...courseFilters, rating: ratingParam });
  };

  const handleCompletionRange = (e: any) => {
    setSliderVal(e.target.value);
    setCourseFilters({
      ...courseFilters,
      completionPercentage: Number(e.target.value),
    });
  };

  const CourseBody = ({ theme }: { theme: ThemeProps }) => {
    return (
      <>
        <div
          className={`text-2xl -mt-8 font-bold`}
          style={{ color: theme.title }}
        >
          My Courses
        </div>
        <div className={`grid  gap-6 mt-9`}>
          {myCourseData ? (
            myCourseData.map((data) => (
              <MyCourseCard
                myCourseData={data}
                key={data.courseId}
                theme={theme}
              />
            ))
          ) : (
            <NoCourse title="No Course Available" icon="school" />
          )}
        </div>
      </>
    );
  };

  const SubMenu = ({ theme }: { theme: ThemeProps }) => {
    return (
      <>
        <div className={`font-bold text-lg`}>Filter by Rating</div>
        <div className={`pl-4`}>
          {ratingFilters.map((rec: CheckboxListType, i: number) => {
            return (
              <div className={`flex mt-3`}>
                <input
                  className={`transform mt-2 scale-x-150 scale-y-150 `}
                  key={rec.value}
                  type="checkbox"
                  name="starRating"
                  value={rec.value}
                  checked={rec.checked}
                  onChange={(e) => handleRatingChange(e, i)}
                />
                <label className={`ml-4 flex text-lg`} htmlFor={rec.value}>
                  {rec.value}
                  {rec.value !== "All" && (
                    <img
                      className={`h-6 w-6 mt-1/2 ml-2`}
                      src={RatingImg}
                      alt={`Ratting`}
                    />
                  )}
                </label>
              </div>
            );
          })}
        </div>
        <div className={`font-bold text-lg my-9`}>
          Filter by Completion Percentage
          <div className={`flex flex-row text-lg font-normal mt-4`}>
            <input
              className={`px-4 ml-2 mr-4 w-8/12`}
              type="range"
              min={0}
              max={100}
              step={10}
              value={sliderVal}
              onChange={(e) => handleCompletionRange(e)}
            />
            {Number(sliderVal) > 0 && Number(sliderVal) !== 100 ? (
              <h4>{sliderVal} Percentage & below</h4>
            ) : (
              <h4>All</h4>
            )}
          </div>
        </div>
        <div className={`pl-4`}></div>

        <div className={`font-bold text-2xl mt-9 mb-6`}>Trending this Week</div>
        <TrendingCourse theme={theme} />
      </>
    );
  };

  const menu = <Menu selectedItem={`MY COURSES`} />;
  return (
    <ThemeContext.Consumer>
      {(context) => {
        const theme: ThemeProps = context.theme!;
        return (
          <Template
            menu={menu}
            body={<CourseBody theme={theme} />}
            submenu={<SubMenu theme={theme} />}
          />
        );
      }}
    </ThemeContext.Consumer>
  );
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setIsLoading: (isLoading: boolean) => {
      dispatch(SpinnerLoadingAction(isLoading));
    },
    updateCoursesInCart: (data: any) => {
      dispatch(CartAction(data));
    },
  };
};

export default connect(null, mapDispatchToProps)(MyCourses);
