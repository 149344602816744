import { useEffect, useState } from "react";
import ThemeContext from "../../Context/ThemeContext";
import { getTopSellingCourse } from "../../Screens/NewCourse/API/NewCourse";
import NoCourse from "./NoCourse";

const SubmenuTrainer = () => {
  const [course, setCourse] = useState<any>({});
  useEffect(() => {
    getTopSellingCourse().then((data) => {
      if (data.status) setCourse(data.data);
    });
  }, []);
  return (
    <ThemeContext.Consumer>
      {(context) => {
        return (
          <>
            <div className="text-black text-2xl font-extrabold font-Lato pb-4">
              Your Top Selling Courses
            </div>
            {course?.title ? (
              <>
                <div className=" bg-gray-500 border-solid border-2 border-gray-500 rounded-md h-72 w-68 font-Lato ">
                  <img
                    src={course.image}
                    className={`w-full h-full object-cover`}
                  />
                </div>
                <div className=" bg-white border-solid border-2 border-gray-500 rounded-b-md h-auto w-auto font-Lato  -mt-2">
                  <div
                    className=" text-2xl font-bold pl-2 pt-4"
                    style={{ color: context.theme?.title }}
                  >
                    {course?.title}
                  </div>
                  <div className="text-gray-700 text-xs md:text-sm pl-2 pt-4 max-h-20 text-clip overflow-hidden">
                    {course.description}
                  </div>
                  <br></br>
                  <div className="text-gray-700 text-sm pl-2">
                    {course.enrolled} Enrolled
                    <br></br>
                    <p className="text-xs mr-24 py-4">
                      {course.rating > 0
                        ? `${course.rating} Rated`
                        : "No Rating yet"}
                    </p>
                  </div>
                </div>
              </>
            ) : (
              <NoCourse title="No Course" />
            )}
          </>
        );
      }}
    </ThemeContext.Consumer>
  );
};

export default SubmenuTrainer;
