import NoCourse from "../../Components/Common/NoCourse";
import { DashEnroll, DashReview, TrainerDashboard } from "../../Utils/types";
import DashSubmenuComponent from "./DashSubmenuComponent";
import NamesDashsubmenu from "./NamesDashSubmenu";

const DashboardSubmenu = ({
  dashboardData,
}: {
  dashboardData: TrainerDashboard;
}) => {
  return (
    <>
      <div className='text-black text-lg md:text-xl lg:text-2xl font-bold font-Lato mt-7'>
        Recent Enrollment
      </div>
      {dashboardData?.reccentEnrollments?.map((enrollData: DashEnroll) => (
        <DashSubmenuComponent enrollData={enrollData} />
      ))}
      {(!dashboardData ||
        (dashboardData.reccentEnrollments &&
          dashboardData.reccentEnrollments.length === 0)) && (
        <div className={`mt-12`}>
          <NoCourse title='No Recent Enrollments' icon='people' />
        </div>
      )}
      <div className='text-black text-lg md:text-xl lg:text-2xl font-bold font-Lato mt-7'>
        Recent Reviews
      </div>
      {dashboardData?.recentReviews?.map((recentReviews: DashReview) => (
        <NamesDashsubmenu recentReviews={recentReviews} />
      ))}
      {(!dashboardData ||
        (dashboardData.recentReviews &&
          dashboardData.recentReviews.length === 0)) && (
        <div className={`mt-12`}>
          <NoCourse title='No Recent Reveiws' icon='reviews' />
        </div>
      )}
    </>
  );
};
export default DashboardSubmenu;
