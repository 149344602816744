import { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import InputFull from "../../Components/Common/InuptFull";
import { EducationDetails, UserInfoDetailsType } from "../../Utils/types";
import Required from "../../Components/Common/Required";
import { deleteEducation, updateEducation } from "./ProfileApi";

const Education = ({
  detail,
  userInfo,
}: {
  detail: EducationDetails;
  userInfo?: UserInfoDetailsType;
}): JSX.Element => {
  const [isNew, setIsNew] = useState<boolean>(detail.courseName ? false : true);
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [isEdit, setisEdit] = useState<boolean>(isNew);
  const [Studentdetail, setStudentdetail] = useState<
    EducationDetails | undefined
  >(detail);
  const registerType = userInfo?.registerType || "student";
  useEffect(() => {
    const duration = Studentdetail?.duration?.split("-");
    const sDate = duration?.[0] ? new Date(duration?.[0]) : new Date();
    const eDate = duration?.[1] ? new Date(duration?.[1]) : new Date();
    setStartDate(sDate);
    setEndDate(eDate);
    const toRet = `${sDate.getFullYear()}-${eDate.getFullYear()}`;
    Studentdetail &&
      setStudentdetail({
        ...Studentdetail,
        duration: toRet,
      });
  }, []);

  return (
    <>
      <div className="col-span-10 leading-5 mt-4 font-bold text-md">
        <p>
          {isEdit ? (
            <>
              <Required />
              <InputFull
                type={`text`}
                label={`Institute Name`}
                value={Studentdetail?.institutionName}
                onChange={(e) => {
                  Studentdetail &&
                    setStudentdetail({
                      ...Studentdetail,
                      institutionName: e.target.value,
                    });
                }}
              />
            </>
          ) : (
            Studentdetail?.institutionName
          )}
        </p>
        <p className="font-normal mt-2">
          {isNew && Studentdetail?.courseName !== undefined ? (
            <>
              <Required />
              <InputFull
                type={`text`}
                label={`Course`}
                value={Studentdetail?.courseName}
                onChange={(e) => {
                  Studentdetail &&
                    setStudentdetail({
                      ...Studentdetail,
                      courseName: e.target.value,
                    });
                }}
              />
            </>
          ) : (
            <>
              {isEdit ? (
                <>
                  <div>Course </div>
                  {Studentdetail?.courseName}
                </>
              ) : (
                Studentdetail?.courseName
              )}
            </>
          )}
        </p>
        <p className="font-normal mt-2">
          {isEdit ? (
            <div>
              <span>Start Date</span>
              <ReactDatePicker
                showYearPicker
                dateFormat="yyyy"
                className={` w-full border border-gray-400 rounded mt-1 p-2 pb-3 border-solid`}
                selected={startDate}
                maxDate={endDate}
                onChange={(date: any) => {
                  if (date?.getFullYear()) {
                    setStartDate(date);
                    const toRet = `${date.getFullYear()}-${endDate.getFullYear()}`;
                    Studentdetail &&
                      setStudentdetail({
                        ...Studentdetail,
                        duration: toRet,
                      });
                  }
                }}
              />
              <span>End Date</span>
              <ReactDatePicker
                showYearPicker
                dateFormat="yyyy"
                className={` w-full border border-gray-400 rounded mt-1 p-2 pb-3 border-solid`}
                selected={endDate}
                minDate={startDate}
                maxDate={new Date()}
                onChange={(date: any) => {
                  if (date?.getFullYear()) {
                    setEndDate(date);
                    const toRet = `${startDate.getFullYear()}-${date.getFullYear()}`;
                    Studentdetail &&
                      setStudentdetail({
                        ...Studentdetail,
                        duration: toRet,
                      });
                  }
                }}
              />
            </div>
          ) : (
            Studentdetail?.duration
          )}
        </p>
      </div>
      {Studentdetail && (
        <div className={` mt-4`}>
          <div className={``}>
            {isEdit ? (
              <>
                <div className={`flex justify-end`}>
                  <div>
                    <button
                      className="bg-lp_blue text-white text-sm ml-4 p-2 w-32 rounded-md font-bold  "
                      type="button"
                      onClick={() => {
                        if (
                          Studentdetail.courseName &&
                          Studentdetail.institutionName &&
                          Studentdetail.duration
                        ) {
                          // setStudentdetail(undefined);
                          setisEdit(false);
                          setIsNew(false);
                          updateEducation(Studentdetail, registerType)
                            .then((data) => {
                              if (
                                data.data ===
                                "Education Details has been updated successfully"
                              ) {
                                toast.success("Updated", {
                                  position: "top-right",
                                  autoClose: 3000,
                                  hideProgressBar: false,
                                  closeOnClick: true,
                                  pauseOnHover: true,
                                  draggable: true,
                                  progress: undefined,
                                });
                              } else {
                                toast.error("Sorry, Try after some time", {
                                  position: "top-right",
                                  autoClose: 3000,
                                  hideProgressBar: false,
                                  closeOnClick: true,
                                  pauseOnHover: true,
                                  draggable: true,
                                  progress: undefined,
                                });
                              }
                            })
                            .catch((e) => {
                              toast.error("Sorry, Try after some time", {
                                position: "top-right",
                                autoClose: 3000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                              });
                            });
                        } else {
                          toast.error("Fields can't be empty", {
                            position: "top-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                          });
                        }
                      }}
                    >
                      Save
                    </button>
                  </div>
                  <div>
                    <button
                      className="bg-lp_blue text-white text-sm  ml-4 p-2 w-32 rounded-md font-bold   "
                      type="button"
                      onClick={() => {
                        setisEdit(false);
                        if (isNew) setStudentdetail(undefined);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <>
                <button
                  className="bg-lp_blue text-white text-sm m-2 p-2 w-32 rounded-md font-bold place-self-end  "
                  type="button"
                  onClick={() => {
                    setisEdit(true);
                  }}
                >
                  Edit
                </button>
                <button
                  className="bg-lp_blue text-white text-sm  m-2 p-2 w-32 rounded-md font-bold place-self-end  "
                  type="button"
                  onClick={() => {
                    setisEdit(false);
                    const reqData = {
                      courseName: Studentdetail?.courseName,
                    };
                    deleteEducation(reqData, registerType)
                      .then(({ data }) => {
                        if (
                          data.data ===
                          "Education Details has been successfully deleted"
                        ) {
                          setStudentdetail(undefined);
                          toast.success("Deleted", {
                            position: "top-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                          });
                        } else {
                          toast.error("Sorry, Try after some time", {
                            position: "top-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                          });
                        }
                      })
                      .catch((e) => {
                        toast.error("Sorry, Try after some time", {
                          position: "top-right",
                          autoClose: 3000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                        });
                      });
                  }}
                >
                  Delete
                </button>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

// export default Education;
const mapStateToProps = (state: any) => {
  return {
    userInfo: state && state.userInfoReducer,
  };
};

export default connect(mapStateToProps, null)(Education);
