import { Dispatch, SetStateAction } from "react";
import Modal from "react-modal";
import RenderMediaComponents from "../../Components/Common/RenderMediaComponents";
import PDFViewer from "../../Components/Resources/PDFViewer";
import VideoPlayer from "../../Components/Resources/VideoPlayer";
import WordDocViewer from "../../Components/Resources/WordDocViewer";

type ResourceViewerType = {
  originalName: string;
  resourceLink: string;
  isModalOpen: boolean;
  closeModal: () => void;
};

const ResourceViewer = ({
  originalName,
  resourceLink,
  isModalOpen,
  closeModal,
}: ResourceViewerType) => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "90%",
      height: "85%",
    },
  };

  return (
    <div>
      <Modal
        appElement={document.getElementById("root") as HTMLElement}
        isOpen={isModalOpen}
        style={customStyles}
      >
        <div className={`flex flex-col h-full`}>
          <div className={`grid grid-cols-2`}>
            <div className={`p-4 pl-8 text-lg font-bold`}>{originalName}</div>
            <button
              className={`mb-6 flex flex-auto justify-end`}
              onClick={closeModal}
            >
              <i className={`material-icons text-3xl font-bold mr-8`}>close</i>
            </button>
          </div>
          <div className={`h-full`}>
            <RenderMediaComponents
              originalName={originalName}
              resourceLink={resourceLink}
              key={resourceLink}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ResourceViewer;
