const Terms = () => {
  return (
    <div className="m-auto w-full flex items-center justify-center">
      <div className="w-full   text-black  text-base sm:text-lg :text-2xl  grid items-center justify-center">
        <h1 className="flex place-self-center font-bold text-2xl mb-4">
          Terms and Conditions
        </h1>

        <div>
          <h4 className="font-bold mt-8 mb-2 ">A. About Her Springboard</h4>
          <p className=" text-base">
            The domain name, Website and the Apps are owned, registered and
            operated by Prowess Technologies
          </p>
        </div>
        <div>
          <h4 className="font-bold mt-8 mb-2 ">B. Her Springboard Platform </h4>

          <p className=" text-base">
            <ol className="list-decimal ">
              <li className="ml-8">
                {" "}
                These Terms and Conditions apply to all Users of the Her
                Springboard Platform, including educators who are also
                contributors of User Content on the Her Springboard Platform.
                The Platform includes all aspects of the Website and Apps which
                includes but is not limited to products, software and service
                offered via the Her Springboard Platform, or any other service
                or application that Her Springboard introduces from time to
                time.
              </li>
              <li className="ml-8">
                Her Springboard Platform is an online platform that supports and
                facilitates the online creation of educational videos/or
                tutorials, live classes by the Users of the Her Springboard
                Platform and acts as an intermediary between the educator and
                the User. A User may create a video, audio clip or tutorial, by
                using the Platform. Such content uploaded through the use of the
                Her Springboard Platform shall hereinafter be referred to as
                “User Content”. You agree and acknowledge that Her Springboard
                has no control over and assumes no responsibility for, the User
                Content and by using the Her Springboard Platform, you expressly
                relieve Her Springboard from any and all liability arising from
                the User Content.
              </li>
              <li className="ml-8">
                The Her Springboard Platform may include or contain links to any
                third-party websites that may or may not be owned or controlled
                by Her Springboard. Her Springboard has no control over, and
                assumes no responsibility for, the content, privacy policies, or
                practices of any third-party websites. In addition, Her
                Springboard will not and cannot censor or edit the content of
                any third-party site. By using the Her Springboard Platform, you
                expressly relieve Her Springboard from any and all liability
                arising from your use of any third-party website or services.
                However, we recommend that before you use any third-party
                services or website you read and understand the terms and
                conditions and privacy policy of each such website that you
                visit.
              </li>{" "}
              <li className="ml-8 ">
                {" "}
                Subject to these Terms and Conditions, Privacy Policy and all
                other rules and policies made available or published elsewhere,
                Her Springboard hereby grants the you a non-exclusive,
                non-transferable, non-sublicensable, limited license to use the
                Her Springboard Platform in accordance with these Terms and
                Conditions.
              </li>{" "}
              <li className="ml-8">
                You agree and acknowledge that Her Springboard shall have the
                right at any time to change or discontinue any aspect or feature
                of the Her Springboard Platform, including, but not limited to,
                the User Content, hours of availability and equipment needed for
                access or use. Further, Her Springboard may discontinue
                disseminating any portion of information or category of
                information may change or eliminate any transmission method and
                may change transmission speeds or other signal characteristics.
                Her Springboard reserves the right to refuse access to the Her
                Springboard Platform, terminate Accounts, remove or edit
                contents without any notice to You.
              </li>
            </ol>
          </p>
        </div>

        <div>
          <h4 className="font-bold mt-8 mb-2 ">C. Her Springboard Accounts</h4>
          <p className="text-base">
            In order to access some of the features of the Her Springboard
            Platform, you may have to create your account with Her Springboard.
            You agree and confirm that you will never use another User’s account
            nor provide access to your account to any third-party. When creating
            your account, you confirm that the information so provided is
            accurate and complete. Further, you agree that you are solely
            responsible for the activities that occur on your account, and you
            shall keep your account password secure and not share the same with
            anyone. You must notify Her Springboard immediately of any breach of
            security or unauthorized use of your account. At no point in time
            will Her Springboard be liable for any losses caused by any
            unauthorized use of your account, you shall solely be liable for the
            losses caused to Her Springboard or others due to such unauthorized
            use, if any.
          </p>
          <p className="text-base">
            Her Springboard takes no responsibility for any User Content that is
            uploaded on the Her Springboard Platform, and further, the User
            shall be solely responsible for his or her own actions in utilizing
            such User Content and availing the Her Springboard Platform provided
            herein.
          </p>
        </div>

        <div>
          <h4 className="font-bold mt-8 mb-2 ">
            D. Access, Permissions and Restrictions
          </h4>
          <p className=" text-base">
            Her Springboard hereby grants you permission to access and use the
            Her Springboard Platform as set forth in these Terms and Conditions,
            provided that:
            <ol className="list-decimal ">
              <li className="ml-8">
                {" "}
                You agree not to distribute in any medium any part of the Her
                Springboard Platform or the content without Her Springboard’s
                prior written authorization.
              </li>
              <li className="ml-8">
                You agree not to alter or modify any part of the Her Springboard
                Platform.
              </li>{" "}
              <li className="ml-8">
                {" "}
                You agree not to access content of any other User through any
                technology or means other than the video playback pages of the
                Her Springboard Platform itself.
              </li>{" "}
              <li className="ml-8">
                You agree not to use the Her Springboard Platform for any of the
                following commercial uses unless you obtain Her Springboard’s
                prior written approval:
                <ul>
                  <li className="list-disc">
                    {" "}
                    the sale of access to the Her Springboard Platform;
                  </li>{" "}
                  <li className="list-disc">
                    the sale of advertising, sponsorships, or promotions placed
                    on or within the Her Springboard Platform or content; or
                  </li>
                  <li className="list-disc">
                    {" "}
                    the sale of advertising, sponsorships, or promotions on any
                    page or website that provide similar Her Springboard
                    Platform as that of Her Springboard.
                  </li>
                </ul>
              </li>
            </ol>
            <ol className="list-decimal">
              <li className="ml-8">
                {" "}
                You agree to receive installs and updates from time to time from
                Her Springboard. These updates are designed to improve, enhance
                and further develop the Her Springboard Platform and may take
                the form of bug fixes, enhanced functions, new software modules
                and completely new versions. You agree to receive such updates
                (and permit Her Springboard to deliver these to you) as part of
                your use of the Her Springboard Platform.
              </li>
              <li className="ml-8">
                You agree not to use or launch any automated system, including
                without limitation, “robots,” “spiders,” or “offline readers,”
                that accesses the Her Springboard Platform in a manner that
                sends more request messages to Her Springboard’s servers in a
                given period of time than a human can reasonably produce in the
                same period by using a conventional on-line web browser.
                Notwithstanding the foregoing, Her Springboard grants the
                operators of public search engines permission to use spiders to
                copy materials from the site for the sole purpose of and solely
                to the extent necessary for creating publicly available
                searchable indices of the materials, but not caches or archives
                of such materials. Her Springboard reserves the right to revoke
                these exceptions either generally or in specific cases at any
                time with or without providing any notice in this regard. You
                agree not to collect or harvest any personally identifiable
                information, including account names, from the Her Springboard
                Platform, nor to use the communication systems provided by the
                Her Springboard Platform (e.g., comments, email) for any
                commercial solicitation purposes. You agree not to solicit, for
                commercial purposes, any Users of the Her Springboard Platform
                with respect to User Content.
              </li>
              <li className="ml-8">
                You may post reviews, comments and other content; send other
                communications; and submit suggestions, ideas, comments,
                questions or other information as long as the content is not
                illegal, obscene, threatening, defamatory, invasive of privacy,
                infringement of intellectual property rights, or otherwise
                injurious to third parties or objectionable and does not consist
                of or contain software viruses, political campaigning,
                commercial solicitation, chain letters, mass mailings or any
                other form of spam. Further, you give Her Springboard limited,
                royalty free, worldwide, non-exclusive license to use the User
                Content and communication in developing its Her Springboard
                Platform and in any of its marketing or promotional activities.
              </li>
              <li className="ml-8">
                In your use of the Her Springboard Platform, you will at all
                times comply with all applicable laws and regulations.
              </li>
              <li className="ml-8">
                Her Springboard reserves the right to discontinue any aspect of
                the Her Springboard Platform at any time with or without notice
                at its sole discretion.
              </li>
            </ol>
          </p>
        </div>

        <div>
          <h4 className="font-bold mt-8 mb-2 ">E. Content Use</h4>
          <p className=" text-base">
            In addition to the general restrictions mentioned above, the
            following limitation and conditions shall apply to your use of the
            Content.
            <ol className="list-decimal">
              <li className="ml-8">
                Her Springboard Content utilized on the Her Springboard Platform
                which shall include but not be limited to trademarks, service
                marks and logos (“Marks”), process, images, software, graphics
                are owned by or licensed to Her Springboard and subject to
                copyright and other intellectual property rights under the law.
              </li>
              <li className="ml-8">
                User Content is provided to you on an AS IS basis. You may
                access Content for your information and personal use solely as
                intended through the provided functionality on the Her
                Springboard Platform and as permitted under these Terms and
                Conditions. You shall not download any User Content unless you
                see a “download” or similar link displayed by Her Springboard on
                the Her Springboard Platform for that User Content. You shall
                not copy, reproduce, make available online or electronically
                transmit, publish, adapt, distribute, transmit, broadcast,
                display, sell, license, or otherwise exploit any User Content
                for any other purposes other than as provided herein without the
                prior written consent of Her Springboard or the respective
                licensors of the User Content. Her Springboard and its licensors
                reserve all rights not expressly granted in and to the Her
                Springboard Platform and the User Content.
              </li>
              <li className="ml-8">
                You agree not to circumvent, disable or otherwise interfere with
                security-related features of the Her Springboard Platform or
                features that prevent or restrict use or copying of any User
                Content or enforce limitations on use of the Her Springboard
                Platform or the User Content therein.
              </li>
              <li className="ml-8">
                You understand that when using the Her Springboard Platform, you
                will be exposed to User Content from variety of sources and by
                different Users, and that Her Springboard is not responsible for
                the accuracy, usefulness, safety, or intellectual property
                rights of or relating to such User Content. You further
                understand and acknowledge that you may be exposed to User
                Content that is inaccurate, offensive, indecent, or
                objectionable, and you agree to waive, and hereby do waive, any
                legal or equitable rights or remedies you have or may have
                against Her Springboard with respect thereto, and, to the extent
                permitted by applicable law, agree to indemnify and hold
                harmless Her Springboard, its owners, operators, affiliates,
                licensors, and licensees to the fullest extent allowed by law
                regarding all matters related to your use of the Her Springboard
                Platform.
              </li>
            </ol>
          </p>
        </div>

        <div>
          <h4 className="font-bold mt-8 mb-2 ">F. Content and Conduct</h4>
          <p className=" text-base">
            <ol className="list-decimal">
              <li className="ml-8">
                As an Her Springboard User, you may submit User Content on the
                Her Springboard Platform, including videos and User comments.
                You understand that Her Springboard does not guarantee any
                confidentiality with respect to any User Content you submit.
              </li>
              <li className="ml-8">
                You shall be solely responsible for your own User Content and
                the consequences of submitting and publishing such User Content
                on the Her Springboard Platform. You affirm, represent, and
                warrant that you own or have the necessary licenses, rights,
                consents, and permissions to publish the User Content that you
                submit; and you grant limited license to Her Springboard to all
                patent, trademark, trade secret, copyright or other proprietary
                rights in and to such User Content for publication on the Her
                Springboard Platform pursuant to these Terms and Conditions for
                the duration the said User Content is available on Her
                Springboard Platform.
              </li>{" "}
              <li className="ml-8">
                For clarity, you retain your ownership rights in your User
                Content. However, by submitting User Content to Her Springboard,
                you hereby grant Her Springboard a limited, worldwide,
                non-exclusive, royalty-free, sub-licenseable and transferable
                license to use, reproduce, distribute, display, publish, make
                available online or electronically transmit, the User Content in
                connection with the Her Springboard Platform and Her
                Springboard’s (and its successors’ and affiliates’) business,
                including without limitation for promoting and redistributing
                part or all of the Her Springboard Platform in any media formats
                and through any media channels. You also hereby grant each User
                of the Her Springboard Platform a limited, non-exclusive license
                to access your User Content through the Platform. The above
                licenses granted by you in the User Content you submit to the
                Her Springboard Platform terminate within a commercially
                reasonable time after you request Her Springboard to remove or
                delete your videos from the Her Springboard Platform provided
                you pay the mutually agreed amount that Her Springboard incurred
                in providing the Her Springboard Platform to you. You understand
                and agree, however, that Her Springboard may retain, but not
                display, distribute, or perform, server copies of your videos
                that have been removed or deleted. The above licenses granted by
                you in User comments and testimonial you submit are perpetual
                and irrevocable.
              </li>
              <li className="ml-8">
                You further agree that User Content you submit on the Her
                Springboard Platform will not contain third party copyrighted
                material, or material that is subject to other third party
                proprietary rights, unless you have permission from the rightful
                owner of the material or you are otherwise legally entitled to
                post the material and to grant Her Springboard all of the
                license rights granted herein.
              </li>
              <li className="ml-8">
                Furthermore, you confirm that the User Content uploaded by you
                is not:
              </li>
              <ol className="list-decimal">
                <li className="ml-16"> False, inaccurate or misleading.</li>
                <li className="ml-16">
                  Infringe any third party’s copyright, patent, trademark, trade
                  secret or other proprietary rights or rights of publicity or
                  privacy.
                </li>
                <li className="ml-16">
                  Violate any law, statue, ordinance or regulation.
                </li>
                <li className="ml-16">
                  defamatory, unlawfully threatening or unlawfully harassing.
                </li>
                <li className="ml-16"> obscene or contain pornography.</li>
                <li className="ml-16">
                  Contain any viruses, trojan horses, worms, cancelbots or other
                  computer programming routines that may damage, detrimentally
                  interfere with, surreptitiously intercept or expropriate any
                  system, data or personal information.
                </li>
              </ol>
              <li className="ml-8">
                You understand and confirm that you shall not during your use of
                the Her Springboard Platforms at any time post or publish any
                content, comments or act in any way which will amount to
                harassment of any other User, whether a learner or an educator.
                If at any given point it comes to the notice of Her Springboard
                that you are engaged in any kind of harassment of other Users,
                then in such a case you agree that Her Springboard shall have
                the sole right to suspend/terminate your account with immediate
                effect and without any notice of such suspension or termination
                and we also reserve the right in our sole discretion to initiate
                any legal proceedings against you in such cases.
              </li>
              <li className="ml-8">
                Her Springboard at its sole discretion may process any audio or
                audio-visual content uploaded by you to the Her Springboard
                Platform to confirm if the same is in accordance with the Her
                Springboard internal quality requirements and is compatible with
                the Her Springboard Platform.
              </li>
              <li className="ml-8">
                Her Springboard does not endorse any User Content submitted on
                the Her Springboard Platform by any User or other licensor, or
                any opinion, recommendation, or advice expressed therein, and
                Her Springboard expressly disclaims any and all liability in
                connection with User Content. Her Springboard does not permit
                copyright infringing activities and infringement of intellectual
                property rights on the Her Springboard Platform, and Her
                Springboard will remove all User Content if properly notified
                that such Content infringes on another’s intellectual property
                rights. Her Springboard reserves the right to remove User
                Content without prior notice if it has reason to believe that
                the User Content infringes any copyright.
              </li>
            </ol>
          </p>
        </div>
        <div>
          <h4 className="font-bold mt-8 mb-2 ">
            G. Her Springboard Intellectual Property Right
          </h4>
          <p className=" text-base">
            The Her Springboard Platform, the processes, and their selection and
            arrangement, including but not limited to all text, graphics, User
            interfaces, visual interfaces, sounds and music (if any), artwork
            and computer code on the Her Springboard Platform is owned and
            controlled by Her Springboard and the design, structure, selection,
            coordination, expression, look and feel and arrangement of such
            content mentioned hereinabove is protected by copyright, patent and
            trademark laws, and various other national and international IPR
            laws and regulations. For clarity, content in the above sentence
            shall not include User Content. The mark “Her Springboard” is the
            sole property of Her Springboard. Reproduction in whole or in part
            of the same is strictly prohibited unless used with an express
            written permission from Her Springboard.
          </p>
        </div>

        <div>
          <h4 className="font-bold mt-8 mb-2 ">H. Refunds</h4>
          <p className=" text-base">
            Any refunds that are to be processed shall be processed in
            accordance with Her Springboard’s refund policy.
          </p>
        </div>

        <div>
          <h4 className="font-bold mt-8 mb-2 ">I. Electronic Communication</h4>
          <p className=" text-base">
            When you visit Her Springboard Platform or send email to us, you are
            communicating with us electronically. By communicating with us, you
            consent to receive communication from us electronically. We will
            communicate with you by email or posting notices on Her Springboard
            Platform. You agree that all agreements, notices, disclosures,
            disclaimers, offers and other communications that are provided to
            you electronically satisfy any legal requirement that such
            communication should be in writing.
          </p>
        </div>

        <div>
          <h4 className="font-bold mt-8 mb-2 ">J. Termination of Account</h4>
          <p className=" text-base">
            <ol className="list-decimal">
              <li className="ml-8">
                Her Springboard will terminate a User’s access to the Her
                Springboard Platform, if
              </li>
              <ol className="list-decimal">
                {" "}
                <li className="ml-16">
                  {" "}
                  the User is a repeat copyright infringer.
                </li>
                <li className="ml-16">
                  {" "}
                  the Users breaches any terms of these Terms and Conditions.
                </li>
                <li className="ml-16"> Violation of any applicable laws.</li>
                <li className="ml-16">
                  {" "}
                  your use of the Her Springboard Platform disrupts our business
                  operations or affects any other party/ User; or
                </li>
                <li className="ml-16">
                  {" "}
                  you have behaved in a way, which objectively could be regarded
                  as inappropriate or unlawful or illegal or which would bring
                  any claims against Her Springboard.
                </li>
              </ol>{" "}
              <li className="ml-8">
                Her Springboard reserves the right to decide whether User
                Content violates these Terms and Conditions for reasons other
                than copyright infringement, such as, but not limited to,
                pornography, obscenity, or excessive length or any other
                parameter that Her Springboard deems fit from time to time. Her
                Springboard may at any time, without prior notice and in its
                sole discretion, remove such User Content and/or terminate a
                User's account for submitting such material in violation of
                these Terms and Conditions.
              </li>
              <li className="ml-8">
                {" "}
                We may suspend access to the Her Springboard Platforms or
                require You to change Your password if we reasonably believe
                that the Her Springboard Platforms have been or are likely to be
                misused, and we will notify You accordingly. Any termination of
                Your registration and/or Account or the Her Springboard
                Platforms will not affect liability previously incurred by You.
              </li>
            </ol>
          </p>
        </div>

        <div>
          <h4 className="font-bold mt-8 mb-2 ">K. Effective of Termination</h4>
          <p className=" text-base">
            In case any of the above-mentioned cases occur, Her Springboard
            reserves the right, in its sole discretion, to terminate or suspend
            the User’s account with immediate effect. Upon suspension or
            termination of the User’s account:
            <ol className="list-decimal">
              <li className="ml-8">
                Her Springboard shall at its sole discretion withhold or stop
                any and all payments that are to be made to such User and
                evaluate the pay-outs. Payments shall only be made for all
                legitimate work that is not subject matter of any violation or
                dispute.
              </li>
              <li className="ml-8">
                Payments shall not be made for the work that is the subject
                matter of any of the breaches.
              </li>
              <li className="ml-8">
                User access shall be terminated immediately, and the User shall
                not be able to access the said terminated account.
              </li>
            </ol>
          </p>
        </div>

        <div>
          <h4 className="font-bold mt-8 mb-2 ">L. Copyright Infringement</h4>
          <p className=" text-base">
            <ol className="list-decimal">
              <li className="ml-8">
                {" "}
                Her Springboard operates a clear copyright policy in relation to
                any User Content alleged to infringe the copyright of a third
                party.
              </li>
              <li className="ml-8">
                As part of Her Springboard’s copyright policy, Her Springboard
                will terminate User access to the Her Springboard Platform if a
                User has been determined to be a repeat infringer. A repeat
                infringer is a User who has been notified of infringing activity
                more than twice.
              </li>
              <li className="ml-8">
                If you believe that your work has been copied in a way that
                constitutes copyright infringement, please refer to the
                instructions given in the copyright policy on how to make a
                claim of copyright infringement.
              </li>
              <li className="ml-8"> Notice of Copyright Infringement:</li>
            </ol>
            <ol className="list-decimal">
              <li className="ml-16">
                Pursuant to provisions of the Indian Copyright Act, 1952 read
                with the Information Technology Act of 2000, we an intermediary,
                allow general public to post content on our platform. We have
                implemented procedures for receiving written notification of
                claimed infringements on the User Content uploaded on Her
                Springboard Platform. We have also designated an agent to
                receive notices of claimed copyright infringement. If you
                believe in good faith that your copyright has been infringed,
                you may provide us with a written communication which contains:
              </li>
              <ol className="list-decimal">
                <li className="ml-24">
                  An electronic or physical signature of the person authorized
                  to act on behalf of the owner of the copyright interest;
                </li>
                <li className="ml-24">
                  A description of the copyrighted work that you claim has been
                  infringed upon;
                </li>
                <li className="ml-24">
                  A description of where the material that you claim is
                  infringing is located on the Her Springboard Platform,
                  including the educator name, topic and any other detail
                  identifying the infringing content;
                </li>
                <li className="ml-24">
                  Your email address, your physical address and telephone
                  number;
                </li>
                <li className="ml-24">
                  A statement by you that you have a good faith belief that the
                  disputed use is not authorized by the copyright owner, its
                  agent, or the law; and
                </li>
                <li className="ml-24">
                  A statement by you, made under penalty of perjury, that the
                  information in your notice is accurate and that you are the
                  copyright owner or authorized to act on the copyright owner’s
                  behalf.
                </li>
              </ol>
            </ol>
            Please submit your notice to our Copyright Agent as follows:
            <br></br>
            <br></br>
            Email us at{" "}
            <a
              className="underline text-blue-900 font-bold ml-2"
              href="mail:support@nimbusgurus.in"
            >
              support@nimbusgurus.in
            </a>
            <br></br>
            <br></br>
            <ol className="list-decimal">
              <li className="ml-8">
                Our policy to deal with Copyright infringements: Instances
                brought to our notice that are provided with all required
                supporting information will lead to the educator getting banned
                from the Her Springboard Platform. His/her right to any and all
                compensation or monetary benefit that he/she might be eligible
                for shall be revoked. The detailed process is as under:
              </li>
              <ol className="list-decimal">
                <li className="ml-16">
                  The educator is suspended from making any more videos on the
                  Her Springboard Platform for a month and all User Content that
                  amount to copyright violations are brought down immediately.
                </li>
                <li className="ml-16">
                  The educator is banned from the Her Springboard Platform and
                  will be unable to make any more courses in the future.
                </li>
              </ol>
            </ol>{" "}
          </p>
        </div>
        <div>
          <h4 className="font-bold mt-8 mb-2 ">M. Confidentiality</h4>
          <p className=" text-base">
            You will not without obtaining prior written consent of Her
            Springboard, disclose to third party any Confidential Information
            (as defined below) that is disclosed to you during the term of your
            use of the Her Springboard Platform. For the purpose of this clause
            Confidential Information shall include but shall not be limited to
            employee details, User list, business model, processes, ideas,
            concepts etc. relating to Her Springboard or Her Springboard
            Platform which are not available in the public domain. You
            acknowledge and agree that the Confidential Information so provided
            to you shall at all-time be the property of Her Springboard and any
            breach of the same shall cause irreparable damage to us.
          </p>
        </div>

        <div>
          <h4 className="font-bold mt-8 mb-2 ">N. Disclaimer</h4>
          <p className=" text-base">
            YOU AGREE THAT YOUR USE OF HER SPRINGBOARD PLATFORM SHALL BE AT YOUR
            SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, HER SPRINGBOARD,
            ITS OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS EXCLUDE ALL
            WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE HER
            SPRINGBOARD PLATFORM AND YOUR USE THEREOF. TO THE FULLEST EXTENT
            PERMITTED BY LAW, HER SPRINGBOARD EXCLUDES ALL WARRANTIES,
            CONDITIONS, TERMS OR REPRESENTATIONS ABOUT THE ACCURACY OR
            COMPLETENESS OF THE USER CONTENT OR THE CONTENT OF ANY SITES SO
            LINKED AND ASSUMES NO LIABILITY OR RESPONSIBILITY FOR ANY (I)
            ERRORS, MISTAKES, OR INACCURACIES OF USER CONTENT OR ANY CONTENT ON
            HER SPRINGBOARD, (II) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY
            NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE HER
            SPRINGBOARD PLATFORM, (III) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR
            SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR
            FINANCIAL INFORMATION STORED THEREIN, (IV) ANY INTERRUPTION OR
            CESSATION OF TRANSMISSION TO OR FROM THE HER SPRINGBOARD PLATFORM,
            (IV) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE
            TRANSMITTED TO OR THROUGH THE HER SPRINGBOARD PLATFORM BY ANY THIRD
            PARTY, AND/OR (V) ANY ERRORS OR OMISSIONS IN ANY USER CONTENT OR ANY
            OTHER CONTENT OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A
            RESULT OF THE USE OF ANY USER CONTENT OR ANY OTHER CONTENT THAT IS
            POSTED, EMAILED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE
            HER SPRINGBOARD PLATFORM. HER SPRINGBOARD DOES NOT WARRANT, ENDORSE,
            GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY USER CONTENT OR HER
            SPRINGBOARD PLATFORM ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH
            THE HER SPRINGBOARD PLATFORM OR ANY HYPERLINKED SERVICES OR FEATURED
            IN ANY BANNER OR OTHER ADVERTISING, AND HER SPRINGBOARD WILL NOT BE
            A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY
            TRANSACTION BETWEEN YOU AND THIRD-PARTY PROVIDERS OF USER CONTENT OR
            SERVICES. AS WITH THE PURCHASE OF A SUBSCRIPTION OR SERVICE THROUGH
            ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT
            AND EXERCISE CAUTION WHERE APPROPRIATE.
          </p>
        </div>

        <div>
          <h4 className="font-bold mt-8 mb-2 ">O. Limitation of Liability</h4>
          <p className=" text-base">
            TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT SHALL HER
            SPRINGBOARD, ITS OFFICERS, DIRECTORS, EMPLOYEES, OR AGENTS, BE
            LIABLE TO YOU FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL,
            PUNITIVE, LOSSES OR EXPENSES OR CONSEQUENTIAL DAMAGES WHATSOEVER
            RESULTING FROM ANY (I) ERRORS, MISTAKES, OR INACCURACIES OF USER
            CONTENT OR ANY OTHER CONTENT AVAILABLE AT HER SPRINGBOARD, (II)
            PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER,
            RESULTING FROM YOUR ACCESS TO AND USE OF OUR HER SPRINGBOARD
            PLATFORMS, (III) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE
            SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL
            INFORMATION STORED THEREIN, (IV) ANY INTERRUPTION OR CESSATION OF
            TRANSMISSION TO OR FROM THE HER SPRINGBOARD PLATFORM, (IV) ANY BUGS,
            VIRUSES, TROJAN HORSES, OR THE LIKE, WHICH MAY BE TRANSMITTED TO OR
            THROUGH OUR HER SPRINGBOARD PLATFORMS BY ANY THIRD PARTY, AND/OR (V)
            ANY ERRORS OR OMISSIONS IN ANY USER CONTENT OR ANY OTHER CONTENT OR
            FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF YOUR USE
            OF ANY CONTENT POSTED, EMAILED, TRANSMITTED, OR OTHERWISE MADE
            AVAILABLE VIA THE HER SPRINGBOARD PLATFORM, WHETHER BASED ON
            WARRANTY, CONTRACT, TORT, OR ANY OTHER LEGAL THEORY, AND WHETHER OR
            NOT HER SPRINGBOARD IS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
            WE UNDERSTAND THAT, IN SOME JURISDICTIONS, WARRANTIES, DISCLAIMERS
            AND CONDITIONS MAY APPLY THAT CANNOT BE LEGALLY EXCLUDED, IF THAT IS
            TRUE IN YOUR JURISDICTION, THEN TO THE EXTENT PERMITTED BY LAW, HER
            SPRINGBOARD LIMITS ITS LIABILITY FOR ANY CLAIMS UNDER THOSE
            WARRANTIES OR CONDITIONS TO EITHER SUPPLYING YOU THE HER SPRINGBOARD
            PLATFORMS AGAIN. YOU SPECIFICALLY ACKNOWLEDGE THAT HER SPRINGBOARD
            SHALL NOT BE LIABLE FOR USER CONTENT OR THE DEFAMATORY, OFFENSIVE,
            OR ILLEGAL CONDUCT OF ANY THIRD PARTY AND THAT THE RISK OF HARM OR
            DAMAGE FROM THE FOREGOING RESTS ENTIRELY WITH YOU. THE HER
            SPRINGBOARD PLATFORM IS CONTROLLED AND OFFERED BY HER SPRINGBOARD
            FROM ITS FACILITIES IN INDIA. HER SPRINGBOARD MAKES NO
            REPRESENTATIONS THAT THE HER SPRINGBOARD PLATFORM IS APPROPRIATE OR
            AVAILABLE FOR USE IN OTHER LOCATIONS. THOSE WHO ACCESS OR USE THE
            HER SPRINGBOARD PLATFORM FROM OTHER JURISDICTIONS DO SO AT THEIR OWN
            VOLITION AND ARE RESPONSIBLE FOR COMPLIANCE WITH LOCAL LAW.
          </p>
        </div>

        <div>
          <h4 className="font-bold mt-8 mb-2 ">P. Indemnity</h4>
          <p className=" text-base">
            To the extent permitted by applicable law, you agree to defend,
            indemnify and hold harmless Her Springboard, its parent corporation,
            officers, directors, employees and agents, from and against any and
            all claims, damages, obligations, losses, liabilities, costs or
            debt, and expenses (including but not limited to attorney's fees)
            arising from: (i) your use of and access to the Her Springboard
            Platform; (ii) your violation of any term of these Terms and
            Conditions; (iii) your violation of any third party right, including
            without limitation any copyright, property, or privacy right; (iv)
            any claim that your User Content caused damage to a third party; or
            (v) violation of any applicable laws. This defence and
            indemnification obligation will survive these Terms and Conditions
            and your use of the Her Springboard Platform.
          </p>
        </div>

        <div>
          <h4 className="font-bold mt-8 mb-2 ">
            Q. Eligibility to use and Acceptance of the Terms and Conditions
          </h4>
          <p className=" text-base">
            You affirm that you are either more than 18 years of age, or possess
            legal parental or guardian consent, and are fully able and competent
            to enter into the terms, conditions, obligations, affirmations,
            representations, and warranties set forth in these Terms and
            Conditions, and to abide by and comply with these Terms and
            Conditions. If you are under 18 years of age, then please talk to
            your parents or guardian before using the Her Springboard Platform.
          </p>
          <br></br>

          <p className="text-base">
            Her Springboard reserves the right to refuse access to use the Her
            Springboard Platforms to any Users or to suspend and/or terminate
            access granted to existing registered Users at any time without
            according any reasons for doing so.
          </p>

          <br></br>
          <p className="text-base">
            We provide these Terms and Conditions with our Her Springboard
            Platform so that you know what terms apply to your use. You
            acknowledge that we have given you a reasonable opportunity to
            review these Terms and Conditions and that you have agreed to them.
            You agree and acknowledge that your use of the Her Springboard
            Platform is subject to the most current version of the Terms and
            Conditions made available on the Her Springboard Platform at the
            time of such use.
          </p>
        </div>

        <div>
          <h4 className="font-bold mt-8 mb-2 ">R. Force Majeure</h4>
          <p className=" text-base">
            Her Springboard shall not be liable for failure to perform, or the
            delay in performance of, any of its obligations if, and to the
            extent that, such failure or delay is caused by events substantially
            beyond its control, including but not limited to acts of God, acts
            of the public enemy or governmental body in its sovereign or
            contractual capacity, war, terrorism, floods, fire, strikes,
            epidemics, civil unrest or riots, power outage, and/or unusually
            severe weather.
          </p>
        </div>

        <div>
          <h4 className="font-bold mt-8 mb-2 ">
            S. Information Technology Act
          </h4>
          <p className=" text-base">
            These Terms and Conditions are published in accordance with the
            provisions of Rule 3 (1) of the Information Technology
            (Intermediaries guidelines) Rules, 2011 that require publishing the
            rules and regulations, privacy policy and terms and conditions for
            access or usage of the Her Springboard Platform.
          </p>
          <br></br>
          <p className="text-base">
            You are advised not to post any information or messages that are, or
            that may be construed, as being malicious, defamatory,
            inappropriate, slanderous, pornographic or otherwise sexually
            oriented or that makes attacks on or the otherwise opines or
            comments on any individuals or groups of individuals, educational
            institutions or any other entities whatsoever (whether companies,
            firms, or any other institutions). You also agree not to post any
            information to which you do not have copyrights or other appropriate
            permissions to post in public forum. Your failure to comply with
            these terms may result in removal of your postings without prior
            notice to User. The IP address of all posts is recorded to aid in
            enforcing these conditions.
          </p>
        </div>

        <div>
          <h4 className="font-bold mt-8 mb-2 ">T. Other Laws</h4>
          <p className=" text-base">
            Certain laws require to maintain data with respect to the Her
            Springboard Platform and other personal information in a prescribed
            format and Her Springboard will use all the information to the
            extent required in compliance with the applicable laws and as may be
            directed or amended from time to time.
          </p>
        </div>

        <div>
          <h4 className="font-bold mt-8 mb-2 ">U. General</h4>
          <p className=" text-base">
            Her Springboard may, in its sole discretion, modify or revise these
            Terms and Conditions and policies at any time, and you agree to be
            bound by such modifications or revisions. Your continued use of the
            Her Springboard Platform post any modification of the Terms and
            Conditions shall be taken as your consent and acceptance to such
            modifications. If any part of these Terms and Conditions is
            unlawful, void or unenforceable, that part of will be deemed
            severable and will not affect the validity and enforceability of any
            remaining provisions. Any notice required to be given in connection
            with the Her Springboard Platform shall be in writing and sent to
            the registered office of Her Springboard. We do not guarantee
            continuous, uninterrupted or secure access to the Her Springboard
            Platform, and operation of the Her Springboard Platform may be
            interfered by numerous factors outside our control. Headings are for
            reference purpose only an on no way define, limit, construe or
            describe the scope or extent of such section. Out failure to act
            with respect to any breach by you or others does not waive our right
            to act with respect to subsequent or similar breaches.
          </p>
        </div>
        <div>
          <h4 className="font-bold mt-8 mb-2 ">V. Consumer Grievance</h4>
          <p className=" text-base">Contact us at support@nimbusgurus.in</p>
        </div>
      </div>
    </div>
  );
};

export default Terms;
