import { createStore, compose, applyMiddleware } from "redux";
import { logger } from "redux-logger";
import rootReducer from "./redux/rootReducer";

const middlewares =
  process.env.NODE_ENV !== "production" && applyMiddleware(logger);
const store = compose(
  middlewares,
  window.__REDUX_DEVTOOLS_EXTENSION__
    ? window.__REDUX_DEVTOOLS_EXTENSION__()
    : (f) => f
)(createStore)(rootReducer);

export default store;
