import { useEffect, useState } from "react";
import FetchResourcesAPIService from "../../Api/Resources/ResourcesAPI";
import ResourceCard from "./ResourceCard";
import NoCourse from "../../Components/Common/NoCourse";
// import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
// import ResourceViewer from "./ResourceViewer";
// import ThemeContext from "../../Context/ThemeContext";
import { ResourcesType } from "../../Utils/types";
import { ThemeProps } from "../../Themes/Themes";

const ResourceBody = ({ theme }: { theme: ThemeProps }) => {
  const [resourceFiles, setResourceFiles] = useState<Array<ResourcesType>>([]);

  useEffect(() => {
    const fetchResourcesService = new FetchResourcesAPIService();
    fetchResourcesService
      .fetchResources()
      .then((resData) => {
        if (resData.data) {
          setResourceFiles(resData.data);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div>
      <div
        className={`text-2xl -mt-6 mb-8 font-bold`}
        style={{ color: theme.title }}
      >
        Resources
      </div>
      <div
        className={`grid grid-cols-2 gap-x-6 gap-y-6`}
        style={{ color: theme.resources.text }}
      >
        {resourceFiles &&
          resourceFiles.length > 0 &&
          resourceFiles.map((rec) => {
            return (
              <ResourceCard
                key={rec.resourceId}
                originalName={rec.originalName}
                resourceId={rec.resourceId}
                resourceLink={rec.resourceLink}
                theme={theme.resources}
              />
            );
          })}
        {(!resourceFiles || resourceFiles.length === 0) && (
          <div
            className={`mt-16 ml-60`}
            style={{ color: theme.resources.text }}
          >
            <NoCourse
              title="No Resources Available"
              icon="folder"
              key="folder"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ResourceBody;
