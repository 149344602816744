import Required from "./Required";

type props = {
  type: string;
  label: string;
  labelClass?: string;
  inputClass?: string;
  widthClass?: string;
  sizeClass?: string;
  mginClass?: string;
  holderClass?: string;
  isRequired?: boolean;
  pattern?: string;
  formprops?: any;
  displayErr?: boolean;
  accept?: string;
  diabled?: boolean;
};

const Textarea = ({
  type,
  label,
  labelClass,
  inputClass,
  widthClass,
  sizeClass,
  mginClass,
  holderClass,
  isRequired,
  pattern,
  formprops,
  displayErr,
  accept,
  diabled,
}: props) => {
  return (
    <>
      <div className={`text-base || ${labelClass} font-lato`}>
        <div>
          {isRequired && <Required />}
          {label}
        </div>
        <textarea
          cols="20"
          type={type}
          className={`w-full h-10 p-2 border-gray-400 border rounded-md mt-2 font-lato ${inputClass} ${widthClass} ${mginClass} `}
          placeholder={holderClass}
          pattern={pattern}
          accept={accept}
          {...formprops}
          disabled={diabled ? diabled : false}
        />
        {displayErr && <p className={`text-red-500`}>This field is Required</p>}
      </div>
    </>
  );
};

export default Textarea;
