import { SpinnerLoadingTypes } from '../types/index';

const { SPINNER_LOADING_TYPE } = SpinnerLoadingTypes;

type SpinnerType = {
    isLoading: boolean;
}

const intitalState = {
    isLoading: false
}

export default function spinnerLoadingReducer(state: SpinnerType = intitalState, action: { type: string, payload: any }) {
    const { type, payload } = action;
    switch (type) {
        case SPINNER_LOADING_TYPE:
            return {
                ...state,
                isLoading: payload
            }
        default:
            return state;
    }
}