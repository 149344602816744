import LoginImageTemplate from "../../Components/Utils/LoginImageTemplate";
import Logo from "../../Components/Common/Logo";
import { useState } from "react";

const SocialLoginInfo = () => {
  const matginTop = `mt-8`;
  const centerItem = `flex items-center justify-center`;
  const roleList = ["Student", "Trainer "];
  const genderList = ["Male", "Female "];
  const [isActive, setIsActive] = useState<string>();
  const [isGender, setIsGender] = useState<string>();
  return (
    <div className={`grid grid-cols-1 lg:grid-cols-2 font-lato`}>
      <div className={``}>
        <LoginImageTemplate />
      </div>
      <div className={`${centerItem} overflow-auto`}>
        <div>
          <div className={`${centerItem}`}>
            <Logo />
          </div>
          <div
            className={`grid justify-items-center text-lp_blue font-bold text-4xl ${matginTop}`}
          ></div>
          <div className={`mt-4`}>
            <div className={`p-1 text-xs text-red-600`}></div>
          </div>
          Select Gender:
          <div className="grid grid-cols-2 mr-8 mb-5 gap-6   mt-1">
            {genderList.map((value) => {
              return (
                <button
                  style={{
                    paddingLeft: "27px",
                    paddingRight: "27px",
                    background: isGender === value ? "#0B4089" : "white",
                    color: isGender === value ? "white" : "black",
                    borderColor: isGender === value ? "#0B4089" : "black",
                  }}
                  className={`h-auto w-80% ${centerItem} text-white border-2 rounded-md ml-4 px-2 py-2 mt-2`}
                  onClick={() => setIsGender(value)}
                >
                  {value}
                </button>
              );
            })}
          </div>
          Select Role:
          <div className="grid grid-cols-2 mr-8 gap-6 mt-1">
            {roleList.map((value) => {
              return (
                <button
                  style={{
                    paddingLeft: "27px",
                    paddingRight: "27px",
                    background: isActive === value ? "#0B4089" : "white",
                    color: isActive === value ? "white" : "black",
                    borderColor: isActive === value ? "#0B4089" : "black",
                  }}
                  className={`h-auto w-80% ${centerItem} text-white border-2 rounded-md ml-4 px-2 py-2 mt-2`}
                  onClick={() => setIsActive(value)}
                >
                  {value}
                </button>
              );
            })}
          </div>
          <div className={`mt-6`}>
            <button
              className={`w-full text-white bg-lp_blue text-xl p-2 rounded`}
            >
              Finish
            </button>
          </div>
          <div className={`mt-3 text-lp_blue   mb-5 text-base`}></div>
        </div>
      </div>
    </div>
  );
};

export default SocialLoginInfo;
