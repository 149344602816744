import APIService from "../API";
import { BaseUrl } from "../axios.config";
import { TrainerMyCourseAPIResult } from "../MyCourses/MyCourseAPIService";

const apiService = new APIService();
const fetchCourses: string = `${BaseUrl}/moderator/getCourses`;

class ModeratorService {
  fetchCourses = async () => {
    return await apiService.getReqs({
      APIUrl: fetchCourses,
      payload: {},
      resType: TrainerMyCourseAPIResult,
    });
  };
}

export default ModeratorService;
