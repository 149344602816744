import { Assets } from "../../Api/Assets";
import Footer from "../../Screens/Footer/Footer";

const LoginImageTemplate = () => {
  return (
    <div
      className={`hidden lg:flex bg-lp_pink h-screen w-1/2 relative md:fixed  items-center justify-center`}
    >
      <img src={Assets.SignUpBannerImage} alt={`LLP Banner`} />
      <div className="absolute bottom-8 w-full">
        <Footer />
      </div>
    </div>
  );
};

export default LoginImageTemplate;
