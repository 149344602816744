import AddingEmailIcons from "./AddingEmail";
import AddingPhoneIcons from "./AddingPhone";

const Contact = () => {
  return (
    <div className="m-auto w-full flex items-center justify-center">
      <div className="w-full text-black text-base sm:text-lg :text-2xl  grid items-center justify-center">
        <h1 className="flex place-self-center font-bold text-2xl pb-8">
          Contact US
        </h1>
        <h5 className="flex place-self-center text-base">
          We appreciate your interest in Her Springboard.
        </h5>
        <div className="flex place-self-center text-base mt-4">
          <span className="material-icons-outlined">email</span>
          <a
            className="underline text-blue-900 font-bold ml-2"
            href="mail:support@nimbusgurus.in"
          >
            support@nimbusgurus.in
          </a>
        </div>

        <div className="flex place-self-center text-base mt-4">
          <span className="material-icons-outlined">smartphone</span>

          <a
            className="underline text-blue-900 font-bold ml-2"
            href="tel:+918778449236"
          >
            (+91) 877 844 9236
          </a>
        </div>
      </div>
    </div>
  );
};
export default Contact;
