type InputFullProps = {
  type: string;
  label: string;
  value?: string;
  errCls?: string;
  placeHolder?: string;
  disabled?: boolean;
  maxLength?: number;
  labelStyle?: string;
  pattern?: string;

  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const InputFull = ({
  type,
  label,
  value,
  errCls,
  placeHolder,
  disabled,
  onBlur,
  onChange,
  maxLength,
  labelStyle,
  pattern,
}: InputFullProps) => {
  return (
    <>
      <div className={`text-sm  float-left font-normal ${labelStyle}`}>
        {label}
      </div>
      <input
        type={type}
        className={`w-full border border-gray-400 rounded mt-1 p-2 pb-3 ${errCls}`}
        placeholder={placeHolder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
        maxLength={maxLength}
        pattern={pattern}
      />
    </>
  );
};

export default InputFull;
