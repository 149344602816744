import axios, { AxiosError } from "axios";
import { ApplicationJsonConfigHeader, BaseUrl } from "../../Api/axios.config";
import { GeneralError } from "../../Api/Error.type";

export const getCourseList = async () => {
  try {
    const token: string | null = localStorage.getItem("token");
    const response = await axios.get(BaseUrl + "/getCoursesList", {
      headers: {
        ...ApplicationJsonConfigHeader,
        authorization: `Bearer ${token}`,
      },
      responseType: "json",
    });
    return response;
  } catch (e) {
    const err = e as AxiosError;
    const { error } = err && err.response?.data;
    const errorMsg = new GeneralError(error || "Something went wrong", 400);
    return { errorMsg, statusCode: errorMsg.statusCode };
  }
};

export const getStudentListForCourse = async (courseId: string) => {
  const token: string | null = localStorage.getItem("token");
  try {
    const response = await axios.get(
      BaseUrl + `/studentReport/studentsList?courseId=${courseId}`,
      {
        headers: {
          ...ApplicationJsonConfigHeader,
          authorization: `Bearer ${token}`,
        },
        responseType: "json",
      }
    );
    return response;
  } catch (e) {
    const err = e as AxiosError;
    const { error } = err && err.response?.data;
    const errorMsg = new GeneralError(error || "Something went wrong", 400);
    return { errorMsg, statusCode: errorMsg.statusCode };
  }
};

export const getStudentReport = async ({ courseId, studentId, name }: any) => {
  const token: string | null = localStorage.getItem("token");
  try {
    const response = await axios.get(
      BaseUrl +
        `/studentReport?courseId=${courseId}&studentId=${studentId}&name=${name}`,
      {
        headers: {
          ...ApplicationJsonConfigHeader,
          authorization: `Bearer ${token}`,
        },
        responseType: "json",
      }
    );
    return response;
  } catch (e) {
    const err = e as AxiosError;
    const { error } = err && err.response?.data;
    const errorMsg = new GeneralError(error || "Something went wrong", 400);
    return { errorMsg, statusCode: errorMsg.statusCode };
  }
};
