import APIService from "../API";
import { BaseUrl } from "../axios.config";
import { NetworkError, Payload } from "../Error.type";

const fetchCoursesURL: string = `${BaseUrl}/course/exploreCourses`;
const apiService = new APIService();

export type courseExplorerResType = {
  data?: any;
  statusCode: number;
};

export class CourseExplorerAPIResult
  implements Payload<courseExplorerResType, NetworkError>
{
  statusCode: number;
  data?: courseExplorerResType;
  errorMsg?: NetworkError;

  constructor(
    statusCode: number,
    data?: courseExplorerResType,
    errorMsg?: NetworkError
  ) {
    this.statusCode = statusCode;
    this.data = data;
    this.errorMsg = errorMsg;
  }
}

type CourseExplorerReqType = {
  rating: number;
  enrolledCount: number;
};

// Fetch courses list for Course explorer page
export default class CourseExplorerAPIService {
  fetchCourseDetail = async (reqData: CourseExplorerReqType) => {
    let payload = {};
    const { rating, enrolledCount } = reqData;
    if (rating !== 0) {
      payload = { ...payload, rating };
    }
    if (enrolledCount !== 0) {
      payload = { ...payload, enrolledCount };
    }
    return await apiService.getReqWithParams({
      APIUrl: fetchCoursesURL,
      payload,
      resType: CourseExplorerAPIResult,
    });
  };
  searchCourse = async (search: string) => {
    const searchCoursesURL: string = `${BaseUrl}/course/searchCourse?course=${search}`;
    let payload = {};
    return await apiService.getReqWithParams({
      APIUrl: searchCoursesURL,
      payload,
      resType: CourseExplorerAPIResult,
    });
  };
}
