import { useEffect, useState } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import NoCourse from "../../Components/Common/NoCourse";
import { ErrorMsgToaster } from "../../Components/Utils/Toaster";
import AddLessonCard from "./AddLesson";
import AddCourse from "./AddNewcourse";
import ViewChildComps from "./ViewChildComps";

type props = {
  bgClass: String;
  courseContent: any;
  dropedContent: any;
  deleteItem: any;
  setCourseContent: any;
};

const CentreCourse = ({
  bgClass,
  courseContent,
  dropedContent,
  deleteItem,
  setCourseContent,
}: props) => {
  const [showViewComp, setShowViewComp] = useState<boolean>(false);
  const [paramKeyId, setParamKeyId] = useState<string>("");
  const [paramType, setParamType] = useState<string>("");
  const [lessonName, setLessonName] = useState<string>("");
  const [lessonIndex, setLessonIndex] = useState<number>(100000);

  useEffect(() => {}, [showViewComp]);

  return (
    <div className="">
      <div
        className={` flex flex-cols-2 justify-items-center  w-full h-16  rounded-md bg-lp_pink-dash_tail   font-Lato `}
      >
        <div
          className={`h-8 w-full m-3 font-Lato text-lg font-bold opacity-40`}
        >
          Course Content
        </div>
        <div className="w-full flex">
          <input
            className={` h-8 w-9/12 border-solid border-gray-400 border rounded-md  mt-3 font-Lato pl-2`}
            placeholder="Lesson Name"
            value={lessonName}
            onChange={(e) => setLessonName(e.target.value)}
          />
          <button
            className={`h-8 w-2/12 m-3 bg-lp_blue border rounded-md text-base text-white cursor-pointer`}
            onClick={(e) => {
              e.preventDefault();
              if (lessonName) {
                setCourseContent((pre: Array<any>) => {
                  pre.push({
                    itemType: "Lesson",
                    title: lessonName,
                    id: lessonIndex.toString(),
                  });
                  setLessonName("");
                  return pre;
                });
                setLessonIndex((pre) => ++pre);
              } else {
                ErrorMsgToaster({
                  error: "Lesson name can't be empty",
                  statusCode: 404,
                });
              }
            }}
          >
            Add
          </button>
        </div>
      </div>

      <Droppable droppableId="items" type="COURSES">
        {(provided: any) => (
          <ul
            className="characters my-4 h-96 overflow-scroll"
            {...provided?.droppableProps}
            ref={provided?.innerRef}
          >
            {courseContent?.length === 0 ? (
              <div className="py-12 pl-10">
                <NoCourse title="Added course details by draging lesson, topic and quiz from right pane" />
              </div>
            ) : (
              <></>
            )}
            {courseContent?.map(
              ({ id, title, itemType, keyId }: any, index: any) => {
                return (
                  <Draggable key={id} draggableId={id} index={index}>
                    {(provided: any) => (
                      <li
                        className="truncate"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        {itemType === "Lesson" && (
                          <AddLessonCard
                            id={id}
                            title={title}
                            deleteItem={deleteItem}
                            setCourseContent={setCourseContent}
                          />
                        )}

                        {itemType === "Topic" && (
                          <AddCourse
                            value={title}
                            bgClass="bg-lp_pink-dash_tail "
                            valueClass="x"
                            id={id}
                            deleteItem={deleteItem}
                            keyId={keyId}
                            subCType="topic"
                            setShowViewComp={setShowViewComp}
                            setParamType={setParamType}
                            setParamKeyId={setParamKeyId}
                          />
                        )}

                        {itemType === "Quiz" && (
                          <div className="ml-6">
                            <AddCourse
                              value={title}
                              bgClass="bg-lp_yellow-dash_tail "
                              valueClass="x"
                              id={id}
                              deleteItem={deleteItem}
                              keyId={keyId}
                              subCType="quiz"
                              setShowViewComp={setShowViewComp}
                              setParamType={setParamType}
                              setParamKeyId={setParamKeyId}
                            />
                          </div>
                        )}
                      </li>
                    )}
                  </Draggable>
                );
              }
            )}
            {provided.placeholder}
          </ul>
        )}
      </Droppable>
      <div className="custom-temp {state.items2.length > 1 ? 'h-100' : 'h-20'} w-2/2 bg-lp_gray-dash_tail_trainer mt-1 ml-2 mr-3 border-solid border-gray-500 border rounded-md font-Lato">
        <Droppable droppableId="items2" type="COURSES">
          {(provided) => {
            return (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {dropedContent?.map(({ id, title }: any, index: any) => {
                  if (dropedContent.length > 1 && id != "DND") {
                    return (
                      <Draggable key={id} draggableId={id} index={index}>
                        {(provided) => {
                          return (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <div
                                className={`h-10 border-solid border-gray-400 border rounded-md m-3 font-Lato ${bgClass}`}
                              >
                                <div className="pt-1 pl-3 text-base float-left truncate">
                                  {title}
                                </div>
                              </div>
                            </div>
                          );
                        }}
                      </Draggable>
                    );
                  } else {
                    return (
                      <div className="flex items-center justify-center h-20 w-2/2 bg-lp_gray-dash_tail_trainer ml-2 mr-3 py-20 font-Lato ">
                        Drop Area for Lesson Topic and Quiz
                      </div>
                    );
                  }
                })}
                {provided.placeholder}
              </div>
            );
          }}
        </Droppable>
      </div>
      <ViewChildComps
        type={paramType}
        isShown={showViewComp}
        keyId={paramKeyId}
        setShowViewComp={setShowViewComp}
      />
    </div>
  );
};

export default CentreCourse;
