import { useHistory, useLocation } from "react-router";
import { toast } from "react-toastify";
import AuthService from "../../Api/Auth/Auth";
import { ResetPasswordInstructionEligibilityResponseData } from "../../Api/Auth/Auth.types";
import Logo from "../../Components/Common/Logo";
import LoginImageTemplate from "../../Components/Utils/LoginImageTemplate";
import Routes from "../../Routes/Routes";
import "./ResetPassword.css";

const matginTop = `mt-14`;
const centerItem = `flex items-center justify-center`;

const ResetPasswordInstruction = () => {
  const props =
    useLocation<ResetPasswordInstructionEligibilityResponseData>().state;
  const history = useHistory();
  const token: string | null = localStorage.getItem("token");
  if (token) {
    history.push("/dashboard");
  }

  const handleResendMail = async () => {
    const response = await new AuthService().resetPassword(props.email);
    if (response.data?.data) {
      toast.success("Instruction sent successfully. Please check you inbox", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  // if (props.data) {
  return (
    <div className={`grid grid-cols-1 lg:grid-cols-2 font-lato`}>
      <div className={``}>
        <LoginImageTemplate />
      </div>
      <div className={`${centerItem} overflow-auto`}>
        <div>
          <div className={`${centerItem}`}>
            <Logo />
          </div>
          <div
            className={`grid justify-items-center text-lp_blue font-bold text-4xl ${matginTop}`}
          >
            Check your Email
          </div>
          <div className={`mt-12 w-2/3  text-xl text-center m-auto`}>
            We have sent password recover instructions to your Email Address.
          </div>
          <div
            className={`${matginTop} text-lp_blue text-base text-center resendLink`}
            onClick={handleResendMail}
          >
            Didn’t receive the email? Click here to resend
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "1rem",
            }}
          >
            <button
              className={`w-full mb-5 mx-3 text-white bg-lp_blue text-xl p-2 rounded`}
              onClick={() => history.push(Routes.signin)}
            >
              BACK TO SIGN IN
            </button>
          </div>
        </div>
      </div>
    </div>
  );
  // } else {
  //   history.goBack();
  //   return <></>;
  // }
};

export default ResetPasswordInstruction;
