import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { NewTopicbody } from "../NewTopic/NewTopic";
import Modal from "react-modal";
import { NewLessonsbody } from "../Lessons/NewLesson";
import { NewQuizbody } from "../NewQuiz/NewQuiz";

type ViewCompType = {
  type: string;
  keyId: string;
  isShown: boolean;
  setShowViewComp: Dispatch<SetStateAction<boolean>>;
};

const RenderComponentList = (type: string, keyId: string) => {
  switch (type) {
    case "lesson":
      return <NewLessonsbody lessonId={keyId} lessonMode='view' />;
    case "topic":
      return <NewTopicbody topicId={keyId} topicMode='view' />;
    case "quiz":
      return <NewQuizbody quizId={keyId} quizMode='view' />;
    default:
      return <div>No Screens to Display</div>;
  }
};

const ViewChildComps = (props: ViewCompType) => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "90%",
      height: "85%",
    },
  };

  const [isModalOpen, setIsModalOpen] = useState<boolean>(true);

  return (
    <div>
      {props.isShown && (
        <Modal
          appElement={document.getElementById("root") as HTMLElement}
          isOpen={isModalOpen}
          style={customStyles}
          shouldCloseOnEsc={true}
        >
          <div className={`flex flex-col h-full`}>
            <div className={`grid grid-cols-1`}>
              <button
                className={`mb-6 flex flex-auto justify-end`}
                onClick={() => props.setShowViewComp(false)}
              >
                <i className={`material-icons text-3xl font-bold mr-8`}>
                  close
                </i>
              </button>
            </div>
            <div className={`h-full`}>
              {RenderComponentList(props.type, props.keyId)}
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default ViewChildComps;
