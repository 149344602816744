import { useEffect, useState } from "react";
import { type } from "os";
import { Link } from "react-router-dom";
import { ErrorMsgToaster } from "../../Components/Utils/Toaster";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import LessonsAPIService from "../../Api/Lessons/LessonsAPIService";
import NoCourse from "../../Components/Common/NoCourse";
import Menu from "../../Components/Template/Menu";
import Template from "../../Components/Template/Template";
import TopicView from "./TopicView";
import QuizView from "./QuizView";
import {
  itemData,
  LessonsResType,
  QuestionDetailsResType,
} from "../../Utils/types";
import CourseDetail from "../CourseDetail/CourseDetail";

const MyCourseEnrolled = (props: any) => {
  type courseData = {
    topicId?: string;
    lessonId?: string;
    quizId?: string;
    itemType: string;
    id: string;
    title: string;
    result?: string;
    correctQuestionsCount?: string;
    score?: number;
    content?: Array<courseData>;
  };

  const [lessonsData, setLessonsData] = useState<LessonsResType>();
  const [courseData, setCourseData] = useState<Array<courseData>>();
  const [courseDetail, setCourseDetail] = useState<any>();
  const [courseReview, setCourseReview] = useState<Array<any>>();
  const [currentData, setCurrentData] = useState<string>();
  const [topicData, setTopicData] = useState<itemData>();
  const [quizData, setQuizData] = useState<Array<QuestionDetailsResType>>();
  const lessonsAPIService = new LessonsAPIService();
  const courseId = props.match.params.courseId;
  const lessonId = props.match.params.lessonId;
  const itemType = props.match.params.type;

  useEffect(() => {
    lessonId && setCurrentData(itemType);
    lessonsAPIService
      .fetchLessonsDetail({ courseId, lessonId })
      .then((resData) => {
        if (resData?.data) {
          setLessonsData(resData.data);
        }
      })
      .catch((err) => ErrorMsgToaster(err));

    lessonsAPIService
      .getCourseDetails({ courseId, lessonId })
      .then((resData) => {
        if (resData?.data) {
          setCourseDetail(resData.data);
        }
      })
      .catch((err) => ErrorMsgToaster(err));

    lessonsAPIService
      .getCourseContent({ courseId, lessonId })
      .then((resData) => {
        if (resData?.data) {
          let accordionData: Array<courseData> = [];
          let temp: Array<courseData> = [];
          resData?.data?.contents?.forEach(
            (item: courseData, index: number) => {
              if (item.itemType === "Lesson") {
                if (accordionData.length > 0) {
                  accordionData[accordionData.length - 1].content = temp;
                  accordionData.push(item);
                } else {
                  accordionData.push(item);
                }
                temp = [];
              } else {
                temp.push(item);
              }
              if (index === resData?.data?.contents?.length - 1)
                if (accordionData.length > 0)
                  accordionData[accordionData.length - 1].content = temp;
            }
          );
          setCourseData(accordionData);
        }
      })
      .catch((err) => ErrorMsgToaster(err));
  }, []);

  useEffect(() => {
    const courseId = props.match.params.courseId;
    const lessonId = props.match.params.lessonId;
    const itemType = props.match.params.type;
    if (courseId && lessonId && itemType)
      lessonsAPIService
        .getLessonDetails({ courseId, lessonId, itemType })
        .then((resData) => {
          if (resData?.data) {
            if (itemType === "Topic") setTopicData(resData.data);
            else if (itemType === "Quiz") setQuizData(resData.data);
          }
        })
        .catch((err) => ErrorMsgToaster(err));
  }, [currentData]);

  const CourseDetailsBody = () => {
    return (
      <>
        {currentData !== undefined ? (
          <ItemView />
        ) : (
          <>
            {courseDetail && (
              <div className={`mb-6`}>
                <h4
                  className={`font-bold text-2xl mb-4`}
                  style={{ color: "#0b4089" }}
                >
                  {courseDetail?.title}
                </h4>
                <div
                  className={`w-full h-96`}
                  style={{ background: "#D8D8D8" }}
                >
                  <img src={courseDetail?.image} className={`w-full h-96`} />
                </div>
                <div>
                  {courseDetail?.description !== undefined ||
                  courseDetail?.description !== " " ? (
                    <div>
                      <p
                        className={`font-bold text-xl mt-4 `}
                        style={{ color: "#0b4089" }}
                      >
                        Description
                      </p>
                      <p
                        className={`text-black whitespace-pre-wrap mt-4 text-lg`}
                      >
                        {courseDetail?.description}
                      </p>
                    </div>
                  ) : (
                    <></>
                  )}

                  <p
                    className={`font-bold text-xl mt-4 `}
                    style={{ color: "#0b4089" }}
                  >
                    What You Learn
                  </p>
                  <p className={`text-black whitespace-pre-wrap mt-4 text-lg`}>
                    {courseDetail?.whatYouLearn}
                  </p>
                  <p
                    className={`font-bold text-xl mt-4 `}
                    style={{ color: "#0b4089" }}
                  >
                    What We Cover In Course
                  </p>
                  <p className={`text-black mt-4  whitespace-pre-wrap text-lg`}>
                    {courseDetail?.whatWeCoverInCourse}
                  </p>
                  <p
                    className={`font-bold text-xl mt-4 `}
                    style={{ color: "#0b4089" }}
                  >
                    Requirements
                  </p>
                  <p className={`text-black whitespace-pre-wrap mt-4 text-lg`}>
                    {courseDetail?.requirements}
                  </p>
                </div>
              </div>
            )}
            <CourseDetailSubMenu />
          </>
        )}
      </>
    );
  };

  const CourseDetailSubMenu = () => {
    return (
      <>
        <div
          style={{
            fontSize: "16px",
            lineHeight: "24px",
          }}
        >
          <Accordion
            allowMultipleExpanded
            allowZeroExpanded
            className={`border-0`}
          >
            {courseData?.map((rec, index) => {
              return (
                <AccordionItem
                  className={`rounded-3xl m-2 h-full `}
                  key={index}
                >
                  <AccordionItemHeading
                    className={`border-2 border-black font-semibold`}
                  >
                    <AccordionItemButton>
                      <span className="w-full">
                        {`Lesson ${index + 1} : ${rec?.title}`}
                      </span>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel className={`mt-2`}>
                    {rec?.content?.map(
                      (topicOrQuiz: courseData, index: number) => (
                        <Link
                          key={index}
                          to={`/lesson/${courseId}/${
                            topicOrQuiz?.lessonId ||
                            topicOrQuiz.topicId ||
                            topicOrQuiz.quizId
                          }/${topicOrQuiz.itemType}`}
                          onClick={() => {
                            const linkId =
                              topicOrQuiz?.lessonId ||
                              topicOrQuiz.topicId ||
                              topicOrQuiz.quizId;
                            setCurrentData(linkId);
                          }}
                        >
                          <div
                            className={`flex p-2 m-2 border-2 rounded-lg border-black font-semibold ${
                              (topicOrQuiz?.result === "Fail" &&
                                "bg-red-200") ||
                              (topicOrQuiz?.result === "Pass" && "bg-green-100")
                            }`}
                          >
                            {topicOrQuiz?.itemType?.toLowerCase() ===
                              "topic" && (
                              <i className={`material-icons mr-2`}>
                                play_lesson
                              </i>
                            )}
                            {topicOrQuiz?.itemType?.toLowerCase() ===
                              "quiz" && (
                              <i className={`material-icons mr-2`}>
                                help_outline
                              </i>
                            )}
                            {topicOrQuiz.title}
                            {topicOrQuiz?.result && (
                              <div className={`flex flex-row ml-8`}>
                                <h3>Score : </h3>
                                {topicOrQuiz?.score} /
                                {` ${topicOrQuiz?.result.toUpperCase()}`}
                              </div>
                            )}
                          </div>
                        </Link>
                      )
                    )}
                    {!rec?.content?.length && <NoCourse title="Nothing Here" />}
                  </AccordionItemPanel>
                </AccordionItem>
              );
            })}
          </Accordion>
        </div>
      </>
    );
  };

  const ItemView = () => {
    return (
      <div style={{ height: "90%" }}>
        {itemType === "Quiz" && (
          <QuizView courseId={courseId} quizData={quizData} quizId={lessonId} />
        )}
        {itemType === "Topic" && <TopicView topicData={topicData} />}
        {itemType === "Lesson" && `Lesson`}
      </div>
    );
  };

  return (
    <>
      <Template
        menu={<Menu selectedItem={"MY COURSES"} />}
        body={<CourseDetailsBody />}
        submenu={<CourseDetailSubMenu />}
      />
    </>
  );
};

export default MyCourseEnrolled;
