import { HandsOnData } from "../../Api/Dashboard/Dashboard.types";
import NoCourse from "../Common/NoCourse";

type UpcomingCourseCardProps = {
  data: HandsOnData[];
};

const UpcomingCourseTail = ({ data }: UpcomingCourseCardProps) => {
  return (
    <>
      <div className={`font-bold text-xl mt-6`}>Upcoming Hand On</div>
      {data ? (
        <div className={`mt-6 `}>
          {data?.map((item) => {
            return (
              <div
                key={item.id}
                className={`font-extrabold  text-xs md:text-xs  p-2 md:p-5  w-full max-h-screen lg:text-sm bg-white  rounded-md mt-3 md:flex`}
              >
                <div
                  className={`transform scale-150 flex mt-3 justify-center items-center`}
                >
                  <i className={`material-icons mr-2 ml-1 transform scale-150`}>
                    history_edu
                  </i>
                </div>
                <div className={`md:pl-4 mt-3 md:mt-1`}>
                  <div>{item.title}</div>
                  <div>{item.date}</div>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <NoCourse title='No Upcoming Course' icon={""} />
      )}
    </>
  );
};

export default UpcomingCourseTail;
