import { ContinueLearningData } from "../../Api/Dashboard/Dashboard.types";
import { ThemeProps } from "../../Themes/Themes";
import NoCourse from "../Common/NoCourse";

type UpcomingCourseCardProps = {
  data: ContinueLearningData[];
  theme: ThemeProps;
};

const ContinueCourseTail = ({ data, theme }: UpcomingCourseCardProps) => {
  return (
    <>
      <div className={`font-bold text-xl`}>Continue Learning</div>
      {data ? (
        <div className={`mt-6 `}>
          {data?.map((item) => {
            return (
              <div
                key={item.id}
                className={`font-extrabold text-xs md:text-xs lg:text-sm w-full max-h-screen p-4 md:p-1 rounded-md mt-3 md:flex`}
                style={{backgroundColor: theme.continueLearning.background}}
              >
                <div
                  className={`transform scale-150  flex justify-center  items-center`}
                >
                  <i
                    className={`material-icons ml-5 md:mb-1 mr-2 mb-5 md:mt-1 mt-3 transform scale-150`}
                  >
                    ondemand_video
                  </i>
                </div>
                <div className={`md:pl-4 mt-2 mb-2 ml-1 mr-2`}>
                  <div>{item.title}</div>
                  <div>{item.videoStatus}</div>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <NoCourse title='No Recommended Course' icon={""} />
      )}
    </>
  );
};

export default ContinueCourseTail;
