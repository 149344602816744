type templateProps = {
  menutrainer: JSX.Element;
  body: JSX.Element;
  submenu: JSX.Element;
};

const TemplateTrainers = ({ menutrainer, body, submenu }: templateProps) => {
  return (
    <div
      className={`grid grid-cols-12 gap-4 w-full h-screen font-lato bg-white`}
    >
      <div
        className={`flex flex-col items-center col-span-2 h-screen bg-lp_blue-light pt-8 overflow-scroll`}
      >
        {menutrainer}
      </div>
      <div className={`col-span-7 mt-10 py-10 pr-2 overflow-scroll`}>
        {body}
      </div>
      <div
        className={`col-span-3 h-screen bg-lp_pink py-10 px-4 overflow-scroll`}
      >
        {submenu}
      </div>
    </div>
  );
};

export default TemplateTrainers;
