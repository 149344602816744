import { courseExplorerTypes } from '../types/index';

const { COURSE_EXPLORER_TYPE } = courseExplorerTypes;

export type CourseListType = {
    rating: number,
    courseId: string,
    enrolled: number,
    description: string,
    title: string,
    image: string,
    category: string
}

const CourseExplorerAction = (data: Array<CourseListType>) => {
    return {
        type: COURSE_EXPLORER_TYPE,
        payload: data
    }
}

export { CourseExplorerAction };