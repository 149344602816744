const Spinner = () => {
  return (
    <>
      <div
        className={`h-screen w-screen z-20 text-4xl flex flex-auto justify-center items-center overflow-x-hidden overflow-y-hidden`}
      >
        {<h1>Loading....</h1>}
      </div>
      )
    </>
  );
};

export default Spinner;
