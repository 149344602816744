// import MyCourseCard from "../../Components/Template/MyCourseCard";
// import Menu from "../../Components/Template/Menu";
// import Template from "../../Components/Template/Template";
// import StarRating from "../../Components/Feedback/StarRating";
// import ContinueCourseTail from "../../Components/Template/ContinueCourseTail";
// import DisplayContent from "../../Components/Course/DisplayContent";
// import CourseCard from "../../Components/Course/Card";
import React, { useEffect, useState } from "react";
import { Rating } from "react-simple-star-rating";
import FeedbackAPIService from "../../Api/Feedback/FeedbackAPIService";
import {
  ErrorMsgToaster,
  SuccessMsgToaster,
} from "../../Components/Utils/Toaster";
import Required from "../../Components/Common/Required";
import { ThemeProps } from "../../Themes/Themes";

const QuizFeedback = ({
  title,
  courseId,
  theme
}: {
  title?: string;
  courseId?: string;
  theme: ThemeProps;
}) => {
  return <FeedbackBody courseTitle={title} courseId={courseId} theme={theme}/>;
};

const ratingCatg = [
  "Course Video",
  "Course Material",
  "Understandability",
  "Concept Clarity",
  "Quiz",
  "Overall",
];

type RatingParamsType = {
  title: string;
  key: string;
  rating: number;
};

const FeedbackBody = ({
  courseTitle,
  courseId,
  theme,
}: {
  courseTitle?: string;
  courseId?: string;
  theme: ThemeProps;
}) => {
  const [ratingParams, setRatingsParams] = useState<Array<RatingParamsType>>(
    []
  );
  const [feedbackValue, setFeedbackValue] = useState<string>("");
  const [count, setCount] = useState<number>(0);
  const [maxLength, setMaxLength] = useState<number>(320);

  useEffect(() => {
    const ratingsIntialValue = ratingCatg.map((rec) => {
      const key = rec.toLowerCase().replaceAll(" ", "").trim();
      return { title: rec, key, rating: 0 };
    });
    setRatingsParams(ratingsIntialValue);
  }, []);

  const handleRating = (rate: number, key: string) => {
    let data = ratingParams;
    data = data.map((rec) => {
      if (rec.key === key) {
        return { ...rec, rating: rate };
      } else {
        return { ...rec };
      }
    });
    setRatingsParams(data);
  };

  const handleFeedback = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCount(e.target.value.length);
    setFeedbackValue(e.target.value);
  };

  const handleSubmit = () => {
    const totalRating = ratingParams.reduce(
      (prev, curr, currIndex, arr) => prev + curr.rating,
      0
    );
    if (totalRating === 0) {
      ErrorMsgToaster({ error: "Course Rating is Required", statusCode: 400 });
      return;
    }
    if (!feedbackValue) {
      ErrorMsgToaster({ error: "Feedback is Required", statusCode: 400 });
      return;
    }

    const payload = {
      courseId,
      feedback: feedbackValue,
      rating: totalRating && Number((totalRating / 6).toPrecision(2)),
    };
    const feedbackAPIService = new FeedbackAPIService();
    feedbackAPIService
      .postCourseRating(payload)
      .then(({ data, errorMsg }) => {
        if (data) SuccessMsgToaster(data);
        if (errorMsg) ErrorMsgToaster(errorMsg);
        handleFormCancel();
      })
      .catch((err) => console.log(err));
  };

  const handleFormCancel = () => {
    let data = ratingParams;
    data = data.map((rec) => ({ ...rec, rating: 0 }));
    setRatingsParams(data);
    setFeedbackValue("");
    setCount(0);
  };

  return (
    <div>
      <div className={`text-2xl font-bold`} style={{color: theme.title}}>
        {courseTitle}
      </div>
      <div className={` text-black grid grid-cols-2 px-4`}>
        {ratingParams &&
          ratingParams.length > 0 &&
          ratingParams.map((rec) => (
            <div
              className={`text-base md:text-xl font-bold mt-5 grid grid-cols-1`}
              key={rec.key}
            >
              {rec.title}
              <Rating
                className={`mt-1`}
                key={rec.key}
                onClick={(e) => handleRating(e, rec.key)}
                ratingValue={rec.rating}
              />
            </div>
          ))}
      </div>
      <div className={`text-lg font-bold mt-6 ml-4 text-black`}>
        <Required />
        Your Feedback
      </div>
      <label className="feedback" htmlFor="feedback"></label>
      <div className={`flex flex-col`}>
        <textarea
          className={` h-40 w-11/12 mt-5 -ml-6 p-2 text-lg  border-gray-200 border-2 self-center rounded-lg`}
          id="address"
          name="address"
          maxLength={maxLength}
          onChange={(e) => handleFeedback(e)}
        />
        <div
          className={`text-lg mt-2 place-self-end mr-8`}
        >{`(${count}/${maxLength})`}</div>
      </div>
      <div className={`m-4 flex flex-row float-right`}>
        <button
          onClick={handleFormCancel}
          className={`h-10 text-xl w-32 bg-gray-500 rounded-md font-Lato font-bold text-white`}
        >
          RESET
        </button>
        <button
          type="submit"
          onClick={handleSubmit}
          className={`h-10 text-xl w-28 ml-4 rounded-md font-Lato font-bold text-white`}
          style={{backgroundColor: theme.button.background}}
        >
          SAVE
        </button>
      </div>
    </div>
  );
};

export default QuizFeedback;
