import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import Logo from "../Common/Logo";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import images from "../../Assets/searchicon.png";
// import MyCartButton from "./MyCartButton";
import { UserInfoAction } from "../../redux/action";
import ThemeContext from "../../Context/ThemeContext";
import MyCartButton from "./MyCartButton";

type MenuPropsType = {
  selectedItem: string;
  userInfo?: any;
  cartData?: any;
  clearUserInfo: (data: any) => void;
};

const Menu = (props: MenuPropsType) => {
  const { selectedItem, cartData } = props;
  const history = useHistory();
  const [search, setSearch] = useState("");
  const selectedStyle = `text-white rounded-md w-full text-xs lg:text-sm py-2 px-3`;
  let menuItem = [
    { title: "DASHBOARD", link: "/dashboard", icon: "space_dashboard" },
    { title: "MY COURSES", link: "/myCourses", icon: "menu_book" },
    { title: "RESOURCES", link: "/resources", icon: "device_hub" },
    { title: "FEEDBACK", link: "/feedback", icon: "thumb_up_off_alt" },
    { title: "MY PROFILE", link: "/profile", icon: "person" },
  ];

  const [loginType, setLoginType] = useState<string>("Student");

  useEffect(() => {
    const { registerType } = props.userInfo;
    registerType && setLoginType(registerType);
  }, []);

  const serachCourse = () => {
    const searchurl = `/courses?s=${search}`;
    history.push(searchurl);
  };

  return (
    <ThemeContext.Consumer>
      {(context) => {
        const selectedCssStyle: React.CSSProperties = {
          backgroundColor: context.theme?.Menu.select,
        };
        return (
          <>
            <Logo />
            <br></br>
            <br></br>
            <div className="flex flex-cols-2 justify-items-center bg-white w-10/12 h-10  rounded-md border border-gray-400">
              <input
                className="text-sm items-center  w-full h-full pl-2 border-none outline-none italic"
                type="text"
                placeholder="Search Courses"
                value={search}
                onKeyUp={(e) => {
                  if (e.key === "Enter") serachCourse();
                }}
                onChange={(e) => setSearch(e.target.value)}
              ></input>
              <button type="button">
                <img
                  className="float-right  items-center h-5 w-5 mr-2 "
                  src={images}
                  alt="my-img"
                  onClick={() => {
                    serachCourse();
                  }}
                />
              </button>
            </div>
            <br></br>
            <Link to="/mycart" className={`px-7 w-full`}>
              <MyCartButton />
            </Link>
            <Link
              className="w-10/12 h-auto py-3 justify-center items-center text-center bg-green-500 rounded-md text-white mt-5"
              to="/checkout"
            >
              <button
                className={`w-full ${
                  cartData && cartData.cart && cartData.cart.length === 0
                    ? `cursor-not-allowed`
                    : `cursor-pointer`
                }`}
                disabled={
                  cartData && cartData.cart && cartData.cart.length === 0
                }
              >
                CHECKOUT
              </button>
            </Link>
            <Link to={"/courses"} className="w-10/12 items-center ">
              <button
                className={` text-sm lg:text-sm font-bold mt-8 rounded-md w-full h-auto py-3 text-white`}
                style={{ backgroundColor: context.theme?.Menu.block1 }}
              >
                EXPLORE COURSES
              </button>
            </Link>

            <div
              className={`flex flex-col mt-4  text-sm lg:text-sm font-bold w-full lg:w-11/12 text-gray-700`}
            >
              {menuItem.map((item, index) => {
                const isSelected =
                  selectedItem === item.title ? selectedStyle : "";
                const _selectedCssStyle =
                  selectedItem === item.title ? selectedCssStyle : {};
                return (
                  <Link to={item.link} key={index}>
                    <div
                      className={`mt-6 flex px-2 ${isSelected}`}
                      style={_selectedCssStyle}
                    >
                      <i className={`material-icons mr-3`}>{item.icon}</i>
                      {item.title}
                    </div>
                  </Link>
                );
              })}
              <div
                className={`mt-6 px-2 cursor-pointer flex`}
                onClick={() => {
                  props.clearUserInfo({
                    firstName: "",
                    lastName: "",
                    registerType: "",
                    setGoal: undefined,
                  });
                  localStorage.removeItem("token");
                  localStorage.removeItem("registerType");
                  history.push("/signin");
                }}
              >
                <i className={`material-icons mr-2`}>logout</i>LOGOUT
              </div>
            </div>
          </>
        );
      }}
    </ThemeContext.Consumer>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userInfo: state && state.userInfoReducer,
    cartData: state && state.myCartReducer,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    clearUserInfo: (data: any) => {
      dispatch(UserInfoAction(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
