type props = {
  name: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const InputRadio = ({ name, value, onChange }: props) => {
  return (
    <>
      <div className={`text-xl mt-3`}>
        <input
          type="radio"
          className={`transform scale-x-150 scale-y-150 ml-4`}
          name={name}
          value={value}
          id={value}
          defaultChecked={value === "Student"}
          onChange={onChange}
        />
        <label htmlFor={value} className={`ml-2 text-sm font-normal`}>
          {value}
        </label>
      </div>
    </>
  );
};

export default InputRadio;
