import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import Logo from "../Common/Logo";
import { UserInfoAction } from "../../redux/action";
import { connect } from "react-redux";
import { useState } from "react";
import Collapse from "@kunukn/react-collapse";
import ThemeContext from "../../Context/ThemeContext";
import accordianContext from "../../Context/AccordianContext";
import AccordianContext from "../../Context/AccordianContext";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

const MenuTrainer = ({
  selectedItem,
  clearUserInfo,
}: {
  selectedItem: string;
  clearUserInfo: any;
}) => {
  const history = useHistory();
  const trainerMenuItem1 = [
    { title: "MY PROFILE", link: "/profile", icon: "account_circle" },
    {
      title: "DASHBOARD",
      link: "/dashboard",
      icon: "space_dashboard",
    },
  ];
  const trainerMenuItem2 = [
    {
      title: "QUESTION BANK",
      link: "/trainer/questions",
      icon: "question_answer",
    },
    { title: "STUDENTS", link: "/trainer/students", icon: "person" },
    { title: "MY REVIEWS", link: "/trainer/reviews", icon: "reviews" },
    {
      title: "MY EARNINGS",
      link: "/trainer/my-earnings",
      icon: "cast_for_education",
    },
    { title: "FEEDBACK", link: "/feedback", icon: "thumb_up_off_alt" },
  ];

  const trainerMenuAccordianItem = [
    { title: "MY COURSES", link: "/trainer/mycourses", icon: "menu_book" },
    { title: "TOPICS", link: "/trainer/topic", icon: "topic" },
    { title: "QUIZ", link: "/trainer/quiz", icon: "quiz" },
  ];

  const selectedStyle = ` text-white rounded-md w-full px-2  py-2`;

  return (
    <ThemeContext.Consumer>
      {(context) => {
        const selectedCssStyle: React.CSSProperties = {
          backgroundColor: context.theme?.Menu.select,
        };

        return (
          <>
            <Logo />

            <div
              className={`flex flex-col -mt-3 h-auto text-sm lg:text-sm font-bold w-full lg:w-11/12  text-gray-700`}
            >
              {trainerMenuItem1.map((item, index) => {
                const isSelected =
                  selectedItem === item.title ? selectedStyle : "";
                const _selectedCssStyle =
                  selectedItem === item.title ? selectedCssStyle : {};
                return (
                  <Link to={item.link} key={index}>
                    <div
                      className={`mt-8 flex px-2 ${isSelected}`}
                      style={_selectedCssStyle}
                    >
                      <i className={`material-icons mr-3`}>{item.icon}</i>
                      {item.title}
                    </div>
                  </Link>
                );
              })}
              <AccordianContext.Consumer>
                {(context) => {
                  return (
                    <>
                      <div
                        className={`flex flex-col h- mt-8 cursor-pointer text-sm lg:text-sm font-bold w-full lg:w-11/12  text-gray-700`}
                      >
                        <div
                          className={` flex text-sm flow-root px-2`}
                          onClick={() => {
                            if (context.accordianToggled !== undefined)
                              context.accordianToggled();
                          }}
                        >
                          <i className={`material-icons float-left mr-3`}>
                            menu_book
                          </i>
                          COURSES
                          {context.isOpen ? (
                            <IoIosArrowUp className="mt-1 float-right" />
                          ) : (
                            <IoIosArrowDown className="mt-1 float-right" />
                          )}
                        </div>
                      </div>
                      <Collapse isOpen={context.isOpen} className="mt-3 h-auto">
                        {trainerMenuAccordianItem.map((item, index) => {
                          const isSelected =
                            selectedItem === item.title ? selectedStyle : "";
                          const _selectedCssStyle =
                            selectedItem === item.title ? selectedCssStyle : {};

                          return (
                            <div className="p-1 ml-4">
                              <Link to={item.link} key={index}>
                                <div
                                  className={`mt-3 flex px-2 ${isSelected}`}
                                  style={_selectedCssStyle}
                                >
                                  <i className={`material-icons mr-3`}>
                                    {item.icon}
                                  </i>
                                  {item.title}
                                </div>
                              </Link>
                            </div>
                          );
                        })}
                      </Collapse>
                    </>
                  );
                }}
              </AccordianContext.Consumer>

              <div className="-mt-3">
                {trainerMenuItem2.map((item, index) => {
                  const isSelected =
                    selectedItem === item.title ? selectedStyle : "";
                  const _selectedCssStyle =
                    selectedItem === item.title ? selectedCssStyle : {};

                  return (
                    <Link to={item.link} key={index}>
                      <div
                        className={`mt-8 text-sm flex px-2 ${isSelected}`}
                        style={_selectedCssStyle}
                      >
                        <i className={`material-icons mr-3`}>{item.icon}</i>
                        {item.title}
                      </div>
                    </Link>
                  );
                })}
              </div>

              <div
                className={`mt-8 mb-6 px-2 cursor-pointer flex`}
                onClick={() => {
                  clearUserInfo({
                    firstName: "",
                    lastName: "",
                    registerType: "",
                    setGoal: undefined,
                  });
                  localStorage.removeItem("token");
                  localStorage.removeItem("registerType");
                  history.push("/signin");
                }}
              >
                <i className={`material-icons text-sm mr-2 `}>logout</i>LOGOUT
              </div>
            </div>
          </>
        );
      }}
    </ThemeContext.Consumer>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userInfo: state && state.userInfoReducer,
    cartData: state && state.myCartReducer,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    clearUserInfo: (data: any) => {
      dispatch(UserInfoAction(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuTrainer);
