const validEmail = new RegExp(
  "^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+[.]+[a-zA-Z]+$"
);
const validPassword = new RegExp("^(?=.*?[A-Za-z])(?=.*?[0-9]).{6,}$");
const validName = new RegExp("^[a-zA-Z ]{3,30}$");

export const validateInput = (type: string, value: string) => {
  switch (type) {
    case "email":
      return validEmail.test(value) ? "" : "Enter vaild e-mail";
    case "password":
      return validPassword.test(value) ? "" : "Enter valid password";
    case "name":
      return validName.test(value) ? "" : "Enter valid Name";
    default:
      return "Undefined field";
  }
};
