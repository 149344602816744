import { toast } from "react-toastify";
import { addCartDataRes } from "../../Api/Cart/CartAPIService";
import { NetworkError } from "../../Api/Error.type";

export const SuccessMsgToaster = (data: addCartDataRes) => {
  toast.success(data.data!, {
    position: "top-right",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

export const ErrorMsgToaster = (msg: NetworkError) => {
  toast.error(msg.error!, {
    position: "top-right",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};
