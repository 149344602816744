import { Dispatch, SetStateAction, useState } from "react";
import AddingDeleteIcons from "./AddingDelete";

type props = {
  title: string;
  id: string;
  deleteItem: any;
  setCourseContent: any;
};
const AddLessonCard = ({ title, id, deleteItem, setCourseContent }: props) => {
  const [isEdit, setEdit] = useState<boolean>(false);
  const [lessonName, setLessonName] = useState<string>(title);
  const saveLesson = () => {
    setEdit(false);
    setCourseContent((pre: any) => {
      return pre.map((item: any) => {
        if (item.id === id) {
          return { ...item, title: lessonName };
        } else {
          return item;
        }
      });
    });
  };
  return (
    <>
      <div
        className={` flex flex-cols-2 h-10 mr-3 ml-2 border-gray-400 border-solid border rounded-md bg-lp_blue-dash_tail font-Lato mt-3`}
      >
        {!isEdit ? (
          <>
            <div
              className='flex float-left pl-3 mt-1 w-full truncate'
              title={title}
            >
              <span>{title}</span>
            </div>
            <div className='flex float-right justify-self-end mr-2'>
              {/* <AddingDropIcons /> */}
              <span
                className='material-icons-outlined m-2 cursor-pointer'
                onClick={() => setEdit(true)}
              >
                edit
              </span>

              <AddingDeleteIcons id={id} deleteItem={deleteItem} />
            </div>
          </>
        ) : (
          <>
            <input
              className='w-full p-2'
              autoFocus
              value={lessonName}
              onChange={(e) => setLessonName(e.target.value)}
            />
            <span
              className='material-icons-outlined m-2'
              onClick={() => setEdit(false)}
            >
              close
            </span>
            <span
              className='material-icons-outlined my-2 mr-2'
              onClick={() => saveLesson()}
            >
              save
            </span>
          </>
        )}
      </div>
    </>
  );
};
export default AddLessonCard;
