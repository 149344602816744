import Menu from "../../Components/Template/Menu";
import Template from "../../Components/Template/Template";
import Payment from "./PaymentScreencomponent";
const PaymentScreens = () => {
  const menu = <Menu selectedItem={``} />;
  return (
    <Template menu={menu} body={<PaymentScreensbody />} submenu={<SubMenu />} />
  );
};

const PaymentScreensbody = () => {
  return (
    <div className={`flex flex-col text-gray-700`}>
      <div className="text-lp_blue text-center text-2xl font-Lato font-bold -mt-10 ">
        Payment Successful
      </div>
      <br></br>
      <div className=" text-md text-black font-Lato font-normal   ml-5">
        Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
        nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat
        volutpat. Ut wisi enim ad minim laoreet dolore magna aliquam erat
        volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation
        ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat
      </div>
      <br></br>
      <div className=" text-lp_blue  text-2xl font-Lato font-bold mt-5 ml-5">
        Your Order ID : 99999
      </div>
      <div>
        <br></br>

        <Payment></Payment>
        <br></br>

        <Payment></Payment>
        <br></br>
        <Payment></Payment>
        <br></br>
      </div>

      <button
        className="h-10 w-48 bg-lp_blue text-white text-xl  rounded-md font-Lato font-bold place-self-end mr-3  "
        type="button"
      >
        START LEARNING
      </button>
    </div>
  );
};

const SubMenu = () => {
  return <></>;
};
export default PaymentScreens;
