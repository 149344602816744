const Quizescomponent = () => {
  return (
    <>
      <div className=" text-black text-base md:text-lg font-bold font-Lato w-full  border-solid border-2 border-gray-400 pt-3 pl-3">
        Ability to use newer syntax and offers reliability
      </div>
    </>
  );
};
export default Quizescomponent;
