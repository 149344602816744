import { combineReducers } from 'redux';
import { myCartReducer, courseExplorerReducer, spinnerLoadingReducer, userInfoReducer } from './reducer/index';

const rootReducer = combineReducers({
    myCartReducer: myCartReducer,
    courseExplorerReducer: courseExplorerReducer,
    spinnerReducer: spinnerLoadingReducer,
    userInfoReducer: userInfoReducer
});

export default rootReducer;
