import APIService from "../API";
import { BaseUrl } from "../axios.config";
import { NetworkError, Payload } from "../Error.type";

const fetchCourseDetailURL: string = `${BaseUrl}/course/courseDetails`;
const fetchCourseReviewsURL: string = `${BaseUrl}/course/courseDetails/reviews`;
const checkIfEnrolledURL: string = `${BaseUrl}/course/checkEnrolled`;
const topSellingCourse: string = `${BaseUrl}/trainer/topSellingCourse`;

const apiService = new APIService();

export type courseDetailResType = {
  data?: any;
  statusCode: number;
};

export class CourseDetailAPIResult
  implements Payload<courseDetailResType, NetworkError>
{
  statusCode: number;
  data?: courseDetailResType;
  errorMsg?: NetworkError;

  constructor(
    statusCode: number,
    data?: courseDetailResType,
    errorMsg?: NetworkError
  ) {
    this.statusCode = statusCode;
    this.data = data;
    this.errorMsg = errorMsg;
  }
}

// Promise<CourseDetailAPIResult>

export default class CourseDetailAPIService {
  fetchCourseDetail = async (courseId: string) => {
    return await apiService.getReqWithParams({
      APIUrl: fetchCourseDetailURL,
      payload: { courseId },
      resType: CourseDetailAPIResult,
    });
  };

  getTopSellingCourse = async (courseId: string) => {
    return await apiService.getReqWithParams({
      APIUrl: topSellingCourse,
      payload: { courseId },
      resType: CourseDetailAPIResult,
    });
  };

  fetchCourseReviews = async (courseId: string) => {
    return await apiService.getReqWithParams({
      APIUrl: fetchCourseReviewsURL,
      payload: { courseId },
      resType: CourseDetailAPIResult,
    });
  };

  checkIfEnrolled = async (courseId: string) => {
    return await apiService.postReqs({
      APIUrl: checkIfEnrolledURL,
      payload: { courseId },
      resType: CourseDetailAPIResult,
    });
  };
}
