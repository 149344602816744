import images from "../../Assets/lock1.png";

const Icons = () => {
  return (
    <div>
      <img className="h-5 w-5  mr-2" src={images} alt="my-img" />
    </div>
  );
};
export default Icons;
