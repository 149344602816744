import { useEffect, useState } from "react";
import {
  DashboardResult,
  DashboardService,
} from "../../Api/Dashboard/Dashboard";
import {
  // DashboardBodyData,
  DashboardDetailData,
  SubMenuData,
  // DashboardNotificationToastData,
  // DashboardNotificationToastDataList,
} from "../../Api/Dashboard/Dashboard.types";
import { connect } from "react-redux";
import NoCourse from "../../Components/Common/NoCourse";
import ContinueCourseTail from "../../Components/Template/ContinueCourseTail";
import CourseCard from "../../Components/Template/CourseTail";
import Menu from "../../Components/Template/Menu";
import Template from "../../Components/Template/Template";
import UpcomingCourseTail from "../../Components/Template/UpcomingCourseTail";
import {
  CartAction,
  SpinnerLoadingAction,
  UserInfoAction,
} from "../../redux/action";
import CartAPIService from "../../Api/Cart/CartAPIService";
import {
  ErrorMsgToaster,
  SuccessMsgToaster,
} from "../../Components/Utils/Toaster";
import TrendingCourse from "../../Components/Template/TrendingCourse";
import { Link } from "react-router-dom";
import AuthService from "../../Api/Auth/Auth";
import { ThemeProps } from "../../Themes/Themes";
// import { AuthenticationError, GeneralError } from "../../Api/Error.type";
// import DefaultError from "../../Components/Error";
// import Routes from "../../Routes/Routes";
// import DashboardNotificationToast from "../../Components/Dashboard/NotificationToast";
// import UserInfoAPIService from "../../Api/UserInfo/UserInfoAPI";
// import ThemeContext from "../../Context/ThemeContext";

// TODO: Update dynamic data in Dashboard detail
const DashboardStudent = ({
  updateCoursesInCart,
  theme,
}: {
  updateCoursesInCart: any;
  theme: ThemeProps;
}) => {
  const [dashboardDetails, setDashboardDetails] = useState<DashboardResult>(
    {} as DashboardResult
  );
  const dashboardService = new DashboardService();
  const cartAPIService = new CartAPIService();

  useEffect(() => {
    dashboardService.getDashboard().then((value) => {
      setDashboardDetails(value);
    });
    cartAPIService
      .fetchCourseInCartList()
      .then((resData) => {
        if (resData.data) {
          const { cart, totalAmount } = resData.data;
          updateCoursesInCart({ cart, totalAmount });
        }
      })
      .catch((err) => ErrorMsgToaster(err));
  }, []);

  return (
    <Template
      menu={<Menu selectedItem={`DASHBOARD`} />}
      body={<DashboardBody data={dashboardDetails.data} theme={theme} />}
      submenu={
        <SubMenu
          continueLearning={dashboardDetails.data?.continueLearning}
          handsOn={dashboardDetails?.data?.handsOn}
          theme={theme}
        />
      }
    />
  );
};

const DashboardBody = ({
  data,
  theme,
}: {
  data: DashboardDetailData;
  theme: ThemeProps;
}) => {
  const authService = new AuthService();
  return (
    <div className={`flex flex-col text-gray-700`}>
      {data ? (
        <>
          <div
            className={`mt-0  text-2xl font-bold`}
            style={{ color: theme?.title }}
          >
            {data?.welcomeMessage}
          </div>
          <div
            className="flex flex-col text-lg mt-3 font-bold font-Lato "
            style={{ color: theme?.title }}
          >
            Role: Student
          </div>
          <div>
            {!data?.emailVerified && (
              <div
                className="text-lg h-auto rounded-md py-2 pl-7 "
                style={{
                  backgroundColor: theme?.dashboardStudent.block1,
                  color: theme?.dashboardStudent.block1Text,
                }}
              >
                A verification mail has been sent to your registered email ID.
                Please verify.{" "}
                <span
                  className="underline cursor-pointer"
                  style={{
                    color: theme?.title,
                  }}
                  onClick={() => {
                    authService.resendMail().then(({ data, errorMsg }) => {
                      if (data) SuccessMsgToaster(data);
                      if (errorMsg) ErrorMsgToaster(errorMsg);
                    });
                  }}
                >
                  Resend e-mail
                </span>
              </div>
            )}
            <br></br>
            {!data?.completeYourProfile && (
              <Link to="/profile">
                <div
                  className="text-lg h-auto rounded-md py-2 pl-7 "
                  style={{
                    backgroundColor: theme?.dashboardStudent.block2,
                    color: theme?.dashboardStudent.text,
                  }}
                >
                  Click here to complete your profile
                </div>
              </Link>
            )}
          </div>
          <div className="grid grid-cols-3 gap-10 mt-4">
            <div
              className="flex flex-col gap-5 items-center justify-center h-60 w-full rounded-lg"
              style={{
                backgroundColor: theme?.dashboardStudent.tile1,
              }}
            >
              <div className="text-black font-bold text-5xl md:text-7xl ">
                {data?.coursesCompleted || 0}
              </div>
              <div className="font-bold text-xs md:text-sm text-center mt-6  ">
                Courses Completed
              </div>
            </div>
            <div
              className="flex flex-col  items-center justify-center gap-5 h-60 w-full rounded-lg"
              style={{
                backgroundColor: theme?.dashboardStudent.tile2,
              }}
            >
              <div className="text-black font-bold text-5xl md:text-7xl">
                {data?.coursesInProgress || 0}
              </div>
              <div className="font-bold text-xs md:text-sm text-center mt-6 ">
                Courses In Progress
              </div>
            </div>
            <div
              className="flex flex-col  items-center justify-center gap-5 h-60 w-full rounded-lg"
              style={{
                backgroundColor: theme?.dashboardStudent.tile3,
              }}
            >
              <div className="text-black font-bold text-5xl md:text-7xl">
                {data?.hoursSpentThisWeek || 0}
              </div>
              <div className="font-bold text-xs md:text-sm text-center mt-6 ">
                Hours Spent this Week
              </div>
            </div>
          </div>
          <div>
            <div
              className={`text-2xl font-bold mt-14`}
              style={{ color: theme?.title }}
            >
              Recommended for you
            </div>
            <div className={`grid grid-cols-2 gap-4 gap-x-12 mt-9`}>
              {data?.courseRecommendations?.map((courseRecommendation) => {
                return (
                  <CourseCard
                    courseData={courseRecommendation}
                    key={courseRecommendation.courseId}
                    theme={theme}
                  />
                );
              })}
            </div>
            {data?.courseRecommendations?.length === 0 && (
              <NoCourse
                title="Currently No Recommended Courses"
                icon="school"
              />
            )}
          </div>
        </>
      ) : (
        <NoCourse title="Somthing Went Wrong" icon="school" />
      )}
    </div>
  );
};

const SubMenu = ({ continueLearning, handsOn, theme }: SubMenuData) => {
  return (
    <>
      <ContinueCourseTail data={continueLearning} theme={theme} />
      <UpcomingCourseTail data={handsOn} />
      <div className={`font-bold text-xl mt-9 mb-6`}>Trending this Week</div>
      <TrendingCourse theme={theme} />
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setIsLoading: (isLoading: boolean) => {
      dispatch(SpinnerLoadingAction(isLoading));
    },
    updateCoursesInCart: (data: any) => {
      dispatch(CartAction(data));
    },
    setUserInfo: (data: any) => {
      dispatch(UserInfoAction(data));
    },
  };
};

export default connect(null, mapDispatchToProps)(DashboardStudent);

// const NotificationsMsg = ({
//   notificationList,
// }: DashboardNotificationToastDataList) => {
//   const authService = new AuthService();
//   return (
//     <div>
//       {notificationList.map((rec: DashboardNotificationToastData) => {
//         return (
//           <>
//             {rec.message.toLocaleLowerCase().includes("profile") ? (
//               <Link to={Routes.profile}>
//                 <div
//                   key={rec.id}
//                   className={`py-3 mt-4 rounded-md flex flex-row font-bold text-lg ${
//                     rec.message.toLowerCase().includes("profile") &&
//                     "cursor-pointer"
//                   }`}
//                   style={{ background: rec.bgColor, color: rec.fontColor }}
//                 >
//                   <img src={rec.icon} className={`h-6 w-6 ml-4 mr-4`} />
//                   {rec.message}
//                 </div>
//               </Link>
//             ) : (
//               <div
//                 key={rec.id}
//                 className={`py-3 mt-4 rounded-md flex flex-row font-bold text-lg`}
//                 style={{ background: rec.bgColor, color: rec.fontColor }}
//               >
//                 <img src={rec.icon} className={`h-6 w-6 ml-4 mr-4`} />
//                 {rec.message}{" "}
//                 <span
//                   className='text-blue-500 underline cursor-pointer'
//                   onClick={() => {
//                     authService.resendMail().then(({ data, errorMsg }) => {
//                       if (data) SuccessMsgToaster(data);
//                       if (errorMsg) ErrorMsgToaster(errorMsg);
//                     });
//                   }}
//                 >
//                   Resend e-mail
//                 </span>
//               </div>
//             )}
//           </>
//         );
//       })}
//     </div>
//   );
// };
