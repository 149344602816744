import { useState } from "react";
import NoCourse from "../../Components/Common/NoCourse";
import ThemeContext from "../../Context/ThemeContext";
// import ButtonReuse from "../../Components/Common/ButtonReuse";
// import QuizMarks from "./QuizScore";

const StudentListBody = ({
  filterKey,
  setFilterKey,
  courseList,
  studentList,
  setStudentData,
}: any) => {
  const [courseName, setCourseName] = useState("");
  const [lastSelected, setLastSelected] = useState("");
  return (
    <ThemeContext.Consumer>
      {(context) => {
        return (
          <>
            <div className={`flow-root`}>
              <div
                className={`text-xl md:text-2xl font-bold float-left`}
                style={{ color: context.theme!.title }}
              >
                Students
              </div>
            </div>
            <div className={`mt-4 md:mr-6`}>
              <select
                className="w-full h-10 mt-2 border border-gray-400 rounded-md text-base pl-3"
                onChange={(e) => {
                  let courseTitle =
                    courseList &&
                    courseList.length &&
                    courseList?.[e.target.selectedIndex]?.title;
                  setCourseName(courseTitle);
                  setFilterKey(e.target.value);
                  setLastSelected(e.target.value);
                }}
                value={filterKey}
              >
                <option value="">Select Course</option>
                {courseList?.map((item: any) => (
                  <option key={item.courseId} value={item.courseId}>
                    {item?.title}
                  </option>
                ))}
              </select>
            </div>
            <div>
              {studentList &&
                studentList.map((rec: any) => {
                  return (
                    <div
                      key={rec.studentId}
                      className={` mt-5 w-12/12 h-11 mr-6 pt-2 rounded-md border-opacity-0`}
                      style={{
                        backgroundColor: "#F7EDE6",
                        borderColor: "grey",
                        border: "solid 0.5px",
                      }}
                    >
                      <div className={`flex flex-auto justify-between`}>
                        <label
                          className={` text-base mt-0 ml-3 mr-3 flex justify-start font-semibold h-6 overflow-hidden`}
                          style={{ color: context.theme!.title }}
                          title={rec.name}
                        >
                          {rec.name}
                        </label>
                        <button
                          className={`p-1 px-2 border rounded-md text-white font-semibold -mt-1 mr-4`}
                          style={{
                            backgroundColor: context.theme!.viewEditButton,
                          }}
                          onClick={() => {
                            setStudentData({ ...rec, courseName: courseName });
                          }}
                        >
                          View
                        </button>
                      </div>
                    </div>
                  );
                })}
              {(!studentList || !studentList.length) && (
                <div className={`mt-32`}>
                  <NoCourse title={`No Student Enrolled`} icon="account_box" />
                </div>
              )}
            </div>
          </>
        );
      }}
    </ThemeContext.Consumer>
  );
};

export default StudentListBody;
