import { FC, useEffect, useState } from "react";
import AccordianContext from "../Context/AccordianContext";

const AccordianProvider: FC = ({ children }) => {
  const [isopen, setisopen] = useState(false);
  useEffect(() => {
    setisopen(true);
  }, []);

  const accordianToggled = () => {
    setisopen(!isopen);
  };

  return (
    <AccordianContext.Provider
      value={{
        isOpen: isopen,
        accordianToggled: accordianToggled,
      }}
    >
      {children}
    </AccordianContext.Provider>
  );
};

export default AccordianProvider;
