import { Link } from "react-router-dom";
import { Assets } from "../../Api/Assets";
import RatingImg from "../../Assets/rating.png";
import { ThemeProps } from "../../Themes/Themes";
import { TrendingCourseType } from "../../Utils/types";
import NoCourse from "../Common/NoCourse";

type CourseCardData = {
  courseData: TrendingCourseType;
  theme: ThemeProps | undefined;
};

const CourseTail = ({ courseData, theme }: CourseCardData) => {
  return (
    <>
      {courseData.title ? (
        <Link to={`/coursedetail/${courseData.courseId}`} className={``}>
          <div className={` w-full`}>
            {courseData && (
              <div
                className={`grid grid-row-2 w-full border-gray-300 rounded-lg border-2 bg-lp_pink-course_tail`}
              >
                <div className={`w-full h-64`}>
                  <img
                    src={
                      courseData?.image
                        ? courseData?.image
                        : Assets.SetGoalBannerImage
                    }
                    className={`w-full h-full object-cover`}
                    alt={courseData?.title}
                  />
                </div>
                <hr />
                <div className={` p-3`}>
                  <div className={`w-full `}>
                    <div
                      className={`text-xl font-semmibold h-8 overflow-hidden`}
                      style={{ color: theme?.title }}
                      title={courseData?.title}
                    >
                      {courseData?.title}
                    </div>
                    <div
                      className={`text-sm text-gray-700 mt-4 h-16 overflow-hidden`}
                      title={courseData?.description}
                    >
                      {courseData?.description}
                    </div>
                    <div className={`text-sm text-gray-700 mt-4 `}>
                      {courseData?.enrolled} Enrolled{" "}
                    </div>
                    <div className={`text-sm text-gray-700 mt-4 `}></div>
                    <div className={`text-sm text-black mt-2 flex flex-auto`}>
                      <img
                        className={`h-6 w-6`}
                        src={RatingImg}
                        alt={`Rating`}
                      ></img>
                      <div className={`flex flex-auto ml-4 mt-1`}>
                        {courseData?.rating}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Link>
      ) : (
        <NoCourse title="No Course Here" />
      )}
    </>
  );
};

export default CourseTail;
