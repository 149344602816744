import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TrainerEarningsAPIService from "../../Api/Earnings/TrainerEarningsAPI";
import NoCourse from "../../Components/Common/NoCourse";
import SubmenuTrainer from "../../Components/Common/SubMenuTrainer";
import Table from "../../Components/Table/Table";
import MenuTrainer from "../../Components/Template/MenuTrainer";
import Template from "../../Components/Template/Template";
import { ErrorMsgToaster } from "../../Components/Utils/Toaster";
import ThemeContext from "../../Context/ThemeContext";
import { EarningType } from "../../Utils/types";

type CardComponentType = {
  bgColor: string;
  title: string;
  value: number;
};

const MyEarnings = () => {
  const menu = <MenuTrainer selectedItem={`MY EARNINGS`} />;
  return (
    <Template menu={menu} body={<MyEarningsBody />} submenu={<SubMenu />} />
  );
};

const MyEarningsBody = () => {
  const [earningsList, setEarningsList] = useState<Array<EarningType>>([]);
  const [earnedAmount, setEarnedAmount] = useState(0);
  const [pendingAmount, setPendingAmount] = useState(0);
  const [selectedStartDate, setSelectedStartDate] = useState(new Date());
  const [selectedEndDate, setSelectedEndDate] = useState(new Date());

  const fetchEarningsDetails = () => {
    const trainerEarningsAPIService = new TrainerEarningsAPIService();
    const startDate = getFormatedDate(selectedStartDate);
    const endDate = getFormatedDate(selectedEndDate);
    trainerEarningsAPIService
      .fetchTrainerEarnings({ startDate, endDate })
      .then((resData) => {
        if (resData?.errorMsg) {
          ErrorMsgToaster(resData.errorMsg);
        } else {
          if (resData?.data) {
            const { items, earned, pendingSettlement } = resData.data;
            if (!items?.length)
              ErrorMsgToaster({ error: "No record found", statusCode: 404 });
            setEarningsList(items);
            setEarnedAmount(earned);
            setPendingAmount(pendingSettlement);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchEarningsDetails();
  }, [selectedStartDate, selectedEndDate]);

  return (
    <ThemeContext.Consumer>
      {(context) => {
        return (
          <>
            <div
              className={`text-2xl font-bold `}
              style={{ color: context.theme!.title }}
            >
              My Earnings
            </div>
            <div className={`flex flex-row right-full place-content-end mt-2`}>
              <div className={`flex flex-col`}>
                <div className={`mb-2 font-semibold`}>Start Date</div>
                <DatePicker
                  dateFormat={`dd/MM/yyyy`}
                  className={` border-solid border-2 border-black rounded-md`}
                  selected={selectedStartDate}
                  maxDate={new Date()}
                  onChange={(date: any) =>
                    setSelectedStartDate(date || new Date())
                  }
                />
              </div>
              <div className={`flex flex-col`}>
                <div className={`mb-2 ml-6 font-semibold`}>End Date</div>
                <DatePicker
                  dateFormat={`dd/MM/yyyy`}
                  className={` border-solid border-2 border-black rounded-md ml-6`}
                  selected={selectedEndDate}
                  minDate={selectedStartDate}
                  maxDate={new Date()}
                  onChange={(date: any) =>
                    setSelectedEndDate(date || new Date())
                  }
                />
              </div>
            </div>
            {earningsList &&
              earningsList.length > 0 &&
              (earnedAmount || pendingAmount) && (
                <EarnedPendingCard
                  earnedAmount={earnedAmount}
                  pendingAmount={pendingAmount}
                />
              )}
            {earningsList && earningsList.length > 0 && (
              <Table earningsList={earningsList} />
            )}
            {(!earningsList || earningsList.length === 0) && (
              <div className={`mt-64`}>
                <NoCourse
                  title='No Earnings Record found'
                  icon='account_balance'
                />
              </div>
            )}
          </>
        );
      }}
    </ThemeContext.Consumer>
  );
};

const EarnedPendingCard = ({
  earnedAmount,
  pendingAmount,
}: {
  earnedAmount: number;
  pendingAmount: number;
}) => {
  const cardsValue = [
    { bgColor: "#E6FAE4", title: "Earned", value: earnedAmount },
    { bgColor: "#EFEFEF", title: "Pending Settlement", value: pendingAmount },
  ];
  return (
    <div
      className={`grid grid-cols-2 grid-rows-1 justify-center align-middle mt-6`}
    >
      {cardsValue.map((rec) => (
        <CardComponent
          key={rec.title}
          bgColor={rec.bgColor}
          title={rec.title}
          value={rec.value}
        />
      ))}
    </div>
  );
};

const CardComponent = ({ bgColor, title, value }: CardComponentType) => {
  return (
    <div
      className={`px-24 py-8 ${
        title && title === "Pending Settlement" && "ml-12"
      } rounded-3xl`}
      style={{ background: bgColor }}
    >
      <div className={`flex justify-center text-5xl font-bold text-black`}>
        {Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "INR",
        }).format(value)}
      </div>
      <div
        className={`flex mt-2 text-lg font-semibold justify-center align-middle text-black`}
      >
        {title}
      </div>
    </div>
  );
};

const getFormatedDate = (inputDate: Date) => {
  const year = inputDate.getFullYear();
  const month = `0${inputDate.getMonth()}`.slice(-2);
  const date = `0${inputDate.getDate()}`.slice(-2);
  const formateDate = Date.UTC(
    year,
    parseInt(month),
    parseInt(date),
    0,
    0,
    0,
    0
  );
  return new Date(formateDate).toISOString();
};

const SubMenu = () => {
  return (
    <>
      <SubmenuTrainer />
    </>
  );
};
export default MyEarnings;
