import { TrainerMyCourseResType } from "../../Utils/types";
import APIService from "../API";
import { BaseUrl } from "../axios.config";
import { NetworkError, Payload } from "../Error.type";

const fetchMyCourseURL: string = `${BaseUrl}/course/myCourses`;
const fetchTrainerMyCourses: string = `${BaseUrl}/trainer/myCourses`;
const apiService = new APIService();

export type myCourseResType = {
  data?: any;
  statusCode: number;
};
export class MyCourseAPIResult
  implements Payload<myCourseResType, NetworkError>
{
  statusCode: number;
  data?: myCourseResType;
  errorMsg?: NetworkError;

  constructor(
    statusCode: number,
    data?: myCourseResType,
    errorMsg?: NetworkError
  ) {
    this.statusCode = statusCode;
    this.data = data;
    this.errorMsg = errorMsg;
  }
}

export class TrainerMyCourseAPIResult
  implements Payload<Array<TrainerMyCourseResType>, NetworkError>
{
  statusCode: number;
  data?: Array<TrainerMyCourseResType>;
  errorMsg?: NetworkError;

  constructor(
    statusCode: number,
    data?: Array<TrainerMyCourseResType>,
    errorMsg?: NetworkError
  ) {
    this.statusCode = statusCode;
    this.data = data;
    this.errorMsg = errorMsg;
  }
}

type MyCourseReqType = {
  rating: number;
  completionPercentage: number;
};

// Fetch courses list for Course explorer page
export default class MyCourseAPIService {
  fetchMyCourseDetail = async (reqData: MyCourseReqType) => {
    let payload = {};
    const { rating, completionPercentage } = reqData;
    if (rating !== 0) {
      payload = { ...payload, rating };
    }
    if (completionPercentage !== 0) {
      payload = { ...payload, completionPercentage };
    }
    return await apiService.getReqWithParams({
      APIUrl: fetchMyCourseURL,
      payload,
      resType: MyCourseAPIResult,
    });
  };


  // Fetch trainer courses created and reviewd
  fetchTrainerMyCourse = async () => {
    return await apiService.getReqs({
      APIUrl: fetchTrainerMyCourses,
      payload: {},
      resType: TrainerMyCourseAPIResult,
    });
  };
}
