import { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import StarRatings from "react-star-ratings";
import FeedbackAPIService from "../../Api/Feedback/FeedbackAPIService";
import Required from "../../Components/Common/Required";
// import Menu from "../../Components/Template/Menu";
// import Template from "../../Components/Template/Template";
import {
  ErrorMsgToaster,
  SuccessMsgToaster,
} from "../../Components/Utils/Toaster";
import ThemeContext from "../../Context/ThemeContext";

type IFormInput = {
  feedback: string;
};

const PlatformFeedback = ({ userType }: { userType: string }) => {
  const [rating, setRating] = useState<number>(0);
  const [count, setCount] = useState<number>(0);
  const [maxLength, setMaxLength] = useState<number>(320);
  const [isRatingError, setIsRatingError] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm<IFormInput>();

  const onSubmit: SubmitHandler<IFormInput> = (data: any) => {
    if (rating <= 0) {
      setIsRatingError(rating <= 0);
      return;
    }

    const feedbackAPI = new FeedbackAPIService();
    const reqData = data && { rating, feedback: data.feedback };
    feedbackAPI
      .postPlatformFeedback(reqData)
      .then((resData) => {
        if (resData.data) {
          SuccessMsgToaster(resData.data);
          resetValues();
        } else {
          ErrorMsgToaster(resData.errorMsg!);
        }
      })
      .catch((err) => console.log(err));
  };

  const resetValues = () => {
    setValue("feedback", "", { shouldValidate: false });
    setRating(0);
    setCount(0);
    setIsRatingError(false);
  };

  const handleFormCancel = () => {
    resetValues();
  };

  const handleRating = (rate: React.SetStateAction<number>) => {
    setRating(rate);
  };

  return (
    <ThemeContext.Consumer>
      {(context) => {
        return (
          <form onSubmit={handleSubmit(onSubmit)} className={`mt-8`}>
            <div>
              {userType && userType === "Trainer" && (
                <div
                  className={`text-2xl -mt-6 mb-8 font-bold`}
                  style={{ color: context.theme!.title }}
                >
                  Platform Feedback
                </div>
              )}
              <h4 className={`text-sm font-lato mt-6`}>
                <Required />
                Feedback
              </h4>
              <textarea
                {...register("feedback", { required: true })}
                className={`h-64 w-full text-xl p-2 border-gray-400 border rounded-md mt-2 font-lato `}
                maxLength={maxLength}
                onChange={(e) => setCount(e.target.textLength)}
              />
              <div
                className={`text-lg float-right mt-2`}
              >{`(${count}/${maxLength})`}</div>
              {errors.feedback?.type === "required" && (
                <p className={`text-red-500`}>Feedback is Required</p>
              )}
            </div>
            <div className={`mt-12`}>
              <h4 className={`text-sm font-lato mt-6 mb-3`}>
                <Required />
                Rating
              </h4>
              <StarRatings
                rating={rating}
                starRatedColor="gold"
                changeRating={handleRating}
                numberOfStars={5}
                starDimension="40px"
                starHoverColor="gold"
                starSpacing="2px"
                name="rating"
                //   svgIconPath='M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm3.5-9c.83 0 1.5-.67 1.5-1.5S16.33 8 15.5 8 14 8.67 14 9.5s.67 1.5 1.5 1.5zm-7 0c.83 0 1.5-.67 1.5-1.5S9.33 8 8.5 8 7 8.67 7 9.5 7.67 11 8.5 11zm3.5 6.5c2.33 0 4.31-1.46 5.11-3.5H6.89c.8 2.04 2.78 3.5 5.11 3.5z'
              />
            </div>
            {isRatingError && (
              <p className={`text-red-500`}>Rating is Required</p>
            )}
            <div className={`mt-2 flex flex-auto flex-row float-right`}>
              <button
                onClick={handleFormCancel}
                className={`h-10 text-xl w-32 bg-gray-500 rounded-md font-Lato font-bold text-white`}
              >
                RESET
              </button>
              <button
                type="submit"
                className={`h-10 text-xl w-28 ml-4 rounded-md font-Lato font-bold  text-white`}
                style={{ backgroundColor: context.theme!.button.background }}
              >
                SAVE
              </button>
            </div>
          </form>
        );
      }}
    </ThemeContext.Consumer>
  );
};

export default PlatformFeedback;
