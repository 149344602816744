const Payment = () => {
  return (
    <div className="flex flex-row gap-5">
      <div className=" bg-gray-300 w-32 h-24 border-solid border rounded-md border-gray-500 ml-5"></div>{" "}
      <div className="  text-lp_blue text-xl font-Lato font-bold mt-7">
        Course Title
      </div>
    </div>
  );
};
export default Payment;
