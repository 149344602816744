import RenderMediaComponents from "../../Components/Common/RenderMediaComponents";
import PDFViewer from "../../Components/Resources/PDFViewer";
import VideoPlayer from "../../Components/Resources/VideoPlayer";
import WordDocViewer from "../../Components/Resources/WordDocViewer";

const TopicView = ({ topicData }: any) => {
  return (
    <>
      {topicData && (
        <div className={``}>
          <div>
            <h1
              className={"font-semibold text-2xl"}
            >{`Title : ${topicData?.title}`}</h1>
          </div>
          <div className="py-4">
            <RenderMediaComponents
              originalName={topicData?.originalName}
              resourceLink={topicData?.file}
            />
          </div>
          <div className={`mt-2`}>
            <h1 className={"font-semibold text-2xl mb-2"}>Description</h1>
            <div
              className={`h-auto w-full text-sm p-2  rounded-md font-lato `}
              style={{ resize: "none" }}
            >
              {topicData?.description}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TopicView;
