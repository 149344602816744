import axios from "axios";
import { ApplicationJsonConfigHeader, BaseUrl } from "../../Api/axios.config";

export const getDashboardResults = async () => {
  const token: string | null = localStorage.getItem("token");
  const response = await axios.get(BaseUrl + "/dashboard/trainer", {
    headers: {
      ...ApplicationJsonConfigHeader,
      authorization: `Bearer ${token}`,
    },
    responseType: "json",
  });
  return response.data;
};
