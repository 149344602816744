import axios, { AxiosResponse } from "axios";
import APIService from "../API";
import { ApplicationJsonConfigHeader, BaseUrl } from "../axios.config";
import {
  AuthenticationError,
  GeneralError,
  NetworkError,
  Payload,
} from "../Error.type";

const setGoalUrl: string = BaseUrl + "/authentication/goal";
const apiService = new APIService();

type SetGoalSuccessData = {
  goal: string;
};

export class SetGoalResult
  implements Payload<SetGoalSuccessData, NetworkError>
{
  statusCode: number;
  data?: SetGoalSuccessData;
  error?: NetworkError;

  constructor(
    statusCode: number,
    data?: SetGoalSuccessData,
    error?: NetworkError
  ) {
    this.statusCode = statusCode;
    this.data = data;
    this.error = error;
  }
}

class SetGoalService {
  setGoal = async (
    goal: string,
    intrest: Array<string>
  ): Promise<SetGoalResult> => {
    console.log("executing setGoal");
    // TODO: Remove token
    const token: string | null = localStorage.getItem("token");

    if (token !== null) {
      console.log(token !== null);

      const response: AxiosResponse = await axios.post(
        setGoalUrl,
        { goal: intrest, category: goal },
        {
          headers: {
            ...ApplicationJsonConfigHeader,
            authorization: `Bearer ${token}`,
          },
          responseType: "json",
        }
      );

      if (response.status === 200) {
        return { data: response.data, statusCode: response.status };
      } else {
        const error = new GeneralError(response.data.error, response.status);
        return { error: error, statusCode: response.status };
      }
    } else {
      const authErrorInstance = new AuthenticationError();
      return {
        error: authErrorInstance,
        statusCode: authErrorInstance.statusCode,
      };
    }
  };

  getGoals = async () => {
    const token: string | null = localStorage.getItem("token");
    const response = await axios.get(BaseUrl + "/categoriesList", {
      headers: {
        ...ApplicationJsonConfigHeader,
        authorization: `Bearer ${token}`,
      },
      responseType: "json",
    });
    return response.data;
  };

  getSubGoals = async (goal: string) => {
    const token: string | null = localStorage.getItem("token");
    const response = await axios.get(BaseUrl + "/selectGoals", {
      params: { category: goal },
      headers: {
        ...ApplicationJsonConfigHeader,
        authorization: `Bearer ${token}`,
      },
      responseType: "json",
    });
    return response.data;
  };
}

export default SetGoalService;
