import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import TrainerTopicsAPIService from "../../Api/Topics/TrainerTopicsAPI";
import InputFull from "../../Components/Common/InuptFull";
import NoCourse from "../../Components/Common/NoCourse";
import SubmenuTrainer from "../../Components/Common/SubMenuTrainer";
import MenuTrainer from "../../Components/Template/MenuTrainer";
import Template from "../../Components/Template/Template";
import { ErrorMsgToaster } from "../../Components/Utils/Toaster";
import ThemeContext from "../../Context/ThemeContext";

type TopicListType = {
  topicId: string;
  title: string;
};

const TopicTrainerBody = () => {
  const [filterKey, setFilterKey] = useState("");
  const [topicsList, setTopicsList] = useState<Array<TopicListType>>([]);
  const [intialTopicsList, setIntialTopicsList] = useState<
    Array<TopicListType>
  >([]);

  useEffect(() => {
    setFilterKey("");
    const trainerTopicAPIService = new TrainerTopicsAPIService();
    trainerTopicAPIService
      .fetchAllTopicsDetail()
      .then(({ data, errorMsg }) => {
        if (data) {
          setTopicsList(data);
          setIntialTopicsList(data);
        } else if (errorMsg) {
          ErrorMsgToaster(errorMsg);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const filterLessons = (key: any) => {
    const trainerTopicAPIService = new TrainerTopicsAPIService();
    if (key) {
      trainerTopicAPIService
        .searchTopics(key)
        .then((resData) => {
          if (resData && resData.data) {
            setTopicsList(resData.data);
          } else {
            setTopicsList([]);
          }
        })
        .catch((err) => console.log(err));
    } else {
      setTopicsList(intialTopicsList);
    }
  };

  useEffect(() => filterLessons(filterKey), [filterKey]);

  return (
    <ThemeContext.Consumer>
      {(context) => {
        return (
          <>
            <div className={`flow-root`}>
              <div
                className={`text-xl md:text-2xl font-bold float-left`}
                style={{ color: context.theme!.title }}
              >
                Topics
              </div>
              <div className={`float-right md:mr-6`}>
                <Link to={`/trainer/topic/new`}>
                  <button
                    className={`bg-yellow-200 h-7 w-36 text-sm font-bold place-self-end rounded-md text-black float-right`}
                  >
                    ADD NEW TOPIC
                  </button>
                </Link>
              </div>
            </div>
            <div className={`mt-4 md:mr-6`}>
              <InputFull
                type="text"
                placeHolder="Search Topic"
                onChange={(e): void => setFilterKey(e.target.value)}
                label={""}
              ></InputFull>
            </div>

            {topicsList &&
              topicsList.map((rec: TopicListType) => {
                return (
                  <div
                    className={` mt-5 w-12/12 h-11 mr-6 pt-2 border-opacity-0 border-solid  border-gray-900 rounded-sm`}
                    style={{
                      backgroundColor: "#F7EDE6",
                      borderColor: "grey",
                      border: "solid 0.5px",
                    }}
                  >
                    <div className={`flex flex-auto justify-between`}>
                      <label
                        className={` text-base mt-0 ml-3 mr-3 flex justify-start font-semibold h-6 overflow-hidden`}
                        style={{ color: "#0B4089", borderRadius: "3px" }}
                        title={rec.title}
                      >
                        {rec.title}
                      </label>
                      <Link
                        to={`/trainer/topic/edit/${rec.topicId}`}
                        className={`h-auto`}
                      >
                        <button
                          className={`p-1 px-2 border  rounded-md text-white font-semibold -mt-1 mr-4`}
                          style={{
                            backgroundColor: context.theme!.viewEditButton,
                          }}
                        >
                          EDIT
                        </button>
                      </Link>
                    </div>
                  </div>
                );
              })}
            {(!topicsList || !topicsList.length) && (
              <div className={`mt-32`}>
                <NoCourse title={`No Topics Available`} icon="school" />
              </div>
            )}
          </>
        );
      }}
    </ThemeContext.Consumer>
  );
};

const SubMenu = () => {
  return (
    <>
      <SubmenuTrainer />
    </>
  );
};

const TopicTrainer = () => {
  const menu = <MenuTrainer selectedItem={`TOPICS`} />;
  return (
    <Template menu={menu} body={<TopicTrainerBody />} submenu={<SubMenu />} />
  );
};
export default TopicTrainer;
