import { DashReview } from "../../Utils/types";

const NamesDashsubmenu = ({ recentReviews }: { recentReviews: DashReview }) => {
  return (
    <>
      <br></br>
      <div className='text-black text-base font-Lato font-bold '>
        {recentReviews.name}
      </div>
      <div className='text-black text-sm font-Lato  '>
        {recentReviews.feedback}
      </div>
    </>
  );
};
export default NamesDashsubmenu;
