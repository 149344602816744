import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MyCourseAPIService from "../../Api/MyCourses/MyCourseAPIService";
import NoCourse from "../../Components/Common/NoCourse";
import SubmenuTrainer from "../../Components/Common/SubMenuTrainer";
import CourseTail from "../../Components/Template/CourseTail";
import MenuTrainer from "../../Components/Template/MenuTrainer";
import MyCourseTrainerCard from "../../Components/Template/MyCourseTrainerCard";
import Template from "../../Components/Template/Template";
import { ErrorMsgToaster } from "../../Components/Utils/Toaster";
import ThemeContext from "../../Context/ThemeContext";
import { TrainerMyCourseResType } from "../../Utils/types";

const MyCoursesTrainer = () => {
  const [myCourses, setMyCourses] = useState<Array<TrainerMyCourseResType>>([]);

  useEffect(() => {
    const myCourseAPIService = new MyCourseAPIService();
    myCourseAPIService
      .fetchTrainerMyCourse()
      .then((resData) => {
        if (resData?.errorMsg) {
          ErrorMsgToaster(resData.errorMsg);
        } else {
          if (resData?.data) {
            setMyCourses(resData.data);
          }
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const menu = <MenuTrainer selectedItem={`MY COURSES`} />;
  const CourseBody = () => {
    return (
      <ThemeContext.Consumer>
        {(context) => {
          return (
            <>
              <div
                className={`text-2xl font-bold`}
                style={{ color: context.theme!.title }}
              >
                My Courses
                <div className={`float-right md:mr-6`}>
                  <Link to={`/trainer/course/new`}>
                    <button
                      className={`bg-yellow-200 h-7 w-36 text-sm font-bold place-self-end rounded-md text-black`}
                    >
                      ADD NEW COURSE
                    </button>
                  </Link>
                </div>
              </div>
              <div className={`grid  gap-6 mt-9`}>
                {myCourses &&
                  myCourses.length > 0 &&
                  myCourses.map((data) => (
                    <Link
                      to={`/trainer/course/new?id=${data.courseId}`}
                      key={data.courseId}
                    >
                      <MyCourseTrainerCard
                        myCourseTrainerData={data}
                        key={data.courseId}
                      />
                    </Link>
                  ))}
                {!myCourses ||
                  (myCourses.length === 0 && (
                    <NoCourse title="No Courses Available" icon="school" />
                  ))}
              </div>
            </>
          );
        }}
      </ThemeContext.Consumer>
    );
  };
  return <Template menu={menu} body={<CourseBody />} submenu={<SubMenu />} />;
};

const SubMenu = () => {
  return (
    <>
      <SubmenuTrainer />
    </>
  );
};
export default MyCoursesTrainer;
