import { Component, ReactNode } from "react";
import { Droppable, Draggable } from "react-beautiful-dnd";
import NoCourse from "../../Components/Common/NoCourse";

type props = {
  bgClass: String;
  lessonContent: any;
  topicContent: any;
  quizContent: any;
  mainCategory: String;
};

const TopicQuiz = ({
  bgClass,
  lessonContent,
  topicContent,
  quizContent,
  mainCategory,
}: props) => {
  return (
    <>
      {mainCategory === "lesson" && (
        <Topic content={lessonContent} bgClass={bgClass} dropid={"itemsL"} />
      )}
      {mainCategory === "topics" && (
        <Topic content={topicContent} bgClass={bgClass} dropid={"itemsT"} />
      )}
      {mainCategory === "quiz" && (
        <Topic content={quizContent} bgClass={bgClass} dropid={"itemsQ"} />
      )}
    </>
  );
};

const Topic = ({ content, bgClass, dropid }: any) => {
  return (
    <Droppable droppableId={dropid} type="COURSES">
      {(provided: any) => (
        <ul
          className="characters h-52 overflow-scroll"
          {...provided.droppableProps}
          ref={provided.innerRef}
        >
          {!content?.length && <NoCourse title="Nothing Here" />}
          {content?.map(({ id, title }: any, index: any) => {
            return (
              <Draggable key={id} draggableId={id} index={index}>
                {(provided: any) => (
                  <li
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <div
                      className={`h-10 border-solid border-gray-400 border rounded-md m-3 font-Lato `}
                      style={{ backgroundColor: "#c5f0de" }}
                    >
                      <div
                        className="w-full truncate  pt-1 pl-3 text-base  float-left"
                        title={title}
                      >
                        {title}
                      </div>
                    </div>
                  </li>
                )}
              </Draggable>
            );
          })}
          {provided.placeholder}
        </ul>
      )}
    </Droppable>
  );
};

export default TopicQuiz;
