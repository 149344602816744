import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { NetworkError } from "../../Api/Error.type";
import InputFull from "../../Components/Common/InuptFull";
import Menu from "../../Components/Template/Menu";
import MenuTrainer from "../../Components/Template/MenuTrainer";
import Template from "../../Components/Template/Template";
import {
  ErrorMsgToaster,
  SuccessMsgToaster,
} from "../../Components/Utils/Toaster";
import { validateInput } from "../../Components/Utils/ValidationUtils";
import Education from "./Education";
import {
  changePassword,
  getCategories,
  getGoals,
  getProfile,
  updateProfile,
  updateProfilePic,
  updateTrainerBankDetails,
} from "./ProfileApi";
import { FaCamera } from "react-icons/fa";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { FaEye } from "react-icons/fa";
import {
  EducationDetails,
  BankDetailsType,
  StudentProfile,
} from "../../Utils/types";
import ThemeContext from "../../Context/ThemeContext";

// type ProfileBodyType = {
//   studentDetail?: StudentProfile;
//   setStudentDetail?: Dispatch<SetStateAction<StudentProfile | undefined>>;
//   registerType: string;
//   goalList: Array<string>;
//   categoryList: Array<string>;
//   defaultSelected: string;
//   bankDetails?: BankDetailsType;
// };

type GoalsType = {
  goalName: string;
  isSelected: boolean;
};

const StudentProfileComp = (props: any) => {
  const [studentDetail, setStudentDetail] = useState<StudentProfile>();
  const [registerType, setRegisterType] = useState<string>("");
  const [categoryList, setCategoryList] = useState<Array<string>>([]);
  const [goalList, setGoalList] = useState<Array<GoalsType>>([]);
  const [selectedCategory, setSelectedCategory] = useState<string>("");
  const [bankDetails, setBankDetails] = useState<BankDetailsType>();
  const [firstRender, setFirstRender] = useState(true);

  // const history = useHistory();

  useEffect(() => {
    getCategories()
      .then((resData: Array<string>) => {
        resData && resData.length && setCategoryList(resData);
      })
      .catch((err) => {
        if (err && err.response) {
          ErrorMsgToaster({
            error: err?.response.data && err?.response.data.error,
            statusCode: 400,
          });
        }
      });
  }, []);

  useEffect(() => {
    const { registerType } = props.userInfo;
    if (registerType && firstRender) {
      setFirstRender(false);
      setRegisterType(registerType);
      registerType &&
        getProfile(registerType)
          .then((data) => {
            setStudentDetail(data);
            setSelectedCategory(data && data?.category);
            data && handleGoals(data?.goal, data?.category);
          })
          .catch((err) =>
            ErrorMsgToaster({
              error: err?.response.data && err?.response.data.error,
              statusCode: 400,
            })
          );
    }
  }, [props.userInfo]);

  const handleGoals = (selectedGoals: string[], category: string) => {
    const payload = { category };
    getGoals(payload)
      .then((resData: string[]) => {
        let tempGoalsList =
          resData &&
          resData.map((rec) => ({ goalName: rec, isSelected: false }));
        tempGoalsList =
          tempGoalsList &&
          tempGoalsList.map((rec: any) => {
            return selectedGoals.includes(rec.goalName)
              ? { ...rec, isSelected: true }
              : { ...rec };
          });
        setGoalList(tempGoalsList);
      })
      .catch((err) => console.log(err));
  };

  // const handleBankDetails = (data: StudentProfile) => {
  //   const { accountHolderName, bankAccountNumber, ifscCode, panNumber } = data;
  //   setBankDetails({
  //     accountHolderName,
  //     accountNumber: bankAccountNumber,
  //     confirmAccountNumber: bankAccountNumber,
  //     ifscCode,
  //     panNumber,
  //   });
  // };

  const menu = <Menu selectedItem={`MY PROFILE`} />;
  const trainerMenu = <MenuTrainer selectedItem={`MY PROFILE`} />;

  return (
    <Template
      menu={registerType === "Student" ? menu : trainerMenu}
      body={
        <StudentProfilesbody
          registerType={registerType}
          studentDetail={studentDetail}
          setStudentDetail={setStudentDetail}
          goalsList={goalList}
          setGoalList={setGoalList}
          categoryList={categoryList}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          bankDetails={bankDetails}
        />
      }
    />
  );
};

const StudentProfilesbody = ({
  studentDetail,
  setStudentDetail,
  registerType,
  goalsList,
  setGoalList,
  categoryList,
  selectedCategory,
  setSelectedCategory,
  bankDetails,
}: {
  studentDetail?: StudentProfile;
  setStudentDetail?: Dispatch<SetStateAction<StudentProfile | undefined>>;
  registerType: string;
  goalsList: Array<GoalsType>;
  setGoalList: Dispatch<SetStateAction<GoalsType[]>>;
  categoryList: Array<string>;
  selectedCategory: string;
  setSelectedCategory: Dispatch<SetStateAction<string>>;
  bankDetails?: BankDetailsType;
}) => {
  const updateField = (field: Object) => {
    setStudentDetail &&
      setStudentDetail({
        ...studentDetail,
        ...field,
      });
  };
  // const inputFile = useRef<HTMLInputElement>(null);

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [firstNameErr, setFirstNameErr] = useState("");
  const [lastNameErr, setLastNameErr] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [passwordErr, setPasswordErr] = useState("");
  const [cnfPasswordErr, setCnfPasswordErr] = useState("");

  const [showChangePassword, setShowChangePassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [accountNumber, setAccountNumber] = useState("");
  const [confirmAccountNumber, setConfirmAccountNumber] = useState("");
  const [ifscCode, setIfscCode] = useState("");
  const [accountHolderName, setAccountHolderName] = useState("");
  const [panNumber, setPanNumber] = useState("");

  const [profilePic, setProfilePic] = useState<any>();

  // const [cities, setCities] = useState([]);
  // const [states, setStates] = useState([]);
  // const [countries, setCountries] = useState([]);

  useEffect(() => {
    setAccountHolderName(studentDetail?.accountHolderName || "");
    setAccountNumber(studentDetail?.bankAccountNumber || "");
    setConfirmAccountNumber(studentDetail?.bankAccountNumber || "");
    setPanNumber(studentDetail?.panNumber || "");
    setIfscCode(studentDetail?.ifscCode || "");
    setProfilePic(studentDetail?.profileUrl);
  }, [studentDetail]);

  const updateBankDetailsInfo = () => {
    const msg: NetworkError = {
      error: "Bank Details cannot be empty",
      statusCode: 400,
    };
    if (
      !accountHolderName ||
      !accountNumber ||
      !confirmAccountNumber ||
      !panNumber ||
      !ifscCode
    ) {
      ErrorMsgToaster(msg);
      return;
    }

    if (accountNumber !== confirmAccountNumber) {
      ErrorMsgToaster({
        error: "Acc Num & Confirm Acc Num doesn't match",
        statusCode: 400,
      });
      return;
    }

    const data = {
      accountHolderName,
      bankAccountNumber: accountNumber,
      confirmBankAccountNumber: accountNumber,
      ifscCode,
      panNumber,
    };
    updateTrainerBankDetails(data)
      .then((resData: any) => {
        if (resData && resData.errorMsg) {
          ErrorMsgToaster(resData.errorMsg);
        } else if (resData.data) {
          SuccessMsgToaster({ data: "Bank Details Updated", statusCode: 200 });
        }
      })
      .catch((err) => {
        console.log(err);
        ErrorMsgToaster(err);
      });
  };

  const handleCategoryChange = (value: string) => {
    value !== selectedCategory &&
      getGoals({ category: value })
        .then((resData) => {
          const data =
            resData &&
            resData.map((rec: any) => ({ goalName: rec, isSelected: false }));
          setGoalList(data);
          setActualGoal(studentDetail?.goal, value);
        })
        .catch((err) => console.log(err));
  };

  const setActualGoal = (selectedGoals: any, category: string) => {
    const payload = { category };
    getGoals(payload)
      .then((resData: string[]) => {
        let tempGoalsList =
          resData &&
          resData.map((rec) => ({ goalName: rec, isSelected: false }));
        tempGoalsList =
          tempGoalsList &&
          tempGoalsList.map((rec: any) => {
            return selectedGoals.includes(rec.goalName)
              ? { ...rec, isSelected: true }
              : { ...rec };
          });
        setGoalList(tempGoalsList);
      })
      .catch((err) => console.log(err));
  };

  const changeSelectedGoals = (value: string) => {
    // const tempList = goalsList;
    const data: Array<GoalsType> = goalsList.map((rec) => {
      return rec.goalName === value
        ? { ...rec, isSelected: !rec.isSelected }
        : { ...rec };
    });
    setGoalList(data);
  };

  const handleFileOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    const name = e.target.files[0].name;
    const lastDot = name.lastIndexOf(".");
    const ext = name.substring(lastDot + 1);
    if (ext !== "jpg" && ext !== "jpeg") {
      ErrorMsgToaster({ error: "Upload an Image file", statusCode: 400 });
      return;
    }
    const formData = new FormData();
    e.target.files[0] && formData.append("image", e.target.files[0]);
    updateProfilePic(registerType, formData)
      .then((resData) => {
        resData && setProfilePic(resData.profileUrl);
      })
      .catch((err) => console.log(err));
  };
  const show_Password = () => {
    setShowChangePassword(!showChangePassword);
  };
  const show_ConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <ThemeContext.Consumer>
      {(context) => {
        return (
          <div className={`flex flex-col text-gray-700`}>
            <div
              className={`-mt-8 text-2xl font-bold`}
              style={{ color: context.theme!.title }}
            >
              My Profile
            </div>
            {!profilePic && (
              <div className={`flex flex-auto self-center mb-6`}>
                <label
                  htmlFor="myInput"
                  className={`w-32 h-32 border border-solid border-gray-400 bg-gray-100 font-bold rounded-full flex justify-center items-center`}
                >
                  <FaCamera />
                </label>
                <input
                  className={` place-self-center bg-gray-300 h-32 w-32 rounded-full border border-gray-500 mb-5 `}
                  id="myInput"
                  onChange={handleFileOnChange}
                  style={{ display: "none" }}
                  type={"file"}
                  accept={`.jpg, .jpeg`}
                />
              </div>
            )}
            {profilePic && (
              <div className={`flex flex-auto self-center mb-2`}>
                <img
                  src={profilePic}
                  alt="profile pic"
                  className={`w-32 h-32 border border-solid border-gray-400 bg-gray-100 font-bold rounded-full flex justify-center items-center`}
                ></img>
              </div>
            )}
            {profilePic && (
              <div className={`flex flex-auto self-center mb-6`}>
                <button
                  className={`pl-2 pr-2 pt-1 pb-1 rounded bg-red-300 text-black text-lg`}
                  onClick={() => setProfilePic(null)}
                >
                  Change Profile Picture
                </button>
              </div>
            )}
            <div className={`grid sm:grid-cols-2 grid-cols-1 gap-6 w-full`}>
              <div className={`mt-2`}>
                <InputFull
                  type="text"
                  label="First Name"
                  value={studentDetail?.firstName}
                  onChange={(e) => {
                    updateField({ firstName: e.target.value });
                  }}
                  errCls={firstNameErr ? "border-red-400" : "border-gray-400"}
                  onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const isErr = validateInput("name", e.target.value);
                    setFirstNameErr(isErr);
                  }}
                />
              </div>
              <div className={`mt-2`}>
                <InputFull
                  type="text"
                  label="Last Name"
                  value={studentDetail?.lastName}
                  onChange={(e) => {
                    updateField({ lastName: e.target.value });
                  }}
                  errCls={lastNameErr ? "border-red-400" : "border-gray-400"}
                  onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const isErr = validateInput("name", e.target.value);
                    setLastNameErr(isErr);
                  }}
                />
              </div>
            </div>
            <div
              className={`grid sm:grid-cols-2 grid-cols-1 gap-6 w-full mt-2`}
            >
              <div className={`mt-2 `}>
                <InputFull
                  type="email"
                  label="Email"
                  value={studentDetail?.email}
                  disabled={true}
                  onChange={(e) => {
                    updateField({ email: e.target.value });
                  }}
                  errCls={emailErr ? "border-red-400" : "border-gray-400"}
                  onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const isErr = validateInput("email", e.target.value);
                    setEmailErr(isErr);
                  }}
                />
              </div>
              <div className={`mt-2`}>
                <InputFull
                  type="tel"
                  label="Mobile"
                  value={studentDetail?.mobileNumber}
                  maxLength={10}
                  onChange={(e) => {
                    let val = e.target.value.replace(/([^0-9])$/g, "");
                    updateField({ mobileNumber: val });
                  }}
                />
              </div>
            </div>
            <div className={`mt-2`}>
              <InputFull
                type="text"
                label="Address"
                value={studentDetail?.address}
                onChange={(e) => {
                  updateField({ address: e.target.value });
                }}
              />
            </div>
            <div
              className={`grid sm:grid-cols-2 grid-cols-1 gap-6 w-full mt-2`}
            >
              <div className={`mt-2 `}>
                <h3>Country</h3>
                <CountryDropdown
                  value={studentDetail?.country || ""}
                  classes={`w-full border border-gray-400 rounded mt-1 p-2 h-12 bg-white focus:outline-none`}
                  onChange={(value) => {
                    updateField({ country: value });
                  }}
                />
              </div>
              <div className={`mt-2`}>
                <h3>State</h3>
                <RegionDropdown
                  classes={`w-full border border-gray-400 rounded mt-1 p-2 h-12 bg-white focus:outline-none`}
                  country={studentDetail?.country || ""}
                  value={studentDetail?.state || ""}
                  onChange={(val) => {
                    updateField({ state: val });
                  }}
                />
              </div>
            </div>
            <div
              className={`grid sm:grid-cols-2 grid-cols-1 gap-6 w-full mt-2`}
            >
              <div className={`mt-2`}>
                <InputFull
                  type="text"
                  label="City"
                  value={studentDetail?.city}
                  errCls="mt-1"
                  labelStyle="text-base"
                  onChange={(e) => {
                    updateField({ city: e.target.value });
                  }}
                />
              </div>
              <div className={`mt-2`}>
                <InputFull
                  type="text"
                  label="Pincode"
                  value={studentDetail?.pincode}
                  errCls="mt-1"
                  maxLength={6}
                  labelStyle="text-base"
                  onChange={(e) => {
                    let val = e.target.value.replace(/([^0-9])$/g, "");
                    updateField({ pincode: val });
                  }}
                />
              </div>
            </div>
            <div className={`mt-2`}>
              <InputFull
                type="text"
                label="Skill"
                value={studentDetail?.skills}
                onChange={(e) => {
                  updateField({ skills: e.target.value });
                }}
              />
            </div>

            <div className={`mt-2`}>
              <h3>Category</h3>
              <select
                id="goals"
                value={selectedCategory}
                className={`w-full border border-gray-400 rounded mt-1 p-2 h-12 bg-white focus:outline-none`}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  setSelectedCategory(e.target.value);
                  handleCategoryChange(e.target.value);
                }}
              >
                {categoryList?.map((goal, index) => (
                  <option key={index} label={goal} value={goal} />
                ))}
              </select>
            </div>

            <div>
              <div className={`mt-5 -mb-2`}>Selected Area of Interest</div>
              <div className=" md:text-base sm:text-sm text-xs mt-4">
                {goalsList &&
                  goalsList.length > 0 &&
                  goalsList.map((value) => {
                    return (
                      <button
                        style={{
                          background: `${
                            value.isSelected
                              ? context.theme!.button.background
                              : "white"
                          }`,
                          color: `${value.isSelected ? "white" : "black"}`,
                          borderColor: `${
                            value.isSelected
                              ? context.theme!.button.background
                              : "black"
                          }`,
                        }}
                        className=" h-auto py-4  w-auto px-4 mr-4 mb-3 text-white border-2 rounded-md "
                        onClick={() => changeSelectedGoals(value.goalName)}
                      >
                        {value.goalName}
                      </button>
                    );
                  })}
              </div>
            </div>
            <button
              className="mt-5 text-white text-sm h-10 w-32 rounded-md font-bold place-self-end  "
              style={{ backgroundColor: context.theme!.button.background }}
              type="button"
              onClick={() => {
                const goal =
                  goalsList &&
                  goalsList.length > 0 &&
                  goalsList
                    .filter((rec) => rec.isSelected)
                    .map((val) => val.goalName);
                const reqData = {
                  ...studentDetail,
                  category: selectedCategory,
                  goal,
                };
                console.log(reqData);
                updateProfile(registerType, reqData).then((data) => {
                  if (data.userId) {
                    toast.success("Updated", {
                      position: "top-right",
                      autoClose: 3000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                  } else {
                    toast.error("Sorry, Try after some time", {
                      position: "top-right",
                      autoClose: 3000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                  }
                });
              }}
            >
              UPDATE
            </button>
            <div className="mt-8 font-Lato font-bold not-italic ">
              Education
            </div>
            {studentDetail?.education?.map((detail: EducationDetails) => (
              <Education detail={detail} />
            ))}
            <button
              className={`text-white text-xs h-10 w-32 rounded-md font-bold place-self-end mt-5`}
              type="button"
              style={{ backgroundColor: context.theme!.button.background }}
              onClick={() => {
                const dummy = {
                  field: "",
                  institutionName: "",
                  duration: "",
                  courseName: "",
                };
                if (studentDetail && studentDetail.education) {
                  updateField({
                    education: [...studentDetail.education, dummy],
                  });
                } else {
                  updateField({ education: [dummy] });
                }
              }}
            >
              ADD EDUCATION
            </button>
            <div className={`mt-6`}>
              {registerType && registerType === "Student" ? (
                <></>
              ) : (
                <>
                  <div className="font-bold">Your Bank Details</div>
                  <div className="grid sm:grid-cols-2 grid-cols-1 mt-4 w-full gap-6">
                    <div>
                      <InputFull
                        label="Bank Account Number"
                        type="text"
                        value={accountNumber}
                        maxLength={18}
                        onChange={(e) => {
                          let val = e.target.value.replace(/([^0-9])/g, "");
                          setAccountNumber(val);
                        }}
                      ></InputFull>
                    </div>
                    <div>
                      <InputFull
                        label="Confirm Bank Account Number"
                        type="text"
                        maxLength={18}
                        value={confirmAccountNumber}
                        onChange={(e) => {
                          let val = e.target.value.replace(/([^0-9])/g, "");
                          setConfirmAccountNumber(val);
                        }}
                      ></InputFull>
                    </div>
                    <div>
                      <InputFull
                        label="IFSC Code"
                        type="text"
                        value={ifscCode}
                        onChange={(e) => {
                          let val = e.target.value.replace(
                            /([^0-9A-Za-z])/g,
                            ""
                          );
                          setIfscCode(val.toUpperCase());
                        }}
                      ></InputFull>
                    </div>
                    <div>
                      <InputFull label="Bank Name" type="text"></InputFull>
                    </div>
                    <div>
                      <InputFull
                        label="Account Holder Name"
                        type="text"
                        value={accountHolderName}
                        onChange={(e) => {
                          setAccountHolderName(e.target.value);
                        }}
                      ></InputFull>
                    </div>
                    <div>
                      <InputFull
                        label="PAN Number"
                        type="text"
                        value={panNumber}
                        onChange={(e) => {
                          let val = e.target.value.replace(
                            /([^0-9A-Za-z])/g,
                            ""
                          );
                          setPanNumber(val.toUpperCase());
                        }}
                      ></InputFull>
                    </div>
                    <div></div>
                  </div>
                  <div className={`flex justify-end `}>
                    <button
                      type="button"
                      className="text-white text-xs h-10 w-32 rounded-md font-bold mt-5"
                      style={{
                        backgroundColor: context.theme!.button.background,
                      }}
                      onClick={() => updateBankDetailsInfo()}
                    >
                      UPDATE
                    </button>
                  </div>
                </>
              )}
            </div>
            <div className="font-Lato font-bold text-md mt-5">
              Change Password
            </div>
            <div
              className={`grid sm:grid-cols-2 grid-cols-1 gap-6 w-full mt-2`}
            >
              <div className={`mt-4 grid `}>
                <div className="z-0">
                  <InputFull
                    type={showChangePassword ? "text" : "password"}
                    label="Password"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    errCls={passwordErr ? "border-red-400" : "border-gray-400"}
                    onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const isErr = validateInput("password", e.target.value);
                      setPasswordErr(isErr);
                    }}
                  />
                </div>
                <div className=" float-right z-40">
                  <FaEye
                    className="float-right -mt-7 mr-3  "
                    onClick={show_Password}
                  />{" "}
                </div>
              </div>

              <div className={`mt-4 grid `}>
                <div className="z-0">
                  <InputFull
                    type={showConfirmPassword ? "text" : "password"}
                    label="Confirm Password"
                    value={confirmPassword}
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                    }}
                    errCls={
                      cnfPasswordErr ? "border-red-400" : "border-gray-400"
                    }
                    onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const isErr =
                        password === confirmPassword
                          ? ""
                          : "Confirm password mismatch";
                      setCnfPasswordErr(isErr);
                    }}
                  />
                </div>

                <div className=" float-right z-40">
                  <FaEye
                    className="float-right -mt-7 mr-3  "
                    onClick={show_ConfirmPassword}
                  />{" "}
                </div>
              </div>
            </div>
            <div className={`text-xs mt-10`}>
              <ul>
                <li>Atleast 8 characters</li>
                <li>Atleast One special character</li>
                <li>Atleast One upper case alphabet(A-Z)</li>
                <li>Atleast One lower case alphabet(a-z)</li>
                <li>Atleast One number (0-9)</li>
              </ul>
            </div>
            <button
              className="text-white text-sm h-10 w-32 rounded-md font-bold place-self-end mt-8"
              style={{ backgroundColor: context.theme!.button.background }}
              type="button"
              onClick={() => {
                changePassword({ password, confirmPassword })
                  .then((data) => {
                    if (data !== undefined) {
                      toast.success("Password reset Successfully", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      });
                    } else {
                      toast.error("Failed To Update Password", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      });
                    }
                  })
                  .catch((error) => {
                    let errorMsg = "Failed to update password";
                    if (error.response.data !== undefined)
                      errorMsg = error.response.data.error;
                    toast.error(errorMsg, {
                      position: "top-right",
                      autoClose: 3000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                  });
              }}
            >
              UPDATE
            </button>
          </div>
        );
      }}
    </ThemeContext.Consumer>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userInfo: state && state.userInfoReducer,
  };
};

export default connect(mapStateToProps, null)(StudentProfileComp);
