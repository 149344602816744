import { DragDropContext } from "react-beautiful-dnd";
import AddNew from "../NewQuiz/AddNewquestion";
import TopicQuiz from "./TopicQuiz";

const NewCourseSubMenu = ({
  courseContent,
  dropedContent,
  lessonContent,
  topicContent,
  quizContent,
  onDragEnd,
  searchLesson,
  searchTopic,
  searchQuiz,
}: {
  courseContent: any;
  dropedContent: any;
  lessonContent: any;
  topicContent: any;
  quizContent: any;
  onDragEnd: any;
  searchLesson: any;
  searchTopic: any;
  searchQuiz: any;
}) => {
  return (
    <>
      <div className="text-base">Topics</div>
      <div className="mt-5  w-full border-solid border-gray-400 border rounded-md bg-white ">
        <input
          placeholder="Search Topics"
          className={`h-10 w-11/12 border-solid border-gray-400 border rounded-md m-3 p-2 pt-1 text-base bg-white`}
          onChange={(e) => searchTopic(e.target.value)}
        />
        <DragDropContext onDragEnd={onDragEnd}>
          <TopicQuiz
            bgClass="bg-lp_pink-dash_tail"
            lessonContent={lessonContent}
            topicContent={topicContent}
            quizContent={quizContent}
            mainCategory="topics"
          />
        </DragDropContext>
      </div>
      <br></br>
      <div className="text-base">Quiz</div>
      <div className="mt-5  w-full border-solid border-gray-400 border rounded-md bg-white ">
        <input
          placeholder="Search Topics"
          className={`h-10 w-11/12 border-solid border-gray-400 border rounded-md m-3 p-2 pt-1 text-base bg-white`}
          onChange={(e) => searchQuiz(e.target.value)}
        />
        <DragDropContext onDragEnd={onDragEnd}>
          <TopicQuiz
            bgClass=""
            lessonContent={lessonContent}
            topicContent={topicContent}
            quizContent={quizContent}
            mainCategory="quiz"
          />
        </DragDropContext>
      </div>
    </>
  );
};

export default NewCourseSubMenu;
