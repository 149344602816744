import { myCartTypes } from '../types/cartTypes';

const { MY_CART_ACTION_TYPE } = myCartTypes;

type CourseDetail = {
    courseId: string,
    price: number,
    userId: string,
    title: string
}

type CartActionType = {
    cart: Array<CourseDetail>;
    totalAmount: number;
}

const intitalState = {
    cart: [],
    totalAmount: 0,
}

export default function myCartReducer(state: CartActionType = intitalState, action: { type: string, payload: any }) {
    const { type, payload } = action;

    switch (type) {
        case MY_CART_ACTION_TYPE:
            return {
                ...state,
                cart: payload.cart,
                totalAmount: payload.totalAmount,
            }
        default:
            return state;
    }
}