import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import LessonsAPIService from "../../Api/Lessons/LessonsAPIService";
import NoCourse from "../../Components/Common/NoCourse";
import {
  ErrorMsgToaster,
  SuccessMsgToaster,
} from "../../Components/Utils/Toaster";
import DisplayImage from "../NewQuestion/DisplayImage";

type AnswerType = {
  questionId: string;
  selectedChoice: number;
  selectedAnswer: string;
};

const QuizView = ({ courseId, quizData, quizId }: any) => {
  const [selectedQuestion, setSelectedQuestion] = useState<any>();
  const [selectedAnswerType, setSelectedAnswerType] = useState<string>("");
  const [isLastQuestion, setIsLastQuestion] = useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [selectedAnswerList, setSelectedAnswerList] = useState<
    Array<AnswerType>
  >([]);
  const [correctAnswer, setCorrectAnswer] = useState<number>(0);
  const [isAllNotAnswer, setIsAllNotAnswer] = useState<boolean>(false);

  window.onbeforeunload = (event) => {
    console.log("Reload");
  };

  useEffect(() => {
    if (quizData && quizData?.length > 0) {
      setSelectedQuestion(quizData[0]);
      setSelectedAnswerType(quizData[0].answerType);
      setIsLastQuestion(false);
      setSelectedIndex(0);
    }
  }, []);

  const handleQuestionTileClick = (
    questionId: string,
    type: string,
    index: number
  ) => {
    setSelectedIndex(index);
    setSelectedAnswerType(type);
    setCorrectAnswer(0);
    const data =
      quizData && quizData.filter((rec: any) => rec.questionId === questionId);
    data && setSelectedQuestion(data[0]);
    const ans =
      selectedAnswerList &&
      selectedAnswerList.filter((rec) => rec.questionId === questionId);
    ans && ans[0] && setCorrectAnswer(ans[0].selectedChoice || 0);
    setIsLastQuestion(
      quizData[quizData.length - 1].questionId === questionId ? true : false
    );
  };

  const handlePrevClick = () => {
    const data = quizData && quizData[selectedIndex - 1];
    setCorrectAnswer(0);
    if (data) {
      const ans =
        selectedAnswerList &&
        selectedAnswerList.filter((rec) => rec.questionId === data.questionId);
      ans && ans[0] && setCorrectAnswer(ans[0].selectedChoice);
      setSelectedQuestion(data);
      setSelectedAnswerType(data.answerType);
      setSelectedIndex(selectedIndex - 1);
      setIsLastQuestion(
        quizData[quizData.length - 1].questionId === data.questionId
          ? true
          : false
      );
    }
  };

  const handleNextClick = () => {
    const data = quizData && quizData[selectedIndex + 1];
    setCorrectAnswer(0);
    if (data) {
      const ans =
        selectedAnswerList &&
        selectedAnswerList.filter((rec) => rec.questionId === data.questionId);
      ans && ans[0] && setCorrectAnswer(ans[0].selectedChoice);
      setSelectedQuestion(data);
      setSelectedAnswerType(data.answerType);
      setSelectedIndex(selectedIndex + 1);
      setIsLastQuestion(
        quizData[quizData.length - 1].questionId === data.questionId
          ? true
          : false
      );
    }
  };

  const handleRadioOptionChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    questionId: string
  ) => {
    const choiceNo = Number(e.target.value);
    setCorrectAnswer(choiceNo);
    const index = selectedAnswerList.findIndex(
      (rec: any) => rec.questionId === questionId
    );
    const filterData = quizData.filter(
      (rec: any) => rec.questionId === questionId
    )[0];
    const ansType = filterData.answerType;
    let ans = "";
    if (ansType === "text") {
      const ansList = filterData && JSON.parse(filterData.answers);
      ans = ansList[choiceNo - 1];
    } else if (ansType === "image") {
      ans = filterData && filterData.answers[choiceNo - 1].originalName;
    }
    if (index !== -1) {
      let newData = selectedAnswerList[index];
      newData = { ...newData, selectedAnswer: ans, selectedChoice: choiceNo };
      const oldDataSet = [...selectedAnswerList];
      oldDataSet[index] = newData;
      setSelectedAnswerList(oldDataSet);
    } else {
      const newData = {
        questionId,
        selectedAnswer: ans,
        selectedChoice: choiceNo,
      };
      setSelectedAnswerList([...selectedAnswerList, newData]);
    }
  };

  const RenderQuestion = () => {
    if (selectedAnswerType) {
      switch (selectedAnswerType) {
        case "text":
          return <RenderTextQuestion />;
        case "image":
          return <RenderImageQuestion />;
        default:
          return <NoCourse title='No Question Content Available' icon='quiz' />;
      }
    }
    return <></>;
  };

  const getIsTextChecked = (index: number, questionId: string) => {
    const data =
      selectedAnswerList &&
      selectedAnswerList.filter((rec) => rec.questionId === questionId)[0];
    return data && data.selectedChoice === index + 1 ? true : false;
  };

  const RenderTextQuestion = () => {
    if (selectedQuestion) {
      let {
        answers,
        question,
        description,
        timeToAnswer,
        correctAnswerScore,
        questionId,
      } = selectedQuestion;
      answers = typeof answers === "string" ? JSON.parse(answers) : answers;
      return (
        <div className={`p-4`}>
          <div>
            <h4 className={`text-2xl font-lato mt-2 font-bold`}>Question</h4>
            <input
              className={`w-full h-10 p-2 border-gray-400 border rounded-md mt-4 font-lato`}
              value={question}
              readOnly={true}
            />
          </div>
          <div>
            <h4 className={`text-2xl font-lato mt-2 font-bold`}>Description</h4>
            <input
              className={`w-full h-10 p-2 border-gray-400 border rounded-md mt-4 font-lato`}
              value={description}
              readOnly={true}
            />
          </div>
          <div>
            {answers &&
              answers.map((rec: string, index: number) => (
                <div className={`mt-4 p-4`}>
                  <input
                    type='radio'
                    name='text'
                    value={index + 1}
                    checked={index + 1 === correctAnswer}
                    className={`mr-4 transform scale-x-150 scale-y-150`}
                    onChange={(e) => handleRadioOptionChange(e, questionId)}
                  />
                  <label>{rec}</label>
                </div>
              ))}
          </div>
        </div>
      );
    }
    return <></>;
  };

  const RenderImageQuestion = () => {
    if (selectedQuestion) {
      let {
        answers,
        question,
        description,
        timeToAnswer,
        correctAnswerScore,
        questionId,
      } = selectedQuestion;
      answers = typeof answers === "string" ? JSON.parse(answers) : answers;
      return (
        <div className={`p-4`}>
          <div>
            <h4 className={`text-2xl font-lato mt-2 font-bold`}>Question</h4>
            <input
              className={`w-full h-10 p-2 border-gray-400 border rounded-md mt-4 font-lato`}
              value={question}
              readOnly={true}
            />
          </div>
          <div>
            <h4 className={`text-2xl font-lato mt-2 font-bold`}>Description</h4>
            <input
              className={`w-full h-10 p-2 border-gray-400 border rounded-md mt-4 font-lato`}
              value={description}
              readOnly={true}
            />
          </div>
          <div className={`grid grid-cols-2 gap-5 mt-4`}>
            {answers &&
              answers.map((rec: any, index: number) => {
                return (
                  <div className={`mt-2`}>
                    <div className={`flex flex-auto mb-4`}>
                      <div className={`flex justify-center items-center`}>
                        <input
                          type='radio'
                          name='text'
                          value={index + 1}
                          checked={index + 1 === correctAnswer}
                          className={`mr-4 transform scale-x-150 scale-y-150`}
                          onChange={(e) =>
                            handleRadioOptionChange(e, questionId)
                          }
                        />
                      </div>
                    </div>
                    <div className={`grid grid-cols-2 mb-2`}>
                      <div className={`w-full col-start-1 col-end-3`}>
                        {rec && (
                          <div
                            className={`h-52 w-full rounded-xl border-2  overflow-hidden`}
                          >
                            <img
                              className={`h-full w-full object-fit`}
                              src={rec.file}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      );
    }
    return <></>;
  };

  const resetFields = () => {
    setSelectedAnswerList([]);
    setSelectedQuestion("");
    setSelectedAnswerType("");
    setIsLastQuestion(false);
    setSelectedIndex(0);
    setCorrectAnswer(0);
    setIsAllNotAnswer(false);
  };

  const handleFormSubmit = () => {
    if (selectedAnswerList && selectedAnswerList.length > 0) {
      setIsAllNotAnswer(quizData.length !== selectedAnswerList.length);
      if (quizData.length !== selectedAnswerList.length) {
        return;
      }
      const answers: any[] = [];
      selectedAnswerList.forEach((rec) => {
        answers.push({
          questionId: rec.questionId,
          correctAnswer: rec.selectedAnswer,
        });
      });
      const reqData = { quizId: quizId, answers: answers };
      const lessonsAPIService = new LessonsAPIService();
      lessonsAPIService
        .postQuizData(courseId, reqData)
        .then(({ data, errorMsg }) => {
          if (data) {
            SuccessMsgToaster(data);
            resetFields();
          } else if (errorMsg) {
            ErrorMsgToaster(errorMsg);
          }
        })
        .catch((err) => console.log(err));
    } else {
      setIsAllNotAnswer(true);
    }
  };

  const getIsSelected = (questionId: string) => {
    const data =
      selectedAnswerList &&
      selectedAnswerList.filter((dat) => dat.questionId === questionId)[0];
    return data && data.selectedChoice ? "bg-green-200" : "";
  };

  return (
    <div>
      {quizData && quizData.length > 0 && (
        <div className={`h-full`}>
          <div
            className={`font-bold text-2xl mb-4`}
            style={{ color: "#0b4089" }}
          >
            Questions List
          </div>
          <div className={`grid grid-cols-6 gap-4`}>
            {quizData &&
              quizData.map((rec: any, index: number) => (
                <div
                  key={rec.questionId}
                  className={`border-2 border-black p-6 flex justify-center align-middle cursor-pointer font-semibold text-lg ${getIsSelected(
                    rec.questionId
                  )}`}
                  onClick={() =>
                    handleQuestionTileClick(
                      rec.questionId,
                      rec.answerType,
                      index
                    )
                  }
                >
                  {`Question ${index + 1}`}
                </div>
              ))}
          </div>
          <div
            className={`border-2 mt-6 border-black rounded-md`}
            style={{
              minHeight: "50rem",
              maxHeight: "50rem",
              overflow: "scroll",
            }}
          >
            <RenderQuestion />
          </div>
          <div>
            {isAllNotAnswer && (
              <p className={`text-red-400 m-4`}>
                Kindly answer all questions before Submitting
              </p>
            )}
          </div>
          <div className={`mt-4 float-right`}>
            {isLastQuestion && (
              <button
                type='submit'
                onClick={() => handleFormSubmit()}
                className={`h-10 text-xl w-28 ml-4 rounded-md font-Lato font-bold bg-lp_blue text-white`}
              >
                SUBMIT
              </button>
            )}
          </div>
          <div className={`mt-4 float-right`}>
            {selectedIndex !== 0 && (
              <button
                type='button'
                onClick={() => handlePrevClick()}
                className={`h-10 text-xl w-32 bg-gray-500 rounded-md font-Lato font-bold text-white`}
              >
                PREV
              </button>
            )}
            {!isLastQuestion && (
              <button
                type='button'
                onClick={() => handleNextClick()}
                className={`h-10 text-xl w-28 ml-4 rounded-md font-Lato font-bold bg-lp_blue text-white`}
              >
                NEXT
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default QuizView;
