import { useState } from "react";
import { ResourcesType } from "../../Utils/types";
import ResourceViewer from "./ResourceViewer";

const ResourceCard = ({
  originalName,
  resourceId,
  resourceLink,
  theme,
}: ResourcesType) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  let resourceType = "";
  const lastDot = originalName.lastIndexOf(".");
  const ext = originalName.substring(lastDot + 1);
  switch (ext) {
    case "jpg":
    case "jpeg":
      resourceType = "Image";
      break;
    case "mp4":
    case "mp3":
      resourceType = "Media";
      break;
    case "doc":
    case "docx":
    case "xlsx":
    case "xls":
      resourceType = "Document";
      break;
    case "pdf":
      resourceType = "PDF";
      break;
    default:
      resourceType = "";
  }

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div
      className={`bg-white h-full rounded-xl shadow-lg cursor-pointer shadow-blue-800 hover:shadow-xl hover:shadow-blue-800/50 master`}
    >
      <div
        onClick={() => {
          setIsModalOpen(true);
        }}
      >
        <div className={`h-48`}>
          {/* <img src={``}></img> */}
          <div className="flex justify-center">
            <i
              className={`material-icons text-6xl md:text-9xl font-light self-center py-8`}
              style={{ color: theme.icon }}
            >
              {resourceType === "PDF"
                ? `picture_as_pdf`
                : resourceType === "Media"
                ? `movie`
                : resourceType === "Document"
                ? `description`
                : `folder`}
            </i>
          </div>
        </div>
        <div className={`px-4 mb-2 grid grid-cols-2   border-t-2 border-black`}>
          <div className={`text-sm md:text-lg text-gray-700`}>File Name:</div>
          <div className={`text-sm md:text-lg -ml-14  font-bold `}>
            {originalName}
          </div>
        </div>
        <div className={`px-4 mb-2 grid grid-cols-2 `}>
          <div className={`text-sm md:text-lg text-gray-700`}>File Type:</div>
          <div className={`text-sm md:text-lg  -ml-14 font-bold `}>
            {resourceType}
          </div>
        </div>
      </div>
      <ResourceViewer
        isModalOpen={isModalOpen}
        originalName={originalName}
        key={resourceId}
        closeModal={closeModal}
        resourceLink={resourceLink}
      />
    </div>
  );
};

export default ResourceCard;
