// import { useState } from "react";
import { Assets } from "../../Api/Assets";
import ThemeContext from "../../Context/ThemeContext";
import { TrainerMyCourseResType } from "../../Utils/types";
import ButtonCourse from "../MyCourses/MyCoursesButton";

type ModeratorCoursesCardData = {
  myCourseTrainerData: TrainerMyCourseResType;
};

const ModeratorCoursesCard = ({
  myCourseTrainerData,
}: ModeratorCoursesCardData) => {
  // const [isInReview, setIsInReview] = useState(false);
  // const [isNewCourse, setIsNewCourse] = useState(true);

  return (
    <ThemeContext.Consumer>
      {(context) => {
        return (
          <div className={`flex h-52`}>
            <div
              className={` border-gray-300 h-52 inline-block w-4/12 rounded-lg`}
              style={{ backgroundColor: "#818181" }}
            >
              <img
                src={myCourseTrainerData?.image || Assets.SignUpBannerImage}
                className={`w-full h-full`}
                alt={myCourseTrainerData?.title}
                loading="lazy"
              />
            </div>
            <div
              className={`bg-gray-100 border-gray-300 h-52 inline-block w-8/12 rounded-lg `}
            >
              <div
                className={` not-italic font-bold text-xl ml-5 mt-5`}
                style={{ color: context.theme!.title }}
              >
                {myCourseTrainerData?.title}
              </div>
              <div
                className={`not-italic text-md ml-5 mt-2 font-semibold col-span-2`}
              >
                <div className={`inline-block`}>
                  Enrolled : {myCourseTrainerData?.enrolled}
                </div>
                <div className={`flex flex-auto float-right mr-14`}>
                  Completed : {myCourseTrainerData?.completed}
                </div>
              </div>

              <div className={`not-italic  text-md ml-5 mt-2 font-semibold`}>
                Published on: {myCourseTrainerData?.publishedOn}
              </div>
              <div className={`not-italic  text-md ml-5 mt-2 font-semibold `}>
                Last updated on : {myCourseTrainerData?.lastUpdatedOn}
              </div>

              {myCourseTrainerData?.courseReview === "Pending" && (
                <div
                  className={`rounded-lg h-7 p-1 text-white text-xs mr-4 mt-4 flex flex-auto float-right justify-center`}
                  style={{ backgroundColor: "#6D6D6D", borderRadius: "3px" }}
                >
                  REVIEW IN PROGRESS
                </div>
              )}
              {myCourseTrainerData?.courseReview !== "Pending" && (
                <button
                  className={`rounded-lg h-6 ml-96 p-2 text-white text-xs  mr-4 mt-4 flex flex-auto float-right justify-center`}
                  style={{ backgroundColor: "#CA0404", borderRadius: "3px" }}
                >
                  {myCourseTrainerData?.courseReview}
                </button>
              )}
            </div>
          </div>
        );
      }}
    </ThemeContext.Consumer>
  );
};

export default ModeratorCoursesCard;
