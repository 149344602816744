import { Link } from "react-router-dom";
import LogoContext from "../../Context/LogoContext";

const Logo = () => {
  return (
    <Link
      to={`/dashboard`}
      className={`flex items-center justify-center text-white text-4xl w-48 `}
    >
      <LogoContext.Consumer>
        {(context) => {
          return <img src={context.logo} alt="Her Springboard" />;
        }}
      </LogoContext.Consumer>
    </Link>
  );
};

export default Logo;
