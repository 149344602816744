import TableRows from "./TableRows";
import "./Table.css";
import { EarningType } from "../../Utils/types";

type propsType = {
  earningsList: Array<EarningType>;
};

const Table = ({ earningsList }: propsType) => {
  const tableHeaders = [
    "Date",
    "Transaction ID",
    "Description",
    "Amount",
    "Status",
  ];

  return (
    <div className={`text-xs md:text-sm lg:text-base`}>
      <table className={`mt-10`}>
        <tr style={{ background: "#CCE5FF" }}>
          {tableHeaders.map((header) => (
            <th>{header}</th>
          ))}
        </tr>

        {earningsList &&
          earningsList.length &&
          earningsList.map((rec) => {
            const { amount, transactionId, description, date, status } = rec;
            const TableInfo = {
              transactionId,
              amount,
              description,
              date,
              status,
            };
            return <TableRows TableInfo={TableInfo} />;
          })}
      </table>
    </div>
  );
};

export default Table;
