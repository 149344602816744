import { createContext } from "react";
import { Assets } from "../Api/Assets";

type ThemeValueProps = {
  logo: string;
  genderToggled?: VoidFunction;
};

const logoContext = createContext<ThemeValueProps>({
  logo: Assets.FallBackLogoHerspringboard,
});

export default logoContext;
