import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import AuthService from "../../Api/Auth/Auth";
import Logo from "../../Components/Common/Logo";
import { Link } from "react-router-dom";
import InputFull from "../../Components/Common/InuptFull";
import LoginImageTemplate from "../../Components/Utils/LoginImageTemplate";
import { validateInput } from "../../Components/Utils/ValidationUtils";
import Routes from "../../Routes/Routes";
import { useEffect } from "react";
import NodeCache from 'node-cache';

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [passwordErr, setPasswordErr] = useState("");
  const [cnfPasswordErr, setCnfPasswordErr] = useState("");

  const authService = new AuthService();
  const history = useHistory();
  const path = useLocation().pathname;
  const tokenCache = new NodeCache( { stdTTL: 900 } );
  const success = tokenCache.set( "token", path.replace("/reset/confirm-password/", ""), 900 );
  window.history.replaceState("/reset/confirm-password/", 'Title', '/reset/confirm-password/');

  const authToken: string | null = localStorage.getItem("token");
  if (authToken) {
    history.push("/dashboard");
  } 

  const matginTop = `mt-8`;
  const centerItem = `flex items-center justify-center`;

  const handleConfirmPassword = async () => {
    const response = await authService.confirmPasswordReset({
      password,
      confirmPassword,
      token: tokenCache.get('token'),
    });
    if (response.data !== undefined) {
      toast.success("Password reset Successfully", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setPassword("");
      setConfirmPassword("");
      history.replace(Routes.dashboard, response.data);
    } else {
      toast.error(response.error!.error!, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      history.replace(Routes.dashboard, response.data);
    }
  };

  useEffect(()  => {
      const handleConfirmPasswordGet = async () => {
        const response = await authService.confirmPasswordResetGet(tokenCache.get('token'));
        if (response.data == undefined) {
          toast.error(response.error!.error!, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          history.replace(Routes.dashboard); 
        }
      }
      
      handleConfirmPasswordGet();
  })


  return (
    <div className={` w-full  grid grid-cols-1 lg:grid-cols-2 font-lato`}>
      <div className={``}>
        <LoginImageTemplate />
      </div>
      <div className={`${centerItem}`}>
        <div>
          <div className={`${centerItem}`}>
            <Logo />
          </div>
          <div
            className={`grid justify-items-center text-lp_blue font-bold text-4xl ${matginTop}`}
          >
            Reset your Password
          </div>
          <div className={`mt-4`}>
            <InputFull
              type='password'
              label='Password'
              onChange={(event) => {
                setPassword(event.target.value);
              }}
              errCls={passwordErr ? "border-red-400" : "border-gray-400"}
              onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                const isErr = validateInput("password", e.target.value);
                setPasswordErr(isErr);
              }}
            />
            <div className={`p-1 text-xs text-red-600`}>{passwordErr}</div>
          </div>
          <div className={`mt-4`}>
            <InputFull
              type='password'
              label='Confirm Password'
              onChange={(event) => {
                setConfirmPassword(event.target.value);
              }}
              errCls={cnfPasswordErr ? "border-red-400" : "border-gray-400"}
              onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                const isErr =
                  password === confirmPassword
                    ? ""
                    : "Confirm password mismatch";
                setCnfPasswordErr(isErr);
              }}
            />
            <div className={`p-1 text-xs text-red-600`}>{cnfPasswordErr}</div>
          </div>
          <div className={`mt-6`}>
            <button
              className={`w-96 text-white bg-lp_blue text-xl p-2 rounded`}
              onClick={handleConfirmPassword}
            >
              RESET PASSWORD
            </button>
          </div>
          <div className={`${matginTop} text-lp_blue  mb-5 text-base`}>
            <Link to='/signin'>Click here to Sign in</Link>
          </div>
        </div>
      </div>
    </div>
  );
  
};

export default ResetPassword;
