import { useEffect, useState } from "react";
import { DashboardService } from "../../Api/Dashboard/Dashboard";
import { ErrorMsgToaster } from "../Utils/Toaster";
import NoCourse from "../Common/NoCourse";
import CourseTail from "./CourseTail";
import { TrendingCourseType } from "../../Utils/types";
import { ThemeProps } from "../../Themes/Themes";

const TrendingCourse = ({theme}: {theme: ThemeProps}) => {
  const [trendingCourse, setTrendingCourse] = useState<TrendingCourseType>();

  useEffect(() => {
    const dashboardService = new DashboardService();
    dashboardService
      .getTrendingCourse()
      .then((resData) => {
        if (resData.data) {
          setTrendingCourse(resData.data);
        }
      })
      .catch((err) => {
        console.log(err, "err for trending");
        ErrorMsgToaster(err);
      });
  }, []);

  return (
    <>
      {trendingCourse ? (
        <CourseTail
          courseData={trendingCourse}
          key={trendingCourse?.courseId}
          theme={theme}
        />
      ) : (
        <NoCourse title='Nothing Here' icon='school' />
      )}
    </>
  );
};

export default TrendingCourse;
