import { useEffect, useState } from "react";
// import StarRatings from "react-star-ratings";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import MyCourseAPIService from "../../../Api/MyCourses/MyCourseAPIService";
import { ErrorMsgToaster } from "../../../Components/Utils/Toaster";
import NoCourse from "../../../Components/Common/NoCourse";
import QuizFeedback from "../../QuizFeedback/QuizFeedback";
import { MyCourseDetailsType } from "../../../Utils/types";
import { ThemeProps } from "../../../Themes/Themes";

const CourseFeedback = ({theme}: {theme: ThemeProps}) => {
  const [myCourseData, setMyCourseData] =
    useState<Array<MyCourseDetailsType>>();
  // const [rating, setRating] = useState<number>(0);

  const myCourseAPIService = new MyCourseAPIService();

  useEffect(() => {
    myCourseAPIService
      .fetchMyCourseDetail({ rating: 0, completionPercentage: 0 })
      .then(({ data, errorMsg }) => {
        if (data) {
          setMyCourseData(data);
        }
        if (errorMsg) {
          ErrorMsgToaster(errorMsg);
        }
      })
      .catch((err) => ErrorMsgToaster(err));
  }, []);

  return (
    <div>
      {myCourseData && myCourseData.length !== 0 ? (
        <Accordion allowZeroExpanded className={`border-0`}>
          {myCourseData.map((rec) => {
            return (
              <AccordionItem className={`rounded-3xl m-2 h-full `}>
                <AccordionItemHeading>
                  <AccordionItemButton>{rec?.title}</AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className={`mb-20 mt-2`}>
                  <QuizFeedback
                    title={rec.title}
                    key={rec.courseId}
                    courseId={rec.courseId}
                    theme={theme}
                  />
                </AccordionItemPanel>
              </AccordionItem>
            );
          })}
        </Accordion>
      ) : (
        <div className={`mt-12`}>
          <NoCourse title='No Courses Available' />
        </div>
      )}
    </div>
  );
};

export default CourseFeedback;
