import { createContext } from "react";
import { ThemeProps } from "../Themes/Themes";

export type ThemeValueProps = {
  theme?: ThemeProps;
  getTheme?: VoidFunction;
};

const themeContext = createContext<ThemeValueProps>({});

export default themeContext;
