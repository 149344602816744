import axios, { AxiosError, AxiosResponse } from "axios";
import {
  ApplicationJsonConfigHeader,
  FormDataFileConfigHeader,
} from "./axios.config";
import { GeneralError } from "./Error.type";

type APIRequestType = {
  APIUrl: string;
  payload: any;
  resType?: any;
};
// const token: string | null = localStorage.getItem("token");
export default class APIService {
  // Get Request with params
  getReqWithParams = async ({ APIUrl, payload, resType }: APIRequestType) => {
    const token: string | null = localStorage.getItem("token");
    try {
      const response: AxiosResponse = await axios.get(APIUrl, {
        params: payload,
        headers: {
          ...ApplicationJsonConfigHeader,
          authorization: `Bearer ${token}`,
        },
        responseType: "json",
      });
      if (response.status === 200) {
        return { data: response.data, statusCode: response.status };
      } else {
        const errorMsg = new GeneralError(response.data.error, response.status);
        return { errorMsg, statusCode: response.status };
      }
    } catch (e: any) {
      const err = e as AxiosError;
      const { error } = err && err.response?.data;
      const errorMsg = new GeneralError(error || "Something went wrong", 400);
      return { errorMsg, statusCode: error.statusCode };
    }
  };

  // Get Request without params
  getReqs = async ({ APIUrl, payload, resType }: APIRequestType) => {
    const token: string | null = localStorage.getItem("token");

    try {
      const response: AxiosResponse = await axios.get(APIUrl, {
        headers: {
          ...ApplicationJsonConfigHeader,
          authorization: `Bearer ${token}`,
        },
        responseType: "json",
      });
      if (response.status === 200) {
        return { data: response.data, statusCode: response.status };
      } else {
        const errorMsg = new GeneralError(response.data.error, response.status);
        return { errorMsg, statusCode: response.status };
      }
    } catch (e: any) {
      const err = e as AxiosError;
      const { error } = err && err.response?.data;
      const errorMsg = new GeneralError(error || "Something went wrong", 400);
      return { errorMsg, statusCode: error.statusCode };
    }
  };

  // Post Request
  postReqs = async ({ APIUrl, payload, resType }: APIRequestType) => {
    const token: string | null = localStorage.getItem("token");
    try {
      const response: AxiosResponse = await axios.post(APIUrl, payload, {
        headers: {
          ...ApplicationJsonConfigHeader,
          authorization: `Bearer ${token}`,
        },
        responseType: "json",
      });
      if (response.status === 200) {
        return { data: response.data, statusCode: response.status };
      } else {
        const errorMsg = new GeneralError(response.data.error, response.status);
        return { errorMsg, statusCode: response.status };
      }
    } catch (e: any) {
      const err = e as AxiosError;
      const { error } = err && err.response?.data;
      const errorMsg = new GeneralError(error || "Something went wrong", 400);
      return { errorMsg, statusCode: errorMsg.statusCode };
    }
  };

  // Post Request with files
  postReqsWithFiles = async ({ APIUrl, payload, resType }: APIRequestType) => {
    const token: string | null = localStorage.getItem("token");
    try {
      const response: AxiosResponse = await axios.post(APIUrl, payload, {
        headers: {
          ...FormDataFileConfigHeader,
          authorization: `Bearer ${token}`,
        },
        responseType: "json",
      });
      if (response.status === 200) {
        return { data: response.data, statusCode: response.status };
      } else {
        const errorMsg = new GeneralError(response.data.error, response.status);
        return { errorMsg, statusCode: response.status };
      }
    } catch (e: any) {
      const err = e as AxiosError;
      const { error } = err && err.response?.data;
      const errorMsg = new GeneralError(error || "Something went wrong", 400);
      return { errorMsg, statusCode: errorMsg.statusCode };
    }
  };

  // Delete Request
  deleteReqs = async ({ APIUrl, payload, resType }: APIRequestType) => {
    const token: string | null = localStorage.getItem("token");
    try {
      const response: AxiosResponse = await axios.delete(APIUrl, {
        data: { payload },
        headers: {
          ...ApplicationJsonConfigHeader,
          authorization: `Bearer ${token}`,
        },
        responseType: "json",
      });
      if (response.status === 200) {
        return { data: response.data, statusCode: response.status };
      } else {
        const errorMsg = new GeneralError(response.data.error, response.status);
        return { errorMsg, statusCode: response.status };
      }
    } catch (e: any) {
      const err = e as AxiosError;
      const { error } = err && err.response?.data;
      const errorMsg = new GeneralError(error || "Something went wrong", 400);
      return { errorMsg, statusCode: error.statusCode };
    }
  };

  // Put Request
  putReqs = async ({ APIUrl, payload, resType }: APIRequestType) => {
    const token: string | null = localStorage.getItem("token");
    try {
      const response: AxiosResponse = await axios.put(APIUrl, payload, {
        headers: {
          ...ApplicationJsonConfigHeader,
          authorization: `Bearer ${token}`,
        },
        responseType: "json",
      });
      if (response.status === 200) {
        return { data: response.data, statusCode: response.status };
      } else {
        const errorMsg = new GeneralError(response.data.error, response.status);
        return { errorMsg, statusCode: response.status };
      }
    } catch (e: any) {
      const err = e as AxiosError;
      const { error } = err && err.response?.data;
      const errorMsg = new GeneralError(error || "Something went wrong", 400);
      return { errorMsg, statusCode: error.statusCode };
    }
  };

  // Patch Request - with both body and params
  patchReqs = async ({ APIUrl, payload, resType }: APIRequestType) => {
    const token: string | null = localStorage.getItem("token");
    try {
      const response: AxiosResponse = await axios.patch(APIUrl, payload, {
        headers: {
          ...ApplicationJsonConfigHeader,
          authorization: `Bearer ${token}`,
        },
        responseType: "json",
      });
      if (response.status === 200) {
        return { data: response.data, statusCode: response.status };
      } else {
        const errorMsg = new GeneralError(response.data.error, response.status);
        return { errorMsg, statusCode: response.status };
      }
    } catch (e: any) {
      const err = e as AxiosError;
      const { error } = err && err.response?.data;
      const errorMsg = new GeneralError(error || "Something went wrong", 400);
      return { errorMsg, statusCode: error.statusCode };
    }
  };

  // Patch Request with files - with both body and params
  patchReqsWithFiles = async ({ APIUrl, payload, resType }: APIRequestType) => {
    const token: string | null = localStorage.getItem("token");
    try {
      const response: AxiosResponse = await axios.patch(APIUrl, payload, {
        headers: {
          ...FormDataFileConfigHeader,
          authorization: `Bearer ${token}`,
        },
        responseType: "json",
      });
      if (response.status === 200) {
        return { data: response.data, statusCode: response.status };
      } else {
        const errorMsg = new GeneralError(response.data.error, response.status);
        return { errorMsg, statusCode: response.status };
      }
    } catch (e: any) {
      const err = e as AxiosError;
      const { error } = err && err.response?.data;
      const errorMsg = new GeneralError(error || "Something went wrong", 400);
      return { errorMsg, statusCode: error.statusCode };
    }
  };
}
