import { useEffect, useState } from "react";
import TrainerLessonsAPIService from "../../Api/Lessons/TrainerLessonAPI";
import TrainerQuizAPIService from "../../Api/Quiz/TrainerQuizAPIService";
import TrainerTopicsAPIService from "../../Api/Topics/TrainerTopicsAPI";
import MenuTrainer from "../../Components/Template/MenuTrainer";
import Template from "../../Components/Template/Template";
import NewCoursebody from "./NewCourseBody";
import NewCourseSubMenu from "./NewCourseSubMenu";

type dropData = {
  id: string;
  itemType: string;
  title: string;
  keyId: string;
};

let totalLessonandTopics: Array<dropData> = [];
let totalTopics: Array<dropData> = [];
let totalQuiz: Array<dropData> = [];

let newDD = [
  {
    id: "DND",
    itemType: String,
    title: "Drag and drop topics or quiz",
  },
];
let initialTopic: Array<dropData> = [];
let initialQuiz: Array<dropData> = [];
let initialLesson: Array<dropData> = [];
let itemId = 400000;

const NewCourse = () => {
  const lessonAPIService = new TrainerLessonsAPIService();
  const topicAPIService = new TrainerTopicsAPIService();
  const quizAPIService = new TrainerQuizAPIService();

  const [courseContent, setCourseContent] = useState(totalLessonandTopics);
  const [ddContent, setDDContent] = useState(newDD);

  const [lessonContent, setLessonContent] = useState(initialLesson);
  const [topicContent, setTopicContent] = useState(totalTopics);
  const [quizContent, setQuizContent] = useState(totalQuiz);

  const [updateindex, updateComponent] = useState(1);
  const [isLoading, setLoading] = useState(true);

  const [lessonSearch, setLessonSearch] = useState("");
  const [topicSearch, setTopicSearch] = useState("");
  const [quizSearch, setQuizSearch] = useState("");

  const onDragEnd = ({ destination, source }: any) => {
    if (!destination) {
      if (
        source.droppableId === "itemsL" ||
        source.droppableId === "itemsT" ||
        source.droppableId === "itemsQ"
      ) {
        destination = {
          droppableId: "items2",
          index: 0,
        };
      } else {
        return;
      }
    }
    let arrSource: any;
    let arrDest: any;
    if (source.droppableId == "items") {
      arrSource = courseContent;
    } else if (source.droppableId === "items2") {
      arrSource = newDD;
    } else if (source.droppableId === "itemsL") {
      arrSource = lessonContent;
    } else if (source.droppableId === "itemsT") {
      arrSource = topicContent;
    } else if (source.droppableId === "itemsQ") {
      arrSource = quizContent;
    }

    if (destination.droppableId == "items") {
      arrDest = courseContent;
    } else if (destination.droppableId == "items2") {
      arrDest = newDD;
    } else if (destination.droppableId == "itemsL") {
      arrDest = lessonContent;
    } else if (destination.droppableId == "itemsT") {
      arrDest = topicContent;
    } else if (destination.droppableId === "itemsQ") {
      arrDest = quizContent;
    }

    const sourceClone = arrSource;
    const destClone = arrDest;
    const [removed] = sourceClone.splice(source.index, 1);
    destClone.splice(destination.index, 0, removed);
    const result: any = {};
    result[source.droppableId] = sourceClone;
    result[destination.droppableId] = destClone;
    if (destination.droppableId !== source.droppableId) {
      if (destination.droppableId === "items") {
        setDDContent(destClone);
      }
      if (source.droppableId === "items2") {
        setDDContent(sourceClone);
      }
    }
    updateComponent(updateindex + 1);
  };

  useEffect(() => {
    setCourseContent([]);
    topicAPIService.fetchAllTopicsDetail().then((resData) => {
      let id = 200000;
      if (resData.data) {
        const tempData = resData.data.map((rec: any) => ({
          id: (id++).toString(),
          topicId: rec.topicId,
          itemType: "Topic",
          title: `${rec.title}`,
          keyId: rec.topicId,
        }));
        initialTopic = tempData;
        setTopicContent(tempData);
      }
    });
    quizAPIService.fetchAllQuizes().then((resData) => {
      let id = 300000;
      if (resData.data) {
        const tempData = resData.data.map((rec: any) => ({
          id: (id++).toString(),
          quizId: rec.quizId,
          itemType: "Quiz",
          title: `${rec.title}`,
          keyId: rec.quizId,
        }));
        initialQuiz = tempData;
        setQuizContent(tempData);
      }
    });
  }, []);

  useEffect(() => {
    if (topicSearch) {
      topicAPIService.searchTopics(topicSearch).then((resData) => {
        if (resData && resData.data) {
          const tempData = resData.data.map((rec: any) => ({
            id: (itemId++).toString(),
            topicId: rec.topicId,
            itemType: "Topic",
            title: `${rec.title}`,
          }));
          setTopicContent(tempData);
        } else {
          setTopicContent([] as Array<dropData>);
        }
      });
    } else {
      setTopicContent(initialTopic);
    }
  }, [topicSearch]);

  useEffect(() => {
    if (quizSearch) {
      quizAPIService.searchQuiz(quizSearch).then((resData) => {
        if (resData && resData.data) {
          const tempData = resData.data.map((rec: any) => ({
            id: (itemId++).toString(),
            quizId: rec.quizId,
            itemType: "Quiz",
            title: `${rec.title}`,
          }));
          setQuizContent(tempData);
        } else {
          setQuizContent([] as Array<dropData>);
        }
      });
    } else {
      setQuizContent(initialQuiz);
    }
  }, [quizSearch]);

  const deleteItem = (id: string) => {
    let isDelete = false;
    setCourseContent(
      courseContent.filter((item) => {
        if (isDelete && item.itemType === "Lesson") {
          isDelete = !isDelete;
          return true;
        }
        if (isDelete && item.itemType !== "Lesson") {
          return false;
        }
        if (item.id !== id) {
          return true;
        } else if (item.id === id) {
          if (item.itemType === "Lesson") isDelete = !isDelete;
          return false;
        }
      })
    );
  };

  return (
    <>
      {isLoading && (
        <div className={`m-0 h-screen w-full bg-white fixed z-10`}>Loading</div>
      )}
      <Template
        menu={<MenuTrainer selectedItem={`COURSES`} />}
        body={
          <NewCoursebody
            setCourseContent={setCourseContent}
            courseContent={courseContent}
            dropedContent={ddContent}
            onDragEnd={onDragEnd}
            deleteItem={deleteItem}
            isLoading={isLoading}
            setLoading={setLoading}
          />
        }
        submenu={
          <NewCourseSubMenu
            courseContent={courseContent}
            dropedContent={ddContent}
            lessonContent={lessonContent}
            topicContent={topicContent}
            quizContent={quizContent}
            onDragEnd={onDragEnd}
            searchLesson={setLessonSearch}
            searchTopic={setTopicSearch}
            searchQuiz={setQuizSearch}
          />
        }
      />
    </>
  );
};

export default NewCourse;
