import { ThemeProps } from "../../Themes/Themes";

const DashboardCommon = ({
  pendingSettlement,
  earnedThisMonth,
  theme,
}: {
  pendingSettlement: number | undefined;
  earnedThisMonth: number | undefined;
  theme: ThemeProps;
}) => {
  const currencyFormat = new Intl.NumberFormat("en-US", {
    style: "currency",
    maximumFractionDigits: 0,
    currency: "INR",
  });
  return (
    <>
      <div className='grid grid-cols-2 gap-5'>
        <div 
          className='flex flex-col gap-5 items-center justify-center h-48 w-full rounded-lg'
          style={{
            backgroundColor: theme.dashboardTrainer.tile4,
          }}
        >
          <div className='text-black font-bold text-5xl md:text-7xl mt-8 '>
            {earnedThisMonth ? currencyFormat.format(earnedThisMonth) : 0}
          </div>
          <div className='font-bold text-xs md:text-sm text-center mt-6  '>
            Earned this month
          </div>
        </div>
        <div 
          className='flex flex-col  items-center justify-center gap-5 h-48 w-full rounded-lg'
          style={{
            backgroundColor: theme.dashboardTrainer.tile5,
          }}
        >
          <div className='text-black font-bold text-5xl md:text-7xl mt-8'>
            {pendingSettlement ? currencyFormat.format(pendingSettlement) : 0}
          </div>
          <div className='font-bold text-xs md:text-sm text-center mt-6'>
            Pending Settlement
          </div>
        </div>
      </div>
    </>
  );
};
export default DashboardCommon;
