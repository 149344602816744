import { Component, Dispatch, SetStateAction } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import Required from "../../Components/Common/Required";
import { QuestionsListType } from "../../Utils/types";
import AddNew from "./AddNewquestion";
// import NoCourse from "../../Components/Common/NoCourse";

type props = {
  addQuestionList: Array<QuestionsListType>;
  questionBankList: Array<QuestionsListType>;
  setFilterKey: Dispatch<SetStateAction<string>>;
  isViewMode: boolean;
};

export class QuizDnd extends Component<props> {
  addQuestionList = this.props.addQuestionList;
  questionBankList = this.props.questionBankList;
  setFilterKey = this.props.setFilterKey;
  isViewMode = this.props.isViewMode;

  render = () => (
    <>
      <div className={`font-bold text-lg mt-2`}>
        <Required />
        {this.props.isViewMode ? "Added Questions" : "Add Questions"}
      </div>
      <div className='mt-4 min-h-48 h-full w-full border-solid border-gray-400 border rounded-md  '>
        <Droppable droppableId='addQuestionListId' type='QUESTIONS'>
          {(provided: any) => (
            <ul
              className={`characters h-auto`}
              style={{ minHeight: "12rem" }}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {this.props.addQuestionList &&
                this.props.addQuestionList.length > 0 &&
                this.props.addQuestionList.map(
                  ({ question, questionId }: any, index: any) => {
                    return (
                      <Draggable
                        key={questionId}
                        draggableId={questionId}
                        index={index}
                        disableInteractiveElementBlocking={
                          this.props.isViewMode
                        }
                        isDragDisabled={this.props.isViewMode}
                      >
                        {(provided: any) => (
                          <li
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <AddNew
                              value={question}
                              bgClass='bg-green-100'
                            ></AddNew>
                          </li>
                        )}
                      </Draggable>
                    );
                  }
                )}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </div>
      {!this.props.isViewMode && (
        <>
          <div className={`font-bold text-lg mt-2`}>Question Bank</div>
          <div className='mt-4 h-68 w-full border-solid border-gray-400 border rounded-md  '>
            <div className={`w-11/12 mt-2`}>
              <input
                placeholder='Search Question'
                className={`h-10 w-full text-sm my-4 mx-2 p-4 border-gray-400 border rounded-md mt-2 font-lato `}
                type='text'
                onChange={(e) => this.setFilterKey(e.target.value)}
              />
            </div>
            <Droppable droppableId='questionBankListId' type='QUESTIONS'>
              {(provided: any) => (
                <ul
                  className={`characters h-auto`}
                  style={{ minHeight: "12rem" }}
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {this.props.questionBankList &&
                    this.props.questionBankList.length > 0 &&
                    this.props.questionBankList.map(
                      ({ question, questionId }: any, index: any) => {
                        return (
                          <Draggable
                            key={questionId}
                            draggableId={questionId}
                            index={index}
                          >
                            {(provided: any) => (
                              <li
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <AddNew
                                  value={question}
                                  bgClass='bg-blue-100'
                                ></AddNew>
                              </li>
                            )}
                          </Draggable>
                        );
                      }
                    )}
                  {provided.placeholder}
                </ul>
              )}
            </Droppable>
          </div>
        </>
      )}
    </>
  );
}
