import axios from "axios";

export const BaseUrl: string = "https://api.herspringboard.in/production";


export const ApplicationJsonConfigHeader = {
  "Content-Type": "application/json",
};

export const FormDataFileConfigHeader = {
  "Content-Type": "multipart/form-data",
};

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);
