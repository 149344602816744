import { useEffect, useState } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import { SubmitHandler, useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import QuestionsAPIService from "../../Api/Questions/QuestionsAPIService";
import TrainerQuizAPIService from "../../Api/Quiz/TrainerQuizAPIService";
import ButtonReuse from "../../Components/Common/ButtonReuse";
import Required from "../../Components/Common/Required";
import SubmenuTrainer from "../../Components/Common/SubMenuTrainer";
import MenuTrainer from "../../Components/Template/MenuTrainer";
import Template from "../../Components/Template/Template";
import {
  ErrorMsgToaster,
  SuccessMsgToaster,
} from "../../Components/Utils/Toaster";
import ThemeContext from "../../Context/ThemeContext";
import Routes from "../../Routes/Routes";
import { QuestionsListType, QuizReqType, QuizResType } from "../../Utils/types";
import { QuizDnd } from "./QuizDnd";

type IFormInput = {
  title: string;
  description: string;
  quizType: string;
  passingScore: string;
  questions: any;
};

export const NewQuizbody = ({
  quizMode,
  quizId,
}: {
  quizMode: string;
  quizId: string;
}) => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm<IFormInput>();

  const history = useHistory();

  const [mode, setMode] = useState("new");
  const [editQuizData, setEditQuizData] = useState<QuizResType>({
    quizId: "",
    userId: "",
    description: "",
    title: "",
    passingScore: 0,
    questions: [],
  });
  const [questionsList, setQuestionsList] = useState<Array<QuestionsListType>>(
    []
  );
  const [questionsBankList, setQuestionsBankList] = useState<
    Array<QuestionsListType>
  >([]);
  const [originalQbankList, setOriginalQbankList] = useState<
    Array<QuestionsListType>
  >([]);
  const [minScoreReq, setMinScoreReq] = useState<number>(0);
  const [isMinScoreErr, setIsMinScoreErr] = useState<boolean>(false);
  const [isQuestionListEmpty, setIsQuestionListEmpty] =
    useState<boolean>(false);
  const [filterKey, setFilterKey] = useState("");
  const [isViewMode, setIsViewMode] = useState<boolean>(false);

  useEffect(() => {
    setFilterKey("");
    setQuestionsList([]);
    setMode(quizMode);
    setIsViewMode(quizMode === "view" ? true : false);
    if ((quizMode === "edit" || quizMode === "view") && quizId) {
      const trainerQuizAPI = new TrainerQuizAPIService();
      trainerQuizAPI
        .fetchQuizDetails(quizId)
        .then(({ data, errorMsg }) => {
          if (data) {
            setEditQuizData(data);
          } else if (errorMsg) {
            ErrorMsgToaster(errorMsg);
          }
        })
        .catch((err) => console.log(err));
    }
    const questionsAPIService = new QuestionsAPIService();
    questionsAPIService
      .fetchAllQuestions()
      .then(({ data, errorMsg }) => {
        if (data) {
          setQuestionsBankList(data);
          setOriginalQbankList(data);
        } else if (errorMsg) {
          ErrorMsgToaster(errorMsg);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const filterQuestions = (key: any) => {
    const questionsAPIService = new QuestionsAPIService();
    if (key) {
      questionsAPIService
        .searchQuestions(key)
        .then((resData) => {
          if (resData && resData.data) {
            const questionsIdList =
              questionsList && questionsList.map((rec) => rec.questionId);
            let rData = resData.data;
            if (questionsIdList.length > 0) {
              rData = rData.filter(
                (rec: any) => !questionsIdList.includes(rec.questionId)
              );
            }
            setQuestionsBankList(rData);
          } else {
            setQuestionsBankList([]);
          }
        })
        .catch((err) => console.log(err));
    } else {
      setQuestionsBankList(originalQbankList);
    }
  };

  useEffect(() => {
    if (editQuizData) {
      if (questionsBankList) {
        const prevQuestionsIdList =
          editQuizData && editQuizData.questions.map((rec) => rec.questionId);
        const newQList = questionsBankList.filter(
          (rec) => !prevQuestionsIdList.includes(rec.questionId)
        );
        setQuestionsBankList(newQList);
      }
      const { title, description, passingScore, questions } = editQuizData;
      title && setValue("title", title);
      description && setValue("description", description);
      passingScore && setValue("passingScore", String(passingScore));
      setQuestionsList(questions);
    }
  }, [editQuizData]);

  useEffect(() => filterQuestions(filterKey), [filterKey]);

  const resetFields = () => {
    const questionsAPIService = new QuestionsAPIService();
    questionsAPIService
      .fetchAllQuestions()
      .then(({ data, errorMsg }) => {
        if (data) {
          setQuestionsBankList(data);
          setOriginalQbankList(data);
        } else if (errorMsg) {
          ErrorMsgToaster(errorMsg);
        }
      })
      .catch((err) => console.log(err));
    setValue("title", "", { shouldValidate: false });
    setValue("description", "", { shouldValidate: false });
    setValue("passingScore", "", { shouldValidate: false });
    setMinScoreReq(0);
    setQuestionsList([]);
    setFilterKey("");
    setIsMinScoreErr(false);
    setIsQuestionListEmpty(false);
  };

  const handleFormReset = () => {
    resetFields();
  };

  const onDragEnd = ({ destination, source }: any) => {
    if (!destination) {
      return;
    }

    let sourceId = "",
      destinationId = "";
    sourceId = source.droppableId;
    destinationId = destination.droppableId;

    let arrSource: any;
    let arrDest: any;

    if (sourceId !== destinationId) {
      if (sourceId == "addQuestionListId") {
        arrSource = questionsList;
      } else if (sourceId == "questionBankListId") {
        arrSource = questionsBankList;
      }

      if (destinationId == "addQuestionListId") {
        arrDest = questionsList;
      } else if (destinationId == "questionBankListId") {
        arrDest = questionsBankList;
      }
      const sourceClone: any[] = arrSource;
      const destClone: any[] = arrDest;
      const [removed] = sourceClone && sourceClone.splice(source.index, 1);
      destClone && destClone.splice(destination.index, 0, removed);
      const result: any = {};
      result[sourceId] = sourceClone;
      result[destinationId] = destClone;

      if (
        sourceId == "questionBankListid" &&
        destinationId == "addQuestionListId"
      ) {
        setQuestionsList(destClone);
        setQuestionsBankList(sourceClone);
      } else if (
        sourceId == "addQuestionListId" &&
        destinationId == "questionBankListid"
      ) {
        setQuestionsList(sourceClone);
        setQuestionsBankList(destClone);
      }
    } else if (sourceId === destinationId) {
      if (
        sourceId === "addQuestionListId" &&
        destinationId === "addQuestionListId"
      ) {
        const sdArr: any[] = questionsList;
        const [removed] = sdArr && sdArr.splice(source.index, 1);
        sdArr.splice(destination.index, 0, removed);
        setQuestionsList(sdArr);
      } else if (
        sourceId == "questionBankListid" &&
        destinationId == "questionBankListid"
      ) {
        const sdArr: any[] = questionsBankList;
        const [removed] = sdArr && sdArr.splice(source.index, 1);
        sdArr.splice(destination.index, 0, removed);
        setQuestionsBankList(sdArr);
      }
    }
  };

  const onSubmit: SubmitHandler<IFormInput> = (data: IFormInput) => {
    const { title, description, passingScore } = data;
    const questionsIdList =
      questionsList && questionsList.map((rec) => rec.questionId);
    const minReqScore =
      questionsList &&
      questionsList.reduce((prev, curr) => prev + curr.correctAnswerScore, 0);

    setMinScoreReq(minReqScore);

    if (!questionsList || questionsList.length === 0) {
      setIsQuestionListEmpty(true);
      return;
    } else {
      setIsQuestionListEmpty(false);
    }

    if (Number(passingScore) > minReqScore) {
      setIsMinScoreErr(true);
      return;
    } else {
      setIsMinScoreErr(false);
    }

    const payload: QuizReqType = {
      title,
      description,
      passingScore: Number(passingScore),
      questions: questionsIdList,
      totalScore: Number(minScoreReq),
    };

    if (mode === "new") {
      const trainerQuizService = new TrainerQuizAPIService();
      trainerQuizService.addNewQuiz(payload).then((resData) => {
        if (resData?.data) {
          SuccessMsgToaster(resData?.data);
          resetFields();
        }
        if (resData?.errorMsg) {
          ErrorMsgToaster(resData?.errorMsg);
        }
      });
    } else if (mode === "edit") {
      const trainerQuizService = new TrainerQuizAPIService();
      trainerQuizService.editQuiz(payload, quizId).then((resData) => {
        if (resData?.data) {
          SuccessMsgToaster(resData?.data);
          resetFields();
          history.push(Routes.quizTrainer);
        }
        if (resData?.errorMsg) {
          ErrorMsgToaster(resData?.errorMsg);
        }
      });
    }
  };

  return (
    <ThemeContext.Consumer>
      {(context) => {
        return (
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={`flex flex-col text-black`}>
              <div
                className="flex flex-col  text-2xl font-bold font-Lato "
                style={{ color: context.theme!.title }}
              >
                <p className="  float-left">
                  {mode === "new"
                    ? `Add New Quiz`
                    : mode === "edit"
                    ? `Edit Quiz - ${editQuizData.title}`
                    : `View Quiz - ${editQuizData.title}`}
                  {!isViewMode && (
                    <Link to={Routes.quizTrainer}>
                      <ButtonReuse
                        value={"VIEW ALL QUIZZES"}
                        colorClass={"bg-yellow-200"}
                        heightClass={"h-8"}
                        widthClass={"w-44"}
                        sizeClass={"text-sm"}
                        positionClass={"float-right"}
                        tcolorClass={"text-black"}
                      ></ButtonReuse>
                    </Link>
                  )}
                </p>
              </div>
              <div className="flex flex-col gap-5">
                <div>
                  <h4 className={`text-sm font-lato mt-6`}>
                    <Required />
                    Quiz Title
                  </h4>
                  <input
                    {...register("title", { required: true })}
                    className={`h-10 w-full text-sm p-2 border-gray-400 border rounded-md mt-2 font-lato `}
                    type="text"
                    disabled={isViewMode}
                  />
                  {errors && errors.title && (
                    <p className={`text-red-500`}>Quiz Title is Required</p>
                  )}
                </div>
                <div>
                  <h4 className={`text-sm font-lato mt-2`}>
                    Description (Optional)
                  </h4>
                  <textarea
                    {...register("description")}
                    className={`h-48 w-full text-sm p-2 border-gray-400 border rounded-md mt-2 font-lato `}
                    disabled={isViewMode}
                  />
                </div>
              </div>
              <div className="grid grid-cols-3 gap-5 mt-4">
                <div>
                  <h4 className={`text-sm font-lato`}>
                    <Required />
                    Passing Score
                  </h4>
                  <input
                    {...register("passingScore", { required: true })}
                    className={`h-10 w-full text-sm p-2 border-gray-400 border rounded-md mt-2 font-lato `}
                    type="number"
                    maxLength={3}
                    min="1"
                    disabled={isViewMode}
                    onInput={(e) => {
                      return (
                        e.currentTarget.validity.valid ||
                        (e.currentTarget.value = "")
                      );
                    }}
                  />
                  {errors && errors.passingScore && (
                    <p className={`text-red-500`}>Passing Score is Required</p>
                  )}
                  {isMinScoreErr && (
                    <p className={`text-red-500`}>
                      {`Total Passing Score should be lesser or equal to "${minScoreReq}" - [ Sum of all added questions correct answer score]`}
                    </p>
                  )}
                </div>
              </div>
              <DragDropContext onDragEnd={onDragEnd}>
                <QuizDnd
                  addQuestionList={questionsList}
                  questionBankList={questionsBankList}
                  setFilterKey={setFilterKey}
                  isViewMode={isViewMode}
                ></QuizDnd>
              </DragDropContext>
              <div>
                {isQuestionListEmpty && (
                  <p className={`text-red-500 mt-4`}>
                    Questions List cannot be empty
                  </p>
                )}
              </div>
              {!isViewMode && (
                <div className={`mt-4 flex flex-auto flex-row place-self-end`}>
                  <button
                    type="button"
                    onClick={handleFormReset}
                    className={`h-10 text-xl w-32 bg-gray-500 rounded-md font-Lato font-bold text-white`}
                  >
                    RESET
                  </button>
                  <button
                    type="submit"
                    className={`h-10 text-xl w-28 ml-4 rounded-md font-Lato font-bold text-white`}
                    style={{
                      backgroundColor: context.theme!.button.background,
                    }}
                  >
                    SAVE
                  </button>
                </div>
              )}
            </div>
          </form>
        );
      }}
    </ThemeContext.Consumer>
  );
};

const SubMenu = () => {
  return (
    <>
      <SubmenuTrainer />
    </>
  );
};

const NewQuiz = (props: any) => {
  const menu = <MenuTrainer selectedItem={`QUIZ`} />;
  const mode = props.match.params.mode;
  const quizId = props.match.params.quizId;

  return (
    <Template
      menu={menu}
      body={<NewQuizbody quizMode={mode} quizId={quizId} />}
      submenu={<SubMenu />}
    />
  );
};

export default NewQuiz;
