import { useState, useEffect } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { useHistory } from "react-router";

import ButtonReuse from "../../Components/Common/ButtonReuse";
import SubmenuTrainer from "../../Components/Common/SubMenuTrainer";
import MenuTrainer from "../../Components/Template/MenuTrainer";
import TemplateTrainers from "../../Components/Template/TrainerTemplate";
import Required from "../../Components/Common/Required";
import Routes from "../../Routes/Routes";
import TrainerTopicsAPIService from "../../Api/Topics/TrainerTopicsAPI";
import {
  ErrorMsgToaster,
  SuccessMsgToaster,
} from "../../Components/Utils/Toaster";
import { NetworkError } from "../../Api/Error.type";
import RenderMediaComponents from "../../Components/Common/RenderMediaComponents";
import { TopicDetailResType } from "../../Utils/types";
import ThemeContext from "../../Context/ThemeContext";
import InputProfile from "../../Components/Common/InputProfile";
// import PDFViewer from "../../Components/Resources/PDFViewer";
// import VideoPlayer from "../../Components/Resources/VideoPlayer";
// import WordDocViewer from "../../Components/Resources/WordDocViewer";

type IFormInput = {
  title: string;
  description: string;
  mediaType: string;
  duration: string;
  durationMin: string;
  uploadedfile: any;
};

export const NewTopicbody = ({
  topicMode,
  topicId,
}: {
  topicMode: string;
  topicId: string;
}) => {
  const history = useHistory();

  const [mode, setMode] = useState("new");
  const [editTopicData, setEditTopicData] = useState<TopicDetailResType>({
    topicId: "",
    originalName: "",
    file: "",
    mediaType: "",
    userId: "",
    description: "",
    duration: "",
    title: "",
  });
  const [uploadedFiles, setUploadedFiles] = useState<any>([]);
  // const [inputTitle, setInputTitle] = useState("");
  const [selectedMediaType, setSelectedMediaType] = useState("pdf");
  const [acceptFileTypes, setAcceptFileTypes] = useState(".pdf");
  const [isFileExtValid, setIsFileExtValid] = useState<boolean>(false);
  const [isViewMode, setIsViewMode] = useState<boolean>(false);

  // intial call
  useEffect(() => {
    const trainerTopicAPIService = new TrainerTopicsAPIService();
    setMode(topicMode);
    setIsViewMode(topicMode === "view" ? true : false);
    if ((topicMode === "edit" || topicMode === "view") && topicId) {
      trainerTopicAPIService
        .fetchTopicsDetail(topicId)
        .then((resData) => {
          if (resData?.errorMsg) {
            ErrorMsgToaster(resData.errorMsg);
          } else {
            if (resData?.data) {
              setEditTopicData(resData.data);
              validateUploadedFile(resData.data.originalName);
            }
          }
        })
        .catch((err) => console.log(err));
    }
  }, []);

  // edit topic handle effect
  useEffect(() => {
    if (editTopicData) {
      editTopicData.title && setValue("title", editTopicData.title);
      editTopicData.description &&
        setValue("description", editTopicData.description);
      editTopicData.mediaType && setSelectedMediaType(editTopicData.mediaType);
      editTopicData.mediaType && handleFileTypeChange(editTopicData.mediaType);
      let duration = editTopicData?.duration?.split(":");
      duration?.[0] && setValue("duration", duration[0]);
      duration?.[1] && setValue("durationMin", duration[1]);
      editTopicData.originalName &&
        setUploadedFiles([{ name: editTopicData.originalName }]);
    }
  }, [editTopicData]);

  useEffect(() => {
    uploadedFiles &&
      uploadedFiles.length &&
      validateUploadedFile(uploadedFiles[0].name);
  }, [selectedMediaType]);

  const {
    register,
    handleSubmit,
    // watch,
    // reset,
    setValue,
    formState: { errors },
  } = useForm<IFormInput>();

  const onSubmit: SubmitHandler<IFormInput> = (data: any) => {
    data.duration = data.duration
      ? data.duration + `:${data.durationMin}`
      : data.duration + `:00`;
    delete data.durationMin;
    const { title, description, mediaType, duration } = data;
    if (title && description && mediaType && duration) {
      let formData = new FormData();
      formData.append("title", title);
      formData.append("description", description);
      formData.append("mediaType", mediaType);
      formData.append("duration", duration);

      const msg: NetworkError = {
        error: "File cannot be empty",
        statusCode: 400,
      };
      if (mode === "new" && (!uploadedFiles || !uploadedFiles[0])) {
        ErrorMsgToaster(msg);
        return;
      }

      if (mode === "new") {
        if (uploadedFiles && uploadedFiles[0]) {
          formData.append("image", uploadedFiles[0]);
        } else {
          ErrorMsgToaster(msg);
          return;
        }
      }
      if (mode === "edit") {
        if (!uploadedFiles[0]) {
          ErrorMsgToaster(msg);
          return;
        }
        if (
          uploadedFiles &&
          uploadedFiles[0] &&
          uploadedFiles[0].name !== editTopicData.originalName
        ) {
          formData.append("image", uploadedFiles[0]);
        }
      }

      const fileExtNotVali: NetworkError = {
        error: "Uploaded File Extension is not valid",
        statusCode: 400,
      };

      if (!isFileExtValid) {
        ErrorMsgToaster(fileExtNotVali);
        return;
      }

      if (mode === "new") {
        saveNewTopicData(formData);
      } else if (mode === "edit") {
        saveEditTopicData(formData);
      }
    }
  };

  const saveNewTopicData = (formData: any) => {
    const trainerTopicAPIService = new TrainerTopicsAPIService();
    trainerTopicAPIService
      .addNewTopic(formData)
      .then((resData) => {
        if (resData?.errorMsg) {
          ErrorMsgToaster(resData.errorMsg);
          resetFields();
        } else {
          if (resData?.data) {
            // const { data } = resData.data;
            SuccessMsgToaster(resData.data);
            resetFields();
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const saveEditTopicData = (formData: any) => {
    const trainerTopicAPIService = new TrainerTopicsAPIService();
    trainerTopicAPIService
      .editTopicDetails(topicId, formData)
      .then((resData) => {
        if (resData?.errorMsg) {
          ErrorMsgToaster(resData.errorMsg);
        } else {
          if (resData?.data) {
            // const { data } = resData.data;
            SuccessMsgToaster(resData.data);
            resetFields();
            history.push(Routes.topicTrainer);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Handle file upload change
  const handleFileOnChange = (event: any) => {
    const fileUploaded = event.target.files[0];
    const filesList = fileUploaded && [fileUploaded];
    fileUploaded && validateUploadedFile(fileUploaded.name);
    setUploadedFiles(filesList);
  };

  const removeFileFromList = () => {
    setUploadedFiles([]);
  };

  const resetFields = () => {
    setValue("title", "", { shouldValidate: false });
    setValue("description", "", { shouldValidate: false });
    setSelectedMediaType("pdf");
    setValue("duration", "", { shouldValidate: false });
    removeFileFromList();
  };

  const handleFormCancel = () => {
    resetFields();
    removeFileFromList();
  };

  // const handleInputTitle = (e: any) => {
  //   setInputTitle(e.target.value);
  // };

  const redirectToTopics = () => {
    history.push(Routes.topicTrainer);
  };

  const handleFileTypeChange = (value: string) => {
    let acceptType = ".pdf";
    switch (value) {
      case "pdf":
        acceptType = `.pdf`;
        break;
      case "image":
        acceptType = ".jpeg,.jpg,.svg,.png";
        break;
      case "video":
        acceptType = `.mp4"`;
        break;
      case "ppt":
        acceptType = "application/vnd.ms-powerpoint,.pptx";
        break;
      case "word":
        acceptType = "application/msword,.docx";
        break;
      case "other":
        acceptType = `.*`;
        break;
      default:
        acceptType = `.pdf`;
    }
    setAcceptFileTypes(acceptType);
  };

  const validateUploadedFile = (filename: string) => {
    // let isValid = false;
    let regex = new RegExp("");
    switch (selectedMediaType) {
      case "pdf":
        regex = new RegExp(/\.(pdf)$/i);
        break;
      case "image":
        regex = new RegExp(/\.(jpeg|jpg|svg|png)$/i);
        break;
      case "video":
        regex = new RegExp(/\.(mp4)$/i);
        break;
      case "ppt":
        regex = new RegExp(/\.(ppt|pptx)$/i);
        break;
      case "word":
        regex = new RegExp(/\.(doc|docx)$/i);
        break;
    }
    setIsFileExtValid(regex.test(filename));
  };

  return (
    <ThemeContext.Consumer>
      {(context) => {
        return (
          <form
            className={`flex flex-col text-black -mt-4 ${
              isViewMode && `h-full`
            }`}
            onSubmit={handleSubmit(onSubmit)}
          >
            <div
              className={`flex flex-col text-black ${isViewMode && `h-full`}`}
            >
              <div
                className='flex flex-col  text-xl md:text-2xl font-bold font-Lato '
                style={{ color: context.theme!.title }}
              >
                <div className='  md:float-left'>
                  {mode === "new"
                    ? `Add New Topic`
                    : mode === "edit"
                    ? `Edit Topic - ${editTopicData.title}`
                    : `View Topic -  ${editTopicData.title}`}
                  {mode !== "view" && (
                    <ButtonReuse
                      value={"VIEW ALL TOPICS"}
                      colorClass={"bg-yellow-200"}
                      heightClass={"h-7"}
                      widthClass={"w-36"}
                      sizeClass={"text-sm"}
                      positionClass={"float-right"}
                      tcolorClass={"md:text-black"}
                      onClick={redirectToTopics}
                    ></ButtonReuse>
                  )}
                </div>
              </div>

              <div>
                <h4 className={`text-sm font-lato mt-6`}>
                  <Required />
                  Title
                </h4>
                <input
                  {...register("title", { required: true })}
                  className={`h-10 w-full text-sm p-2 border-gray-400 border rounded-md mt-2 font-lato `}
                  disabled={isViewMode}
                />
                {errors && errors.title && (
                  <p className={`text-red-500`}>Title is Required</p>
                )}
              </div>
              <div>
                <h4 className={`text-sm font-lato mt-2`}>
                  <Required />
                  Description
                </h4>
                <textarea
                  {...register("description", { required: true })}
                  className={`h-48 w-full text-sm p-2 border-gray-400 border rounded-md font-lato `}
                  disabled={isViewMode}
                />
                {errors.description?.type === "required" && (
                  <p className={`text-red-500`}>Description is Required</p>
                )}
              </div>
              <div className='grid grid-cols-2 gap-5'>
                <div>
                  <h4 className={`text-sm font-lato mt-2`}>
                    <Required />
                    Media Type
                  </h4>
                  <select
                    {...register("mediaType", { required: true })}
                    value={selectedMediaType}
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                      const type = e.target.value;
                      setSelectedMediaType(type);
                      handleFileTypeChange(type);
                    }}
                    className={`h-10 w-full text-sm p-2 border-gray-400 border rounded-md mt-2 font-lato `}
                    disabled={isViewMode}
                  >
                    <option value='pdf'>PDF</option>
                    <option value='image'>IMAGE</option>
                    <option value='video'>VIDEO</option>
                    <option value='ppt'>PPT</option>
                    <option value='word'>WORD</option>
                    {/* <option value='other'>OTHER</option> */}
                  </select>
                  {errors.mediaType?.type === "required" && (
                    <p className={`text-red-500`}>Media Type is Required</p>
                  )}
                </div>
                <div>
                  <div>
                    <div className='flex flex-row mt-1'>
                      <div style={{ marginTop: 2 }}>
                        <Required />
                      </div>
                      <div
                        className={`text-sm font-lato`}
                        style={{ marginTop: 2 }}
                      >
                        Duration (HHH:MM)
                      </div>
                    </div>
                    <div className='grid grid-cols-2 gap-2 '>
                      <div>
                        <InputProfile
                          type='number'
                          pattern='/^[0-9]{1,3}$/gik'
                          holderClass={`HH`}
                          formprops={{
                            ...register("duration", {
                              required: true,
                              pattern: /^[0-9]{1,3}$/gi,
                            }),
                          }}
                          onInput={(e) => {
                            return (
                              e.currentTarget.validity.valid ||
                              (e.currentTarget.value = "")
                            );
                          }}
                          min='0'
                          isRequired={false}
                          displayErr={
                            errors.duration?.type === "required" ? true : false
                          }
                        />
                        {(errors.duration?.type === "pattern" ||
                          errors.duration?.type === "required") && (
                          <p className={`text-red-500`}>Enter valid time</p>
                        )}
                      </div>
                      <div>
                        <InputProfile
                          type='number'
                          pattern='/^[0-9]{1,3}$/gi'
                          holderClass={`MM`}
                          formprops={{
                            ...register("durationMin", {
                              required: true,
                              pattern: /^([0-5]?[0-9])$/gi,
                            }),
                          }}
                          onInput={(e) => {
                            return (
                              e.currentTarget.validity.valid ||
                              (e.currentTarget.value = "")
                            );
                          }}
                          min='0'
                          isRequired={false}
                          displayErr={
                            errors.durationMin?.type === "required"
                              ? true
                              : false
                          }
                          diabled={isViewMode}
                        />
                        {(errors.durationMin?.type === "pattern" ||
                          errors.durationMin?.type === "required") && (
                          <p className={`text-red-500`}>Enter valid time</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br></br>
              {mode !== "view" && (
                <div className='md:grid md:grid-cols-2 md:gap-5'>
                  <div>
                    <h4 className={`text-sm font-lato mt-4`}>
                      <Required />
                      Upload Media
                    </h4>
                    <label
                      htmlFor='myInput'
                      className={`w-full h-28 border border-solid border-gray-400 bg-gray-100 font-bold rounded-md flex justify-center items-center
              ${
                uploadedFiles && uploadedFiles.length
                  ? `cursor-not-allowed`
                  : `cursor-pointer`
              }`}
                    >
                      {uploadedFiles && uploadedFiles.length
                        ? "File Uploaded"
                        : "Click to select file"}
                    </label>
                    <input
                      {...register("uploadedfile")}
                      id='myInput'
                      disabled={uploadedFiles && uploadedFiles.length}
                      onChange={handleFileOnChange}
                      style={{ display: "none" }}
                      type={"file"}
                      accept={acceptFileTypes}
                    />
                    {errors.uploadedfile?.type === "required" && (
                      <p className={`text-red-500`}>File is Required</p>
                    )}
                  </div>
                  <div>
                    {uploadedFiles &&
                      uploadedFiles.map((rec: any, index: number) => (
                        <div className={`flex flex-auto`} key={index}>
                          <h2 className={`mt-4 font-bold`}>
                            {rec && rec.name}
                          </h2>
                          {rec && rec.name && (
                            <i
                              className={`material-icons ml-2 mt-4 text-md text-red-500 cursor-pointer`}
                              onClick={removeFileFromList}
                            >
                              close
                            </i>
                          )}
                        </div>
                      ))}
                  </div>
                </div>
              )}
              {mode === "view" && (
                <div className={`h-full overflow-scroll`}>
                  <RenderMediaComponents
                    originalName={editTopicData.originalName}
                    resourceLink={editTopicData.file}
                  />
                </div>
              )}
              {mode !== "view" && (
                <div className={`mt-4 flex flex-auto flex-row place-self-end`}>
                  <button
                    type='button'
                    onClick={handleFormCancel}
                    className={`h-10 text-xl w-32 bg-gray-500 rounded-md font-Lato font-bold text-white`}
                  >
                    RESET
                  </button>
                  <button
                    type='submit'
                    className={`h-10 text-xl w-28 ml-4 rounded-md font-Lato font-bold  text-white`}
                    style={{
                      backgroundColor: context.theme!.button.background,
                    }}
                  >
                    SAVE
                  </button>
                </div>
              )}
            </div>
          </form>
        );
      }}
    </ThemeContext.Consumer>
  );
};

const SubMenu = () => {
  return (
    <>
      <SubmenuTrainer></SubmenuTrainer>
    </>
  );
};

const NewTopic = (props: any) => {
  const menu = <MenuTrainer selectedItem={`TOPICS`} />;
  const mode = props.match.params.mode;
  const topicId = props.match.params.topicId;

  return (
    <TemplateTrainers
      menutrainer={menu}
      body={<NewTopicbody topicMode={mode} topicId={topicId} />}
      submenu={<SubMenu />}
    />
  );
};

export default NewTopic;
