import APIService from "../API";
import { BaseUrl } from "../axios.config";
import { NetworkError, Payload } from "../Error.type";

const addNewTopicsURL: string = `${BaseUrl}/trainer/createTopics`;
const fetchAllTopicsURL: string = `${BaseUrl}/trainer/getTopics`;
const fetchTopicDetailURL: string = `${BaseUrl}/trainer/getTopics`;
const editTopicURL: string = `${BaseUrl}/trainer/editTopics`;
const searchTopicsURL: string = `${BaseUrl}/trainer/searchTopics`;
const apiService = new APIService();

type TopicsReqParamType = {
  title: string;
  description: string;
  mediaType: string;
  duration: string;
  image: any;
};

type AddNewTopicsResType = {
  data?: { data: string; topicId: string };
};

type TopicsResType = {
  data: string;
  topicId: string;
};

type FetchTopicResType = {
  topicId: string;
  title: string;
};

type EditTopicResType = {
  data: string;
  description: string;
};

type TopicDetailResType = {
  topicId: string;
  file: string;
  mediaType: string;
  userId: string;
  description: string;
  duration: string;
  title: string;
};

export class AddNewTopicsAPIResult
  implements Payload<AddNewTopicsResType, NetworkError>
{
  statusCode: number;
  data?: AddNewTopicsResType;
  errorMsg?: NetworkError;

  constructor(
    statusCode: number,
    data?: AddNewTopicsResType,
    errorMsg?: NetworkError
  ) {
    this.statusCode = statusCode;
    this.data = data;
    this.errorMsg = errorMsg;
  }
}

export class FetchAllTopicsAPIResult
  implements Payload<Array<FetchTopicResType>, NetworkError>
{
  statusCode: number;
  data?: Array<FetchTopicResType>;
  errorMsg?: NetworkError;

  constructor(
    statusCode: number,
    data?: Array<FetchTopicResType>,
    errorMsg?: NetworkError
  ) {
    this.statusCode = statusCode;
    this.data = data;
    this.errorMsg = errorMsg;
  }
}

export class FetchTopicsDetailsResult
  implements Payload<TopicDetailResType, NetworkError>
{
  statusCode: number;
  data?: TopicDetailResType;
  errorMsg?: NetworkError;

  constructor(
    statusCode: number,
    data?: TopicDetailResType,
    errorMsg?: NetworkError
  ) {
    this.statusCode = statusCode;
    this.data = data;
    this.errorMsg = errorMsg;
  }
}

export class EditTopicsResult
  implements Payload<EditTopicResType, NetworkError>
{
  statusCode: number;
  data?: EditTopicResType;
  errorMsg?: NetworkError;

  constructor(
    statusCode: number,
    data?: EditTopicResType,
    errorMsg?: NetworkError
  ) {
    this.statusCode = statusCode;
    this.data = data;
    this.errorMsg = errorMsg;
  }
}

export default class TrainerTopicsAPIService {
  // Add new Topic
  addNewTopic = async (reqData: FormData) => {
    let payload = {};
    if (reqData.get("title") && reqData.get("description")) {
      return await apiService.postReqsWithFiles({
        APIUrl: addNewTopicsURL,
        payload: reqData,
        resType: AddNewTopicsAPIResult,
      });
    }
    return null;
  };

  // fetch all Topics
  fetchAllTopicsDetail = async () => {
    return await apiService.getReqs({
      APIUrl: fetchAllTopicsURL,
      payload: {},
      resType: FetchAllTopicsAPIResult,
    });
  };

  // fetch particular Topics details
  fetchTopicsDetail = async (topicId: string) => {
    if (topicId) {
      const payload = { topicId };
      return await apiService.getReqWithParams({
        APIUrl: fetchTopicDetailURL,
        payload,
        resType: FetchTopicsDetailsResult,
      });
    }
  };

  // edit Topics details
  editTopicDetails = async (topicId: string, reqData: FormData) => {
    const urlWithId = `${editTopicURL}?topicId=${topicId}`;
    return await apiService.patchReqsWithFiles({
      APIUrl: urlWithId,
      payload: reqData,
      resType: EditTopicsResult,
    });
  };

  // search topics
  searchTopics = async (key: string) => {
    if (key) {
      const urlWithKey = `${searchTopicsURL}?topicName=${key}`;
      return await apiService.postReqs({
        APIUrl: urlWithKey,
        payload: {},
        resType: FetchAllTopicsAPIResult,
      });
    }
  };
}
