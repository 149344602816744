export abstract class NetworkError {
    error?: string;
    statusCode: number;

    constructor(error: string, statusCode: number) {
        this.error = error; 
        this.statusCode = statusCode;
    }
}

export class AuthenticationError implements NetworkError {
    // TODO: Redirect to login page
    constructor(_error: string = "Please Login and try again") {
        this.error = _error;
        this.statusCode = 401; 
    }
    error?: string;
    statusCode: number;
}

export class UserAlreadyExists implements NetworkError {
    // TODO: Redirect to login page
    constructor(_error: string) {
        this.error = _error;
        this.statusCode = 409; 
    }
    error?: string;
    statusCode: number;
}

export class GeneralError implements NetworkError {
    // TODO: Just show the error as toast
    constructor(_error: string, statusCode: number = 400) {
        this.error = _error;
        this.statusCode = statusCode; 
    }
    error?: string;
    statusCode: number;
}

export interface Payload<T, E> {
    data?: T
    error?: E
    statusCode: number
}
