import { ReviewData } from "../../Api/Dashboard/Dashboard.types";
import { Rating } from "react-simple-star-rating";
import StarRatings from "react-star-ratings";

const Review = ({ ReviewInfo }: { ReviewInfo: ReviewData }) => {
  const handleRating = (rate: React.SetStateAction<number>) => {
    console.log(rate);
  };

  return (
    <>
      <div className={`text-xl mt-10 font-bold`} style={{ color: "#0B4089" }}>
        {ReviewInfo.title}
      </div>
      <div className={`text-base mt-2 font-bold`}>{ReviewInfo.review}</div>

      <div className={`flex mt-1`}>
        <StarRatings
          rating={ReviewInfo.rating}
          starRatedColor="black"
          changeRating={handleRating}
          numberOfStars={5}
          starDimension="20px"
          starHoverColor="black"
          starSpacing="2px"
          name="rating"
        />
        <div className={`inline-block ml-4 font-bold`}>{ReviewInfo.rating}</div>
      </div>
      <div
        className={`text-base font-semibold mt-2 `}
        style={{ color: "#0B4089" }}
      >
        {ReviewInfo.name}
      </div>
      <br />
      <hr />
    </>
  );
};

export default Review;
