import QuizComponent from "../../Screens/QuizScores/QuizComponents";
import Icons from "../../Screens/QuizScores/Icons";

const SubmenuItems = () => {
  const lessonlist = ["lesson1", "lesson2", "lesson3", "lesson4"];
  return (
    <>
      <div className="flex flex-row gap-3 bg-white border-solid border-2 border-gray-500 h-10 w-full font-Lato font-bold mt-3  text-xs sm:text-base md:text-xl items-center">
        <QuizComponent></QuizComponent> Lesson 1
      </div>
      {lessonlist.map((rec) => {
        return (
          <div className="flex flex-row items-center bg-white border-solid  text-xs sm:text-base md:text-xl border-2 h-10 w-full font-Lato font-bold mt-3">
            <Icons></Icons>
            {rec}
          </div>
        );
      })}
    </>
  );
};

export default SubmenuItems;
