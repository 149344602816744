import axios from "axios";
import APIService from "../../Api/API";
import {
  ApplicationJsonConfigHeader,
  BaseUrl,
  FormDataFileConfigHeader,
} from "../../Api/axios.config";
import { NetworkError, Payload } from "../../Api/Error.type";
import { BankDetailsType } from "../../Utils/types";

export const getProfile = async (registerType: string) => {
  const token: string | null = localStorage.getItem("token");
  let url = `${BaseUrl}/profile/student`;
  url =
    registerType && registerType === "Student"
      ? url
      : `${BaseUrl}/profile/trainer`;
  const response = await axios.get(url, {
    headers: {
      ...ApplicationJsonConfigHeader,
      authorization: `Bearer ${token}`,
    },
    responseType: "json",
  });
  return response.data;
};

export const updateProfile = async (registerType: string, data: any) => {
  const token: string | null = localStorage.getItem("token");
  let url = `${BaseUrl}/profile/student/details`;
  url =
    registerType && registerType === "Student"
      ? url
      : `${BaseUrl}/profile/trainer/details`;
  const response = await axios.put(url, data, {
    headers: {
      ...ApplicationJsonConfigHeader,
      authorization: `Bearer ${token}`,
    },
    responseType: "json",
  });
  return response.data;
};

export const updateProfilePic = async (registerType: string, data: any) => {
  const token: string | null = localStorage.getItem("token");
  let url = `${BaseUrl}/profile/student/details`;
  url =
    registerType && registerType === "Student"
      ? url
      : `${BaseUrl}/profile/trainer/details`;
  const response = await axios.put(url, data, {
    headers: {
      ...FormDataFileConfigHeader,
      authorization: `Bearer ${token}`,
    },
    responseType: "json",
  });
  return response.data;
};

export const updateEducation = async (data: any, registerType: string) => {
  const token: string | null = localStorage.getItem("token");
  const response = await axios.put(
    BaseUrl + `/profile/${registerType ? registerType : `student`}/education`,
    data,
    {
      headers: {
        ...ApplicationJsonConfigHeader,
        authorization: `Bearer ${token}`,
      },
      responseType: "json",
    }
  );
  return response.data;
};

export const deleteEducation = async (data: any, registerType: string) => {
  const token: string | null = localStorage.getItem("token");
  const response = await axios.delete(
    BaseUrl +
      `/profile/${registerType ? registerType : `student`}/educationDelete`,
    {
      headers: {
        ...ApplicationJsonConfigHeader,
        authorization: `Bearer ${token}`,
      },
      data,
      responseType: "json",
    }
  );
  return response;
};

export const changePassword = async (data: any) => {
  const token: string | null = localStorage.getItem("token");
  const response = await axios.post(
    BaseUrl + "/authentication/changePassword",
    data,
    {
      headers: {
        ...ApplicationJsonConfigHeader,
        authorization: `Bearer ${token}`,
      },
      responseType: "json",
    }
  );
  return response.data;
};

export const getCategories = async () => {
  const token: string | null = localStorage.getItem("token");
  const response = await axios.get(BaseUrl + "/categoriesList", {
    headers: {
      ...ApplicationJsonConfigHeader,
      authorization: `Bearer ${token}`,
    },
    responseType: "json",
  });
  return response.data;
};

type UpdateBankDetailsResType = {
  data: any;
};

export class UpdateBankDetailsResult
  implements Payload<UpdateBankDetailsResType, NetworkError>
{
  statusCode: number;
  data?: UpdateBankDetailsResType;
  errorMsg?: NetworkError;

  constructor(
    statusCode: number,
    data?: UpdateBankDetailsResType,
    errorMsg?: NetworkError
  ) {
    this.statusCode = statusCode;
    this.data = data;
    this.errorMsg = errorMsg;
  }
}

export const updateTrainerBankDetails = async (data: BankDetailsType) => {
  // const token: string | null = localStorage.getItem("token");
  const apiService = new APIService();
  return await apiService.putReqs({
    APIUrl: `${BaseUrl}/profile/trainer/paymentDetails`,
    payload: data,
    resType: UpdateBankDetailsResult,
  });
};

export const getGoals = async (payload: any) => {
  const token: string | null = localStorage.getItem("token");
  const response = await axios.get(BaseUrl + "/selectGoals", {
    params: payload,
    headers: {
      ...ApplicationJsonConfigHeader,
      authorization: `Bearer ${token}`,
    },
    responseType: "json",
  });
  return response.data;
};
