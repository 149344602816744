import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import UserInfoAPIService from "../../Api/UserInfo/UserInfoAPI";
import ThemeContext from "../../Context/ThemeContext";
import {
  CartAction,
  SpinnerLoadingAction,
  UserInfoAction,
} from "../../redux/action";
import DashboardTrainer from "../DashboardTrainer/DashdoardTrainer";
import DashboardStudent from "./DashboardStudent";

// TODO: Update dynamic data in Dashboard detail
const Dashboard = ({
  userInfo,
  setUserInfo,
}: {
  userInfo: any;
  setUserInfo: any;
}) => {
  const userInfoAPIService = new UserInfoAPIService();
  const history = useHistory();
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.log("Bahubali code executing");
      history.replace("/signin");
    }
    if (!userInfo.registerType)
      userInfoAPIService
        .fetchUserInfo()
        .then((resData) => {
          if (resData.data) {
            setUserInfo(resData.data);
          }
        })
        .catch((err) => console.log(err));
  }, []);
  return (
    <ThemeContext.Consumer>
      {(context) => {
        return (
          <>
            {userInfo.registerType === "Student" && (
              <DashboardStudent theme={context.theme!} />
            )}
            {userInfo.registerType === "Trainer" && (
              <DashboardTrainer theme={context.theme!} />
            )}
          </>
        );
      }}
    </ThemeContext.Consumer>
  );
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setIsLoading: (isLoading: boolean) => {
      dispatch(SpinnerLoadingAction(isLoading));
    },
    updateCoursesInCart: (data: any) => {
      dispatch(CartAction(data));
    },
    setUserInfo: (data: any) => {
      dispatch(UserInfoAction(data));
    },
  };
};

const mapStateToProps = (state: any) => {
  return {
    userInfo: state && state.userInfoReducer,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
