const About = () => {
  return (
    <div className='m-auto w-full flex items-center justify-center'>
      <div className='w-full   text-black  text-base sm:text-lg :text-2xl  grid items-center justify-center'>
        <h1 className='flex place-self-center font-bold text-2xl mb-4'>
          About Us
        </h1>
        <p className='indent-8 text-base'>
          We believe that everyone has a right to quality education. In
          ourstrife to dramatically eradicate poverty, improve health care,
          mould the next generation leaders, and eventually bring world peace we
          seek to complement education providers in churning out world leaders.
          Today with the technological advancements available at our fingertips
          a dad, a mom, a teacher, a soldier, or even a less privileged
          agriculturist stands a chance at educating himself and advancing in
          his career.
        </p>
        <div>
          <h4 className='font-bold mt-8 mb-2 underline'>Our Mission:</h4>
          <p className='indent-96 text-base'>
            Democratize education to empower people to succeed in their chosen
            field
          </p>
        </div>
        <div>
          <h4 className='font-bold mt-8 mb-2 underline'>Our Vision: </h4>
          <p className='text-base'>
            Educate, guide, and help a million people achieve their lifetime
            goals and live happily
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
