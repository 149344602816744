import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ErrorMsgToaster } from "../../Components/Utils/Toaster";
import {
  CourseListType,
  CourseExplorerAction,
} from "../../redux/action/courseExplorerAction";
import RatingImg from "../../Assets/rating.png";
import CourseExplorerAPIService from "../../Api/CourseExplorer/CourseExplorerAPIService";
import CourseTail from "../../Components/Template/CourseTail";
import Menu from "../../Components/Template/Menu";
import Template from "../../Components/Template/Template";
import CartAPIService from "../../Api/Cart/CartAPIService";
import { CartAction } from "../../redux/action";
import { DashboardService } from "../../Api/Dashboard/Dashboard";
import NoCourse from "../../Components/Common/NoCourse";
import { TrendingCourseType } from "../../Utils/types";
import ThemeContext from "../../Context/ThemeContext";
import { ThemeProps } from "../../Themes/Themes";

type CheckboxListType = {
  value: string;
  checked: boolean;
};

const Course = (props: any) => {
  const courseExplorerAPIService = new CourseExplorerAPIService();
  const cartAPIService = new CartAPIService();
  const dashboardService = new DashboardService();
  const ratingList = ["All", "5", "4", "3", "2", "1"];

  const [coursesList, setCoursesList] = useState<Array<CourseListType>>([]);
  const [ratingFilters, setRatingFilters] = useState<Array<CheckboxListType>>(
    []
  );
  const param = props.location.search;
  const search = new URLSearchParams(param).get("s");
  const [courseFilters, setCourseFilters] = useState({
    rating: 0,
    enrolledCount: 0,
  });
  const [sliderVal, setSliderVal] = useState(0);
  const [trendingCourse, setTrendingCourse] = useState<TrendingCourseType>();

  useEffect(() => {
    const data = ratingList.map((rec) => {
      return { value: rec, checked: rec !== "All" ? false : true };
    });
    setRatingFilters(data);
    async function fetchCoursesList() {
      if (!search) {
        const coursesListRes = await courseExplorerAPIService.fetchCourseDetail(
          courseFilters
        );
        if (coursesListRes) {
          const data: Array<CourseListType> = coursesListRes?.data;
          props.setCourseExplorerData({
            data,
            totalCourses: (data && data.length) || 0,
          });
          setCoursesList(data && data.slice(0, 4));
        }
      } else {
        courseExplorerAPIService
          .searchCourse(search)
          .then(({ data, errorMsg }) => {
            if (data) setCoursesList(data);
            if (errorMsg) ErrorMsgToaster(errorMsg);
          });
      }
    }

    fetchCoursesList();
    fetchCartDetails();
    fetchTrendingCourse();
  }, [search]);

  async function fetchCartDetails() {
    cartAPIService
      .fetchCourseInCartList()
      .then((resData) => {
        if (resData.data) {
          const { cart, totalAmount } = resData.data;
          props.updateCoursesInCart({ cart, totalAmount });
        }
      })
      .catch((err) => ErrorMsgToaster(err));
  }

  async function fetchTrendingCourse() {
    dashboardService
      .getTrendingCourse()
      .then((resData) => {
        if (resData.data) {
          setTrendingCourse(resData.data);
        }
      })
      .catch((err) => ErrorMsgToaster(err));
  }

  useEffect(() => {
    if (!search) {
      courseExplorerAPIService
        .fetchCourseDetail(courseFilters)
        .then(({ data, errorMsg }) => {
          if (data) setCoursesList(data);
          if (errorMsg) ErrorMsgToaster(errorMsg);
        })
        .catch((err) => ErrorMsgToaster(err));
    }
  }, [courseFilters]);

  const handleRatingChange = (e: any, changedIndex: number) => {
    let ratingData = [...ratingFilters];
    ratingData = ratingData.map((rec) => ({ ...rec, checked: false }));
    ratingData[changedIndex].checked = true;
    setRatingFilters(ratingData);
    const ratingParam = e.target.value === "All" ? 0 : Number(e.target.value);
    setCourseFilters({ ...courseFilters, rating: ratingParam });
  };

  const handleCompletionRange = (e: any) => {
    setSliderVal(e.target.value);
    setCourseFilters({
      ...courseFilters,
      enrolledCount: Number(e.target.value),
    });
  };

  const CourseBody = ({ theme }: { theme: ThemeProps }) => {
    return (
      <ThemeContext.Consumer>
        {(context) => {
          return (
            <div className={`-mt-9`}>
              <div
                className={`text-2xl font-bold `}
                style={{ color: context.theme?.title }}
              >
                Courses
              </div>
              {search && (
                <div className="text-xl pt-8">
                  Showing results for {"  "}
                  <span className="font-extrabold text-2xl">{search}</span>
                </div>
              )}
              <div className={`grid grid-rows-2 grid-cols-2 gap-6 mt-6`}>
                {coursesList &&
                  coursesList.length > 0 &&
                  coursesList.map((rec: CourseListType) => {
                    return (
                      <CourseTail
                        courseData={rec}
                        key={rec.courseId}
                        theme={theme}
                      />
                    );
                  })}
              </div>
              {(!coursesList || !coursesList.length) && (
                <div className={`justify-center`}>
                  <NoCourse title="No Courses Available" icon="school" />
                </div>
              )}
            </div>
          );
        }}
      </ThemeContext.Consumer>
    );
  };

  const SubMenu = ({ theme }: { theme: ThemeProps }) => {
    return (
      <>
        {!search && (
          <>
            <div className={`font-bold text-lg md:text-2xl`}>
              Filter by Rating
            </div>
            <div className={`pl-4`}>
              {ratingFilters.map((rec: CheckboxListType, i: number) => {
                return (
                  <div className={`flex  text-base md:text-xl mt-3`} key={i}>
                    <input
                      className={`transform mt-2 scale-x-150 scale-y-150 `}
                      key={rec.value}
                      type="checkbox"
                      name="starRating"
                      value={rec.value}
                      checked={rec.checked}
                      onChange={(e) => handleRatingChange(e, i)}
                    />
                    <label className={`ml-4 flex`} htmlFor={rec.value}>
                      {rec.value}
                      {rec.value !== "All" && (
                        <img
                          className={`h-6 w-6 mt-1/2 ml-2`}
                          src={RatingImg}
                          alt={`Ratting`}
                        />
                      )}
                    </label>
                  </div>
                );
              })}
            </div>

            <div className={`font-bold text-lg md:text-2xl mt-9`}>
              Filter by Enrolled Count
            </div>
            <div className={`pl-4`}>
              <input
                className={`h-12 p-4 w-full text-base md:text-xl `}
                type="range"
                min={0}
                max={10000}
                step={500}
                value={sliderVal}
                onChange={(e) => handleCompletionRange(e)}
              />
              {Number(sliderVal) > 0 && Number(sliderVal) !== 100 ? (
                <h4>{sliderVal} Enrolled</h4>
              ) : (
                <h4 className={`text-base md:text-xl`}>All</h4>
              )}
            </div>
          </>
        )}
        <div className={`font-bold text-lg md:text-2xl mt-9 mb-6`}>
          Trending this Week
        </div>
        <CourseTail courseData={trendingCourse ?? {}} theme={theme} />
      </>
    );
  };

  const menu = <Menu selectedItem={``} />;
  return (
    <ThemeContext.Consumer>
      {(context) => {
        const theme: ThemeProps = context.theme!;
        return (
          <Template
            menu={menu}
            body={<CourseBody theme={theme} />}
            submenu={<SubMenu theme={theme} />}
          />
        );
      }}
    </ThemeContext.Consumer>
  );
};

const mapStateToProps = (state: any) => {
  return {
    courseExplorerData: state && state.courseExplorerReducer,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setCourseExplorerData: (courseDataList: Array<CourseListType>) => {
      dispatch(CourseExplorerAction(courseDataList));
    },
    updateCoursesInCart: (data: any) => {
      dispatch(CartAction(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Course);
