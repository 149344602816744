const Refund = () => {
  return (
    <div className="m-auto w-full flex items-center justify-center">
      <div className="w-full text-black text-base sm:text-lg :text-2xl  grid items-center justify-center">
        <h2 className="flex place-self-center font-bold text-2xl pb-8">
          Cancellation/Refund Policy
        </h2>
        <p className="text-base ">
          {" "}
          Please read the subscription terms and conditions carefully before
          subscribing to any of the subscription plans, as once you have
          subscribed you cannot change, cancel your subscription plan. Once you
          subscribe and make the required payment, it shall be final and there
          cannot be any changes or modifications to the same and neither will
          there be any refund.
        </p>
      </div>
    </div>
  );
};
export default Refund;
