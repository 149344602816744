import { ResourcesType } from "../../Utils/types";
import APIService from "../API";
import { BaseUrl } from "../axios.config";
import { NetworkError, Payload } from "../Error.type";

const fetchResourcesURL: string = `${BaseUrl}/resources`;
const apiService = new APIService();

type ResourceResType = {
    data: Array<ResourcesType>
}

export class FetchResourcesAPIResult
    implements Payload<ResourceResType, NetworkError>
{
    statusCode: number;
    data?: ResourceResType;
    errorMsg?: NetworkError;

    constructor(
        statusCode: number,
        data?: ResourceResType,
        errorMsg?: NetworkError
    ) {
        this.statusCode = statusCode;
        this.data = data;
        this.errorMsg = errorMsg;
    }
}

export default class FetchResourcesAPIService {
    fetchResources = async () => {
        return await apiService.getReqs({
            APIUrl: fetchResourcesURL,
            payload: {},
            resType: FetchResourcesAPIResult
        });
    };
}