import { DashEnroll } from "../../Utils/types";

const DashSubmenuComponent = ({ enrollData }: { enrollData: DashEnroll }) => {
  return (
    <div
      className={`font-extrabold text-xs md:text-xs lg:text-sm bg-lp_blue-dash_course_tail  w-full max-h-screen p-4 md:p-1 rounded-md mt-3 md:flex`}
    >
      <div className={`  flex justify-center  items-center`}>
        <i
          className={`flex flex-col justify-center bg-lp_yellow-dash_tail_trainer h-12 w-12 rounded-full ml-5 md:mb-1 mr-2 mb-5 md:mt-1 mt-3`}
        >
          <div className={"text-2xl font-black text-center"}>
            {enrollData?.name[0]}
          </div>
        </i>
      </div>
      <div
        className={`md:pl-4 mt-2 mb-2 ml-1 mr-2 font-bold text-lg md:text-lg`}
      >
        <div>{enrollData.title}</div>
        <div className={"leading-10"}>{enrollData.name}</div>
      </div>
    </div>
  );
};
export default DashSubmenuComponent;
