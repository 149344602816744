import { useState, useEffect } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { useHistory } from "react-router";
import Template from "../../Components/Template/Template";
import SubmenuTrainer from "../../Components/Common/SubMenuTrainer";
import MenuTrainer from "../../Components/Template/MenuTrainer";
import Required from "../../Components/Common/Required";
import Routes from "../../Routes/Routes";
import TrainerLessonsAPIService from "../../Api/Lessons/TrainerLessonAPI";
import {
  ErrorMsgToaster,
  SuccessMsgToaster,
} from "../../Components/Utils/Toaster";
import { LessonDetailResType } from "../../Utils/types";

type IFormInput = {
  title: string;
  description: string;
};

export const NewLessonsbody = ({
  lessonMode,
  lessonId,
}: {
  lessonMode: string;
  lessonId: string;
}) => {
  const [mode, setMode] = useState("new");
  const [editLessonData, setEditLessonData] = useState<LessonDetailResType>({
    title: "",
    description: "",
    lessonId: "",
    userId: "",
  });
  const [isViewMode, setIsViewMode] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm<IFormInput>();

  useEffect(() => {
    const trainerLessonAPIService = new TrainerLessonsAPIService();
    setMode(lessonMode);
    setIsViewMode(lessonMode === "view" ? true : false);
    if ((lessonMode === "edit" || lessonMode === "view") && lessonId) {
      trainerLessonAPIService
        .fetchLessonsDetail(lessonId)
        .then((resData) => {
          if (resData?.errorMsg) {
            ErrorMsgToaster(resData.errorMsg);
          } else {
            if (resData?.data) {
              setEditLessonData(resData.data);
            }
          }
        })
        .catch((err) => console.log(err));
    }
  }, []);

  useEffect(() => {
    editLessonData.title && setValue("title", editLessonData.title);
    editLessonData.description &&
      setValue("description", editLessonData.description);
  }, [editLessonData]);

  const history = useHistory();
  const trainerLessonAPIService = new TrainerLessonsAPIService();

  const onSubmit: SubmitHandler<IFormInput> = (data: any) => {
    if (mode === "new") {
      saveNewLessonData(data);
    } else if (mode === "edit") {
      saveEditLessonData(data);
    }
  };

  const saveNewLessonData = (data: any) => {
    const { title, description } = data;
    if (title && description) {
      trainerLessonAPIService
        .addNewLesson({ title, description })
        .then((resData) => {
          if (resData?.errorMsg) {
            ErrorMsgToaster(resData.errorMsg);
          } else {
            if (resData?.data) {
              const { data } = resData.data;
              SuccessMsgToaster(resData.data);
              resetFields();
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const saveEditLessonData = (data: any) => {
    const { title, description } = data;
    if (lessonId && title && description) {
      trainerLessonAPIService
        .editLessonDetails({ lessonId, title, description })
        .then((resData) => {
          if (resData?.errorMsg) {
            ErrorMsgToaster(resData.errorMsg);
          } else {
            if (resData?.data) {
              const { data } = resData.data;
              SuccessMsgToaster(resData.data);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const resetFields = () => {
    setValue("title", "", { shouldValidate: false });
    setValue("description", "", { shouldValidate: false });
  };

  const handleFormCancel = () => {
    resetFields();
  };

  const redirectToLessons = () => {
    history.push(Routes.lessonTrainer);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={`flex flex-col text-black -mt-8`}>
        <div className='flex flex-row justify-between text-lp_blue text-2xl font-bold font-Lato '>
          <p className='float-left'>
            {mode === "new"
              ? `Add New Lesson`
              : mode === "edit"
              ? `Edit Lesson - ${editLessonData.title}`
              : `View Lesson - ${editLessonData.title}`}
          </p>
          {mode !== "view" && (
            <button
              className={`bg-yellow-200 h-7 w-36 text-sm font-bold place-self-end rounded-md text-black`}
              onClick={redirectToLessons}
            >
              VIEW ALL LESSONS
            </button>
          )}
        </div>
        <div className='flex flex-col gap-5 font-Lato'>
          <div>
            <h4 className={`text-sm font-lato mt-6`}>
              <Required />
              Title
            </h4>
            <input
              {...register("title", { required: true })}
              className={`h-10 w-full text-sm p-2 border-gray-400 border rounded-md mt-2 font-lato `}
              disabled={isViewMode}
            />
            {errors.title?.type === "required" && (
              <p className={`text-red-500`}>Title is Required</p>
            )}
          </div>
          <div>
            <h4 className={`text-sm font-lato`}>
              <Required />
              Description
            </h4>
            <textarea
              {...register("description", { required: true })}
              className={`h-48 w-full text-sm p-2 border-gray-400 border rounded-md font-lato `}
              disabled={isViewMode}
            />
            {errors.description?.type === "required" && (
              <p className={`text-red-500`}>Description is Required</p>
            )}
          </div>
        </div>
        {mode !== "view" && (
          <div className={`mt-2 flex flex-auto flex-row place-self-end`}>
            <button
              type='button'
              onClick={handleFormCancel}
              className={`h-10 text-xl w-32 bg-gray-500 rounded-md font-Lato font-bold text-white`}
            >
              RESET
            </button>
            <button
              type='submit'
              className={`h-10 text-xl w-28 ml-4 rounded-md font-Lato font-bold bg-lp_blue text-white`}
            >
              SAVE
            </button>
          </div>
        )}
      </div>
    </form>
  );
};

const SubMenu = () => {
  return (
    <>
      <SubmenuTrainer />
    </>
  );
};

const NewLessons = (props: any) => {
  const menu = <MenuTrainer selectedItem={`LESSONS`} />;
  const mode = props.match.params.mode;
  const lessonId = props.match.params.lessonId;
  return (
    <Template
      menu={menu}
      body={<NewLessonsbody lessonMode={mode} lessonId={lessonId} />}
      submenu={<SubMenu />}
    />
  );
};

export default NewLessons;
