import { useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
// import StarRatings from "react-star-ratings";
import "react-tabs/style/react-tabs.css";
import ThemeContext from "../../../Context/ThemeContext";
import PlatformFeedback from "../PlatformFeedback";
import TrainerFeedbackList from "../TrainerFeedback";
import CourseFeedback from "./CourseFeedback";

const CustomTab = ({ children }: { children: any }) => (
  <Tab className={`border-b-4 border-red-500`}>
    <h1>{children}</h1>
  </Tab>
);

CustomTab.tabsRole = "Tab";

const StudentFeedback = () => {
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [tabList, setTabList] = useState<Array<string>>([
    "Course Feedback",
    "Trainer Feedback",
    "Platform Feedback",
  ]);
  return (
    <ThemeContext.Consumer>
      {(context) => {
        return (
          <div>
            <div
              className={`text-2xl -mt-6 mb-8 font-bold`}
              style={{ color: context.theme!.title }}
            >
              Feedback Portal
            </div>
            <Tabs
              selectedIndex={selectedIndex}
              onSelect={(index) => setSelectedIndex(index)}
            >
              <TabList>
                {tabList.map((rec: string) => {
                  return (
                    <Tab>
                      {
                        <div
                          className={`text-xl py-4 px-8 font-bold`}
                          style={{ color: context.theme!.title }}
                        >
                          {rec}
                        </div>
                      }
                    </Tab>
                  );
                })}
              </TabList>
              <TabPanel>
                <CourseFeedback theme={context.theme!} />
              </TabPanel>
              <TabPanel>
                <TrainerFeedbackList />
              </TabPanel>
              <TabPanel>
                <PlatformFeedback userType='Student' />
              </TabPanel>
            </Tabs>
          </div>
        );
      }}
    </ThemeContext.Consumer>
  );
};

export default StudentFeedback;
