const Pricing = () => {
  return (
    <div className="m-auto w-full flex items-center justify-center">
      <div className="w-full text-black text-base sm:text-lg :text-2xl  grid items-center justify-center">
        <h1 className="flex place-self-center font-bold text-2xl pb-8">
          Pricing
        </h1>
        <h6 className="flex place-self-center text-base mt-4">
          Please reach out to us for pricing
        </h6>
        <div className="flex place-self-center text-base mt-4">
          <h5>Please write to us at </h5>
          <a
            className="underline text-blue-900 font-bold ml-2"
            href="mail:support@nimbusgurus.in"
          >
            support@nimbusgurus.in
          </a>
        </div>

        <div className="flex place-self-center text-base mt-4">
          <h5>Call us on Mob</h5>
          <a
            className="underline text-blue-900 font-bold ml-2"
            href="tel:+918778449236"
          >
            (+91) 877 844 9236
          </a>
        </div>
      </div>
    </div>
  );
};
export default Pricing;
