const resetPassword = "/reset-password";
const resetPasswordInstruction = "/reset-password/instruction";
const confirmPassword = "/reset/confirm-password/:id";
const courseDetail = "/coursedetail/:courseId";
const dashboard = "/dashboard";
const setGoal = "/set-goal";
const lesson = "/lesson/:courseId/:lessonId?/:type?";
const cart = "/mycart";
const myCourses = "/mycourses";
const courses = "/courses";
const logout = "/logout";
const signin = "/signin";
const signup = "/signup";
const socialLoginInfo = "/social-login-info";
const quizFeedback = "/quiz-feedback";
const myCoursesTrainer = "/trainer/mycourses";
const lessonTrainer = "/trainer/lesson";
const topicTrainer = "/trainer/topic";
const questionsTrainer = "/trainer/questions";
const quizTrainer = "/trainer/quiz";
const createTopicTrainer = "/trainer/topic/:mode";
const editTopicTrainer = "/trainer/topic/:mode/:topicId";
const createLessonTrainer = "/trainer/lesson/:mode";
const editLessonTrainer = "/trainer/lesson/:mode/:lessonId";
const createCourseTrainer = "/trainer/course/new";
const createQuizTrainer = "/trainer/quiz/:mode";
const editQuizTrainer = "/trainer/quiz/:mode/:quizId";
const createQuestionsTrainer = "/trainer/questions/:mode";
const editQuestionsTrainer = "/trainer/questions/:mode/:questionId";
const trainerDashboard = "/dashboard";
const viewStudentDetailTrainer = "/trainer/students";
const studentTrainer = "/trainer/student";
const paymentScreen = "/payment";
const quizScore = "/quiz-score";
const quiz = "/quiz";
const checkout = "/checkout";
const profile = "/profile";
const reviews = "/trainer/reviews";
const myEarnings = "/trainer/my-earnings";
const resources = "/resources";
const feedback = "/feedback";
const legalDocs = "/legaldocs/:page";
const moderateCourses = "/moderator/courses";
const moderateCourseDescription = "/moderator/course-description/:courseId";
export default class Routes {
  // Authentication
  static get signin() {
    return signin;
  }
  static get signup() {
    return signup;
  }
  static get resetPassword() {
    return resetPassword;
  }
  static get resetPasswordInstruction() {
    return resetPasswordInstruction;
  }
  static get confirmPassword() {
    return confirmPassword;
  }
  static get socialLoginInfo() {
    return socialLoginInfo;
  }

  // Courses
  static get myCourses() {
    return myCourses;
  }
  static get courseDetail() {
    return courseDetail;
  }
  static get lesson() {
    return lesson;
  }
  static get courses() {
    return courses;
  }

  // Checkout
  static get cart() {
    return cart;
  }
  static get checkout() {
    return checkout;
  }
  static get paymentScreen() {
    return paymentScreen;
  }

  // Quiz
  static get quiz() {
    return quiz;
  }
  static get quizScore() {
    return quizScore;
  }
  static get quizFeedback() {
    return quizFeedback;
  }

  // Trainer
  static get myCoursesTrainer() {
    return myCoursesTrainer;
  }
  static get lessonTrainer() {
    return lessonTrainer;
  }
  static get topicTrainer() {
    return topicTrainer;
  }
  static get questionsTrainer() {
    return questionsTrainer;
  }
  static get quizTrainer() {
    return quizTrainer;
  }
  static get studentTrainer() {
    return studentTrainer;
  }
  static get reviews() {
    return reviews;
  }
  static get myEarnings() {
    return myEarnings;
  }
  static get editLessonTrainer() {
    return editLessonTrainer;
  }
  static get editTopicTrainer() {
    return editTopicTrainer;
  }

  // Common
  static get logout() {
    return logout;
  }
  static get setGoal() {
    return setGoal;
  }
  static get profile() {
    return profile;
  }
  static get dashboard() {
    return dashboard;
  }

  static get createTopicTrainer() {
    return createTopicTrainer;
  }
  static get createLessonTrainer() {
    return createLessonTrainer;
  }
  static get createCourseTrainer() {
    return createCourseTrainer;
  }
  static get createQuizTrainer() {
    return createQuizTrainer;
  }
  static get editQuizTrainer() {
    return editQuizTrainer;
  }
  static get createQuestionsTrainer() {
    return createQuestionsTrainer;
  }
  static get editQuestionsTrainer() {
    return editQuestionsTrainer;
  }
  static get trainerDashboard() {
    return trainerDashboard;
  }
  static get viewStudentDetailTrainer() {
    return viewStudentDetailTrainer;
  }
  static get resources() {
    return resources;
  }
  static get feedback() {
    return feedback;
  }

  static get legalDocs() {
    return legalDocs;
  }

  // Moderator
  static get moderateCourses() {
    return moderateCourses;
  }
  static get moderateCourseDescription() {
    return moderateCourseDescription;
  }
}
