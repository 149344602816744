type VideoPlayerType = {
  resourceLink: string;
};

const VideoPlayer = ({ resourceLink }: VideoPlayerType) => {
  return (
    <>
      {resourceLink ? (
        <video
          id="clip"
          controls
          preload="auto"
          height="90%"
          width="90%"
          data-setup="{}"
          controlsList="nodownload"
        >
          <source src={resourceLink} type="video/mp4" />
        </video>
      ) : (
        <div>No Content Found</div>
      )}
    </>
  );
};

export default VideoPlayer;
