import { connect } from "react-redux";

type MyCartButtonType = {
  cart: [];
  totalAmount: number;
};

const MyCartButton = (props: { cartData: MyCartButtonType }) => {
  const { cartData } = props;
  const { cart, totalAmount } = cartData && cartData;
  return (
    <button
      className={`w-full h-auto text-lg py-3 items-center bg-white rounded-md text-black font-bold mt-5 border border-gray-400`}
    >
      <div className={`w-full flex justify-center items-center`}>
        <div className={`content-center`}>{`My Cart (${
          cart && cart.length
        })`}</div>
        {cart && cart.length > 0 && (
          <>
            <i className={`material-icons text-xl mt-1 -mr-1 ml-1`}>
              currency_rupee
            </i>
            <div>{totalAmount}</div>
          </>
        )}
      </div>
    </button>
  );
};

const mapStateToProps = (state: any) => {
  return {
    cartData: state && state.myCartReducer,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(MyCartButton);
