import { Link } from "react-router-dom";
import NoCourse from "../Components/Common/NoCourse";

const NotFound = () => {
  return (
    <div
      className={`h-screen w-full flex flex-col justify-center items-center`}
    >
      <div>
        <NoCourse title='Nothing here!!!' icon='do_not_disturb' />
      </div>
      <div>
        <Link to='/'>
          <button className={`text-white bg-lp_blue text-xl m-6 p-4 rounded`}>
            Back to Home
          </button>
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
