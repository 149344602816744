import { useEffect, useState } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import { confirmAlert } from "react-confirm-alert";
import { useForm } from "react-hook-form";
import { Link, useHistory, useLocation } from "react-router-dom";
import ButtonReuse from "../../Components/Common/ButtonReuse";
import InputProfile from "../../Components/Common/InputProfile";
import Required from "../../Components/Common/Required";
import Textarea from "../../Components/Common/Textarea";
import {
  ErrorMsgToaster,
  SuccessMsgToaster,
} from "../../Components/Utils/Toaster";
import ThemeContext from "../../Context/ThemeContext";
import {
  createCourse,
  createCourseBuilder,
  getCategory,
  getCourse,
  getCourseBuilder,
  updateCourse,
  deleteCourse,
} from "./API/NewCourse";
import CentreCourse from "./CentreCourse";

type CourseType = {
  title: string;
  description: string;
  category: string;
  estimatedDuration: string;
  estimatedDurationMin: string;
  cost: string;
  whatYouLearn: string;
  requirements: string;
  whatWeCoverInCourse: string;
  image: string;
};
const NewCoursebody = ({
  setCourseContent,
  courseContent,
  dropedContent,
  onDragEnd,
  deleteItem,
  setLoading,
  isLoading,
}: {
  setCourseContent: any;
  courseContent: any;
  dropedContent: any;
  onDragEnd: any;
  deleteItem: any;
  setLoading: any;
  isLoading: boolean;
}) => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<CourseType>();
  const [coverImage, setCoverImage] = useState("");
  const [category, setCategory] = useState(["others"]);
  const [isEdit, setIsEdit] = useState(false);
  const history = useHistory();
  const onSubmit = (data: any) => {
    setLoading(true);
    data.estimatedDuration = data.estimatedDurationMin
      ? data.estimatedDuration + `:${data.estimatedDurationMin}`
      : data.estimatedDuration + `:00`;
    delete data.estimatedDurationMin;
    const postCourse = isEdit ? updateCourse : createCourse;
    postCourse(data).then(({ data, errorMsg }) => {
      const msg = { data: data };
      if (data?.courseId) {
        createCourseBuilder(courseContent, data.courseId)
          .then(({ data, errorMsg, error }) => {
            setLoading(false);
            const msg = {
              data: "Course Created / Updated Sucessfully",
              statusCode: 200,
            };
            const errmsg = errorMsg ? errorMsg : data?.error;
            if (data?.contents?.length) {
              SuccessMsgToaster(msg);
              history.push("/trainer/mycourses");
            }
            if (errmsg) {
              ErrorMsgToaster(data);
            }
          })
          .catch((e) => {
            setLoading(false);
            let errorMsg = { error: "Somthing Went Wrong", statusCode: 400 };
            ErrorMsgToaster(errorMsg);
          })
          .finally(() => setLoading(false));
      }
      if (errorMsg) ErrorMsgToaster(errorMsg);
    });
  };

  const query = new URLSearchParams(useLocation().search);
  const id = query.get("id");
  useEffect(() => {
    getCategory().then(({ data }) => {
      setCategory(data);
    });
    if (id) {
      getCourseBuilder(id).then(({ data }) => {
        setLoading(false);
        if (data?.error) {
          ErrorMsgToaster(data);
        }
        if (data?.contents?.length > 0) {
          const msg = {
            data: "You can start editing course",
            statusCode: 200,
          };
          SuccessMsgToaster(msg);
          data.contents = data.contents.map((item: any, index: any) => {
            item.id = (index + 1).toString();
            return item;
          });
          setCourseContent(data.contents);
        }
        if (!data) {
          const msg = {
            data: "Somthing Went Wrong, Can't edit Course",
            statusCode: 504,
          };
          ErrorMsgToaster(msg);
        }
      });
      getCourse(id).then(({ data }) => {
        if (data?.error) {
          ErrorMsgToaster(data);
        } else {
          let duration = data?.estimatedDuration?.split(":");
          data.estimatedDuration = duration[0];
          data.estimatedDurationMin = duration[1];
          setIsEdit(true);
          for (const [key, value] of Object.entries(data)) {
            if (key === "image") {
              // @ts-expect-error
              setCoverImage(value);
            } else {
              // @ts-expect-error
              setValue(key, value);
            }
          }
        }
      });
    } else {
      setLoading(false);
    }
  }, [id]);

  const deletecourse = async () => {
    if (id) {
      confirmAlert({
        title: "Do you want to delete the course?",
        message: "All data related to this course will be deleted completely",
        buttons: [
          {
            label: "Yes",
            onClick: async () => {
              const data = await deleteCourse(id);
              console.log(data);
              if (data?.error) {
                ErrorMsgToaster(data);
              } else {
                SuccessMsgToaster(data.data);
                history.goBack();
              }
            },
          },
          {
            label: "No",
            onClick: () => {
              return;
            },
          },
        ],
      });
    }
  };

  return (
    <ThemeContext.Consumer>
      {(context) => {
        return (
          <>
            <form
              className={`flex flex-col text-black -mt-4`}
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className={`flex flex-col text-black`}>
                <div
                  className='flex flex-col  text-2xl font-bold font-Lato '
                  style={{ color: context.theme!.title }}
                >
                  <div className=' float-left'>
                    Add New Course
                    <div className={`float-right md:mr-6`}>
                      <Link to={`/trainer/mycourses`}>
                        <button
                          className={`bg-yellow-200 h-7 w-36 text-sm font-bold place-self-end rounded-md text-black`}
                        >
                          VIEW ALL COURSE
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
                <br></br>
                <div className='flex flex-col gap-5'>
                  <div>
                    <InputProfile
                      isRequired={true}
                      type='text'
                      label='Course Title'
                      formprops={{ ...register("title", { required: true }) }}
                      displayErr={errors.title ? true : false}
                    />
                  </div>

                  <div>
                    <Required />
                    <label>Description</label>
                    <textarea
                      className='h-48 w-full text-sm p-2 border-gray-400 border rounded-md font-lato '
                      {...register("description", { required: true })}
                    />
                    {errors && errors.description && (
                      <p className={`text-red-500`}>This field is Required</p>
                    )}
                  </div>
                </div>
                <br></br>
                <div className='grid grid-cols-3 gap-5'>
                  <div>
                    <Required />
                    <label>Category</label>
                    <br></br>
                    <select
                      className='w-full h-10 mt-2 border border-gray-400 rounded-md text-base pl-3'
                      {...register("category", { required: true })}
                    >
                      <option value=''>Select option</option>
                      {category?.map((item) => (
                        <option key={item} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                    {errors && errors.category && (
                      <p className={`text-red-500`}>This field is Required</p>
                    )}
                  </div>
                  <div className='grid grid-cols-1'>
                    <div className='flex flex-row'>
                      <div style={{ marginTop: 1 }}>
                        <Required />
                      </div>
                      <div>Estimated Duration</div>
                    </div>
                    <div className='grid grid-cols-2 gap-2 '>
                      <div>
                        <InputProfile
                          type='number'
                          pattern='/^[0-9]{1,3}$/gik'
                          holderClass={`HH`}
                          formprops={{
                            ...register("estimatedDuration", {
                              required: true,
                              pattern: /^[0-9]{1,3}$/gi,
                            }),
                          }}
                          onInput={(e) => {
                            return (
                              e.currentTarget.validity.valid ||
                              (e.currentTarget.value = "")
                            );
                          }}
                          min='0'
                          isRequired={false}
                          displayErr={
                            errors.estimatedDuration?.type === "required"
                              ? true
                              : false
                          }
                        />
                        {errors?.estimatedDuration?.type === "pattern" && (
                          <p className={`text-red-500`}>Enter valid time</p>
                        )}
                      </div>
                      <div>
                        <InputProfile
                          type='number'
                          pattern='/^[0-9]{1,3}$/gi'
                          holderClass={`MM`}
                          formprops={{
                            ...register("estimatedDurationMin", {
                              required: true,
                              pattern: /^[0-9]{1,3}$/gi,
                            }),
                          }}
                          onInput={(e) => {
                            return (
                              e.currentTarget.validity.valid ||
                              (e.currentTarget.value = "")
                            );
                          }}
                          min='0'
                          isRequired={false}
                          displayErr={
                            errors.estimatedDurationMin?.type === "required"
                              ? true
                              : false
                          }
                        />
                        {errors?.estimatedDurationMin?.type === "pattern" && (
                          <p className={`text-red-500`}>Enter valid time</p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div>
                    <InputProfile
                      type='number'
                      label='Cost'
                      holderClass={`$$$`}
                      maxLength={4}
                      min='0'
                      onInput={(e) => {
                        return (
                          e.currentTarget.validity.valid ||
                          (e.currentTarget.value = "")
                        );
                      }}
                      formprops={{
                        ...register("cost", {
                          required: true,
                          pattern: /^[0-9]+([.])?([0-9]+)?$/gi,
                        }),
                      }}
                      isRequired={true}
                      displayErr={
                        errors?.cost?.type === "required" ? true : false
                      }
                    />
                    {errors?.cost?.type === "pattern" && (
                      <p className={`text-red-500`}>Only Number</p>
                    )}
                  </div>
                </div>
                <br></br>
                <div>
                  <Textarea
                    type='text'
                    label='What you will learn?'
                    inputClass='h-16'
                    formprops={{
                      ...register("whatYouLearn", { required: true }),
                    }}
                    isRequired={true}
                    displayErr={errors.whatYouLearn ? true : false}
                  />
                </div>
                <br></br>
                <div>
                  <Textarea
                    type='text'
                    label='Requirements'
                    inputClass='h-16'
                    formprops={{
                      ...register("requirements", { required: true }),
                    }}
                    isRequired={true}
                    displayErr={errors.requirements ? true : false}
                  />
                </div>
                <br></br>
                <div>
                  <Textarea
                    type='text'
                    label='What do we cover in this course?'
                    inputClass='h-16'
                    formprops={{
                      ...register("whatWeCoverInCourse", { required: true }),
                    }}
                    isRequired={true}
                    displayErr={errors.whatWeCoverInCourse ? true : false}
                  />
                </div>
                <br></br>
                <label className='p-2'>
                  <Required />
                  Course Cover Image
                </label>
                <div>
                  <input
                    {...register("image")}
                    id='myInput'
                    onChange={(e) => {
                      let img;
                      if (e?.target?.files && e?.target?.files?.length > 0) {
                        img = URL.createObjectURL(e.target.files[0]);
                        const imgNode = document.getElementById(
                          "coverimg"
                        ) as HTMLImageElement;
                        imgNode.src = img;
                      }
                    }}
                    type={"file"}
                    accept='image/*'
                  />
                  <div
                    className={
                      "p-2 w-96 h-96 overflow-hidden object-cover center"
                    }
                  >
                    <img
                      src={coverImage}
                      id='coverimg'
                      alt='Course cover image'
                      className={"w-96 h-96"}
                    />
                  </div>
                </div>
                <br></br>
                <div className='text-base'>Add Lessons, Topics & Quiz</div>
                <div className='mt-5  w-full border-solid border-gray-400 border rounded-md text-base '>
                  <DragDropContext onDragEnd={onDragEnd}>
                    <CentreCourse
                      bgClass='white'
                      courseContent={courseContent}
                      dropedContent={dropedContent}
                      deleteItem={deleteItem}
                      setCourseContent={setCourseContent}
                    ></CentreCourse>
                  </DragDropContext>
                </div>

                <br></br>
                <div className='flex justify-end'>
                  <ButtonReuse
                    disabled={isLoading}
                    type={"submit"}
                    sizeClass='text-xl'
                    mginClass='mr-2'
                    widthClass='w-32'
                    value='SAVE'
                  />
                  <ButtonReuse
                    disabled={isLoading}
                    sizeClass='text-xl'
                    widthClass='w-32'
                    mginClass='mx-2'
                    value='RESET'
                    colorClass='bg-gray-400'
                    onClick={() => {
                      setCourseContent(null);
                      reset();
                    }}
                  />
                  {id && (
                    <ButtonReuse
                      sizeClass='text-xl'
                      widthClass='w-32'
                      mginClass='ml-2'
                      value='DELETE'
                      onClick={deletecourse}
                    />
                  )}
                </div>
              </div>
            </form>
          </>
        );
      }}
    </ThemeContext.Consumer>
  );
};

export default NewCoursebody;
