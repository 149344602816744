import { UserInfoDetailsType } from '../../Utils/types';
import { UserInfoTypes } from '../types/index';

const { USER_INFO_TYPE } = UserInfoTypes;

const UserInfoAction = (data: UserInfoDetailsType) => {
    return {
        type: USER_INFO_TYPE,
        payload: data
    }
}

export { UserInfoAction };