import axios, { AxiosError, AxiosResponse } from "axios";
import { resolve } from "dns";
import {
  ApplicationJsonConfigHeader,
  BaseUrl,
  FormDataFileConfigHeader,
} from "../../../Api/axios.config";
import { GeneralError } from "../../../Api/Error.type";

export const createCourse = async (courseData: any): Promise<any> => {
  try {
    const token: string | null = localStorage.getItem("token");
    const formData = new FormData();
    for (const property in courseData) {
      if (property === "image") {
        const imageFile = courseData[property] && courseData[property][0];
        formData.append(property, imageFile);
      } else {
        formData.append(property, courseData[property]);
      }
    }
    const response: AxiosResponse = await axios.post(
      `${BaseUrl}/trainer/createCourse/basicDetails`,
      formData,
      {
        headers: {
          ...FormDataFileConfigHeader,
          authorization: `Bearer ${token}`,
        },
        responseType: "json",
      }
    );
    return response;
  } catch (e: any) {
    return e;
  }
};

export const updateCourse = async (courseData: any): Promise<any> => {
  try {
    console.log("courseData[property]", courseData);
    const qparam = courseData.courseId
      ? `?courseId=${courseData.courseId}`
      : ``;
    const token: string | null = localStorage.getItem("token");
    const formData = new FormData();
    for (const property in courseData) {
      if (property === "image") {
        const imageFile = courseData[property] && courseData[property][0];
        formData.append(property, imageFile);
      } else {
        formData.append(property, courseData[property]);
      }
    }
    const response: AxiosResponse = await axios.patch(
      `${BaseUrl}/trainer/editCourse/basicDetails${qparam}`,
      formData,
      {
        headers: {
          ...FormDataFileConfigHeader,
          authorization: `Bearer ${token}`,
        },
        responseType: "json",
      }
    );
    return response;
  } catch (e: any) {
    return e;
  }
};

export const createCourseBuilder = async (
  courseData: any,
  courseId: any
): Promise<any> => {
  try {
    const token: string | null = localStorage.getItem("token");
    const formData = new FormData();
    if (courseData.length && courseData[0].itemType === "Lesson") {
      const response: AxiosResponse = await axios.post(
        `${BaseUrl}/trainer/addCourseBuilder?courseId=${courseId}`,
        { contents: courseData },
        {
          headers: {
            ...ApplicationJsonConfigHeader,
            authorization: `Bearer ${token}`,
          },
          responseType: "json",
        }
      );
      return response;
    } else {
      let err = "Course should start with a Lesson";
      if (!courseData.length) err = "A course should consist atlest one lesson";
      return new Promise((resolve, reject) =>
        resolve({
          data: {
            error: err,
          },
        })
      );
    }
  } catch (e: any) {
    return e;
  }
};

export const getCourse = async (courseId: any): Promise<any> => {
  try {
    const token: string | null = localStorage.getItem("token");
    const response: AxiosResponse = await axios.get(
      `${BaseUrl}/trainer/getCourse/basicDetails?courseId=${courseId}`,
      {
        headers: {
          ...ApplicationJsonConfigHeader,
          authorization: `Bearer ${token}`,
        },
        responseType: "json",
      }
    );
    return response;
  } catch (e: any) {
    return e;
  }
};

export const getCourseBuilder = async (courseId: any): Promise<any> => {
  try {
    const token: string | null = localStorage.getItem("token");
    const response: AxiosResponse = await axios.get(
      `${BaseUrl}/trainer/getCourseBuilder?courseId=${courseId}`,
      {
        headers: {
          ...ApplicationJsonConfigHeader,
          authorization: `Bearer ${token}`,
        },
        responseType: "json",
      }
    );
    return response;
  } catch (e: any) {
    return e;
  }
};

export const getCategory = async (): Promise<any> => {
  try {
    const token: string | null = localStorage.getItem("token");
    const response: AxiosResponse = await axios.get(
      `${BaseUrl}/getListOfGoals`,
      {
        headers: {
          ...ApplicationJsonConfigHeader,
          authorization: `Bearer ${token}`,
        },
        responseType: "json",
      }
    );
    return response;
  } catch (e: any) {
    return e;
  }
};

export const getTopSellingCourse = async (): Promise<any> => {
  try {
    const token: string | null = localStorage.getItem("token");
    const response: AxiosResponse = await axios.get(
      `${BaseUrl}/trainer/topSellingCourse`,
      {
        headers: {
          ...ApplicationJsonConfigHeader,
          authorization: `Bearer ${token}`,
        },
        responseType: "json",
      }
    );
    return response;
  } catch (e: any) {
    return e;
  }
};

export const deleteCourse = async (courseId: any): Promise<any> => {
  try {
    const token: string | null = localStorage.getItem("token");
    const response: AxiosResponse = await axios.delete(
      `${BaseUrl}/trainer/deleteCourse`,
      {
        headers: {
          ...ApplicationJsonConfigHeader,
          authorization: `Bearer ${token}`,
        },
        responseType: "json",
        data: { courseId: courseId },
      }
    );
    return response;
  } catch (e: any) {
    return e;
  }
};
