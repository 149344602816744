import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ModeratorService from "../../Api/Moderator/ModeratorService";
import Logo from "../../Components/Common/Logo";
import NoCourse from "../../Components/Common/NoCourse";
import ModeratorCoursesCard from "../../Components/Template/ModeratorCoursesCard";
import MyCourseTrainerCard from "../../Components/Template/MyCourseTrainerCard";
import { ErrorMsgToaster } from "../../Components/Utils/Toaster";
import ThemeContext from "../../Context/ThemeContext";
import { TrainerMyCourseResType } from "../../Utils/types";

const ModeratorCourses = () => {
  const [myCourses, setMyCourses] = useState<Array<TrainerMyCourseResType>>([]);

  useEffect(() => {
    const myCourseAPIService = new ModeratorService();
    myCourseAPIService
      .fetchCourses()
      .then((resData) => {
        if (resData?.errorMsg) {
          ErrorMsgToaster(resData.errorMsg);
        } else {
          if (resData?.data) {
            setMyCourses(resData.data);
          }
        }
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <ThemeContext.Consumer>
      {(context) => {
        return (
          <div className="m-6 ">
            <div className=" flex justify-between">
              <Logo />
              <div className=" ">
                <i className={`material-icons text-sm mr-2 `}>logout</i>LOGOUT
              </div>
            </div>

            <div
              className={`text-2xl font-bold mt-5 `}
              style={{ color: context.theme!.title }}
            >
              Courses
            </div>
            <div className={`grid  gap-6 mt-9`}>
              {myCourses &&
                myCourses.length > 0 &&
                myCourses.map((data) => (
                  <Link
                    // `/lesson/${myCourseData?.courseId}`
                    to={`/moderator/course-description/${data?.courseId}`}
                    key={data.courseId}
                  >
                    <ModeratorCoursesCard
                      myCourseTrainerData={data}
                      key={data.courseId}
                    />
                  </Link>
                ))}
              {!myCourses ||
                (myCourses.length === 0 && (
                  <NoCourse title="No Courses Available" icon="school" />
                ))}
            </div>
          </div>
        );
      }}
    </ThemeContext.Consumer>
  );
};

export default ModeratorCourses;
