type PDFViewerType = {
  resourceLink: string;
};

const PDFViewer = ({ resourceLink }: PDFViewerType) => {
  return (
    <div style={{ height: "inherit" }} className='w-full'>
      {resourceLink ? (
        // <object
        //   onContextMenu={(e) => e.preventDefault()}
        //   data={resourceLink + "#toolbar=0"}
        //   type='application/pdf'
        //   width='100%'
        //   height='100%'
        // ></object>
        <iframe
          src={`http://docs.google.com/gview?url=${resourceLink}&embedded=true`}
          sandbox='allow-scripts allow-same-origin'
          width='100%'
          height='100%'
        ></iframe>
      ) : (
        <div>No Content Found</div>
      )}
    </div>
  );
};

export default PDFViewer;
