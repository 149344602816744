const Privacy = () => {
  return (
    <div className="m-auto w-full flex items-center justify-center">
      <div className="w-full text-black text-base sm:text-lg :text-2xl  grid items-center justify-center">
        <h1 className="flex place-self-center font-bold text-2xl text-base text-black pb-8">
          Privacy Policy
        </h1>
        <h4 className="flex place-self-start font-bold  text-black">
          Table of contents:
        </h4>
        <div className="text-base text-black">
          <ol>
            <li>A.General</li>
            <li>B.Applicability</li>

            <li>C. Access</li>
            <li>D. Personal Information </li>
            <li>E. Automatic Information Collection</li>
            <li>F. Information Security</li>
            <li>G. Use of your personal information</li>
            <li>H. Exceptions</li>
          </ol>
        </div>
        <div className="mt-6 mb-6">
          <h4 className="font-bold text-black ">A.General</h4>
          <p className="text-base text-black mt-4 ">
            In this policy, the words "we", "our", and "us" refer to Her
            Springboard and the privacy mechanism outlined identify personally
            identifiable or personal information that may be collected, how such
            information is used, and the choices You have regarding our use of
            this information.
          </p>
          <p className="text-base text-black mt-4">
            {" "}
            In addition to the terms stated in Terms of Service provided under
            this Website/mobile application (together “Platform”), we are
            committed to protecting Your privacy. Only authorized
            representatives of Her Springboard and on a need-to-know basis only
            use any information received from You and as consented by You. We
            constantly review our systems and data to secure Your personal
            information. In the event of any identified unauthorized use or
            disclosure of information or upon Your complaint, we will
            investigate any such complaint or such actions with a view to
            prosecuting and/or taking civil proceedings to recover damages
            against those responsible. Any User who does not agree with any
            provisions of this Privacy Policy and the Terms of Service is
            required to leave the Platform immediately. Should You disagree with
            this Policy (defined below) and still continue to access the
            Platform and provide Your personal information, Her Springboard
            disclaim all the liabilities arising therefrom and it shall be
            assumed by Your continued use of the Platform that You have accepted
            the Policy and any other online terms and conditions available on
            the Platform.
          </p>
          <p className="text-base text-black mt-4 ">
            {" "}
            If any information You have provided or uploaded on the Platform
            violates the terms of this Policy, Her Springboard may be required
            to delete such information upon informing You of the same and revoke
            Your access if required without incurring any liability to You.
          </p>
        </div>
        <div className="mt-6 mb-6">
          <h4 className=" font-bold text-black ">B.Applicability</h4>
          <p className="text-base text-black mt-4 ">
            This privacy policy (“Policy”) applies to all Users who access the
            Platform and are therefore required to read and understand the
            Policy before submitting any Personal Information (defined
            hereinafter). By submitting Personal Information, You are consenting
            to the use and processing of such information in accordance with
            this Policy. If You have inadvertently submitted any Personal
            Information to Her Springboard prior to reading the Policy
            statements set out herein, or You do not agree with the manner in
            which the Personal Information is collected, stored or used, then
            You may access, modify and delete all information stored about You
            by writing to us at support@nimbusgurus.in. Her Springboard is
            keeping personal data of the User, and every User is also entitled
            to require Her Springboard to delete and destroy all such
            information (but not that of other Users). This Policy applies to
            the Platform and any other services that are owned and operated by
            Her Springboard. Third party websites may place their own cookies or
            other files on the Users' computer, collect data or solicit personal
            information from the Users, for which Her Springboard is not
            responsible or liable. Accordingly, Her Springboard does not make
            any representations concerning the privacy practices or policies of
            such third parties or terms of use of such websites, nor does Her
            Springboard guarantee the accuracy, integrity, or quality of the
            information, data, text, software, sound, photographs, graphics,
            videos, messages or other materials available on such websites. The
            inclusion or exclusion does not imply any endorsement by Her
            Springboard of the website, the website's provider, or the
            information on the website. Her Springboard encourages the User to
            read the privacy policies of each such website and the User
            understands that its solely such third party who is responsible to
            the User in this regard. Her Springboard has taken reasonable
            precautions as per applicable Indian law and implemented industry
            standards to treat Personal Information as confidential and to
            protect it from unauthorized access, improper use or disclosure,
            modification and unlawful destruction or accidental loss of the
            Personal Information.
          </p>
        </div>
        <div className="mt-6 mb-6">
          <h4 className="font-bold text-black ">C.Access</h4>
          <p className="text-base text-black mt-4 ">
            In order to have access to all the features and benefits on our
            Platform, a User is required to first create an account on our
            Platform. To create an account, a User is required to provide
            Personal Information as may be required during the time of
            registration. Other information requested on the registration page,
            including the ability to receive promotional offers from Her
            Springboard, is optional. Her Springboard may, in future, include
            other optional requests for information from the User to help Her
            Springboard to customize the Platform to deliver personalized
            information to the User. Without User's agreement, Her Springboard
            will not share, rent or sell any Personal Information with third
            parties in any way other than what is disclosed in this Policy. Her
            Springboard may keep records of telephone calls received and made
            for making enquiries, registered courses, feedback or other purposes
            for the purpose of rendering services effectively and efficiently.
          </p>
        </div>
        <div className="mt-6 mb-6">
          <h4 className=" font-bold text-black ">D.Personal Information</h4>
          <p className="text-base text-black mt-4 ">
            “Personal Information” shall mean the information which identifies
            the User, name, identification number, email address, age, gender,
            or phone number provided to Her Springboard at the time of
            registration or any time thereafter. Personal information may
            include “sensitive personal information or data” as defined under
            the Information Technology (Reasonable Security Practices and
            Procedures and Sensitive Personal Data or Information) Rules, 2011
            and in this context, includes passwords or any financial account
            information provided by You during registration.
          </p>
          <p className="text-base text-black ">
            {" "}
            We request You to not provide us with any Personal Information
            unless specifically requested by us. In such events, we bear no
            liability to such Personal Information provided by You.
          </p>
        </div>

        <div className="mt-6 mb-6">
          <h4 className=" font-bold text-black ">
            E.Automatic Information Collection
          </h4>
          <p className="text-base text-black mt-4 ">
            Our system collects information that is sent over the internet
            without Your control. Some examples of the information we collect
            and analyse include the Internet protocol (IP) address used to
            connect Your computer to the internet; login; email; password;
            computer and connection information such as browser type and
            version, operating system, and platform; course enrolled history,
            which we sometimes aggregate with similar information from other
            users to create features, reports such as popular courses, most used
            features etc; the full Uniform Resource Locator (URL) click stream
            to, and from our Platform, including date and time; cookie number;
            courses or videos You viewed or searched for; the email id You used
            to call our customer service.
          </p>
          <p className="text-base text-black ">
            {" "}
            During some visits we may use software tools such as JavaScript to
            measure and collect session information, including page response
            times, download errors, length of visits to certain page, course and
            videos, page interaction information (such as scrolling, clicks and
            mouse overs), and methods used to browse away from the page.
          </p>
        </div>

        <div className="mt-6 mb-6">
          <h4 className=" font-bold text-black ">F. Information Security</h4>
          <p className="text-base text-black mt-4 ">
            To protect the security of Your Personal information during
            transmission, we use Secure Sockets Layer (SSL) software, which
            encrypts information You input. To prevent unauthorized access to
            sensitive information, it is important for You to monitor those who
            have access to Your password and to Your computer, laptop or any
            other device through which Your account can be accessed. Be sure to
            sign off when finished while using a shared computer.
          </p>
          <p className="text-base text-black ">
            {" "}
            However, please do note that given the nature of internet
            transactions, Her Springboard does not take any responsibility for
            the transmission of information including Personal Information
            shared by You other than as set forth in this Policy. Any
            transmission of information on the internet is done at Your risk and
            shall not be responsible for the circumvention of the privacy
            settings or security measures either by You or any third-party.
          </p>
        </div>

        <div className="mt-6 mb-6">
          <h4 className="font-bold text-black ">
            G. Use of your personal information
          </h4>

          <p className="text-base text-black mt-4 ">
            While availing our services You will provide access to Her
            Springboard with Your Personal Information. If that information is
            wrong, You can request us to modify or delete the same. Her
            Springboard will take all reasonable measures to ensure that the
            Personal Information is modified and used for rendering services to
            You and as otherwise in compliance with laws. When editing or
            deleting Personal Information, we may ask You to verify Your
            identity before we can act on Your request. Her Springboard may at
            its discretion reject any request that is contrary to law, requires
            un-reasonable technical efforts. We do not assure that we will
            delete all residual copies and archives made by any third party
            without our knowledge and consent. We never retain Your Personal
            Information longer than it is required for the purposes of providing
            services through the Platform or as required by applicable Indian
            law. At times, we may also require to transfer Your Personal
            Information to third-party service providers, for instance to
            authorize transactions we may share Your financial data with such
            third-party providers. You hereby consent to Your transfer of
            Personal Information in all such instances. Her Springboard carries
            out all such transfers strictly in accordance with law.
          </p>
          <p className="text-base text-black mt-4 ">
            Her Springboard shall use Your Personal Information to ensure
            services with respect to the Platform are presented to You in the
            most effective manner, to secure the Platform and make improvements,
            to carry out our obligations to You, and to communicate with You.
            The said communication can either be by calls, text or emails and
            for purposes which include transactional, service, or promotional
            calls or messages. If at any time You wish to not receive any
            communication from our end You can opt-out of the same by writing to
            us on support@nimbusgurus.in
          </p>
        </div>

        <div className="mt-6 mb-6">
          <h4 className=" font-bold text-black ">H. Exceptions</h4>
          <p className="text-base text-black mt-4 ">
            Your Personal Information may be shared with third parties who have
            a need or authority to receive such information, if we have a
            good-faith belief that access, use, preservation or disclosure of
            the information is reasonably necessary to comply with (i) in
            response to any authority having to receive such information under
            law (ii) any order of court (iii) detect, prevent, or otherwise
            address fraud, security or technical issues (iv) protect against
            harm to the rights, property or safety of Her Springboard, our users
            or the public as required or permitted by law.
          </p>
        </div>
      </div>
    </div>
  );
};
export default Privacy;
