import { constants } from "buffer";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { Assets } from "../../Api/Assets";
import SetGoalService from "../../Api/SetGoal/SetGoal";
import UserInfoAPIService from "../../Api/UserInfo/UserInfoAPI";
import Logo from "../../Components/Common/Logo";
import { UserInfoAction } from "../../redux/action";
import Routes from "../../Routes/Routes";
import "./SetGoalStyle.css";

type SetGoalProps = {
  firstName: string;
  lastName: string;
  setUserInfo: any;
  userInfo: any;
};

const SetGoal = ({
  firstName = "",
  lastName = "",
  setUserInfo,
  userInfo,
}: SetGoalProps) => {
  const setGoalService = new SetGoalService();
  const [goalCategory, setGoalCategory] = useState("");

  const [goalIntrest, setGoalIntrest] = useState<Array<string>>([]);
  const [activeGoals, setActiveGoals] = useState<Array<string>>([]);

  const [goalList, setGoalList] = useState<Array<string>>([]);
  const [subGoalList, setSubGoalList] = useState<Array<string>>([]);
  const [registerType, setRegisterType] = useState<string>("");
  const history = useHistory();

  useEffect(() => {
    const loginType: string | null = localStorage.getItem("registerType");
    // if (loginType === "Trainer") {
    //   history.push(Routes.trainerDashboard);
    // }
    loginType && setRegisterType(loginType);
    setGoalService.getGoals().then((value: Array<string>) => {
      if (value && value.length) {
        setGoalList(value);
      }
    });
  }, []);
  useEffect(() => {
    if (userInfo.setGoal === true) {
      history.push(Routes.dashboard);
    }
  }, [userInfo]);
  const getSubGoalList = (goal: string) => {
    setGoalService.getSubGoals(goal).then((value) => {
      setSubGoalList(value);
    });
  };

  const setIntrest = (intrest: string) => {
    let itemIndex = activeGoals.indexOf(intrest);
    if (itemIndex > -1) {
      activeGoals.splice(itemIndex, 1);
      setActiveGoals([...activeGoals]);
    } else {
      setActiveGoals([...activeGoals, intrest]);
    }
  };

  const handleProceed = () => {
    setGoalService.setGoal(goalCategory, activeGoals).then((goalResponse) => {
      if (goalResponse.error !== undefined) {
        toast.error(goalResponse.error!, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        setGoalCategory("");
        setGoalIntrest([]);
        const userInfoAPIService = new UserInfoAPIService();
        userInfoAPIService
          .fetchUserInfo()
          .then((resData) => {
            if (resData.data) {
              setUserInfo(resData.data);
            }
          })
          .catch((err) => console.log(err));
        history.push(Routes.dashboard);
      }
    });
  };

  const marginTop = `mt-8`;
  const centerItem = `flex items-center justify-center`;

  return (
    <div className={`  h-screen font-lato`}>
      <div className={``}>
        <div>
          <div className={`-ml-5 -mt-9`}>
            <Logo />
          </div>
          <div className={`${centerItem} -mt-8`}>
            <img
              src={Assets.SetGoalBannerImage}
              alt={`Set Goals Banner`}
              width={250}
              height={250}
            />
          </div>
          <div
            className={`grid justify-items-center text-lp_blue font-bold text-2xl md:text-3xl mt-4`}
          >
            Let's start with your goal
          </div>
          <div
            className={`mt-4 w-auto md:w-full grid items-center justify-center  `}
          >
            <select
              id="goals"
              className={`w-full border border-gray-400 rounded mt-1 p-2 h-12 bg-white focus:outline-none  `}
              onChange={(e) => {
                setGoalCategory(e.target.value);
                getSubGoalList(e.target.value);
              }}
            >
              <option disabled selected>
                -- select an option --
              </option>
              {goalList?.map((goal, index) => (
                <option label={goal} value={goal} />
              ))}
            </select>
          </div>

          <br></br>

          {goalCategory && subGoalList && (
            <div className={`grid grid-cols-2 col-span-12 ${centerItem} `}>
              <div className={`flex justify-end mr-7`}>
                <div
                  className={`bg-gray-400 h-52 sm:h-60 lg:h-80 w-96 ml-4 text-center text-white flex  justify-center items-center`}
                >
                  <div className="text-center text-white align-middle place-self-center">
                    {" "}
                    video
                  </div>
                </div>
              </div>
              <div>
                <div
                  className={`flex
                   justify-start md:ml-28 sm:ml-16 ml-10
                     w-auto mx-auto`}
                >
                  <div>
                    <div className="text-lp_blue -mb-5    text-base sm:text-lg :text-2xl font-bold grid items-center justify-center">
                      Select area of Interest
                    </div>
                    <br></br>

                    <div className="grid mr-8  mt-1">
                      {subGoalList.map((value) => {
                        let isActive = activeGoals.includes(value);
                        return (
                          <button
                            style={{
                              background: isActive ? "#0B4089" : "white",
                              color: isActive ? "white" : "black",
                              borderColor: isActive ? "#0B4089" : "black",
                            }}
                            className={`h-auto w-80% ${centerItem} text-white border-2 rounded-md ml-4 px-2 py-2 mt-2`}
                            onClick={() => setIntrest(value)}
                          >
                            {value}
                          </button>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div
            className={`md:w-full mt-6 grid items-center justify-center mb-20`}
          >
            {activeGoals.length && goalCategory ? (
              <button
                className={`w-96  text-white bg-lp_blue text-xl mb-8 -mt-4 p-2 rounded `}
                onClick={handleProceed}
              >
                PROCEED
              </button>
            ) : (
              <button
                className={`w-96  text-white bg-lp_blue mb-8 text-xl mt-2 p-2 rounded opacity-50 cursor-not-allowed`}
                onClick={handleProceed}
              >
                PROCEED
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setUserInfo: (data: any) => {
      dispatch(UserInfoAction(data));
    },
  };
};

const mapStateToProps = (state: any) => {
  return {
    userInfo: state && state.userInfoReducer,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SetGoal);
