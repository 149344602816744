import { TableData } from "../../Api/Dashboard/Dashboard.types";
import "./Table.css";

const TableRows = ({ TableInfo }: { TableInfo: TableData }) => {
  const { date, transactionId, description, amount, status } = TableInfo;
  return (
    <>
      <tr className={`font-semibold`}>
        <td>{date}</td>
        <td>{transactionId}</td>
        <td>{description}</td>
        <td>
          {Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "INR",
          }).format(amount)}
        </td>
        <td>{status}</td>
      </tr>
    </>
  );
};

export default TableRows;
