import SubmenuItems from "../../Components/Common/SubmenuItems";
import Menu from "../../Components/Template/Menu";
import Template from "../../Components/Template/Template";

const QuizScores = () => {
  const menu = <Menu selectedItem={``} />;
  return (
    <Template menu={menu} body={<QuizScoresbody />} submenu={<SubMenu />} />
  );
};

const QuizScoresbody = () => {
  return (
    <div className={`flex flex-col text-gray-700`}>
      <div className="text-lp_blue text-lg- md:text-2xl font-Lato font-bold -mt-10 ">
        Course Title
      </div>
      <br></br>
      <div className="text-lp_blue  text-lg- md:text-2xl font-Lato font-bold  ">
        Quiz Title
      </div>
      <br></br>
      <div className="flex flex-row gap-5 ">
        <div className=" flex flex-col gap-5 justify-center items-center h-40 w-full bg-lp_blue-dash_tail  rounded-xl text-lp_blue font-Lato font-bold text-5xl md:text-7xl ">
          23
          <div className="text-lp_blue text-base text-center md:text-xl justify-center items-center font-Lato font-bold">
            Questions Answered Correctly
          </div>
        </div>

        <div className="flex flex-col gap-5 justify-center items-center h-40 w-full bg-lp_yellow-dash_tail text-lp_blue font-Lato font-bold text-5xl md:text-7xl rounded-xl mr-2">
          6.5
          <div className="text-lp_blue justify-center  text-center items-center text-base md:text-xl">
            Minutes to complete the Quiz
          </div>
        </div>
      </div>

      <br></br>

      <button
        className="h-10 w-32 bg-lp_blue text-white text-xl  rounded-md font-Lato font-bold place-self-end mr-2  "
        type="button"
      >
        PROCEED
      </button>
      <div></div>
    </div>
  );
};

const SubMenu = () => {
  return (
    <>
      <SubmenuItems></SubmenuItems>
    </>
  );
};
export default QuizScores;
