import { useEffect, useState } from "react";
import SubmenuTrainer from "../../Components/Common/SubMenuTrainer";
import MenuTrainer from "../../Components/Template/MenuTrainer";
import Template from "../../Components/Template/Template";
import { ErrorMsgToaster } from "../../Components/Utils/Toaster";
import StudentDetail from "./StudentDetails";
import StudentListBody from "./StudentList";
import { getCourseList, getStudentListForCourse } from "./StudentReport";

const StudentListReport = () => {
  const menu = <MenuTrainer selectedItem={`STUDENTS`} />;
  return (
    <Template menu={menu} body={<StudentDetailbody />} submenu={<SubMenu />} />
  );
};

const StudentDetailbody = () => {
  const [filterKey, setFilterKey] = useState("");
  const [courseList, setCourseList] = useState([]);
  const [studentList, setStudentList] = useState([]);

  const [studentData, setStudentData] = useState();

  useEffect(() => {
    getCourseList().then(({ data, errorMsg }: any) => {
      if (data) setCourseList(data);
      else if (errorMsg) ErrorMsgToaster(errorMsg);
    });
  }, []);

  useEffect(() => {
    filterKey &&
      getStudentListForCourse(filterKey).then(({ data, errorMsg }: any) => {
        setStudentList([]);
        if (data) setStudentList(data);
        else if (errorMsg) ErrorMsgToaster(errorMsg);
      });
  }, [filterKey]);

  return (
    <>
      {studentData ? (
        <StudentDetail
          studentData={studentData}
          setStudentData={setStudentData}
        />
      ) : (
        <StudentListBody
          filterKey={filterKey}
          setFilterKey={setFilterKey}
          courseList={courseList}
          studentList={studentList}
          setStudentData={setStudentData}
        />
      )}
    </>
  );
};

const SubMenu = () => {
  return (
    <>
      <SubmenuTrainer />
    </>
  );
};
export default StudentListReport;
