type NoCourseType = {
  title: string;
  icon?: string;
};

const NoCourse = ({ title, icon }: NoCourseType) => {
  return (
    <div
      className={`flex flex-col justify-center items-center text-gray-600  `}
    >
      <div className={`flex justify-center items-center m-10`}>
        <div className={`transform scale-150 `}>
          <div className={`transform scale-150 `}>
            <div className={`transform scale-150 `}>
              <i className={`material-icons mr-2 transform scale-150`}>
                {icon || "school"}
              </i>
            </div>
          </div>
        </div>
      </div>
      <h2 className={`text-2xl mt-4 font-semibold text-gray-400`}>{title}</h2>
    </div>
  );
};

export default NoCourse;
