import { QuizListType, QuizReqType, QuizResType } from "../../Utils/types";
import APIService from "../API";
import { BaseUrl } from "../axios.config";
import { NetworkError, Payload } from "../Error.type";

const fetchAllQuizURL: string = `${BaseUrl}/trainer/getQuiz`;
const searchQuizURL: string = `${BaseUrl}/trainer/searchQuiz`;
const addNewQuizURL: string = `${BaseUrl}/trainer/createQuiz`;
const editQuizURL: string = `${BaseUrl}/trainer/editQuiz`;
const apiService = new APIService();

type EditResType = {
    data: string;
    quizId: string;
}
export class FetchAllQuizAPIResult
    implements Payload<Array<QuizListType>, NetworkError>
{
    statusCode: number;
    data?: Array<QuizListType>;
    errorMsg?: NetworkError;

    constructor(
        statusCode: number,
        data?: Array<QuizListType>,
        errorMsg?: NetworkError
    ) {
        this.statusCode = statusCode;
        this.data = data;
        this.errorMsg = errorMsg;
    }
}

export class AddNewQuizAPIResult
    implements Payload<Array<QuizListType>, NetworkError>
{
    statusCode: number;
    data?: Array<QuizListType>;
    errorMsg?: NetworkError;

    constructor(
        statusCode: number,
        data?: Array<QuizListType>,
        errorMsg?: NetworkError
    ) {
        this.statusCode = statusCode;
        this.data = data;
        this.errorMsg = errorMsg;
    }
}

export class FetchQuizDetailAPIResult
    implements Payload<QuizResType, NetworkError>
{
    statusCode: number;
    data?: QuizResType;
    errorMsg?: NetworkError;

    constructor(
        statusCode: number,
        data?: QuizResType,
        errorMsg?: NetworkError
    ) {
        this.statusCode = statusCode;
        this.data = data;
        this.errorMsg = errorMsg;
    }
}

export class EditQuizAPIResult
    implements Payload<EditResType, NetworkError>
{
    statusCode: number;
    data?: EditResType;
    errorMsg?: NetworkError;

    constructor(
        statusCode: number,
        data?: EditResType,
        errorMsg?: NetworkError
    ) {
        this.statusCode = statusCode;
        this.data = data;
        this.errorMsg = errorMsg;
    }
}

export default class TrainerQuizAPIService {

    // Fetch Quiz list
    fetchAllQuizes = async () => {
        return await apiService.getReqs({
            APIUrl: fetchAllQuizURL,
            payload: {},
            resType: FetchAllQuizAPIResult,
        });
    };

    // fetch quiz details 
    fetchQuizDetails = async (quizId: string) => {
        const payload = { quizId: quizId };
        const urlWithKey = `${fetchAllQuizURL}?quizId=${quizId}`;
        return await apiService.getReqs({
            APIUrl: urlWithKey,
            payload,
            resType: FetchQuizDetailAPIResult,
        });
    };

    // search Quizes
    searchQuiz = async (key: string) => {
        if (key) {
            const urlWithKey = `${searchQuizURL}?quizName=${key}`;
            return await apiService.postReqs({
                APIUrl: urlWithKey,
                payload: {},
                resType: FetchAllQuizAPIResult,
            });
        }
    }

    // add new Quizzes
    addNewQuiz = async (reqData: QuizReqType) => {
        if (reqData) {
            return await apiService.postReqs({
                APIUrl: addNewQuizURL,
                payload: reqData,
                resType: AddNewQuizAPIResult,
            });
        }
    }

    // edit Quiz
    editQuiz = async (reqData: QuizReqType, quizId: string) => {
        const urlWithKey = `${editQuizURL}?quizId=${quizId}`;
        if (reqData) {
            return await apiService.patchReqs({
                APIUrl: urlWithKey,
                payload: reqData,
                resType: EditQuizAPIResult,
            });
        }
    }
}
