import { EarningReqType, EarningType } from "../../Utils/types";
import APIService from "../API";
import { BaseUrl } from "../axios.config";
import { NetworkError, Payload } from '../Error.type';

const fetchEarningsURL: string = `${BaseUrl}/myEarnings`;
const apiService = new APIService();

type EarningsResponseType = {
    items: Array<EarningType>;
    earned: number;
    pendingSettlement: number;
}

export class FetchEarningsAPIResult
    implements Payload<EarningsResponseType, NetworkError>
{
    statusCode: number;
    data?: EarningsResponseType;
    errorMsg?: NetworkError;

    constructor(
        statusCode: number,
        data?: EarningsResponseType,
        errorMsg?: NetworkError
    ) {
        this.statusCode = statusCode;
        this.data = data;
        this.errorMsg = errorMsg;
    }
}

export default class TrainerEarningsAPIService {
    fetchTrainerEarnings = async ({ startDate, endDate }: EarningReqType) => {
        let payload = {};
        if (startDate && endDate) {
            payload = { startDate, endDate };
        }
        return await apiService.getReqWithParams({
            APIUrl: fetchEarningsURL,
            payload,
            resType: FetchEarningsAPIResult,
        });
    };
}


