const SetGoalBannerImage =
  "https://her-springboard.s3.ap-south-1.amazonaws.com/Bitmap.png";
const GoogleLogo =
  "https://her-springboard.s3.ap-south-1.amazonaws.com/google.png";
const FbLogo =
  "https://her-springboard.s3.ap-south-1.amazonaws.com/facebook.png";
const LinkedInLogo =
  "https://her-springboard.s3.ap-south-1.amazonaws.com/linkedin.png";
const SignUpBannerImage =
  "https://her-springboard.s3.ap-south-1.amazonaws.com/pic.png";
const Youtube = "https://her-springboard.s3.ap-south-1.amazonaws.com/utube.png";
const Logo = "https://her-springboard.s3.ap-south-1.amazonaws.com/logo.png";
const Menu = "https://her-springboard.s3.ap-south-1.amazonaws.com/menu.png";
const FallBackLogoOurspringboard =
  "https://her-springboard.s3.ap-south-1.amazonaws.com/logo/Her+Spring+Board+Final.png";
const FallBackLogoHerspringboard =
  "https://her-springboard.s3.ap-south-1.amazonaws.com/logo/Her+Spring+Board+Final+CS5-01.png";
export class Assets {
  static get SetGoalBannerImage() {
    return SetGoalBannerImage;
  }
  static get GoogleLogo() {
    return GoogleLogo;
  }
  static get FbLogo() {
    return FbLogo;
  }
  static get LinkedInLogo() {
    return LinkedInLogo;
  }
  static get SignUpBannerImage() {
    return SignUpBannerImage;
  }
  static get Youtube() {
    return Youtube;
  }
  static get Logo() {
    return Logo;
  }
  static get FallBackLogoOurspringboard() {
    return FallBackLogoOurspringboard;
  }
  static get FallBackLogoHerspringboard() {
    return FallBackLogoHerspringboard;
  }
  static get Menu() {
    return Menu;
  }
}
