import { CourseListType } from '../action/courseExplorerAction';
import { courseExplorerTypes } from '../types/index';

const { COURSE_EXPLORER_TYPE } = courseExplorerTypes;

type CourseExplorerType = {
    courseExplorerList: Array<CourseListType>,
    totalCourses: number
}

const intitalState = {
    courseExplorerList: [],
    totalCourses: 0,
}

export default function courseExplorerReducer(state: CourseExplorerType = intitalState, action: { type: string, payload: any }) {
    const { type, payload } = action;
    switch (type) {
        case COURSE_EXPLORER_TYPE:
            return {
                ...state,
                courseExplorerList: payload.data,
                totalCourses: payload.totalCourses
            }
        default:
            return state;
    }
}