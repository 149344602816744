export class Theme {
  static get pinkMode(): ThemeProps {
    return {
      title: "#4d0c61",
      footer: "#4d0c61",
      scroll: "#926481",
      button: {
        background: "#4d0c61",
        text: "#ffffff",
      },
      Menu: {
        background: "#ffebfc",
        select: "#4d0c61",
        block1: "#7a787a",
        text: "#1f2937",
      },
      Submenu: {
        background: "#fce6f4",
        text: "black",
      },
      CourseCard: {
        background: "#f9f7f7",
      },
      dashboardStudent: {
        tile1: "#fce6f4",
        tile2: "#fad7ed",
        tile3: "#fcc2d4",
        text: "#4d0c61",
        block1: "#FCF4DA",
        block2: "#e4c3fa",
        block1Text: "#856404",
      },
      dashboardTrainer: {
        tile1: "#fce6f4",
        tile2: "#fad7ed",
        tile3: "#fcc2d4",
        tile4: "#fae1f6",
        tile5: "#efefef",
        text: "#4d0c61",
        block1: "#FCF4DA",
        block2: "#e4c3fa",
        block1Text: "#856404",
      },
      continueLearning: {
        background: "#e4c3fa",
      },
      enrollment: {
        background: "#e4c3fa",
      },
      resources: {
        icon: "#ffb8ed",
        text: "#4d0c61",
      },
      viewEditButton: "#86329c",
    };
  }

  static get blueMode(): ThemeProps {
    return {
      title: "#0B4089",
      footer: "#0B4089",
      scroll: "#0b2364",
      button: {
        background: "#0B4089",
        text: "#ffffff",
      },
      Menu: {
        background: "#e0e7ff",
        select: "#0B4089",
        block1: "#7a787a",
      },
      Submenu: {
        background: "#e0e7ff",
        text: "black",
      },
      CourseCard: {
        background: "#f9f7f7",
      },
      dashboardStudent: {
        tile1: "#cff2fa",
        tile2: "#D1D5DB",
        tile3: "#dbeafe",
        text: "#0B4089",
        block1: "#FCF4DA",
        block2: "#cce5ff",
        block1Text: "#856404",
      },
      dashboardTrainer: {
        tile1: "#cff2fa",
        tile2: "#D1D5DB",
        tile3: "#dbeafe",
        tile4: "#efefef",
        tile5: "#cff2fa",
        text: "#0B4089",
        block1: "#FCF4DA",
        block2: "#cce5ff",
        block1Text: "#856404",
      },
      continueLearning: {
        background: "#a1a6d4",
      },
      enrollment: {
        background: "#a1a6d4",
      },
      resources: {
        icon: "#9ca3af",
        text: "#0b4089",
      },
      viewEditButton: "#3b82f6",
    };
  }
}

export type ThemeProps = {
  title: string;
  viewEditButton: string;
  footer: string;
  scroll: string;
  button: CourseThemeProps;
  Menu: CourseThemeProps;
  Submenu: CourseThemeProps;
  CourseCard: CourseThemeProps;
  continueLearning: CourseThemeProps;
  resources: CourseThemeProps;
  dashboardStudent: CourseThemeProps;
  dashboardTrainer: CourseThemeProps;
  enrollment: CourseThemeProps;
};

export type CourseThemeProps = {
  background?: string;
  select?: string;
  primary?: string;
  secondary?: string;
  tertiary?: string;
  text?: string;
  icon?: string;
  tile1?: string;
  tile2?: string;
  tile3?: string;
  tile4?: string;
  tile5?: string;
  block1?: string;
  block2?: string;
  block3?: string;
  block1Text?: string;
  light?: string;
  dash_tail?: string;
  dash_course_tail?: string;
  dash_trainer_tail?: string;
  course_tail?: string;
  sample?: string;
  dash_tail_trainer?: string;
};
