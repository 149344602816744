import { useEffect, useState } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import CartAPIService, { CartAPIResult } from "../../Api/Cart/CartAPIService";
import CourseDetailAPIService from "../../Api/CourseDetail/CourseDetailAPIService";
import LessonsAPIService from "../../Api/Lessons/LessonsAPIService";
import RatingImg from "../../Assets/rating.png";
import NoCourse from "../../Components/Common/NoCourse";
import Menu from "../../Components/Template/Menu";
import Template from "../../Components/Template/Template";
import {
  ErrorMsgToaster,
  SuccessMsgToaster,
} from "../../Components/Utils/Toaster";
import ThemeContext from "../../Context/ThemeContext";
import { CartAction } from "../../redux/action";
import "./CourseDetail.css";
import {
  authorDetailsType,
  courseDetailType,
  courseIncludesType,
  reviewsType,
} from "./CourseDetail.types";
import axios from "axios";
import { ApplicationJsonConfigHeader, BaseUrl } from "../../Api/axios.config";

const getOrderURL: string = `${BaseUrl}/cart/checkout`;
const getByPassURL: string = `${BaseUrl}/bypassPayment`;

const CourseDetail = (props: any) => {
  const history = useHistory();
  const cartAPIService = new CartAPIService();
  const courseDetailAPIService = new CourseDetailAPIService();

  const [courseTitle, setCourseTitle] = useState("");
  const [courseImg, setCourseImg] = useState("");
  const [preRequirements, setPreRequirements] = useState<Array<string>>([]);
  const [lessonsList, setLessionsList] = useState<string[]>([]);
  const [whatYouLearn, setWhatYouLearn] = useState<string[]>([]);
  const [whatWeCoverInCourse, setWhatWeCoverInCourse] = useState<string[]>([]);
  // const [coverInCourse, setCoverInCourse] = useState<string[]>([]);
  const [courseInfo, setCourseInfo] = useState<courseIncludesType>();
  const [authorDetails, setAuthorDetails] = useState<authorDetailsType>();
  const [studentReviews, setStudentReviews] = useState<Array<reviewsType>>([]);
  const [cousreCost, setCourseCost] = useState<string>("");
  const [isInCart, setIsInCart] = useState(false);
  const [reviewCount, setReviewCount] = useState<number>(4);
  const [isEnrolled, setIsEnrolled] = useState<boolean>(false);
  const [currCourseId, setCurrCourseId] = useState<string>("");
  const [courseHours, setCourseHours] = useState<string>("");
  const [userId, setUserId] = useState<string>("");
  const [courseContent, setCourseContent] = useState<Array<courseData>>();
  const [cartOrderId, setcartOrderId] = useState("");
  const [byPassPayment, setbyPassPayment] = useState(false);

  type courseData = {
    topicId?: string;
    lessonId?: string;
    quizId?: string;
    itemType: string;
    id: string;
    title: string;
    result?: string;
    correctQuestionsCount?: string;
    score?: number;
    content?: Array<courseData>;
  };

  useEffect(() => {
    const paramCourseId = props.match.params.courseId;
    setCurrCourseId(paramCourseId);
    async function fetchCartCourses() {
      const courseListData = await cartAPIService.fetchCourseInCartList();
      courseListData?.data && props.updateCoursesInCart(courseListData?.data);
      if (!paramCourseId) {
        history.push("/courses");
      }
      if (courseListData.data?.cart) {
        const { cart } = courseListData?.data;
        cart?.forEach((rec: { courseId: string }) => {
          setIsInCart(rec.courseId === paramCourseId);
        });
      }
    }

    fetchCartCourses();

    // Fetch Course Details through API
    async function fetchCourseDetails() {
      const resData = await courseDetailAPIService.fetchCourseDetail(
        paramCourseId || ""
      );
      if (resData?.data) {
        const courseData: courseDetailType = resData?.data;
        if (courseData) {
          const {
            title,
            image,
            courseCurriculum,
            // reviews,
            trainer,
            requirements,
            whatYouLearn,
            whatWeCoverInCourse,
            cost,
            topics,
            quizzes,
            estimatedDuration,
            userId,
            rating,
            enrolled,
          } = courseData;
          let courseIncludes: courseIncludesType = {
            topics: topics,
            quizzes: quizzes,
            rating: rating,
            enrolled: enrolled,
          };
          setUserId(userId);
          setCourseTitle(title);
          setCourseImg(image);
          setLessionsList(courseCurriculum);
          setCourseInfo(courseIncludes);
          setAuthorDetails(trainer);
          setCourseCost(cost);
          setCourseHours(estimatedDuration);
          setPreRequirements(
            typeof requirements === "string" ? [requirements] : requirements
          );
          setWhatYouLearn(
            typeof whatYouLearn === "string" ? [whatYouLearn] : whatYouLearn
          );
          setWhatWeCoverInCourse(
            typeof whatWeCoverInCourse === "string"
              ? [whatWeCoverInCourse]
              : whatWeCoverInCourse
          );
        }
        // const sdata = studentReviews.map((rec, index) => {
        //   return { ...rec, id: index + 1 };
        // });
      }
    }
    fetchCourseDetails();

    async function fetchCourseReviews() {
      await courseDetailAPIService
        .fetchCourseReviews(paramCourseId)
        .then(({ data, errorMsg }) => {
          if (data) {
            const reviewsFetched: any[] = data.reviews;
            reviewsFetched?.slice(0, reviewCount);
            setStudentReviews(reviewsFetched);
          }
        })
        .catch((err) => console.log(err));
    }

    fetchCourseReviews();

    async function checkIsEnrolled() {
      await courseDetailAPIService
        .checkIfEnrolled(paramCourseId)
        .then(({ data, errorMsg }) => {
          if (data) {
            setIsEnrolled(data.isEnrolled);
            setbyPassPayment(data.isBypassPayment)
          }
        })
        .catch((err) => console.log(err));
    }

    const lessonsAPIService = new LessonsAPIService();
    const courseId = props.match.params.courseId;

    lessonsAPIService
      .getCourseContent({ courseId })
      .then((resData) => {
        if (resData?.data) {
          let accordionData: Array<courseData> = [];
          let temp: Array<courseData> = [];
          resData?.data?.contents?.forEach(
            (item: courseData, index: number) => {
              if (item.itemType === "Lesson") {
                if (accordionData.length > 0) {
                  accordionData[accordionData.length - 1].content = temp;
                  accordionData.push(item);
                } else {
                  accordionData.push(item);
                }
                temp = [];
              } else {
                temp.push(item);
              }
              if (index === resData?.data?.contents?.length - 1)
                if (accordionData.length > 0)
                  accordionData[accordionData.length - 1].content = temp;
            }
          );
          setCourseContent(accordionData);
        }
      })
      .catch((err) => ErrorMsgToaster(err));

    checkIsEnrolled();
  }, []);

  const byPassShowMessage = async () => {
    const token: string | null = localStorage.getItem("token");
    const courseId = props.match.params.courseId;
    const response = await axios
    .post(
      getByPassURL,
      { courseId },
      {
        headers: {
          ...ApplicationJsonConfigHeader,
          authorization: `Bearer ${token}`,
        },
        responseType: "json",
      }
    )
    .then((response) => {
      SuccessMsgToaster(response.data)
      window.location.replace("/myCourses");
    })
    .catch((error) => {
      ErrorMsgToaster(error);
    });
  }

  const addCourseCount = async () => {
    const courseDetails = [{
      courseId: props.match.params.courseId,
      title: courseTitle,
      price: Number(cousreCost),
      trainerId: userId
    }]
    const token: string | null = localStorage.getItem("token");
    if (byPassPayment) {
      byPassShowMessage()
    } else {
      const response = await axios
      .post(
        getOrderURL,
        { cart: courseDetails },
        {
          headers: {
            ...ApplicationJsonConfigHeader,
            authorization: `Bearer ${token}`,
          },
          responseType: "json",
        }
      )
      .then((response) => {
        const cartOrderId = response.data.orderId;
        if (cartOrderId) {
          localStorage.setItem("orderId", cartOrderId);
        }
        console.log("orderid", cartOrderId);
        setcartOrderId(cartOrderId);
      })
      .catch((error) => {
        console.log("carttocheckout", error);
      });
    }
  };

  const handleReadAllReviews = async () => {
    setReviewCount(reviewCount + 4);
    const courseId = props.match.params.courseId;
    await courseDetailAPIService
      .fetchCourseReviews(courseId)
      .then(({ data, errorMsg }) => {
        if (data) {
          const reviewsFetched: any[] = data.reviews;
          reviewsFetched?.slice(0, reviewCount);
          setStudentReviews(reviewsFetched);
        }
      })
      .catch((err) => console.log(err));
  };

  // To add courses to cart - API and update store
  const addCourseToCart = async () => {
    const addCartResponse: CartAPIResult = await cartAPIService.addDataToCart({
      courseId: props.match.params.courseId,
      title: courseTitle,
      price: Number(cousreCost),
      userId: userId,
    });

    if (addCartResponse.errorMsg !== undefined) {
      ErrorMsgToaster(addCartResponse.errorMsg!);
    } else {
      SuccessMsgToaster(addCartResponse.data!);
      const courseListData = await cartAPIService.fetchCourseInCartList();
      props.updateCoursesInCart(courseListData?.data);
      setIsInCart(true);
    }
  };

  const CourseDetailsBody = () => {
    return (
      <ThemeContext.Consumer>
        {(context) => {
          return (
            <>
              <div className={`-mt-8`}>
                <h4
                  className={`text-2xl font-bold`}
                  style={{ color: context.theme!.title }}
                >
                  {courseTitle}
                </h4>
                <div
                  className={`h-96 w-full mt-4 mr-3 flex-auto content-center justify-center bg-gray-300`}
                >
                  <img
                    className={`w-full h-full object-cover`}
                    src={courseImg}
                    alt={courseTitle}
                  ></img>
                </div>
                <p className="CoursedetailHeading"> What You’ll Learn </p>
                <div className={`mt-3 whitespace-pre-wrap`}>
                  {whatYouLearn.map((rec) => {
                    return <div key={rec}>{rec}</div>;
                  })}
                </div>
                <p className="CoursedetailHeading"> Requirements </p>
                <div className={`mt-3 whitespace-pre-wrap`}>
                  {preRequirements &&
                    preRequirements.map((rec) => {
                      return <div key={rec}>{rec}</div>;
                    })}
                </div>
                <p className="CoursedetailHeading">
                  
                  What we cover in this course:
                </p>
                <div className={`mt-3 whitespace-pre-wrap`}>
                  {whatWeCoverInCourse.map((rec) => {
                    return (
                      <div key={rec} className={`mt-4`}>
                        {rec}
                      </div>
                    );
                  })}
                </div>
                <p className="CoursedetailHeading"> Course Curriculum </p>
                <CourseAccordion />
                <div className={`mt-3`}>
                  {lessonsList?.map((rec) => {
                    return (
                      <h4
                        key={rec}
                        className={`p-2 mb-2 bg-gray-200`}
                        style={{ border: "1px solid #979797" }}
                      >
                        {rec}
                      </h4>
                    );
                  })}
                </div>
              </div>
            </>
          );
        }}
      </ThemeContext.Consumer>
    );
  };

  const CourseDetailSubMenu = () => {
    return (
      <>
        <div>
          <div>{<RenderCouseInfo courseInfo={courseInfo} />}</div>
          <div>{RenderAuthorInfo()}</div>
          <div>{RenderReviewInfo()}</div>
        </div>
      </>
    );
  };

  const RenderCouseInfo = ({
    courseInfo,
  }: {
    courseInfo: courseIncludesType | undefined;
  }) => {
    const { topics, quizzes, certificate, enrolled, rating } = courseInfo || {};
    return (
      <div className="courseInfo">
        <div className={`h-48 w-100 bg-gray-300`}>
          <img
            className={`h-full w-full object-cover`}
            src={courseImg}
            alt={`Course`}
          ></img>
        </div>
        <div
          className={` text-sm sm:text-base md:text-lg font-bold mt-2`}
          style={{ color: "#0B4089" }}
        >
          Course Includes
        </div>
        <div className={`text-xs sm:text-sm md:text-base`}>
          <h4>{`${courseHours || "0"} hours video`}</h4>
          <h4>{`${topics || "0"} Topics`}</h4>
          <h4>{`${quizzes || "0"} Quizzes`}</h4>
          <h4>{certificate ? `Certificate` : `No Certificate`}</h4>
          <h4 className={`font-bold`} style={{ color: "#0B4089" }}>{`${
            enrolled || "0"
          } Enrolled`}</h4>
          <div className={`flex flex-auto`}>
            {rating && (
              <img
                className={`h-6 w-6 mr-2`}
                src={RatingImg}
                alt={`Rating`}
              ></img>
            )}
            <h4>{rating ? `${rating} Rating` : ` No Ratings`}</h4>
          </div>
          {!isEnrolled && (
            <div className={`mt-2 flex flex-auto`}>
              <h1 className={`text-lg text-black`}>Course Price : </h1>
              <h1 className={`text-xl text-black ml-4`}>
                {Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "INR",
                }).format(
                  Number.isNaN(Number(cousreCost)) ? 0 : Number(cousreCost)
                )}
              </h1>
            </div>
          )}
        </div>
        {!isEnrolled && (
          <div className="coursedetailEnroll  items-center">
            {byPassPayment && (
            <button
              className={`rounded-md md:text-2xl sm:text-base text-xs text-center text-white p-2 flex-shrink pr-4 pl-4 md:w-full w-11/12 ml-1  mt-4`}
              style={{ backgroundColor: "#0B8979" }}
              onClick={addCourseCount}
            >
              ENROLL NOW
            </button>
            )
            }

            {!byPassPayment && (<Link
                to={{
                  pathname: "/checkout",
                  state: {
                    page: 'enroll',
                    cartOrderId
                  },
                }}
              >
            <button
              className={`rounded-md md:text-2xl sm:text-base text-xs text-center text-white p-2 flex-shrink pr-4 pl-4 md:w-full w-11/12 ml-1  mt-4`}
              style={{ backgroundColor: "#0B8979" }}
              onClick={addCourseCount}
            >
              ENROLL NOW
            </button>
            </Link>)
            }
            
            {!isInCart && (
              <button
                className={`rounded-md md:text-2xl sm:text-base text-xs text-center text-white p-2 flex-shrink pr-4 pl-4 md:w-full w-11/12 ml-1 mt-4`}
                style={{ backgroundColor: "#0B4089" , opacity : byPassPayment ? '50%' : '100%', cursor: byPassPayment ? 'not-allowed' : 'pointer'}}
                onClick={addCourseToCart}
                disabled={byPassPayment}
              >
                ADD TO CART
              </button>
            )}
            {isInCart && (
              <h4
                className={`font-bold text-yellow-600 mt-4 p-1 border-2 rounded-md flex items-center justify-center border-red-400`}
              >
                Course In Cart
              </h4>
            )}
          </div>
        )}
        {isEnrolled && (
          <div>
            <Link to={`/lesson/${currCourseId}`}>
              <button
                className={`rounded-md md:text-2xl sm:text-base text-xs text-center text-white p-2 flex-shrink pr-4 pl-4 md:w-full w-11/12 ml-1  mt-4`}
                style={{ backgroundColor: "#0B8979" }}
              >
                VIEW COURSE
              </button>
            </Link>
            <div className={`text-xl text-black flex justify-center`}>
              Course already purchased
            </div>
          </div>
        )}
      </div>
    );
  };

  const RenderAuthorInfo = () => {
    if (authorDetails) {
      const { image, name, rating } = authorDetails;
      return (
        <div className={`mt-4 grid grid-rows-1 grid-cols-3`}>
          <img
            className={`h-36 w-36 rounded-md bg-black object-contain`}
            src={image}
            alt={name}
          ></img>
          <div className={`ml-2 col-span-2`}>
            <h4 className={`text-xl font-bold`}>{`${name}`}</h4>
            <div className={`flex flex-auto`}>
              <img className={`h-6 w-6`} src={RatingImg} alt={`Rating`}></img>
              <h4 className={`ml-2`}>{rating}</h4>
            </div>
          </div>
        </div>
      );
    }
  };

  const RenderReviewInfo = () => {
    const totalReview = studentReviews && studentReviews.length;
    return (
      <div className={`mt-4`}>
        <h4 className={`text-2xl font-bold`}>{`Reviews (${totalReview})`}</h4>
        <div className={`mt-8`}>
          {studentReviews?.map((rec: reviewsType, index: number) => {
            const { name, feedback, rating } = rec;
            return (
              <div className={`mb-4`} key={index}>
                <div className="studentProfileImg">
                  <div
                    className={`w-20 h-20 rounded-full`}
                    style={{
                      background: "#6B6B6B",
                    }}
                  ></div>
                  <div className="grid grid-flow-row">
                    <div>
                      <h4
                        className={`text-lg font-bold col-span-2 ml-4 place-self-center`}
                      >
                        {name}
                      </h4>
                    </div>
                    <div>
                      <div className={`flex flex-auto ml-4 mt-2`}>
                        <img
                          className={`h-6 w-6`}
                          src={RatingImg}
                          alt={`Rating`}
                        ></img>
                        <h4 className={`ml-2`}>{rating || 0}</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`ml-6 italic font-normal`}>{feedback}</div>
              </div>
            );
          })}
        </div>
        <div>
          {studentReviews && studentReviews.length > 4 && (
            <h4
              className="readAllReviews"
              onClick={() => handleReadAllReviews()}
            >
              Read More Reviews
            </h4>
          )}
        </div>
      </div>
    );
  };

  const CourseAccordion = () => {
    return (
      <>
        <div
          style={{
            fontSize: "16px",
            lineHeight: "24px",
          }}
        >
          <Accordion
            allowMultipleExpanded
            allowZeroExpanded
            className={`border-0`}
          >
            {courseContent?.map((rec, index) => {
              return (
                <AccordionItem
                  className={`rounded-3xl m-2 h-full `}
                  key={index}
                >
                  <AccordionItemHeading
                    className={`border-2 border-black font-semibold`}
                  >
                    <AccordionItemButton>
                      <span className="w-full">
                        {`Lesson ${index + 1} : ${rec?.title}`}
                      </span>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel className={`mt-2`}>
                    {rec?.content?.map(
                      (topicOrQuiz: courseData, index: number) => (
                        <div
                          className={`flex p-2 m-2 border-2 rounded-lg border-black font-semibold ${
                            (topicOrQuiz?.result === "Fail" && "bg-red-200") ||
                            (topicOrQuiz?.result === "Pass" && "bg-green-100")
                          }`}
                        >
                          {topicOrQuiz?.itemType?.toLowerCase() === "topic" && (
                            <i className={`material-icons mr-2`}>play_lesson</i>
                          )}
                          {topicOrQuiz?.itemType?.toLowerCase() === "quiz" && (
                            <i className={`material-icons mr-2`}>
                              help_outline
                            </i>
                          )}
                          {topicOrQuiz.title}
                          {topicOrQuiz?.result && (
                            <div className={`flex flex-row ml-8`}>
                              <h3>Score : </h3>
                              {topicOrQuiz?.score} /
                              {` ${topicOrQuiz?.result.toUpperCase()}`}
                            </div>
                          )}
                        </div>
                      )
                    )}
                    {!rec?.content?.length && <NoCourse title="Nothing Here" />}
                  </AccordionItemPanel>
                </AccordionItem>
              );
            })}
          </Accordion>
        </div>
      </>
    );
  };

  return (
    <>
      <Template
        menu={<Menu selectedItem={""} />}
        body={<CourseDetailsBody />}
        submenu={<CourseDetailSubMenu />}
      />
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    cartData: state && state.myCartReducer,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateCoursesInCart: (data: { cart: []; totalAmount: number }) => {
      dispatch(CartAction(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CourseDetail);