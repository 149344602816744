import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import QuestionsAPIService from "../../Api/Questions/QuestionsAPIService";
import ButtonReuse from "../../Components/Common/ButtonReuse";
import InputFull from "../../Components/Common/InuptFull";
import NoCourse from "../../Components/Common/NoCourse";
import SubmenuTrainer from "../../Components/Common/SubMenuTrainer";
import MenuTrainer from "../../Components/Template/MenuTrainer";
import Template from "../../Components/Template/Template";
import { ErrorMsgToaster } from "../../Components/Utils/Toaster";
import ThemeContext from "../../Context/ThemeContext";
import { QuestionsListType } from "../../Utils/types";
// import TrainerTile from "../../Components/Common/TrainerTile";
// import ViewAddButton from "../../Components/Common/ViewAddButton";
// import CourseTail from "../../Components/Template/CourseTail";

const RenderQuestionsList = ({
  questions,
}: {
  questions: Array<QuestionsListType>;
}) => {
  return (
    <ThemeContext.Consumer>
      {(context) => {
        return (
          <div>
            {questions &&
              questions.map((rec) => {
                return (
                  <div
                    key={rec.questionId}
                    className={` mt-5 w-12/12 h-11 mr-6 pt-2 rounded-md border-opacity-0`}
                    style={{
                      backgroundColor: "#F7EDE6",
                      borderColor: "grey",
                      border: "solid 0.5px",
                    }}
                  >
                    <div className={`flex flex-auto justify-between`}>
                      <label
                        className={` text-base mt-0 ml-3 mr-3 flex justify-start font-semibold h-6 overflow-hidden `}
                        style={{ color: context.theme!.title }}
                        title={rec.question}
                      >
                        {rec.question}
                      </label>
                      <Link
                        to={`/trainer/questions/edit/${rec.questionId}`}
                        className={`h-auto`}
                      >
                        <button
                          className={`p-1 px-2 border rounded-md text-white font-semibold -mt-1 mr-4`}
                          style={{
                            backgroundColor: context.theme!.viewEditButton,
                          }}
                        >
                          EDIT
                        </button>
                      </Link>
                    </div>
                  </div>
                );
              })}
            {(!questions || !questions.length) && (
              <div className={`mt-32`}>
                <NoCourse title={`No Questions Available`} icon="school" />
              </div>
            )}
          </div>
        );
      }}
    </ThemeContext.Consumer>
  );
};

const QuestionTrainerBody = () => {
  const [filterKey, setFilterKey] = useState("");
  const [originalQuestionsList, setOriginalQuestionsList] = useState<
    Array<QuestionsListType>
  >([]);
  const [questionsList, setQuestionsList] = useState<Array<QuestionsListType>>(
    []
  );

  const filterQuestions = (key: any) => {
    const questionsAPIService = new QuestionsAPIService();
    if (key) {
      questionsAPIService
        .searchQuestions(key)
        .then((resData) => {
          if (resData && resData.data) {
            setQuestionsList(resData.data);
          } else {
            setQuestionsList([]);
          }
        })
        .catch((err) => console.log(err));
    } else {
      setQuestionsList(originalQuestionsList);
    }
  };

  useEffect(() => {
    setFilterKey("");
    const questionsAPIService = new QuestionsAPIService();
    questionsAPIService
      .fetchAllQuestions()
      .then(({ data, errorMsg }) => {
        if (data) {
          setQuestionsList(data);
          setOriginalQuestionsList(data);
        } else if (errorMsg) {
          ErrorMsgToaster(errorMsg);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => filterQuestions(filterKey), [filterKey]);

  return (
    <>
      <div className={`flow-root`}>
        <div
          className={`text-xl md:text-2xl font-bold float-left`}
          style={{ color: "#0B4089" }}
        >
          Questions
        </div>
        <Link to="/trainer/questions/new">
          <div className={`float-right md:mr-6 cursor-pointer`}>
            <ButtonReuse
              value={"ADD NEW QUESTION"}
              colorClass={"bg-yellow-200"}
              heightClass={"h-8"}
              widthClass={"w-44"}
              sizeClass={"text-sm"}
              positionClass={"float-right"}
              tcolorClass={"text-black"}
            ></ButtonReuse>
          </div>
        </Link>
      </div>
      <div className={`mt-4 md:mr-6`}>
        <InputFull
          type="text"
          placeHolder="Search Question"
          onChange={(e): void => setFilterKey(e.target.value)}
          label={""}
        ></InputFull>
      </div>

      <RenderQuestionsList questions={questionsList} />
    </>
  );
};

const SubMenu = () => {
  return (
    <>
      <SubmenuTrainer />
    </>
  );
};

const QuestionTrainer = () => {
  const menu = <MenuTrainer selectedItem={`QUESTION BANK`} />;
  return (
    <Template
      menu={menu}
      body={<QuestionTrainerBody />}
      submenu={<SubMenu />}
    />
  );
};

export default QuestionTrainer;
