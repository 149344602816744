type props = {
  type?: "button" | "submit" | "reset" | undefined;
  heightClass?: String;
  widthClass?: String;
  sizeClass?: String;
  value?: String;
  mginClass?: String;
  colorClass?: String;
  positionClass?: String;
  tcolorClass?: String;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

const ButtonReuse = ({
  type,
  heightClass,
  sizeClass,
  widthClass,
  value,
  mginClass,
  colorClass,
  positionClass,
  tcolorClass,
  onClick,
  disabled,
}: props) => {
  const buttonType = type ? type : "button";
  return (
    <>
      <button
        type={buttonType}
        className={`${colorClass || " bg-lp_blue"} ${
          tcolorClass || "text-white"
        } ${
          heightClass || "h-10"
        } rounded-md font-Lato font-bold place-self-end  ${widthClass} ${sizeClass} ${mginClass} ${colorClass} ${positionClass}`}
        onClick={onClick}
        disabled={disabled}
      >
        {value}
      </button>
    </>
  );
};

export default ButtonReuse;
