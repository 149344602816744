import Menu from "../../Components/Template/Menu";
import Template from "../../Components/Template/Template";
import TrendingCourse from "../../Components/Template/TrendingCourse";
import ThemeContext from "../../Context/ThemeContext";
import { ThemeProps } from "../../Themes/Themes";
import ResourceBody from "./ResourceBody";

const ResourceSubMenu = ({ theme }: { theme: ThemeProps }) => {
  return (
    <div>
      <div className={`font-bold text-2xl mt-9 mb-6`}>Trending this Week</div>
      <TrendingCourse theme={theme} />
    </div>
  );
};

const Resources = () => {
  return (
    <ThemeContext.Consumer>
      {(context) => {
        return (
          <Template
            menu={<Menu selectedItem={"RESOURCES"} />}
            body={<ResourceBody theme={context.theme!} />}
            submenu={<ResourceSubMenu theme={context.theme!} />}
          />
        );
      }}
    </ThemeContext.Consumer>
  );
};

export default Resources;
