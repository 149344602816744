import { QuestionDetailsResType, QuestionsListType } from "../../Utils/types";
import APIService from "../API";
import { BaseUrl } from "../axios.config";
import { NetworkError, Payload } from "../Error.type";

const fetchAllQuestionsURL: string = `${BaseUrl}/trainer/getQuestion`;
const searchQuestionsURL: string = `${BaseUrl}/trainer/searchQuestion`;
const addNewQuestionsURL: string = `${BaseUrl}/trainer/createQuestion`;
const editQuestionsURL: string = `${BaseUrl}/trainer/editQuestion`;
const apiService = new APIService();

export type QuestionsResType = {
    data?: any;
    statusCode: number;
};

type AddNewQuestionResType = {
    data?: { data: string, quizId: string };
};

export class FetchAllQuestionsAPIResult
    implements Payload<Array<QuestionsListType>, NetworkError>
{
    statusCode: number;
    data?: Array<QuestionsListType>;
    errorMsg?: NetworkError;

    constructor(
        statusCode: number,
        data?: Array<QuestionsListType>,
        errorMsg?: NetworkError
    ) {
        this.statusCode = statusCode;
        this.data = data;
        this.errorMsg = errorMsg;
    }
}

export class AddNewQuestionAPIResult
    implements Payload<AddNewQuestionResType, NetworkError>
{
    statusCode: number;
    data?: AddNewQuestionResType;
    errorMsg?: NetworkError;

    constructor(
        statusCode: number,
        data?: AddNewQuestionResType,
        errorMsg?: NetworkError
    ) {
        this.statusCode = statusCode;
        this.data = data;
        this.errorMsg = errorMsg;
    }
}

export class FetchQuestionDetailsResult implements Payload<QuestionDetailsResType, NetworkError> {
    statusCode: number;
    data?: QuestionDetailsResType;
    errorMsg?: NetworkError;

    constructor(
        statusCode: number,
        data?: QuestionDetailsResType,
        errorMsg?: NetworkError
    ) {
        this.statusCode = statusCode;
        this.data = data;
        this.errorMsg = errorMsg;
    }
}

export default class QuestionsAPIService {

    // Fetch Questions list
    fetchAllQuestions = async () => {
        return await apiService.getReqs({
            APIUrl: fetchAllQuestionsURL,
            payload: {},
            resType: FetchAllQuestionsAPIResult,
        });
    };

    // Fetch Question Details
    fetchQuestionDetail = async (questionId: string) => {
        if (questionId) {
            const payload = { questionId };
            return await apiService.getReqWithParams({
                APIUrl: fetchAllQuestionsURL,
                payload,
                resType: FetchQuestionDetailsResult,
            });
        }
    };

    // search questions
    searchQuestions = async (key: string) => {
        if (key) {
            const urlWithKey = `${searchQuestionsURL}?questionName=${key}`;
            return await apiService.postReqs({
                APIUrl: urlWithKey,
                payload: {},
                resType: FetchAllQuestionsAPIResult,
            });
        }
    }

    // post questions 
    addNewQuestions = async (reqData: FormData) => {
        return await apiService.postReqsWithFiles({
            APIUrl: addNewQuestionsURL,
            payload: reqData,
            resType: AddNewQuestionAPIResult
        });
    }

    //edit questions
    editQuestions = async (reqData: FormData, questionId: string) => {
        const editURL: string = `${editQuestionsURL}?questionId=${questionId}`
        return await apiService.patchReqsWithFiles({
            APIUrl: editURL,
            payload: reqData,
            resType: AddNewQuestionAPIResult
        })
    }
}
