import { CourseRatingType } from "../../Utils/types";
import APIService from "../API";
import { BaseUrl } from "../axios.config";
import { NetworkError, Payload } from "../Error.type";

const fetchCoursesRatingURL: string = `${BaseUrl}/courseRating`;
const fetchCoursesListURL: string = `${BaseUrl}/getCoursesList`;
const apiService = new APIService();

export type courseListDetailType = {
    courseId: string;
    title: string;
}

export type courseRatingResType = {
    data?: Array<CourseRatingType>;
    statusCode: number;
};

export type courseListResType = {
    data?: Array<courseListDetailType>;
    statusCode: number;
};


export class CourseRatingAPIResult
    implements Payload<courseRatingResType, NetworkError>
{
    statusCode: number;
    data?: courseRatingResType;
    errorMsg?: NetworkError;

    constructor(
        statusCode: number,
        data?: courseRatingResType,
        errorMsg?: NetworkError
    ) {
        this.statusCode = statusCode;
        this.data = data;
        this.errorMsg = errorMsg;
    }
}

export class CoursesListAPIResult
    implements Payload<courseListResType, NetworkError>
{
    statusCode: number;
    data?: courseListResType;
    errorMsg?: NetworkError;

    constructor(
        statusCode: number,
        data?: courseListResType,
        errorMsg?: NetworkError
    ) {
        this.statusCode = statusCode;
        this.data = data;
        this.errorMsg = errorMsg;
    }
}

export class CoursesRatingDetailAPIResult
    implements Payload<courseRatingResType, NetworkError>
{
    statusCode: number;
    data?: courseRatingResType;
    errorMsg?: NetworkError;

    constructor(
        statusCode: number,
        data?: courseRatingResType,
        errorMsg?: NetworkError
    ) {
        this.statusCode = statusCode;
        this.data = data;
        this.errorMsg = errorMsg;
    }
}

export default class CourseRatingAPIService {

    // Fetch courses rating list for Course rating page
    fetchAllCourseRatings = async () => {
        return await apiService.getReqs({
            APIUrl: fetchCoursesRatingURL,
            payload: {},
            resType: CourseRatingAPIResult,
        });
    };

    // Fetch course list for course rating page
    fetchCourseList = async () => {
        return await apiService.getReqs({
            APIUrl: fetchCoursesListURL,
            payload: {},
            resType: CoursesListAPIResult,
        });
    };

    // Fetch course details based on course ID
    fetchCourseRatingDetails = async (courseId: string) => {
        let payload = {};
        if (courseId) {
            payload = { ...payload, courseId };
        }
        return await apiService.getReqWithParams({
            APIUrl: fetchCoursesRatingURL,
            payload,
            resType: CourseRatingAPIResult,
        });
    }
}