import axios, { AxiosResponse } from "axios";
import { TrendingCourseType } from "../../Utils/types";
import APIService from "../API";
import { ApplicationJsonConfigHeader, BaseUrl } from "../axios.config";
import {
  AuthenticationError,
  GeneralError,
  NetworkError,
  Payload,
} from "../Error.type";
import { DashboardDetailData } from "./Dashboard.types";

const dashboardUrl: string = BaseUrl + "/dashboard/student";
const trendingCourseURL: string = `${BaseUrl}/trendingCourses`;

const apiService = new APIService();
export class DashboardResult
  implements Payload<DashboardDetailData, NetworkError>
{
  statusCode: number;
  data: DashboardDetailData;
  error?: NetworkError;

  constructor(
    statusCode: number,
    data: DashboardDetailData,
    error?: NetworkError
  ) {
    this.statusCode = statusCode;
    this.data = data;
    this.error = error;
  }
}
export class TrendingCourseResult
  implements Payload<TrendingCourseType, NetworkError>
{
  statusCode: number;
  data?: TrendingCourseType;
  error?: NetworkError;
  errorMsg?: NetworkError;

  constructor(
    statusCode: number,
    data: TrendingCourseType,
    error?: NetworkError,
    errorMsg?: NetworkError
  ) {
    this.statusCode = statusCode;
    this.data = data;
    this.error = error;
    this.errorMsg = errorMsg;
  }
}

export class DashboardService {
  getDashboard = async (): Promise<DashboardResult> => {
    // TODO: Remove token
    const token: string | null = localStorage.getItem("token");
    if (token !== null) {
      console.log(token !== null);

      const response: AxiosResponse = await axios.get(dashboardUrl, {
        headers: {
          ...ApplicationJsonConfigHeader,
          authorization: `Bearer ${token}`,
        },
        responseType: "json",
      });
      if (response.status === 200) {
        return { data: response.data, statusCode: response.status };
      } else {
        const error = new GeneralError(response.data.error, response.status);
        return {
          error: error,
          data: {} as DashboardDetailData,
          statusCode: response.status,
        };
      }
    } else {
      const authErrorInstance = new AuthenticationError();
      return {
        error: authErrorInstance,
        data: {} as DashboardDetailData,
        statusCode: authErrorInstance.statusCode,
      };
    }
  };

  // Get Trending Courses data
  getTrendingCourse = async (): Promise<TrendingCourseResult> => {
    return await apiService.getReqs({
      APIUrl: trendingCourseURL,
      payload: "",
      resType: TrendingCourseResult,
    });
  };
}
