import { LessonsReqParamType, LessonsResType } from "../../Utils/types";
import APIService from "../API";
import { BaseUrl } from "../axios.config";
import { NetworkError, Payload } from "../Error.type";

const fetchLessonsURL: string = `${BaseUrl}/course/viewCourse`;
const postQuizURL: string = `${BaseUrl}/course/quizCompletion`;
const apiService = new APIService();

export type lessonsResType = {
  data?: LessonsResType;
  statusCode: number;
};

export class LessonsAPIResult implements Payload<lessonsResType, NetworkError> {
  statusCode: number;
  data?: lessonsResType;
  errorMsg?: NetworkError;

  constructor(
    statusCode: number,
    data?: lessonsResType,
    errorMsg?: NetworkError
  ) {
    this.statusCode = statusCode;
    this.data = data;
    this.errorMsg = errorMsg;
  }
}

// Fetch courses list for Course explorer page
export default class LessonsAPIService {
  fetchLessonsDetail = async (reqData: LessonsReqParamType) => {
    let payload = {};
    const { courseId } = reqData;
    if (courseId) {
      payload = { courseId };
      return await apiService.getReqWithParams({
        APIUrl: fetchLessonsURL,
        payload,
        resType: LessonsAPIResult,
      });
    }
    return null;
  };
  getCourseContent = async (reqData: LessonsReqParamType) => {
    let payload = {};
    const { courseId } = reqData;
    if (courseId) {
      payload = { courseId };
      const fetchCourse: string = `${BaseUrl}/course/courseDetails/contents`;
      return await apiService.getReqWithParams({
        APIUrl: fetchCourse,
        payload,
        resType: LessonsAPIResult,
      });
    }
    return null;
  };

  getCourseDetails = async (reqData: LessonsReqParamType) => {
    let payload = {};
    const { courseId } = reqData;
    if (courseId) {
      payload = { courseId };
      const fetchCourse: string = `${BaseUrl}/course/courseDetails`;
      return await apiService.getReqWithParams({
        APIUrl: fetchCourse,
        payload,
        resType: LessonsAPIResult,
      });
    }
    return null;
  };

  getLessonDetails = async (reqData: LessonsReqParamType) => {
    let payload = {};
    const { itemType, courseId, lessonId, topicId, quizId } = reqData;
    let fetchCourse: string = `${BaseUrl}/course/viewTopic`;
    if (itemType === "Lesson") {
      payload = { courseId, lessonId: lessonId };
      fetchCourse = `${BaseUrl}/course/viewLesson`;
    } else if (itemType === "Topic") {
      payload = { courseId, topicId: lessonId };
      fetchCourse = `${BaseUrl}/course/viewTopic`;
    } else if (itemType === "Quiz") {
      payload = { courseId, quizId: lessonId };
      fetchCourse = `${BaseUrl}/course/viewQuiz`;
    }
    if (courseId && (lessonId || topicId || quizId)) {
      return await apiService.getReqWithParams({
        APIUrl: fetchCourse,
        payload,
        resType: LessonsAPIResult,
      });
    }
    return null;
  };

  postQuizData = async (courseId: string, reqData: any) => {
    const urlWithData = `${postQuizURL}?courseId=${courseId}`
    return await apiService.postReqs({
      APIUrl: urlWithData,
      payload: reqData,
      resType: LessonsAPIResult,
    })
  }
}
