import {
  CourseRatingFeedbackType,
  PlatformFeedbackReqType,
  TrainerRatingFeedbackType,
} from "../../Utils/types";
import APIService from "../API";
import { BaseUrl } from "../axios.config";
import { NetworkError, Payload } from "../Error.type";

const postPlatformFeedbackURL: string = `${BaseUrl}/platformRating`;
const postTrainerFeedbackURL: string = `${BaseUrl}/rating`;
const courseRatingURL: string = `${BaseUrl}/courseRating`;
const trainerListURL: string = `${BaseUrl}/trainersList`;
const apiService = new APIService();

export type feedbackResType = {
  data?: any;
  statusCode: number;
};

export class PlatformFeedbackAPIResult
  implements Payload<feedbackResType, NetworkError>
{
  statusCode: number;
  data?: feedbackResType;
  errorMsg?: NetworkError;

  constructor(
    statusCode: number,
    data?: feedbackResType,
    errorMsg?: NetworkError
  ) {
    this.statusCode = statusCode;
    this.data = data;
    this.errorMsg = errorMsg;
  }
}

export default class FeedbackAPIService {
  postPlatformFeedback = async (reqData: PlatformFeedbackReqType) => {
    return await apiService.postReqs({
      APIUrl: postPlatformFeedbackURL,
      payload: reqData,
      resType: PlatformFeedbackAPIResult,
    });
  };

  postCourseRating = async (reqData: CourseRatingFeedbackType) => {
    return await apiService.postReqs({
      APIUrl: courseRatingURL,
      payload: reqData,
      resType: PlatformFeedbackAPIResult,
    });
  };

  fetchTrainersReviews = async () => {
    return await apiService.getReqWithParams({
      APIUrl: trainerListURL,
      payload: {},
    });
  };

  postTrainersReviews = async (reqData: TrainerRatingFeedbackType) => {
    return await apiService.postReqs({
      APIUrl: postTrainerFeedbackURL,
      payload: reqData,
    });
  };
}
