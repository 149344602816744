import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { AnswerListType, ImageAnswerListType } from "../../Utils/types";
import DisplayImage from "./DisplayImage";
// import Required from "../../Components/Common/Required";

const AddAnswer = ({
  addCount,
  setAddCount,
  answerType,
  countType,
  setCountType,
  answersList,
  imageAnswerList,
  setAnswersList,
  setImageAnswerList,
  correctAnswer,
  setCorrectAnswer,
  mode,
  setEditDelOptions,
  editDelOptions,
}: {
  addCount: number;
  answerType: string;
  countType: string;
  answersList: Array<AnswerListType>;
  imageAnswerList: Array<ImageAnswerListType>;
  correctAnswer: number;
  setAddCount: Dispatch<SetStateAction<number>>;
  setCountType: Dispatch<SetStateAction<string>>;
  setAnswersList: Dispatch<SetStateAction<Array<AnswerListType>>>;
  setImageAnswerList: Dispatch<SetStateAction<Array<ImageAnswerListType>>>;
  setCorrectAnswer: Dispatch<SetStateAction<number>>;
  setEditDelOptions: Dispatch<SetStateAction<Array<number>>>;
  editDelOptions: Array<number>;
  mode: string;
}) => {
  // const [isFileExtValid, setIsFileExtValid] = useState<boolean>(true);
  const [sKey, setSKey] = useState<number>(1);
  const [dataLoading, setDataLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!dataLoading && addCount > 0 && countType === "add") {
      if (answerType === "text") {
        if (answersList.length >= 4) {
          return;
        }
        const tempData = {
          key: answersList.length + 1,
          answer: "",
        };
        setAnswersList([...answersList, tempData]);
      } else if (answerType === "image") {
        if (imageAnswerList.length >= 4) {
          return;
        }
        const tempData = {
          key: imageAnswerList.length + 1,
          imageAnswer: null,
          isValid: true,
        };
        setImageAnswerList([...imageAnswerList, tempData]);
      }
    } else {
      setDataLoading(false);
    }
  }, [addCount]);

  const handleAnswerInput = (
    rec: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    let tempData = answersList;
    if (tempData && tempData.length > 0) {
      tempData = tempData.map((data) => {
        if (data.key === rec) return { ...data, answer: e.target.value };
        else return { ...data };
      });
      setAnswersList(tempData);
    }
  };

  const handleAnswerDelete = (e: number) => {
    if (answersList.length > 0) {
      setCorrectAnswer(1);
      let data = answersList;
      const index = data.findIndex((x) => x.key === e);
      if (index > -1) {
        data.splice(index, 1);
        if (addCount > 0) {
          setAddCount(addCount - 1);
          setCountType("delete");
        }
        data = data.map((rec, index) => ({ ...rec, key: index + 1 }));
        setAnswersList(data);
      }
    }
  };

  const handleImageAnswerDelete = (e: number) => {
    if (imageAnswerList.length > 0) {
      if (mode === "edit") {
        setEditDelOptions([...editDelOptions, e]);
      }
      setCorrectAnswer(1);
      let data = imageAnswerList;
      const index = data.findIndex((x) => x.key === e);
      if (index > -1) {
        data.splice(index, 1);
        if (addCount > 0) {
          setAddCount(addCount - 1);
          setCountType("delete");
        }
        data = data.map((rec, index) => ({ ...rec, key: index + 1 }));
        setImageAnswerList(data);
      }
    }
  };

  useEffect(() => {
    setSKey(imageAnswerList.length);
  }, [imageAnswerList]);

  const handleFileOnChange = (event: any) => {
    let fileUploaded: Array<any> = event.target.files;
    let processFilesList: Array<any> = [];
    if (imageAnswerList.length < 4) {
      if (fileUploaded && fileUploaded.length > 0) {
        for (let i = 0; i < 4 - imageAnswerList.length; i++) {
          fileUploaded[i] && processFilesList.push(fileUploaded[i]);
        }
        let tempData: Array<ImageAnswerListType> = [];
        let sNo = imageAnswerList.length;
        for (let i = 0; i <= processFilesList.length; i++) {
          const isValid = validateUploadedFile(
            processFilesList[i] && processFilesList[i].name
          );
          if (isValid) {
            tempData.push({
              key: ++sNo,
              imageAnswer: processFilesList[i],
              isValid: true,
            });
          }
        }
        setImageAnswerList([...imageAnswerList, ...tempData]);
      }
    }
  };

  const validateUploadedFile = (filename: string) => {
    // let isValid = false;
    let regex = new RegExp(/\.(jpeg|jpg|svg|png|jfif)$/i);
    return regex.test(filename);
  };

  return (
    <>
      {answerType && answerType === "text" && (
        <div className={`mt-2 border-2 p-5 rounded-lg border-gray-400`}>
          {answersList &&
            answersList.map(({ key, answer }) => (
              <div className={`mt-2 `}>
                <input
                  type='radio'
                  name='text'
                  checked={key === correctAnswer}
                  defaultChecked={key === correctAnswer}
                  value={key}
                  onChange={(e) => setCorrectAnswer(Number(e.target.value))}
                  className={`mr-4 transform scale-x-150    scale-y-150`}
                />
                <label>{`Answer${key}`}</label>
                <div className={`flex flex-auto`}>
                  <input
                    defaultValue={""}
                    value={
                      answersList.filter((rec) => rec.key === key)[0].answer
                    }
                    autoFocus
                    className={`w-full h-10 p-2 border-gray-400 border rounded-md mt-2 font-lato `}
                    onChange={(e) => handleAnswerInput(key, e)}
                  />
                  <button
                    type='button'
                    className={`mt-2 ml-4 text-black bg-red-400 h-10 w-12 rounded-lg flex   justify-items-center items-center`}
                    onClick={() => handleAnswerDelete(key)}
                  >
                    <i className={`material-icons ml-3`}>{`delete_outline`}</i>
                  </button>
                </div>
              </div>
            ))}
        </div>
      )}
      {answerType && answerType === "image" && (
        <div className={`mt-2 border-2 p-5 rounded-lg border-gray-400`}>
          <div className={`w-full`}>
            <label
              htmlFor='myInput'
              className={`w-full h-28 border border-solid border-gray-400 bg-gray-100 font-bold rounded-md flex justify-center items-center  ${
                imageAnswerList && imageAnswerList.length === 4
                  ? `cursor-not-allowed`
                  : `cursor-pointer`
              }`}
            >
              {imageAnswerList && imageAnswerList.length === 4
                ? "Maximum files Uploaded"
                : "Click to upload one or more Image file (Maximum of 4 files)"}
            </label>
            <input
              id='myInput'
              disabled={imageAnswerList && imageAnswerList.length === 4}
              onChange={(e) => handleFileOnChange(e)}
              style={{ display: "none" }}
              type={"file"}
              accept={`.jpeg, .jpg, .svg, .png, .jfif`}
              multiple
            />
          </div>
          <div className={`grid grid-cols-2 tem`}>
            {imageAnswerList &&
              imageAnswerList.map(({ key, imageAnswer, isValid }) => {
                return (
                  <div className={`mt-2`}>
                    <div className={`flex flex-auto mb-4`}>
                      <div className={`flex justify-center items-center`}>
                        <input
                          type='radio'
                          name='text'
                          checked={key === correctAnswer}
                          defaultChecked={key === correctAnswer}
                          value={key}
                          onChange={(e) =>
                            setCorrectAnswer(Number(e.target.value))
                          }
                          className={`mr-4 transform scale-x-150 scale-y-150`}
                        />
                        <label>{`Answer${key}`}</label>
                      </div>
                      <div
                        className={
                          "h-auto grid col-span-1 justify-center items-center"
                        }
                      >
                        <button
                          type='button'
                          className={`mt-2 ml-4 text-black bg-red-400 h-10 w-12 rounded-lg flex flex-auto justify-items-center items-center`}
                          onClick={() => handleImageAnswerDelete(key)}
                        >
                          <i
                            className={`material-icons ml-3`}
                          >{`delete_outline`}</i>
                        </button>
                      </div>
                    </div>
                    <div className={`grid grid-cols-2 mb-2`}>
                      <div className={`w-full col-start-1 col-end-3`}>
                        {imageAnswer && (
                          <DisplayImage mode={mode} imageFile={imageAnswer} />
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      )}
    </>
  );
};

export default AddAnswer;
