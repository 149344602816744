import image from "../../Assets/play.png";

const QuizComponent = () => {
  return (
    <>
      <div>
        <img className="h-4 w-4 mt-1pt-1" src={image} alt="my-img"></img>
      </div>
    </>
  );
};
export default QuizComponent;
