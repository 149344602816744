type props = {
  value: String;
  bgClass?: String;
};
const AddNew = ({ value, bgClass }: props) => {
  return (
    <div
      className={` h-10 border-solid border-gray-400 border rounded-md m-3 pl-3 pt-1 text-base ${bgClass}`}
    >
      {value}
    </div>
  );
};
export default AddNew;
