import React, { useState } from "react";
import ReactModal from "react-modal";
import { Link } from "react-router-dom";
import ButtonReuse from "../../Components/Common/ButtonReuse";
import NoCourse from "../../Components/Common/NoCourse";
import ThemeContext from "../../Context/ThemeContext";
import About from "./About";
import Contact from "./Contact";
import Pricing from "./Pricing";
import Privacy from "./Privacy";
import Refund from "./Refund";
import Terms from "./Terms";

const Footer = () => {
  let url = (page: string) => `/legaldocs/${page}`;
  const token: string | null = localStorage.getItem("token");
  let footerPage = [
    { label: `About Us`, link: url(`aboutus`), topic: `aboutus` },
    { label: `Pricing`, link: url(`pricing`), topic: `pricing` },
    { label: `Contact Us`, link: url(`contactus`), topic: `contactus` },
    {
      label: `Terms and Conditions`,
      link: url(`termsandconditions`),
      topic: `termsandconditions`,
    },
    {
      label: `Cancellation and Refund Policy`,
      link: url(`refund`),
      topic: `refund`,
    },
    {
      label: `Privacy Policy`,
      link: url(`privacypolicy`),
      topic: `privacypolicy`,
    },
  ];
  const footerStyle: React.CSSProperties = {
    position: "absolute",
    bottom: "0",
    textAlign: "center",
  };
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "50%",
      height: "80%",
    },
  };
  const [showModal, setShowModal] = useState(false);
  const [bodyContent, setBodyContent] = useState(
    <NoCourse title="Nothing Here" />
  );

  const getPage = (page: string) => {
    switch (page) {
      case "aboutus":
        setBodyContent(<About />);
        break;
      case "contactus":
        setBodyContent(<Contact />);
        break;
      case "pricing":
        setBodyContent(<Pricing />);
        break;
      case "termsandconditions":
        setBodyContent(<Terms />);
        break;
      case "refund":
        setBodyContent(<Refund />);
        break;
      case "privacypolicy":
        setBodyContent(<Privacy />);
        break;
    }
  };

  return (
    <ThemeContext.Consumer>
      {(context) => {
        return (
          <div className="align-bottom">
            <div className="align-bottom">
              <footer
                className="mt-20 flex  flex-wrap gap-4 items-center align-bottom justify-center text-base"
                style={{ color: context.theme!.title }}
              >
                {footerPage?.map((item, index) => (
                  <React.Fragment key={index}>
                    {token ? (
                      <Link to={item.link}>{item.label}</Link>
                    ) : (
                      <div
                        className="underline cursor-pointer"
                        onClick={() => {
                          setShowModal(true);
                          getPage(item.topic);
                        }}
                      >
                        {item.label}
                      </div>
                    )}
                    {index !== footerPage.length - 1 && (
                      <span className="text-2xl">|</span>
                    )}
                  </React.Fragment>
                ))}
                <ReactModal
                  appElement={document.getElementById("root") as HTMLElement}
                  isOpen={showModal}
                  style={customStyles}
                  onRequestClose={() => setShowModal(false)}
                >
                  <ButtonReuse
                    value={`X`}
                    colorClass={`bg-white`}
                    tcolorClass={`text-black`}
                    sizeClass={`text-xl`}
                    onClick={() => setShowModal(false)}
                  />
                  {bodyContent}
                </ReactModal>
              </footer>
            </div>
          </div>
        );
      }}
    </ThemeContext.Consumer>
  );
};
export default Footer;
