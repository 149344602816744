import { ChangeEvent, useEffect, useState } from "react";
import CourseRatingAPIService from "../../Api/CoursesRating/CoursesRatingsAPIService";
import InputFull from "../../Components/Common/InuptFull";
import NoCourse from "../../Components/Common/NoCourse";
import Review from "../../Components/Common/Review";
import SubmenuTrainer from "../../Components/Common/SubMenuTrainer";
import CourseTail from "../../Components/Template/CourseTail";
import MenuTrainer from "../../Components/Template/MenuTrainer";
import Template from "../../Components/Template/Template";
import { ErrorMsgToaster } from "../../Components/Utils/Toaster";
import ThemeContext from "../../Context/ThemeContext";
import { CourseRatingType } from "../../Utils/types";

type courseListDetailType = {
  courseId: string;
  title: string;
};

const Reviews = () => {
  const [courseRatings, setCourseRatings] = useState<Array<CourseRatingType>>(
    []
  );
  const [courseList, setCourseList] = useState<Array<courseListDetailType>>([]);
  const [courseValue, setCourseValue] = useState<string>("all");
  const [isSelectDisabled, setIsSelectDisabled] = useState<boolean>(false);

  useEffect(() => {
    let courseRatingService = new CourseRatingAPIService();
    courseRatingService
      .fetchAllCourseRatings()
      .then((resData) => {
        if (resData?.errorMsg) {
          ErrorMsgToaster(resData.errorMsg);
        } else {
          if (resData?.data) {
            setCourseRatings(resData.data);
          }
        }
      })
      .catch((err) => console.log(err));

    // course list API
    courseRatingService
      .fetchCourseList()
      .then((resData) => {
        if (resData?.errorMsg) {
          ErrorMsgToaster(resData.errorMsg);
          setIsSelectDisabled(true);
          setCourseList([]);
        } else {
          if (resData?.data) {
            const optionList = [
              { courseId: "all", title: "All" },
              ...resData.data,
            ];
            setCourseList(optionList);
          }
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const handleCourseChange = (e: any) => {
    let courseRatingService = new CourseRatingAPIService();
    const courseId = e.target.value;
    setCourseValue(courseId);
    if (courseId === "all") {
      courseRatingService
        .fetchAllCourseRatings()
        .then((resData) => {
          if (resData?.errorMsg) {
            ErrorMsgToaster(resData.errorMsg);
          } else {
            if (resData?.data) {
              setCourseRatings(resData.data);
            }
          }
        })
        .catch((err) => console.log(err));
    } else {
      courseRatingService
        .fetchCourseRatingDetails(courseId)
        .then((resData) => {
          if (resData?.errorMsg) {
            ErrorMsgToaster(resData.errorMsg);
          } else {
            if (resData?.data) {
              setCourseRatings(resData.data);
            }
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const ReviewsBody = () => {
    return (
      <ThemeContext.Consumer>
        {(context) => {
          return (
            <>
              <div
                className={`text-2xl font-bold float-left`}
                style={{ color: context.theme!.title }}
              >
                Reviews
              </div>

              <div className={`mt-4 mr-6`}>
                <select
                  value={courseValue}
                  aria-placeholder="Select course"
                  onChange={handleCourseChange}
                  disabled={isSelectDisabled}
                  className={`h-10 w-full text-sm p-2 border-gray-400 border rounded-md mt-2 font-lato `}
                >
                  {courseList &&
                    courseList.length > 0 &&
                    courseList.map((rec) => {
                      return (
                        <option
                          className={`text-md font-bold py-4`}
                          key={rec.courseId}
                          value={rec.courseId}
                        >
                          {rec.title}
                        </option>
                      );
                    })}
                </select>
                {courseRatings &&
                  courseRatings.length > 0 &&
                  courseRatings.map((rec) => {
                    const { courseRating, feedback, name, title } = rec;
                    return (
                      <Review
                        ReviewInfo={{
                          rating: courseRating,
                          review: feedback,
                          name,
                          title,
                        }}
                      />
                    );
                  })}
                {(!courseRatings || !courseRatings.length) && (
                  <div className={`mt-32`}>
                    <NoCourse
                      title={`No Course Ratings Available`}
                      icon="school"
                    />
                  </div>
                )}
              </div>
            </>
          );
        }}
      </ThemeContext.Consumer>
    );
  };

  const menu = <MenuTrainer selectedItem={`MY REVIEWS`} />;
  return <Template menu={menu} body={<ReviewsBody />} submenu={<SubMenu />} />;
};

const SubMenu = () => {
  return (
    <>
      <SubmenuTrainer />
    </>
  );
};
export default Reviews;
