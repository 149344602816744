import { useState } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import AuthService from "../../Api/Auth/Auth";
import Logo from "../../Components/Common/Logo";
import InputFull from "../../Components/Common/InuptFull";
import LoginImageTemplate from "../../Components/Utils/LoginImageTemplate";
import { validateInput } from "../../Components/Utils/ValidationUtils";
import Routes from "../../Routes/Routes";

const ResetPassword = () => {
  const matginTop = `mt-8`;
  const centerItem = `flex items-center justify-center`;
  const authService = new AuthService();
  const history = useHistory();
  const token: string | null = localStorage.getItem("token");
  if (token) {
    history.push("/dashboard");
  }

  const [emailErr, setEmailErr] = useState("");
  const [email, setEmail] = useState("");

  const handleSendInstructions = async () => {
    console.log("");

    const response = await authService.resetPassword(email);
    if (response.data?.data !== undefined) {
      history.push(Routes.resetPasswordInstruction, response.data);
    } else {
      toast.error(response.error!.error!, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <div className={`grid grid-cols-1 lg:grid-cols-2 font-lato`}>
      <div className={``}>
        <LoginImageTemplate />
      </div>
      <div className={`${centerItem} overflow-auto`}>
        <div>
          <div className={`${centerItem}`}>
            <Logo />
          </div>
          <div
            className={`grid justify-items-center text-lp_blue font-bold text-4xl ${matginTop}`}
          >
            Reset your Password
          </div>
          <div className={`mt-4`}>
            <InputFull
              type='email'
              label='Email'
              onChange={(event) => {
                setEmail(event.target.value);
              }}
              errCls={emailErr ? "border-red-400" : "border-gray-400"}
              onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                const isErr = validateInput("email", e.target.value);
                setEmailErr(isErr);
              }}
            />
            <div className={`p-1 text-xs text-red-600`}>{emailErr}</div>
          </div>
          <div className={`mt-6`}>
            <button
              className={`w-full text-white bg-lp_blue text-xl p-2 rounded`}
              onClick={handleSendInstructions}
            >
              Send Instructions
            </button>
          </div>
          <div className={`mt-3 text-lp_blue   mb-5 text-base`}>
            <Link to='/signin'>Click here to Sign in</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
