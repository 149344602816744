import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import TrainerQuizAPIService from "../../Api/Quiz/TrainerQuizAPIService";
import ButtonReuse from "../../Components/Common/ButtonReuse";
import InputFull from "../../Components/Common/InuptFull";
import NoCourse from "../../Components/Common/NoCourse";
import SubmenuTrainer from "../../Components/Common/SubMenuTrainer";
import MenuTrainer from "../../Components/Template/MenuTrainer";
import Template from "../../Components/Template/Template";
import { ErrorMsgToaster } from "../../Components/Utils/Toaster";
import ThemeContext from "../../Context/ThemeContext";
import { QuizListType } from "../../Utils/types";
// import TrainerTile from "../../Components/Common/TrainerTile";
// import ViewAddButton from "../../Components/Common/ViewAddButton";
// import CourseTail from "../../Components/Template/CourseTail";

const RenderQuizesList = ({ quizes }: { quizes: Array<QuizListType> }) => {
  return (
    <ThemeContext.Consumer>
      {(context) => {
        return (
          <div>
            {quizes &&
              quizes.map((rec) => {
                return (
                  <div
                    key={rec.quizId}
                    className={` mt-5 w-12/12 h-11 mr-6 pt-2 rounded-md border-opacity-0`}
                    style={{
                      backgroundColor: "#F7EDE6",
                      borderColor: "grey",
                      border: "solid 0.5px",
                    }}
                  >
                    <div className={`flex flex-auto justify-between`}>
                      <label
                        className={` text-base mt-0 ml-3 mr-3 flex justify-start font-semibold h-6 overflow-hidden`}
                        style={{ color: context.theme!.title }}
                        title={rec.title}
                      >
                        {rec.title}
                      </label>
                      <Link
                        to={`/trainer/quiz/edit/${rec.quizId}`}
                        className={`h-auto`}
                      >
                        <button
                          className={`p-1 px-2 border  rounded-md text-white font-semibold -mt-1 mr-4`}
                          style={{
                            backgroundColor: context.theme!.viewEditButton,
                          }}
                        >
                          EDIT
                        </button>
                      </Link>
                    </div>
                  </div>
                );
              })}
            {(!quizes || !quizes.length) && (
              <div className={`mt-32`}>
                <NoCourse title={`No Quizes Available`} icon="school" />
              </div>
            )}
          </div>
        );
      }}
    </ThemeContext.Consumer>
  );
};

const QuizTrainerBody = () => {
  const [filterKey, setFilterKey] = useState("");
  const [quizList, setQuizList] = useState<Array<QuizListType>>([]);
  const [intialQuizList, setIntialQuizList] = useState<Array<QuizListType>>([]);

  useEffect(() => {
    setFilterKey("");
    const trainerQuizAPI = new TrainerQuizAPIService();
    trainerQuizAPI
      .fetchAllQuizes()
      .then(({ data, errorMsg }) => {
        if (data) {
          setQuizList(data);
          setIntialQuizList(data);
        } else if (errorMsg) {
          ErrorMsgToaster(errorMsg);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const filterQuiz = (key: any) => {
    const trainerQuizAPI = new TrainerQuizAPIService();
    if (key) {
      trainerQuizAPI
        .searchQuiz(key)
        .then((resData) => {
          if (resData && resData.data) {
            setQuizList(resData.data);
          } else {
            setQuizList([]);
          }
        })
        .catch((err) => console.log(err));
    } else {
      setQuizList(intialQuizList);
    }
  };

  useEffect(() => filterQuiz(filterKey), [filterKey]);

  return (
    <ThemeContext.Consumer>
      {(context) => {
        return (
          <>
            <div className={`flow-root`}>
              <div
                className={`text-xl md:text-2xl font-bold float-left`}
                style={{ color: context.theme!.title }}
              >
                Quizzes
              </div>
              <Link to="/trainer/quiz/new">
                <div className={`float-right md:mr-6 cursor-pointer`}>
                  <ButtonReuse
                    value={"ADD NEW QUIZ"}
                    colorClass={"bg-yellow-200"}
                    heightClass={"h-8"}
                    widthClass={"w-44"}
                    sizeClass={"text-sm"}
                    positionClass={"float-right"}
                    tcolorClass={"text-black"}
                  ></ButtonReuse>
                </div>
              </Link>
            </div>
            <div className={`mt-4 md:mr-6`}>
              <InputFull
                type="text"
                placeHolder="Search Quizzes"
                onChange={(e): void => setFilterKey(e.target.value)}
                label={""}
              ></InputFull>
            </div>

            <RenderQuizesList quizes={quizList} />
          </>
        );
      }}
    </ThemeContext.Consumer>
  );
};

const SubMenu = () => {
  return (
    <>
      <SubmenuTrainer />
    </>
  );
};

const QuizTrainer = () => {
  const menu = <MenuTrainer selectedItem={`QUIZ`} />;
  return (
    <Template menu={menu} body={<QuizTrainerBody />} submenu={<SubMenu />} />
  );
};

export default QuizTrainer;
