import PDFViewer from "../Resources/PDFViewer";
import PPTViewer from "../Resources/PPTViewer";
import VideoPlayer from "../Resources/VideoPlayer";
import WordDocViewer from "../Resources/WordDocViewer";

const RenderMediaComponents = ({
  originalName,
  resourceLink,
}: {
  originalName: string;
  resourceLink: string;
}) => {
  const renderFunc = () => {
    const lastDot = originalName.lastIndexOf(".");
    const ext = originalName.substring(lastDot + 1);

    switch (ext.toLowerCase()) {
      case "pdf":
        return (
          <div className="h-screen">
            <PDFViewer key={resourceLink} resourceLink={resourceLink} />
          </div>
        );
      case "mp4":
        return <VideoPlayer key={resourceLink} resourceLink={resourceLink} />;
      case "doc":
      case "docx":
        return (
          <div className="h-screen">
            <WordDocViewer key={resourceLink} resourceLink={resourceLink} />
          </div>
        );
      case "ppt":
      case "pptx":
        return (
          <div className="h-screen">
            <PPTViewer key={resourceLink} resourceLink={resourceLink} />
          </div>
        );
      case "jpeg":
      case "jpg":
      case "png":
      case "svg":
      case "jfif":
      case "gif":
        return (
          <div className={`flex flex-auto justify-center h-full w-full`}>
            <img
              className={`h-full object-contain`}
              key={resourceLink}
              src={resourceLink}
            />
          </div>
        );
      default:
        return <div>No Content Available</div>;
    }
  };

  return (
    <div style={{ height: "inherit" }} className="w-full">
      {renderFunc()}
    </div>
  );
};

export default RenderMediaComponents;
