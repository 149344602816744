type props = {
  value: String;
  valueClass: String;
};
const QuizMarks = ({ value, valueClass }: props) => {
  return (
    <div className="w-full h-12 border-solid border-gray-400 border rounded-md  bg-lp_pink font-Lato font-bold ">
      <div className=" text-lp_blue pt-2 pl-3 text-lg  float-left">{value}</div>
      <div className="float-right pt-2 pr-3 flex items-center text-lp_blue text-lg  ">
        {valueClass}
      </div>
    </div>
  );
};
export default QuizMarks;
