import { Link } from "react-router-dom";
import { ThemeProps } from "../../Themes/Themes";
// import { generatePath, useHistory } from "react-router";
// import Routes from "../../Routes/Routes";
import { MyCourseDetailsType } from "../../Utils/types";

type MyCourseCardData = {
  myCourseData: MyCourseDetailsType;
  theme: ThemeProps;
};

const MyCourseCard = ({ myCourseData, theme }: MyCourseCardData) => {
  // const history = useHistory();

  const completedProgress: string =
    (myCourseData.progress ?? 0).toString() + "%";

  const uncompletedProgress =
    (100 - (myCourseData.progress ?? 0)).toString() + "%";

  // const navToLessons = (courseId: string) => {
  //   courseId && history.push(generatePath(Routes.lesson, { courseId }));
  // };

  return (
    <Link to={`/lesson/${myCourseData?.courseId}`}>
      <div
        className={`flex cursor-pointer rounded-xl`}
        // onClick={() => navToLessons(myCourseData?.courseId || "")}
        key={myCourseData?.courseId}
      >
        <div
          className={` h-48 inline-block w-4/12 rounded-tl-xl rounded-bl-xl`}
          style={{ backgroundColor: "#F9F7F7" }}
        >
          <img
            src={myCourseData?.image}
            className={`w-full h-full object-cover bg-gray-100 `}
            alt={""}
          />
        </div>
        <div
          className={`h-48 inline-block w-8/12 rounded-lg overflow-scroll`}
          style={{ backgroundColor: "#F9F7F7" }}
        >
          <div
            className={` not-italic font-bold text-xl ml-5 mt-5`}
            style={{ color: theme.title }}
          >
            {myCourseData?.title}
          </div>
          <div className={`not-italic font-normal text-sm  ml-5 mt-2`}>
            {myCourseData?.description}
          </div>
          <div className={`not-italic font-bold text-sm ml-5 mt-2`}>
            {" "}
            Enrolled on: {myCourseData?.enrolledOn}
          </div>
          <div className={`flex`}>
            <div
              className={` ml-5 mt-2 rounded-l-lg  h-2`}
              style={{ width: completedProgress, backgroundColor: "#20D200" }}
            ></div>
            <div
              className={`bg-gray-400 mt-2 rounded-r-lg h-2 mr-4`}
              style={{ width: uncompletedProgress }}
            ></div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default MyCourseCard;
