import { useEffect, useState } from "react";
import { connect } from "react-redux";
import Menu from "../../Components/Template/Menu";
import MenuTrainer from "../../Components/Template/MenuTrainer";
import Template from "../../Components/Template/Template";
import PlatformFeedback from "./PlatformFeedback";
import StudentFeedback from "./Student/StudentFeedback";

const Feedback = (props: any) => {
  const { registerType } = props.userInfo;
  const menu =
    registerType === "Student" ? (
      <Menu selectedItem={`FEEDBACK`} />
    ) : (
      <MenuTrainer selectedItem={"FEEDBACK"} />
    );
  return (
    <Template
      menu={menu}
      body={<FeedbackBody props={props} />}
      submenu={<SubMenu />}
    />
  );
};

const FeedbackBody = ({ props }: { props: any }) => {
  const [userType, setUserType] = useState<string>("");

  useEffect(() => {
    const { registerType } = props.userInfo;
    registerType && setUserType(registerType);
  }, [props]);

  return (
    <>
      {userType && userType === "Student" && <StudentFeedback />}
      {userType && userType === "Trainer" && (
        <PlatformFeedback userType={userType} />
      )}
    </>
  );
};

const SubMenu = () => {
  return null;
};

const mapStateToProps = (state: any) => {
  return {
    userInfo: state && state.userInfoReducer,
  };
};

export default connect(mapStateToProps, null)(Feedback);
