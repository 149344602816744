import { connect } from "react-redux";
import NoCourse from "../../Components/Common/NoCourse";
import Menu from "../../Components/Template/Menu";
import MenuTrainer from "../../Components/Template/MenuTrainer";
import Template from "../../Components/Template/Template";
import About from "./About";
import Contact from "./Contact";
import Pricing from "./Pricing";
import Privacy from "./Privacy";
import Refund from "./Refund";
import Terms from "./Terms";

const FooterContentViewer = (props: any) => {
  const { registerType } = props.userInfo;
  const menu = <Menu selectedItem={``} />;
  const trainerMenu = <MenuTrainer selectedItem={``} />;
  const page = props.match.params.page;
  let BodyContent = <NoCourse title="Nothing Here" />;
  switch (page) {
    case "aboutus":
      BodyContent = <About />;
      break;
    case "contactus":
      BodyContent = <Contact />;
      break;
    case "pricing":
      BodyContent = <Pricing />;
      break;
    case "termsandconditions":
      BodyContent = <Terms />;
      break;
    case "refund":
      BodyContent = <Refund />;
      break;
    case "privacypolicy":
      BodyContent = <Privacy />;
      break;
  }
  return (
    <Template
      menu={registerType === "Student" ? menu : trainerMenu}
      body={BodyContent}
    />
  );
};

const mapStateToProps = (state: any) => {
  return {
    userInfo: state && state.userInfoReducer,
  };
};

export default connect(mapStateToProps, null)(FooterContentViewer);
