const DisplayImage = ({
  imageFile,
  mode,
}: {
  imageFile: any;
  mode: string;
}) => {
  const imageTempURL =
    imageFile && typeof imageFile === "string"
      ? imageFile
      : URL.createObjectURL(imageFile);
  return (
    <div>
      {imageTempURL && (
        <div className={`h-52 w-full rounded-xl border-2  overflow-hidden`}>
          <img className={`h-full w-full object-fit`} src={imageTempURL} />
        </div>
      )}
    </div>
  );
};

export default DisplayImage;
