import { LessonDetailResType, UpdateLessonDetailsType } from "../../Utils/types";
import APIService from "../API";
import { BaseUrl } from "../axios.config";
import { NetworkError, Payload } from "../Error.type";

const addNewLessonsURL: string = `${BaseUrl}/trainer/createLesson`;
const fetchAllLessonsURL: string = `${BaseUrl}/trainer/getLessons`;
const fetchLessonDetailURL: string = `${BaseUrl}/trainer/getLessons`;
const editLessonURL: string = `${BaseUrl}/trainer/editLessons`;
const searchLessonsURL: string = `${BaseUrl}/trainer/searchLessons`;
const apiService = new APIService();

type LessonsReqParamType = {
    title: string;
    description: string;
}

type AddNewLessonsResType = {
    data?: { title: string, description: string };
};

// type LessonsResType = {
//     data: string;
//     lessonId: string;
// }

type FetchLessonResType = {
    lessonId: string;
    title: string;
}

type EditLessonResType = {
    data: string;
    description: string;
}

export class AddNewLessonsAPIResult
    implements Payload<AddNewLessonsResType, NetworkError>
{
    statusCode: number;
    data?: AddNewLessonsResType;
    errorMsg?: NetworkError;

    constructor(
        statusCode: number,
        data?: AddNewLessonsResType,
        errorMsg?: NetworkError
    ) {
        this.statusCode = statusCode;
        this.data = data;
        this.errorMsg = errorMsg;
    }
}

export class FetchAllLessonsAPIResult implements Payload<Array<FetchLessonResType>, NetworkError> {
    statusCode: number;
    data?: Array<FetchLessonResType>;
    errorMsg?: NetworkError;

    constructor(
        statusCode: number,
        data?: Array<FetchLessonResType>,
        errorMsg?: NetworkError
    ) {
        this.statusCode = statusCode;
        this.data = data;
        this.errorMsg = errorMsg;
    }
}

export class FetchLessonsDetailsResult implements Payload<LessonDetailResType, NetworkError> {
    statusCode: number;
    data?: LessonDetailResType;
    errorMsg?: NetworkError;

    constructor(
        statusCode: number,
        data?: LessonDetailResType,
        errorMsg?: NetworkError
    ) {
        this.statusCode = statusCode;
        this.data = data;
        this.errorMsg = errorMsg;
    }
}

export class EditLessonsResult implements Payload<EditLessonResType, NetworkError> {
    statusCode: number;
    data?: EditLessonResType;
    errorMsg?: NetworkError;

    constructor(
        statusCode: number,
        data?: EditLessonResType,
        errorMsg?: NetworkError
    ) {
        this.statusCode = statusCode;
        this.data = data;
        this.errorMsg = errorMsg;
    }
}

export default class TrainerLessonsAPIService {
    // Add new lesson
    addNewLesson = async (reqData: LessonsReqParamType) => {
        let payload = {};
        const { description, title } = reqData;
        if (title && description) {
            payload = { title, description };
            return await apiService.postReqs({
                APIUrl: addNewLessonsURL,
                payload,
                resType: AddNewLessonsAPIResult
            });
        }
        return null;
    };

    // fetch all lessons
    fetchAllLessonsDetail = async () => {
        return await apiService.getReqs({
            APIUrl: fetchAllLessonsURL,
            payload: {},
            resType: FetchAllLessonsAPIResult,
        });
    };

    // fetch particular lessons details
    fetchLessonsDetail = async (lessonId: string) => {
        if (lessonId) {
            const payload = { lessonId };
            return await apiService.getReqWithParams({
                APIUrl: fetchLessonDetailURL,
                payload,
                resType: FetchLessonsDetailsResult,
            });
        }
    };

    // edit lessons details
    editLessonDetails = async (reqData: UpdateLessonDetailsType) => {
        const { lessonId, description, title } = reqData;
        const urlWithId = `${editLessonURL}?lessonId=${lessonId}`;
        if (lessonId && title && description) {
            const payload = { title, description };
            return await apiService.patchReqs({
                APIUrl: urlWithId,
                payload,
                resType: EditLessonsResult
            })
        }
    }

    // search lessons
    searchLessons = async (key: string) => {
        if(key) {
            const urlWithKey = `${searchLessonsURL}?lesson=${key}`;
            return await apiService.postReqs({
                APIUrl: urlWithKey,
                payload: {},
                resType: FetchAllLessonsAPIResult,
            });
        }      
    }
}


