type WordDocViewerType = {
  resourceLink: string;
};

const WordDocViewer = ({ resourceLink }: WordDocViewerType) => {
  return (
    <>
      {resourceLink ? (
        <iframe
          src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
            resourceLink
          )}`}
          width='100%'
          height='100%'
        >
          This is an embedded{" "}
          <a target='_blank' href='http://office.com'>
            Microsoft Office
          </a>{" "}
          document, powered by{" "}
          <a target='_blank' href='http://office.com/webapps'>
            Office Online
          </a>
          .
        </iframe>
      ) : (
        <div>No Content Found</div>
      )}
    </>
  );
};

export default WordDocViewer;
