import { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "./Components/Template/Spinner";
import NotFound from "./Screens/404";
import Cart from "./Screens/Cart/Cart";
import Courses from "./Screens/Courses/Courses";
import PaymentScreens from "./Screens/PaymentScreen/Paymentscreen";
import Quizes from "./Screens/QuizScores/Quizes";
import QuizScores from "./Screens/QuizScores/Quizscores";
import { ToastContainer } from "react-toastify";
import ConfirmPassword from "./Screens/Auth/ConfirmPassword";
import ResetPassword from "./Screens/Auth/ResetPassword";
import ResetPasswordInstruction from "./Screens/Auth/ResetPasswordInstruction";
import Signin from "./Screens/Auth/Signin";
import Signup from "./Screens/Auth/Signup";
import Dashboard from "./Screens/Dashboard/Dashboard";
import "react-toastify/dist/ReactToastify.css";
import { Route, Switch, useLocation } from "react-router-dom";
import MyCourses from "./Screens/MyCourses/MyCourses";
import QuizFeedback from "./Screens/QuizFeedback/QuizFeedback";
import TopicTrainer from "./Screens/TrainerTopic/TrainerTopic";
import QuizTrainer from "./Screens/TrainerCode/TrainerQuiz";
import QuestionTrainer from "./Screens/TrainerQuestion/TrainerQuestion";
import Checkout from "./Screens/Checkout/Checkout";
import Routes from "./Routes/Routes";
import NewTopic from "./Screens/NewTopic/NewTopic";
import NewQuestions from "./Screens/NewQuestion/NewQuestion";
import NewQuiz from "./Screens/NewQuiz/NewQuiz";
import NewCourse from "./Screens/NewCourse/NewCourse";
import Reviews from "./Screens/Reviews/Reviews";
import MyEarnings from "./Screens/MyEarnings/MyEarnings";
import StudentProfiles from "./Screens/StudentProfile/StudentProfile";
import UserInfoAPIService from "./Api/UserInfo/UserInfoAPI";
import { UserInfoAction } from "./redux/action";
import SetGoal from "./Screens/Setgoal/SetGoal";
import MyCoursesTrainer from "./Screens/MyCourses/MyCoursesTrainer";
import Resources from "./Screens/Resources/Resources";
import Feedback from "./Screens/Feedback/Feedback";
import FooterContentViewer from "./Screens/Footer/FooterContentViewer";
import MyCourseEnrolled from "./Screens/MyLesson/MyCourseEnrolled";
import StudentListReport from "./Screens/StudentDetail/StudentListReport";
import ThemeProvider from "./Provider/ThemeProvider";
import LogoProvider from "./Provider/LogoProvider";
import AccordianProvider from "./Provider/AccordianProvider";
import CourseDetail from "./Screens/CourseDetail/CourseDetail";
import ThemeContext from "./Context/ThemeContext";
import SocialLoginInfo from "./Screens/Auth/SocialLoginInfo";
import ModeratorCourses from "./Screens/Moderator/ModerateCourses";
import ModeratorCourseDescription from "./Screens/Moderator/ModerateCourseDescription";
// import LessonTrainer from "./Screens/TrainerLesson/TrainerLesson";
// import NewLessons from "./Screens/Lessons/NewLesson";
// import StudentDetail from "./Screens/StudentDetail/StudentDetails";
// import DashboardTrainer from "./Screens/DashboardTrainer/DashdoardTrainer";

function App(props: any) {
  const history = useHistory();
  const token: string | null = localStorage.getItem("token");
  const path = window.location.pathname;
  if (!token && !path.startsWith("/reset/confirm-password/")) {
    history.push("/");
  }

  // To make common API calls
  useEffect(() => {
    const userInfoAPIService = new UserInfoAPIService();
    userInfoAPIService
      .fetchUserInfo()
      .then((resData) => {
        if (resData.data) {
          props.setUserInfo(resData.data);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <ThemeProvider>
        <LogoProvider>
          <AccordianProvider>
            <ToastContainer />
            <ThemeContext.Consumer>
              {(context) => {
                console.log(context.theme);
                return (
                  <>
                    {props.spinnerState.isLoading &&
                      context.theme !== undefined && <Spinner />}
                    {!props.spinnerState.isLoading &&
                      context.theme !== undefined && (
                        <Switch>
                          <Route
                            path={Routes.signup}
                            component={Signup}
                            exact
                          />
                          <Route
                            path={Routes.signin}
                            component={Signin}
                            exact
                          />
                          <Route
                            path={Routes.resetPasswordInstruction}
                            component={ResetPasswordInstruction}
                            exact
                          />
                          <Route
                            path={Routes.confirmPassword}
                            component={ConfirmPassword}
                            exact
                          />

                          <Route
                            path={Routes.lesson}
                            component={MyCourseEnrolled}
                            exact
                          />
                          <Route
                            path={Routes.setGoal}
                            component={SetGoal}
                            exact
                          />
                          <Route path={Routes.cart} component={Cart} />
                          <Route
                            path={Routes.resetPassword}
                            component={ResetPassword}
                            exact
                          />
                          <Route
                            path={Routes.socialLoginInfo}
                            component={SocialLoginInfo}
                            exact
                          />

                          <Route
                            path={Routes.courseDetail}
                            component={CourseDetail}
                            exact
                          />

                          <Route
                            path={Routes.dashboard}
                            component={Dashboard}
                            exact
                          />
                          <Route
                            path={Routes.profile}
                            component={StudentProfiles}
                            exact
                          />
                          <Route
                            path={Routes.paymentScreen}
                            component={PaymentScreens}
                            exact
                          />
                          <Route
                            path={Routes.quizScore}
                            component={QuizScores}
                            exact
                          />
                          <Route path={Routes.quiz} component={Quizes} exact />
                          <Route
                            path={Routes.checkout}
                            component={Checkout}
                            exact
                          />
                          <Route
                            path={Routes.resources}
                            component={Resources}
                          />
                          <Route
                            path={Routes.feedback}
                            component={Feedback}
                            exact
                          />
                          <Route
                            path={Routes.courses}
                            component={Courses}
                            exact
                          />
                          <Route
                            path={Routes.myCourses}
                            component={MyCourses}
                            exact
                          />
                          <Route
                            path={Routes.quizFeedback}
                            component={QuizFeedback}
                            exact
                          />
                          <Route
                            path={Routes.myCoursesTrainer}
                            component={MyCoursesTrainer}
                            exact
                          />
                          <Route
                            path={Routes.topicTrainer}
                            component={TopicTrainer}
                            exact
                          />
                          <Route
                            path={Routes.quizTrainer}
                            component={QuizTrainer}
                            exact
                          />
                          <Route
                            path={Routes.questionsTrainer}
                            component={QuestionTrainer}
                            exact
                          />

                          <Route
                            path={Routes.createTopicTrainer}
                            component={NewTopic}
                            exact
                          />
                          <Route
                            path={Routes.editTopicTrainer}
                            component={NewTopic}
                            exact
                          />
                          <Route
                            path={Routes.createCourseTrainer}
                            component={NewCourse}
                            exact
                          />
                          <Route
                            path={Routes.createQuizTrainer}
                            component={NewQuiz}
                            exact
                          />
                          <Route
                            path={Routes.editQuizTrainer}
                            component={NewQuiz}
                            exact
                          />
                          <Route
                            path={Routes.createQuestionsTrainer}
                            component={NewQuestions}
                            exact
                          />
                          <Route
                            path={Routes.editQuestionsTrainer}
                            component={NewQuestions}
                            exact
                          />
                          <Route
                            path={Routes.reviews}
                            component={Reviews}
                            exact
                          />
                          <Route
                            path={Routes.viewStudentDetailTrainer}
                            component={StudentListReport}
                            exact
                          />
                          <Route
                            path={Routes.myEarnings}
                            component={MyEarnings}
                            exact
                          />
                          <Route
                            path={Routes.myCoursesTrainer}
                            component={MyCoursesTrainer}
                            exact
                          />
                          <Route
                            path={Routes.legalDocs}
                            component={FooterContentViewer}
                            exact
                          />
                          <Route
                            path={Routes.moderateCourseDescription}
                            component={ModeratorCourseDescription}
                          />

                          <Route
                            path={Routes.moderateCourses}
                            component={ModeratorCourses}
                            exact
                          />

                          <Route path="/" component={Signin} exact />
                          <Route path="/*" component={NotFound} />
                        </Switch>
                      )}
                  </>
                );
              }}
            </ThemeContext.Consumer>
          </AccordianProvider>
        </LogoProvider>
      </ThemeProvider>
    </>
  );
}
const mapStateToProps = (state: any) => {
  return {
    spinnerState: state && state.spinnerReducer,
    userInfo: state && state.userInfoReducer,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setUserInfo: (data: any) => {
      dispatch(UserInfoAction(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
