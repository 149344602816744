import { createContext } from "react";
import { Assets } from "../Api/Assets";

type AccordianProps = {
  isOpen: boolean;
  accordianToggled?: VoidFunction;
};

const accordianContext = createContext<AccordianProps>({
  isOpen: false,
});

export default accordianContext;
