import { useState } from "react";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { Assets } from "../../Api/Assets";
import AuthService from "../../Api/Auth/Auth";
import Logo from "../../Components/Common/Logo";
import { Link } from "react-router-dom";
import InputFull from "../../Components/Common/InuptFull";
import LoginImageTemplate from "../../Components/Utils/LoginImageTemplate";
import { validateInput } from "../../Components/Utils/ValidationUtils";
import { connect } from "react-redux";
import { UserInfoAction } from "../../redux/action";
import { FaEye } from "react-icons/fa";
import ThemeContext, { ThemeValueProps } from "../../Context/ThemeContext";
import Routes from "../../Routes/Routes";

const Signin = (props: any) => {
  const marginTop = `mt-8`;
  const centerItem = `flex items-center justify-center`;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [passwordErr, setPasswordErr] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const token: string | null = localStorage.getItem("token");
  const loginType: string | null = localStorage.getItem("registerType");
  const history = useHistory();
  if (token && loginType) {
    history.push("/dashboard");
  }

  const show_Password = () => {
    setShowPassword(!showPassword);
  };

  const loginHandler = async (fetchTheme?: VoidFunction) => {
    const response = await new AuthService().login({ email, password });

    if (response?.data !== undefined) {
      const { token, type } = response.data;
      localStorage.setItem("token", token);
      localStorage.setItem("registerType", type);
      if (fetchTheme !== undefined) await fetchTheme();
      if (type) {
        console.log("type", type);

        if (type === "Moderator") {
          console.log("executing moderator");

          history.replace(Routes.moderateCourses);
        } else {
          console.log(" not executing moderator");
          history.replace("/dashboard");
        }
      }
    } else {
      console.log("Executing Error block");

      toast.error(response?.errorMsg!.error!, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <div className={`grid grid-cols-1 lg:grid-cols-2 w-full h-full font-lato mt-5`}>
      <div className={``}>
        <LoginImageTemplate />
      </div>
      <div className={`${centerItem} overflow-auto`}>
        <div className={``}>
          <div className={`${centerItem}`}>
            <Logo />
          </div>
          <div
            className={`grid justify-items-center text-lp_blue font-bold text-4xl mt-2`}
          >
            Welcome Back!
          </div>
          {/* <div className={`grid grid-cols-2 gap-4 text-lp_blue text-2xl mt-10`}>
            <div className={`text-right`}>Sign in with</div>
            <div className={`grid grid-cols-4 gap-3`}>
              <img src={Assets.FbLogo} alt={`FB`} />
              <img src={Assets.GoogleLogo} alt={`Google`} />
              <img src={Assets.LinkedInLogo} alt={`LinkedIn`} />
            </div>
          </div>
          <div
            className={`grid justify-items-center text-lp_blue font-bold text-2xl mt-6`}
          >
            - OR -
          </div> */}
          <div className={`mt-5`}>
            <InputFull
              type="email"
              label="Email"
              onChange={(event) => {
                setEmail(event.target.value);
              }}
              errCls={emailErr ? "border-red-400" : "border-gray-400"}
              onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                const isErr = validateInput("email", e.target.value);
                setEmailErr(isErr);
              }}
            />
            <div className={`p-1 text-xs text-red-600`}>{emailErr}</div>
          </div>
          <div className={`mt-4 grid `}>
            <div className="z-0">
              <InputFull
                type={showPassword ? "text" : "password"}
                label="Password"
                onChange={(event) => {
                  setPassword(event.target.value);
                }}
                errCls={passwordErr ? "border-red-400" : "border-gray-400"}
                onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const isErr = validateInput("password", e.target.value);
                  setPasswordErr(isErr);
                }}
              />
            </div>
            <div className=" float-right z-40">
              <FaEye
                className="float-right -mt-7 mr-3  "
                onClick={show_Password}
              />{" "}
            </div>
            <div className={`p-1 text-xs text-red-600`}>{passwordErr}</div>
          </div>
          <ThemeContext.Consumer>
            {(context: ThemeValueProps) => {
              return (
                <div className={`mt-6`}>
                  <button
                    className={`w-full text-white bg-lp_blue text-xl p-2 rounded`}
                    onClick={() => {
                      loginHandler(context.getTheme);
                    }}
                    // disabled={isSignInBtnDisabled}
                  >
                    SIGN IN
                  </button>
                </div>
              );
            }}
          </ThemeContext.Consumer>
          <div className={`mt-3 text-lp_blue text-base`}>
            <Link to="/reset-password">
              Forgot Password? Click here to reset
            </Link>
          </div>
          <div
            className={`${marginTop} text-lp_blue text-xl mb-5 font-extrabold text-center`}
          >
            <Link to="/signup">New User? Create Account</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setUserInfo: (data: any) => {
      dispatch(UserInfoAction(data));
    },
  };
};

export default connect(null, mapDispatchToProps)(Signin);
