import Template from "../../Components/Template/Template";
import MenuTrainer from "../../Components/Template/MenuTrainer";
import DashboardCommon from "./DashboardCommon";
import DashboardSubmenu from "./DashboardSubmenu";
import { getDashboardResults } from "./TrainerDashboardAPI";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AuthService from "../../Api/Auth/Auth";
import {
  ErrorMsgToaster,
  SuccessMsgToaster,
} from "../../Components/Utils/Toaster";
import { TrainerDashboard } from "../../Utils/types";
import { ThemeProps } from "../../Themes/Themes";

const DashboardTrainer = ({ theme }: { theme: ThemeProps }) => {
  const menu = <MenuTrainer selectedItem={`DASHBOARD`} />;
  const [dashboardData, setDashboardData] = useState<TrainerDashboard>(
    {} as TrainerDashboard
  );
  useEffect(() => {
    getDashboardResults().then((data) => setDashboardData(data));
  }, []);
  return (
    <Template
      menu={menu}
      body={
        <DashboardTrainerbody dashboardData={dashboardData} theme={theme} />
      }
      submenu={<SubMenu dashboardData={dashboardData} />}
    />
  );
};

const DashboardTrainerbody = ({
  dashboardData,
  theme,
}: {
  dashboardData: TrainerDashboard;
  theme: ThemeProps;
}) => {
  const authService = new AuthService();
  return (
    <div className={`flex flex-col text-black font-Lato`}>
      <div
        className="flex flex-col text-2xl font-bold font-Lato "
        style={{ color: theme.title }}
      >
        Welcome {dashboardData?.firstName} {dashboardData?.lastName}
      </div>
      <div
        className="flex flex-col text-lg mt-3 font-bold font-Lato "
        style={{ color: theme.title }}
      >
        Role: Trainer
      </div>
      <br></br>
      {!dashboardData?.emailVerified && (
        <div
          className="text-lg h-auto rounded-md py-2 pl-7 "
          style={{
            backgroundColor: theme.dashboardTrainer.block1,
            color: theme.dashboardTrainer.block1Text,
          }}
        >
          A verification mail has been sent to your registered email ID. Please
          verify.{" "}
          <span
            className="text-blue-500 underline cursor-pointer"
            onClick={() => {
              authService.resendMail().then(({ data, errorMsg }) => {
                if (data) SuccessMsgToaster(data);
                if (errorMsg) ErrorMsgToaster(errorMsg);
              });
            }}
          >
            Resend e-mail
          </span>
        </div>
      )}
      <br></br>
      {!dashboardData?.completeYourProfile && (
        <Link to="/profile">
          <div
            className="text-lg  h-auto rounded-md py-2 pl-7 "
            style={{
              backgroundColor: theme.dashboardTrainer.block2,
              color: theme.dashboardTrainer.text,
            }}
          >
            Click here to complete your profile
          </div>
        </Link>
      )}
      <br></br>

      <div className="grid grid-cols-3 gap-10">
        <div
          className="flex flex-col gap-5 items-center justify-center h-60 w-full rounded-lg "
          style={{
            backgroundColor: theme.dashboardTrainer.tile1,
          }}
        >
          <div className="text-black font-bold text-5xl md:text-7xl ">
            {dashboardData?.coursesPublished || 0}
          </div>
          <div className="font-bold text-xs md:text-sm text-center mt-6  ">
            Courses Published
          </div>
        </div>
        <div
          className="flex flex-col  items-center justify-center gap-5 h-60 w-full rounded-lg"
          style={{
            backgroundColor: theme.dashboardTrainer.tile2,
          }}
        >
          <div className="text-black font-bold text-5xl md:text-7xl">
            {dashboardData?.pendingReview || 0}
          </div>
          <div className="font-bold text-xs md:text-sm text-center mt-6 ">
            Pending Review
          </div>
        </div>
        <div
          className="flex flex-col  items-center justify-center gap-5 h-60 w-full rounded-lg"
          style={{
            backgroundColor: theme.dashboardTrainer.tile3,
          }}
        >
          <div className="text-black font-bold text-5xl md:text-7xl">
            {dashboardData?.coursesToBeReviewed || 0}
          </div>
          <div className="font-bold text-xs md:text-sm text-center mt-6 ">
            Course Requires Review
          </div>
        </div>
      </div>
      <br></br>
      <DashboardCommon
        pendingSettlement={dashboardData?.pendingSettlement}
        earnedThisMonth={dashboardData?.earnedThisMonth}
        theme={theme}
      />
    </div>
  );
};

const SubMenu = ({ dashboardData }: { dashboardData: TrainerDashboard }) => {
  return (
    <>
      <DashboardSubmenu dashboardData={dashboardData} />
    </>
  );
};
export default DashboardTrainer;
