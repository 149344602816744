import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import CartAPIService, {
  fetchCardDataRes,
} from "../../Api/Cart/CartAPIService";
import NoCourse from "../../Components/Common/NoCourse";
import Menu from "../../Components/Template/Menu";
import Template from "../../Components/Template/Template";
import { CartAction } from "../../redux/action";
import CartComponents from "./CartComponents";
import axios from "axios";
import { ApplicationJsonConfigHeader, BaseUrl } from "../../Api/axios.config";

const getOrderURL: string = `${BaseUrl}/cart/checkout`;

// type cartDetailsType = {
//   title: string;
//   price: number;
// };

const CartScreen = (props: any) => {
  const cartAPIService = new CartAPIService();
  const [cartCourses, setCartCourses] = useState<[]>([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [cartOrderId, setcartOrderId] = useState("");

  useEffect(() => {
    cartAPIService
      .fetchCourseInCartList()
      .then((data) => {
        if (data.data?.cart && data.data.totalAmount) {
          const { cart, totalAmount }: fetchCardDataRes = data.data;
          cart ?? setCartCourses(cart);
          totalAmount ?? setTotalAmount(totalAmount);
          props.updateCoursesInCart({ cart, totalAmount });
        } else {
        }
      })
      .catch((err) => {
        console.error("Failed " + err.statusCode + " ~ " + err.errorMsg);
      });
  }, []);

  useEffect(() => {
    const { cart, totalAmount } = props.cartData;
    setCartCourses(cart);
    setTotalAmount(totalAmount);
  }, [props.cartData]);

  async function getOrderDetails() {
    const token: string | null = localStorage.getItem("token");
    const response = await axios
      .post(
        getOrderURL,
        { cart: cartCourses },
        {
          headers: {
            ...ApplicationJsonConfigHeader,
            authorization: `Bearer ${token}`,
          },
          responseType: "json",
        }
      )
      .then((response) => {
        const cartOrderId = response.data.orderId;
        if (cartOrderId) {
          localStorage.setItem("orderId", cartOrderId);
        }
        console.log("orderid", cartOrderId);
        setcartOrderId(cartOrderId);
      })
      .catch((error) => {
        console.log("carttocheckout", error);
      });
  }

  const CartScreenbody = () => {
    return (
      <div style={{ height: "100%" }}>
        <div className='text-lp_blue bg-white text-2xl font-Lato font-bold -mt-10 ml-5'>
          Your Cart
        </div>
        {cartCourses.length > 0 && (
          <>
            <div>
              {cartCourses &&
                cartCourses.map(
                  (
                    rec: {
                      title: string;
                      price: number;
                      courseId: string;
                      image: string;
                    },
                    index: number
                  ) => {
                    return (
                      <CartComponents
                        key={index}
                        courseId={rec.courseId}
                        title={rec.title}
                        price={rec.price}
                        image={rec.image}
                      />
                    );
                  }
                )}
            </div>
            <div className=' bg-gray-500 border-solid h-px w-50% ml-5 mt-4 mb-4'></div>
            <div className='text-lp_blue text-xl font-Lato w-full'>
              <div
                className={`mb-6`}
                style={{ display: "grid", gridTemplateColumns: "85% 15%" }}
              >
                <h4 className={`ml-auto`}>Total</h4>
                <h4 className={`font-semibold ml-4`}>Rs {totalAmount}</h4>
              </div>
            </div>
            <div className={`flex flex-col float-right mr-10`}>
              <Link
                to={{
                  pathname: "/checkout",
                  state: cartOrderId,
                }}
              >
                <button
                  className='h-10 w-40 bg-lp_blue text-white text-xl  rounded-md font-Lato font-bold place-self-end  '
                  type='button'
                  onClick={getOrderDetails}
                >
                  CHECKOUT
                </button>
              </Link>
            </div>
          </>
        )}
        {cartCourses.length === 0 && (
          <NoCourse title='No Courses in Cart' icon='school' />
        )}
      </div>
    );
  };

  const SubMenu = () => {
    return <></>;
  };

  const menu = <Menu selectedItem={``} />;

  return (
    <Template menu={menu} body={<CartScreenbody />} submenu={<SubMenu />} />
  );
};

const mapStateToProps = (state: any) => {
  return {
    cartData: state && state.myCartReducer,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateCoursesInCart: (data: any) => {
      dispatch(CartAction(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CartScreen);
