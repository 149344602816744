const ButtonCourse = ({ label }: { label: string }) => {
  return (
    <>
      <div className={`text-xl mt-3`}>
        <button
          className={`rounded-lg h-6  `}
          style={{ backgroundColor: "#0B4089", borderRadius: "3px" }}
        >
          <label
            className={`text-white text-xs ml-3 mr-3  flex justify-center cursor-pointer`}
          >
            {label}
          </label>
        </button>
      </div>
    </>
  );
};

export default ButtonCourse;
