import { useEffect, useState } from "react";
import ButtonReuse from "../../Components/Common/ButtonReuse";
import { ErrorMsgToaster } from "../../Components/Utils/Toaster";
import ThemeContext from "../../Context/ThemeContext";
import QuizMarks from "./QuizScore";
import { getStudentReport } from "./StudentReport";

const StudentDetail = ({ studentData, setStudentData }: any) => {
  const [studentReport, setStudentReport] = useState<any>();
  useEffect(() => {
    getStudentReport(studentData).then(({ data, errorMsg }: any) => {
      if (data) setStudentReport(data);
      else if (errorMsg) ErrorMsgToaster(errorMsg);
    });
  }, []);
  return (
    <ThemeContext.Consumer>
      {(context) => {
        return (
          <div className={`flex flex-col text-black`}>
            <div
              className="flex flex-col  text-lg md:text-2xl font-bold font-Lato -mt-5 "
              style={{ color: context.theme!.title }}
            >
              <p className="  float-left">
                Student Report
                <ButtonReuse
                  value={"VIEW ALL STUDENTS"}
                  colorClass={"bg-yellow-200"}
                  heightClass={"h-7"}
                  widthClass={"w-40"}
                  sizeClass={"text-xs md:text-sm "}
                  positionClass={"float-right"}
                  tcolorClass={"text-black"}
                  onClick={() => setStudentData()}
                />
              </p>
            </div>
            <br></br>

            <div
              className=" font-bold text-lg md:text-xl"
              style={{ color: context.theme!.title }}
            >
              {studentData.name}
            </div>
            <br></br>

            <div
              className=" font-bold text-lg md:text-xl"
              style={{ color: context.theme!.title }}
            >
              {studentData.courseName}
            </div>
            <br></br>

            <div className="grid grid-cols-2 gap-5">
              <div>Enrolled on {studentReport?.enrolledOn}</div>
              <div></div>
              <div
                className="flex flex-col gap-5 items-center justify-center h-48 w-full rounded-md    "
                style={{
                  backgroundColor: context.theme!.dashboardStudent.tile1,
                }}
              >
                <div className="text-black text-base text-center md:text-xl  ">
                  Lessons completed
                </div>
                <div className="text-black  text-5xl sm:text-6xl md:text-7xl lg:text-8xl font-bold font-Lato ">
                  {studentReport?.completedLessons} /{" "}
                  {studentReport?.totalLessons}
                </div>
              </div>
              <div
                className="flex flex-col  items-center justify-center gap-5 h-48 w-full rounded-md   "
                style={{
                  backgroundColor: context.theme!.dashboardStudent.tile3,
                }}
              >
                <div className="text-black text-base md:text-xl text-center ">
                  Quiz completed
                </div>
                <div className="text-black  text-5xl sm:text-6xl md:text-7xl lg:text-8xl font-bold font-Lato  ">
                  {studentReport?.totalQuizzes} / {studentReport?.totalQuizzes}
                </div>
              </div>
            </div>
            <br></br>
            <div className="text-xl">Quiz Scores</div>
            <br></br>
            {studentReport?.quizScores?.map((item: any) => (
              <div key={item?.quizId}>
                <QuizMarks
                  value={item?.title}
                  valueClass={item?.display}
                ></QuizMarks>
                <br></br>
              </div>
            ))}

            <div></div>
          </div>
        );
      }}
    </ThemeContext.Consumer>
  );
};

export default StudentDetail;
