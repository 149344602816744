import { Dispatch, SetStateAction } from "react";

type props = {
  value: string;
  valueClass: string;
  bgClass: string;
  id: string;
  deleteItem: any;
  keyId: string;
  setShowViewComp: Dispatch<SetStateAction<boolean>>;
  setParamType: Dispatch<SetStateAction<string>>;
  setParamKeyId: Dispatch<SetStateAction<string>>;
  subCType: string;
};
const AddCourse = ({
  value,
  valueClass,
  bgClass,
  id,
  deleteItem,
  keyId,
  setParamKeyId,
  setParamType,
  setShowViewComp,
  subCType,
}: props) => {
  return (
    <>
      <div
        className={`flex h-10 border-solid border-gray-400 border rounded-md ml-6 mr-3 mt-3 font-Lato ${bgClass}`}
      >
        <div className='pt-1 pl-3 text-base w-full truncate  float-left'>
          <span title={value}>{value}</span>
        </div>
        <span
          className='material-icons-outlined my-2 cursor-pointer'
          onClick={() => {
            setShowViewComp(true);
            setParamKeyId(keyId);
            setParamType(subCType);
          }}
        >
          visibility
        </span>
        <span
          className='material-icons-outlined m-2 cursor-pointer'
          onClick={() => deleteItem(id)}
        >
          close
        </span>
      </div>
    </>
  );
};
export default AddCourse;
