import React, { useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { confirmAlert } from "react-confirm-alert"; // Import
import Template from "../../Components/Template/Template";
// import InputProfile from "../../Components/Common/InputProfile";
import ButtonReuse from "../../Components/Common/ButtonReuse";
import SubmenuTrainer from "../../Components/Common/SubMenuTrainer";
import MenuTrainer from "../../Components/Template/MenuTrainer";
import Required from "../../Components/Common/Required";
import AddAnswer from "./AddAnswer";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import QuestionsAPIService from "../../Api/Questions/QuestionsAPIService";
import {
  ErrorMsgToaster,
  SuccessMsgToaster,
} from "../../Components/Utils/Toaster";
import Routes from "../../Routes/Routes";
import { QuestionDetailsResType } from "../../Utils/types";
import ThemeContext from "../../Context/ThemeContext";

type IFormInput = {
  question: string;
  description: string;
  answerType: string;
  correctAnswerScore: string;
  answerHours: any;
  answerMinutes: any;
};

type AnswerListType = {
  key: number;
  answer: string;
};

type ImageAnswerListType = {
  key: any;
  imageAnswer: any;
  isValid: boolean;
  originalName?: string;
  file?: string;
};

const NewQuestionsbody = ({
  questionMode,
  questionId,
}: {
  questionMode: string;
  questionId: string;
}) => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm<IFormInput>();
  const history = useHistory();
  const [mode, setMode] = useState("new");
  const [answerType, setAnswerType] = useState<string>("text");
  const [addCount, setAddCount] = useState<number>(0);
  const [countType, setCountType] = useState<string>("add");
  const [scoreForAnswer, setScoreForAnswer] = useState<number>();
  const [answerHours, setAnswerHours] = useState<any>();
  const [answerMinutes, setAnswerMinutes] = useState<any>();
  const [isTimeFormatErr, setIsTimeFormatErr] = useState<boolean>(false);

  const [correctAnswer, setCorrectAnswer] = useState<number>(1);
  const [answersList, setAnswersList] = useState<Array<AnswerListType>>([]);
  const [imageAnswerList, setImageAnswerList] = useState<
    Array<ImageAnswerListType>
  >([]);
  const [isAnswerErr, setIsAnswerErr] = useState<boolean>(false);
  const [editDelOptions, setEditDelOptions] = useState<number[]>([]);

  const [editQuestionData, setEditQuestionData] =
    useState<QuestionDetailsResType>({
      question: "",
      correctAnswerScore: 0,
      correctAnswer: "",
      answers: [],
      description: "",
      answerType: "",
      timeToAnswer: "",
    });

  useEffect(() => {
    const questionsAPI = new QuestionsAPIService();
    setMode(questionMode);
    if (questionMode === "edit" && questionId) {
      questionsAPI
        .fetchQuestionDetail(questionId)
        .then((resData) => {
          if (resData?.errorMsg) {
            ErrorMsgToaster(resData.errorMsg);
          } else {
            if (resData?.data) {
              setEditQuestionData(resData.data);
              setAnswerType(resData.data.answerType);
              setTimeout(() => {
                window.scrollTo({ top: 0, behavior: "smooth" });
              }, 5000);
              console.log("<3<3");
            }
          }
        })
        .catch((err) => console.log(err));
    }
  }, []);

  // edit questions effect
  useEffect(() => {
    if (editQuestionData) {
      const {
        question,
        description,
        answerType,
        correctAnswerScore,
        timeToAnswer,
        answers,
        correctAnswer,
      } = editQuestionData;
      question && setValue("question", question);
      description && setValue("description", description);
      answerType && setValue("answerType", answerType);
      answerType && setAnswerType(answerType);
      correctAnswerScore &&
        setValue("correctAnswerScore", String(correctAnswerScore));
      setAddCount(0);
      setCountType("add");
      timeToAnswer && handleEditTimeToAnswer(timeToAnswer);
      handleEditCorrectAnswer({ answers, answerType, correctAnswer });
    }
  }, [editQuestionData]);

  const handleEditCorrectAnswer = ({
    answers,
    answerType,
    correctAnswer,
  }: {
    answers: any;
    answerType: any;
    correctAnswer: any;
  }) => {
    if (answerType && answers && correctAnswer) {
      if (answerType === "text" && typeof answers === `string`) {
        let ansArr: any[] = JSON.parse(answers ? answers : `[]`);
        setAddCount(ansArr.length);
        ansArr = ansArr.map((rec, index) => ({ key: index + 1, answer: rec }));
        const corrAns = ansArr.filter((rec) => rec.answer === correctAnswer)[0]
          .key;
        setCorrectAnswer(corrAns);
        setAnswersList(ansArr);
      } else if (answerType === "image" && typeof answers === `object`) {
        setAddCount(answers.length);
        let ansArr: any[] = answers.map(
          (rec: ImageAnswerListType, index: number) => ({
            key: index + 1,
            questionKey: rec.key,
            imageAnswer: rec.file,
            isValid: true,
            originalName: rec.originalName,
          })
        );
        const corrAns = ansArr.filter(
          (rec) => rec.originalName === correctAnswer
        )[0];
        setImageAnswerList(ansArr);
        setCorrectAnswer(corrAns ? corrAns.key : 1);
      }
    }
  };

  const handleEditTimeToAnswer = (timeToAnswer: any) => {
    if (timeToAnswer) {
      const hrs = timeToAnswer.split(":")[0];
      const mins = timeToAnswer.split(":")[1];
      setAnswerHours(hrs);
      setAnswerMinutes(mins);
      setValue("answerHours", hrs);
      setValue("answerMinutes", mins);
    }
  };

  const handleAddAnswer = () => {
    setAddCount(addCount + 1);
    setCountType("add");
  };

  const handleAnswerTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const type = e.target.value;
    let message;
    if (answerType === "image" && type === "text") {
      message = "All previously added images will be removed";
    }
    setAnswerType(type);
    confirmAlert({
      title: "Are you sure to change Answer Type ?",
      message: message
        ? message
        : "All previously typed answers will be removed",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            setAddCount(0);
          },
        },
        {
          label: "No",
          onClick: () => {
            return;
          },
        },
      ],
    });
  };

  const resetFields = () => {
    setMode("new");
    setValue("question", "", { shouldValidate: false });
    setValue("description", "", { shouldValidate: false });
    setAnswerType("text");
    setValue("correctAnswerScore", "", { shouldValidate: false });
    setValue("answerHours", "", { shouldValidate: false });
    setValue("answerMinutes", "", { shouldValidate: false });
    setAnswerHours("");
    setAnswerMinutes("");
    setScoreForAnswer(0);
    setAnswersList([]);
    setImageAnswerList([]);
    setAddCount(0);
    setCorrectAnswer(1);
    setCountType("add");
    setIsAnswerErr(false);
    setIsTimeFormatErr(false);
  };

  const handleFormCancel = () => {
    resetFields();
  };

  const onSubmit: SubmitHandler<IFormInput> = (data: IFormInput) => {
    const {
      question,
      description,
      answerType,
      correctAnswerScore,
      answerHours,
      answerMinutes,
    } = data;
    if (
      question &&
      answerType &&
      correctAnswerScore &&
      answerHours &&
      answerMinutes
    ) {
      const timeToAnswer = `${answerHours}:${answerMinutes}`;
      if (timeToAnswer === "00:00") {
        setIsTimeFormatErr(true);
      }
      const formData = new FormData();
      formData.append("question", question);
      formData.append("description", description);
      formData.append("answerType", answerType);
      formData.append("correctAnswerScore", correctAnswerScore);
      formData.append("timeToAnswer", timeToAnswer);

      if (answerType === "text") {
        setIsAnswerErr(answersList.length === 0 ? true : false);
        if (answersList && answersList.length > 0) {
          const isEmpty = answersList.some((rec) => rec.answer === "");
          if (isEmpty) {
            setIsAnswerErr(true);
            return;
          } else {
            setIsAnswerErr(false);
          }
          const ansList = answersList.map((rec) => rec.answer);
          formData.append("answers", JSON.stringify(ansList));
          const cAns = answersList.filter((rec) => rec.key === correctAnswer)[0]
            .answer;
          formData.append("correctAnswer", cAns);
          if (mode === "edit") {
            answersList.forEach((rec) => {
              formData.append("options", String(rec.key));
            });
          }
        }
      } else if (answerType === "image") {
        setIsAnswerErr(imageAnswerList.length === 0 ? true : false);
        if (imageAnswerList && imageAnswerList.length > 0) {
          if (mode === "new") {
            const ansList = imageAnswerList.map((rec) => rec.imageAnswer);
            const cAns = imageAnswerList.filter(
              (rec) => rec.key === correctAnswer
            )[0].imageAnswer;
            ansList.forEach((imgFile) => formData.append("answers", imgFile));
            formData.append("correctAnswer", cAns.name);
          } else if (mode === "edit") {
            const newImgList = imageAnswerList.filter(
              ({ imageAnswer }) => typeof imageAnswer !== "string"
            );
            if (newImgList && newImgList.length > 0) {
              newImgList.forEach((imgFile) =>
                formData.append("answers", imgFile.imageAnswer)
              );
              // if (newImgList.length && !editDelOptions.length) {
              //   newImgList.forEach((rec) => {
              //     formData.append("options", String(rec?.key));
              //   });
              // } else {
              editDelOptions.forEach((rec) => {
                formData.append("options", String(rec));
              });
              // }
            } else {
              formData.append("answers", "");
              formData.append("options", "");
            }
            const filterCAns = imageAnswerList.filter(
              (rec) => rec.key === correctAnswer
            )[0];
            const cAns =
              typeof filterCAns.imageAnswer === "string"
                ? filterCAns.originalName
                : filterCAns.imageAnswer.name;
            formData.append("correctAnswer", cAns);
          }
        }
      }

      if (isAnswerErr === false && isTimeFormatErr == false) {
        if (answerType === "text" && answersList.length <= 0) {
          return;
        } else if (answerType === "image" && imageAnswerList.length <= 0) {
          return;
        }
        if (mode === "new") {
          saveNewQuestionData(formData);
        } else if (mode === "edit") {
          saveEditQuestionData(formData);
        }
      }
    }
  };

  const saveNewQuestionData = (formData: any) => {
    const questionsAPI = new QuestionsAPIService();
    questionsAPI
      .addNewQuestions(formData)
      .then(({ data, errorMsg }) => {
        if (data && !data?.error) {
          SuccessMsgToaster(data);
          resetFields();
          history.push(Routes.questionsTrainer);
        } else if (errorMsg || data?.error) {
          ErrorMsgToaster(data ? data : errorMsg);
        }
      })
      .catch((err) => console.log(err));
  };

  const saveEditQuestionData = (formData: any) => {
    const questionsAPI = new QuestionsAPIService();
    questionsAPI
      .editQuestions(formData, questionId)
      .then(({ data, errorMsg }) => {
        if (data) {
          SuccessMsgToaster(data);
          resetFields();
          history.push(Routes.questionsTrainer);
        } else if (errorMsg) {
          ErrorMsgToaster(errorMsg);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <ThemeContext.Consumer>
      {(context) => {
        return (
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={`flex flex-col text-black`}>
              <div
                className="flex flex-col  text-2xl font-bold font-Lato "
                style={{ color: context.theme!.title }}
              >
                <p className="  float-left">
                  {mode === "new"
                    ? `Add New Question`
                    : `Edit Question - ${editQuestionData.question}`}
                  <Link to="/trainer/questions">
                    <ButtonReuse
                      value={"VIEW ALL QUESTIONS"}
                      colorClass={"bg-yellow-200"}
                      heightClass={"h-8"}
                      widthClass={"w-44"}
                      sizeClass={"text-sm"}
                      positionClass={"float-right"}
                      tcolorClass={"text-black"}
                    ></ButtonReuse>
                  </Link>
                </p>
              </div>
              <div className="flex flex-col gap-5 mt-4">
                <div>
                  <h4 className={`text-sm font-lato mt-2`}>
                    <Required />
                    Question
                  </h4>
                  <input
                    {...register("question", { required: true })}
                    className={`w-full h-10 p-2 border-gray-400 border rounded-md mt-2 font-lato`}
                    type="text"
                  />
                  {errors.question?.type === "required" && (
                    <p className={`text-red-500`}>Question is Required</p>
                  )}
                </div>
                <div>
                  <label>Description (Optional)</label>
                  <textarea
                    {...register("description")}
                    className={`h-48 w-full text-md p-3 border-gray-400 border rounded-md font-lato mt-1`}
                  ></textarea>
                </div>
              </div>
              <div className="grid  grid-cols-1 ml-3 sm:grid-cols-2 lg:gap-3 gap-1 mt-3">
                <div>
                  <h4 className={`text-sm font-lato mt-2`}>
                    <Required />
                    Answer Type
                  </h4>
                  <select
                    {...register("answerType")}
                    value={answerType}
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                      handleAnswerTypeChange(e);
                    }}
                    className={`h-10 w-full text-sm p-2 border-gray-400 border rounded-md mt-2 font-lato `}
                  >
                    <option value="text">TEXT</option>
                    <option value="image">IMAGE</option>
                  </select>
                </div>
                <div>
                  <h4 className={`text-sm font-lato mt-2`}>
                    <Required />
                    Score for correct answer
                  </h4>
                  <input
                    {...register("correctAnswerScore", {
                      required: true,
                      pattern: {
                        value: /^[1-9].*$/gi,
                        message: "Minimum score cannot be zero",
                      },
                    })}
                    min={0}
                    className={`w-full h-10 p-2 border-gray-400 border rounded-md mt-2 font-lato`}
                    type="number"
                    value={scoreForAnswer}
                    pattern={`[0-9]{3}`}
                    onChange={(e) =>
                      /^[.]?[0-9]{4}$/.test(e.target.value)
                        ? e.preventDefault()
                        : setScoreForAnswer(Number(e.target.value))
                    }
                  />
                  {errors.correctAnswerScore?.type === "required" && (
                    <p className={`text-red-500`}>Score is Required</p>
                  )}
                  {errors.correctAnswerScore?.type === "pattern" && (
                    <p className={`text-red-500`}>
                      Minimum score cannot be zero
                    </p>
                  )}
                </div>
                <div>
                  <h4 className={`text-sm font-lato mt-2`}>
                    <Required />
                    Time to answer (HH:MM)
                  </h4>
                  <div className={`grid grid-cols-2 gap-5`}>
                    <div className={`flex flex-col`}>
                      <input
                        {...register("answerHours", {
                          required: true,
                        })}
                        className={`w-full h-10 p-2 border-gray-400 border rounded-md mt-2 font-lato`}
                        type="number"
                        min={0}
                        max={12}
                        maxLength={2}
                        value={answerHours}
                        onChange={(e) =>
                          e.target.value.length <= 2 &&
                          setAnswerHours(e.target.value)
                        }
                        onInput={(e) => {
                          return (
                            e.currentTarget.validity.valid ||
                            (e.currentTarget.value = "")
                          );
                        }}
                      />
                      {errors.answerHours?.type === "required" && (
                        <p className={`text-red-500`}>Hour is Required</p>
                      )}
                    </div>
                    <div className={`flex flex-col`}>
                      <input
                        {...register("answerMinutes", {
                          required: true,
                        })}
                        className={`w-full h-10 p-2 border-gray-400 border rounded-md mt-2 font-lato`}
                        type="number"
                        min={0}
                        max={60}
                        maxLength={2}
                        value={answerMinutes}
                        onChange={(e) =>
                          e.target.value.length <= 2 &&
                          setAnswerMinutes(e.target.value)
                        }
                      />
                      {errors.answerMinutes?.type === "required" && (
                        <p className={`text-red-500`}>Minutes is Required</p>
                      )}
                    </div>
                  </div>
                  {isTimeFormatErr && (
                    <p className={`text-red-500`}>Please enter proper time</p>
                  )}
                </div>
              </div>
              {answerType !== "image" && (
                <div>
                  <button
                    type="button"
                    className={`mt-4 float-right h-10 px-4 text-md w-36 ml-4 rounded-md font-Lato font-bold  text-white`}
                    style={{
                      backgroundColor: context.theme!.button.background,
                    }}
                    onClick={() => handleAddAnswer()}
                  >
                    ADD ANSWER
                  </button>
                </div>
              )}
              <div>
                <div
                  className="mt-8 flex flex-col  text-xl font-bold font-Lato "
                  style={{ color: context.theme!.title }}
                >
                  Answer Section - [Maximum of 4 Answers] [Choose Radio button
                  for Correct answer]
                </div>
                {(!addCount || addCount === 0) && answerType === "text" ? (
                  <div
                    className={`my-4 h-28 bg-gray-100 w-full rounded-md flex justify-center items-center`}
                  >
                    No Answers Added
                  </div>
                ) : (
                  <div>
                    <AddAnswer
                      addCount={addCount}
                      setAddCount={setAddCount}
                      answerType={answerType}
                      countType={countType}
                      setCountType={setCountType}
                      correctAnswer={correctAnswer}
                      setCorrectAnswer={setCorrectAnswer}
                      answersList={answersList}
                      imageAnswerList={imageAnswerList}
                      setAnswersList={setAnswersList}
                      setImageAnswerList={setImageAnswerList}
                      mode={mode}
                      editDelOptions={editDelOptions}
                      setEditDelOptions={setEditDelOptions}
                    />
                  </div>
                )}
              </div>
              <div className={`flex float-left`}>
                {isAnswerErr && (
                  <p className={`text-red-500`}>Answers List cannot be Empty</p>
                )}
              </div>
              <div className={`mt-8 flex flex-auto flex-row place-self-end`}>
                <button
                  type="button"
                  onClick={handleFormCancel}
                  className={`h-10 text-xl w-32 bg-gray-500 rounded-md font-Lato font-bold text-white`}
                >
                  RESET
                </button>
                <button
                  type="submit"
                  className={`h-10 text-xl w-28 ml-4 rounded-md font-Lato font-bold 
                   text-white`}
                  style={{ backgroundColor: context.theme!.button.background }}
                >
                  SAVE
                </button>
              </div>
            </div>
          </form>
        );
      }}
    </ThemeContext.Consumer>
  );
};

const SubMenu = () => {
  return (
    <>
      <SubmenuTrainer></SubmenuTrainer>
    </>
  );
};

const NewQuestions = (props: any) => {
  const menu = <MenuTrainer selectedItem={`QUESTION BANK`} />;
  const mode = props.match.params.mode;
  const questionId = props.match.params.questionId;
  return (
    <Template
      menu={menu}
      body={<NewQuestionsbody questionMode={mode} questionId={questionId} />}
      submenu={<SubMenu />}
    />
  );
};

export default NewQuestions;
