import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Assets } from "../../Api/Assets";
import CartAPIService from "../../Api/Cart/CartAPIService";
import {
  ErrorMsgToaster,
  SuccessMsgToaster,
} from "../../Components/Utils/Toaster";
import { CartAction } from "../../redux/action";

// type CourseDetails = {
//   title: string;
//   price: number;
//   courseId: string;
// };

const CartComponents = (props: any) => {
  const cartAPIService = new CartAPIService();
  const { courseId, title, price, image } = props;
  const removeCourseFromCart = async (courseId: string) => {
    const deleteRes = await cartAPIService.removeCourseFromCart(courseId);
    const resData = await cartAPIService.fetchCourseInCartList();
    if (deleteRes.errorMsg !== undefined) {
      ErrorMsgToaster(deleteRes.errorMsg!);
    } else {
      SuccessMsgToaster(deleteRes.data!);
    }
    if (resData.data?.cart) {
      const { cart, totalAmount } = resData.data;
      props.updateCoursesInCart({ cart, totalAmount });
    } else {
      props.updateCoursesInCart({ cart: [], totalAmount: 0 });
    }
  };

  return (
    <div className='flex flex-row gap-5 mt-6'>
      <Link to={`/coursedetail/${courseId}`}>
        <div className='w-32 h-24 border-solid border rounded-md border-gray-500 ml-5'>
          <img src={image} className='object-fill h-full w-full' alt={title} />
        </div>
      </Link>
      <div className='text-lp_blue text-xl font-Lato font-bold w-full'>
        <div
          key={courseId}
          style={{ display: "grid", gridTemplateColumns: "85% 15%" }}
        >
          <Link to={`/coursedetail/${courseId}`}>{title}</Link>
          <div>Rs {price}</div>
        </div>
        <div className='text-red-500 text-sm font-Lato font-normal mt-2'>
          <button
            className={`pl-2 pr-2 pt-1 pb-1 rounded hover:bg-red-300 hover:text-black text-red-500 text-lg`}
            onClick={() => removeCourseFromCart(courseId)}
          >
            Remove
          </button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {};

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateCoursesInCart: (data: any) => {
      dispatch(CartAction(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CartComponents);
