import APIService from "../API";
import { BaseUrl } from "../axios.config";
import { NetworkError, Payload } from "../Error.type";

const fetchUserInfoURL: string = `${BaseUrl}/authentication/getUserDetails`;
const apiService = new APIService();

export type UserInfoResType = {
    data?: any;
    statusCode: number;
};

export class UserInfoAPIResult
    implements Payload<UserInfoResType, NetworkError>
{
    statusCode: number;
    data?: UserInfoResType;
    errorMsg?: NetworkError;

    constructor(
        statusCode: number,
        data?: UserInfoResType,
        errorMsg?: NetworkError
    ) {
        this.statusCode = statusCode;
        this.data = data;
        this.errorMsg = errorMsg;
    }
}

export default class UserInfoAPIService {
    fetchUserInfo = async () => {
        return await apiService.getReqs({
            APIUrl: fetchUserInfoURL,
            payload: {},
            resType: UserInfoAPIResult,
        });
    };
}
