import { UserInfoDetailsType } from "../../Utils/types";
import { UserInfoTypes } from "../types/index";

const { USER_INFO_TYPE } = UserInfoTypes;

const intitalState = {
  firstName: "",
  lastName: "",
  registerType: "",
  setGoal: true,
};

export default function userInfoReducer(
  state: UserInfoDetailsType = intitalState,
  action: { type: string; payload: UserInfoDetailsType }
) {
  const { type, payload } = action;

  switch (type) {
    case USER_INFO_TYPE:
      const { firstName, lastName, registerType, setGoal } = payload;
      return {
        ...state,
        firstName,
        lastName,
        registerType,
        setGoal,
      };
    default:
      return state;
  }
}
