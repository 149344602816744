import { myCartTypes } from '../types/index';

const { MY_CART_ACTION_TYPE } = myCartTypes;

type CartActionType = {
    cart: [];
    totalAmount: number;
}

const CartAction = ({ cart, totalAmount }: CartActionType) => {
    return {
        type: MY_CART_ACTION_TYPE,
        payload: { cart, totalAmount }
    }
}

export { CartAction };