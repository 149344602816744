import SubmenuItems from "../../Components/Common/SubmenuItems";
import Menu from "../../Components/Template/Menu";
import Template from "../../Components/Template/Template";
import Quizescomponent from "./QuizesComponents";

const Quizes = () => {
  const menu = <Menu selectedItem={``} />;
  return <Template menu={menu} body={<Quizesbody />} submenu={<SubMenu />} />;
};

const Quizesbody = () => {
  return (
    <div className={`flex flex-col text-gray-700`}>
      <div className="text-lp_blue  text-2xl font-Lato font-bold -mt-10 ">
        Course Title
      </div>
      <br></br>
      <div className="text-lp_blue  text-2xl font-Lato font-bold  ">
        Quiz Title
      </div>
      <br></br>
      <div className="flex flex-col text-lp_blue text-base md:text-lg leading-normal  font-bold font-Lato ">
        <p className="  float-left">
          {" "}
          Question 1 of 5
          <span className="float-right text-base md:text-lg mr-4">00:14</span>
        </p>
      </div>
      <br></br>
      <div className="text-black font-Lato font-bold w-full text-base md:text-lg">
        An angular 2 project written in typescript is transpiled to javascript
        during the build process. Which of the following additional features are
        provided to the developer while programming on typescript over
        javascript?
      </div>
      <br></br>
      <div className="text-black font-Lato font-bold w-full text-base md:text-lg">
        Choose the correct answer below:
      </div>
      <br></br>
      <div className="bg-lp_yellow-dash_tail">
        <Quizescomponent></Quizescomponent>
      </div>
      <br></br>
      <div>
        <Quizescomponent></Quizescomponent>
      </div>
      <br></br>
      <div>
        <Quizescomponent></Quizescomponent>
      </div>

      <div className="flex flex-col"></div>
      <br></br>
      <button
        className="h-10 w-32 bg-lp_blue text-white text-xl  rounded-md font-Lato font-bold place-self-end mr-2  "
        type="button"
      >
        SUBMIT
      </button>
      <div></div>
    </div>
  );
};

const SubMenu = () => {
  return (
    <>
      <SubmenuItems></SubmenuItems>
    </>
  );
};
export default Quizes;
